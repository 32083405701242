<template>
  <div>
    <PageHeader>
      <template #button-left>
        <button
          class="my-auto ml-3 mr-1 h-full rounded font-semibold outline-none hover:bg-gray-700 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-1 focus-visible:ring-offset-gray-600"
          data-intercom-target="TemplatePreviewBackButton"
          @click="goBack"
        >
          <ChevronLeftIcon class="h-5 w-5 text-gray-300" />
        </button>
      </template>
      <template #title>
        <div class="flex w-full space-x-3">
          <EditableTitle
            v-if="!loading"
            :editable="!isPublic && !loadingChangeName"
            :content="template ? template.name : ''"
            :class="[!isPublic && !loadingChangeName && 'w-full']"
            @commit="changeTemplateName"
          />
          <div v-else class="my-2 ml-3 h-5 w-60 animate-pulse rounded bg-gray-500 opacity-25" />
          <div class="my-auto flex space-x-2">
            <template v-if="isPublic">
              <SimpleBadge v-if="isPremium" model-value="premium" :icon="StarIcon" theme="sky" class="mx-2" />
              <SimpleBadge v-if="isNewTemplate" :model-value="t('newTemplate')" dot theme="green" />
            </template>
          </div>
        </div>
      </template>
      <template #subtitle>
        <TagsGroup
          :readonly="!templatesUpdate || isPublic"
          :loading="loading"
          :show-tag-colors="!isPublic"
          :tags="tags"
          :loading-tags-options="loadingTags"
          :selected-tags="formattedTags"
          class="my-2 ml-3 min-h-[2rem]"
          @add-tag="addTag"
          @remove-tag="removeTag"
          @create-tag="createTag"
        />
      </template>
      <template #right>
        <div class="flex space-x-3 pr-8">
          <template v-if="isPublic">
            <PermissionsButton
              :permissions="['templates:create']"
              theme="green-lime"
              data-intercom-target="TemplateGalleryPublicUseInNewTemplate"
              @click="useTemplate"
            >
              <template #leading>
                <PencilAltIcon class="h-5 w-5" aria-hidden="true" />
              </template>
              {{ t("useTemplateButton") }}
            </PermissionsButton>
            <!-- <SimpleButton theme="header-primary" @click="createNewCampaign">
              <template #leading>
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </template>
              {{ t("useInNewCampaignButton") }}
            </SimpleButton> -->
          </template>
          <template v-else>
            <PermissionsButton
              :permissions="['templates:update']"
              :disabled="loading || !template"
              theme="header-sky"
              data-intercom-target="TemplateGalleryPrivateEditTemplate"
              @click="useTemplate"
            >
              <template #leading>
                <PencilAltIcon class="h-5 w-5" aria-hidden="true" />
              </template>
              {{ t("editTemplateButton") }}
            </PermissionsButton>
            <DropDownButton
              :disabled="loading || !template"
              :navigation="menuOptions"
              theme="black"
              placement="bottom-end"
              :auto-placements="['bottom-end', 'left']"
              data-intercom-target="TemplateGalleryPrivateMoreOptions"
              @update:selected="HandleMenuOptionSelection"
            />
          </template>
        </div>
      </template>
    </PageHeader>
    <TemplateContentPreview
      v-if="templateId && !loading"
      :template-preview-id="templateId.toString()"
      :tpl-id="templateId"
      :is-public-template="isPublic"
      :preview-format="previewFormat"
      :contact="contactSelected"
      :show-absolute="isPublic"
      :show="{
        showSendTest: !isPublic,
        showShare: !isPublic,
        showPreviewContact: !isPublic,
        showSubject: !isPublic,
        showPreHeader: !isPublic,
        showSender: !isPublic,
        showFormatToggle: true,
        showAudit: false,
      }"
      class="overflow-auto px-8 pt-2"
      @update:preview-format="onTogglePreviewFormat"
      @update:contact="selectContact"
    />
    <ConfirmationModal
      id="deletePrivateTemplateModal"
      v-bind="deleteTemplateAlertModal"
      @accept="deleteTemplate"
      @cancel="closeDeleteTemplateAlertModal"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, computed, onMounted, markRaw } from "vue";

//Components
import PageHeader from "@templates/PageHeader.vue";
import EditableTitle from "@molecules/EditableTitle.vue";
import SimpleBadge from "@atoms/SimpleBadge";
import ConfirmationModal from "@molecules/ConfirmationModal.vue";
import TemplateContentPreview from "@organisms/Templates/TemplatePreview/TemplateContentPreview.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import DropDownButton from "@molecules/DropDownButton";
import type { DataItems, DataItem } from "@molecules/DropDownButton";
import { TagsGroup } from "@organisms/Tags";
import PermissionsButton from "@organisms/Permissions/PermissionsButton";

// Application
import { useTemplatesApp } from "@application";

//Icons
import { ChevronLeftIcon, PencilAltIcon } from "@heroicons/vue/solid";
import { TrashIcon } from "@heroicons/vue/outline";
import ClipboardDocumentListIcon from "@/vue/components/tokens/icons/ClipboardDocumentListIcon.vue";
import { StarIcon } from "@heroicons/vue/solid";

//Utils
import { useI18n } from "vue-i18n";
import { useNotifications } from "@composables/notifications";
import { differenceInDays } from "date-fns";

//API
import { useEmailTemplate } from "@api/modules/templates/templates";
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Store
import { useSessionStore, useAlertStore } from "@store";

//Types
import type { Template } from "@/vue/types/Templates";
import type { previewWidthFormats } from "@organisms/Templates/TemplatePreview/TemplateContentPreview.vue";
import type { Tags, Tag, NewTag } from "@/vue/types/tag";
import type { Contact } from "@/vue/api/modules/contacts/contacts.types";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";

const { t } = useI18n();
const { notify } = useNotifications();
const templatesApp = useTemplatesApp();

const templatesAPI = useEmailTemplate();
const trackingEventsService = useTrackingEvents();
const sessionStore = useSessionStore();
const alertStore = useAlertStore();

const templatesUpdate = sessionStore.hasPermission("templates:update");

//Props
const props = withDefaults(
  defineProps<{
    templateId: string;
    isPublic: boolean;
  }>(),
  {},
);

//Emits
const emit = defineEmits<{
  (e: "select", data: { name: string; id: string }): void;
  (e: "createCampaign"): void;
  (e: "templateDeleted", data: { name: string; id: string }): void;
  (e: "templateDuplicated", data: { name: string; id: string }): void;
  (e: "tagsChanged"): void;
  (e: "close"): void;
}>();

const onTagsChanged = () => {
  emit("tagsChanged");
};

const useTemplate = () => {
  if (!template.value) return;
  emit("select", { name: template.value.name, id: template.value.id });
};
// const createNewCampaign = () => emit("createCampaign");
const goBack = () => emit("close");

// Loading
const loading = ref(true);

// Template
const fetchTemplate = async () => {
  loading.value = true;
  if (!props.isPublic) await fetchTags();

  if (!props.templateId) {
    loading.value = false;
    return;
  }

  if (!props.isPublic) await getTemplate();
  if (props.isPublic) await fetchPublicTemplate();
  loading.value = false;
};

const loadingChangeName = ref(false);
const template = ref<Template>();
const changeTemplateName = async (newName: string) => {
  loadingChangeName.value = true;
  if (!template.value || newName === "") return;
  template.value.name = newName;
  await templatesAPI.saveTemplate({
    tpl_id: template.value.id,
    template: {
      name: newName,
    },
  });
  notify({
    title: t("notifications.saveTemplateName.title"),
    text: t("notifications.saveTemplateName.text"),
    theme: "success",
  });
  loadingChangeName.value = false;
};

const contactSelected = ref<Contact | undefined>();
const selectContact = async (selected: Contact | undefined) => {
  selected && trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_PREVIEW_AS" });
  contactSelected.value = selected;
};
const fetchPublicTemplate = async () => {
  const fetchedTemplate = await templatesAPI.getPublicTemplate({ tpl_id: props.templateId });
  template.value = fetchedTemplate;
};

const getTemplate = async () => {
  const fetchedTemplate = await templatesAPI.getTemplate({ tpl_id: props.templateId });
  template.value = fetchedTemplate;
};

// DeleteTemplate
const deleteTemplateAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteTemplateAlert.confirmationTitle"),
  message: t("deleteTemplateAlert.confirmationMessage"),
  acceptText: t("deleteTemplateAlert.confirmButton"),
  cancelText: t("deleteTemplateAlert.cancelButton"),
  severity: "critical",
});
const closeDeleteTemplateAlertModal = () => {
  deleteTemplateAlertModal.open = false;
};
const openDeleteTemplateAlertModal = () => {
  deleteTemplateAlertModal.open = true;
};

const deleteTemplate = async () => {
  if (props.isPublic || !template.value) return;
  await templatesAPI.deleteTemplate({ tpl_id: template.value.id });
  notify({
    title: t("notifications.deleteTemplate.title"),
    text: t("notifications.deleteTemplate.text"),
    theme: "success",
    timeout: 5000,
  });
  emit("templateDeleted", { name: template.value.name, id: template.value.id });
  goBack();
};

// Duplicate template
const duplicateTemplate = async () => {
  if (props.isPublic || !template.value) return;
  const newTemplate = await templatesAPI.duplicateTemplate({
    tpl_id: template.value.id,
    relation_id: template.value.relation_id,
    relation_type: template.value.relation_type,
  });
  notify({
    title: t("notifications.duplicateTemplate.title"),
    text: t("notifications.duplicateTemplate.text"),
    theme: "success",
    timeout: 5000,
  });
  emit("templateDuplicated", { name: newTemplate.name, id: newTemplate.id });
};

// Tags
const premiumTagID = "premium";
const publishedTag = "tag_clacrfpti001i0b32qoxl7xyb";
const suggested = "tag_clinjqe7l00y40851kjx0kfhx";
const suggestedAr = "tag_clinjry3400yd0b58jggwreaq";
const suggestedBr = "tag_clinjst5f00y90851tpchw7y3";
const suggestedMx = "tag_clinjtsmi00yb0851xkcbh9ue";

const tagsToFilter = computed(() => {
  const tags = [premiumTagID];
  if (props.isPublic) {
    tags.push(publishedTag, suggested, suggestedAr, suggestedBr, suggestedMx);
  }
  return tags;
});

const isPremium = computed<boolean>(() => {
  if (!template.value) return false;
  return template.value.tags.some((template) => template.id === premiumTagID);
});
const formattedTags = computed<Tags>(() => {
  if (!template.value) return [];
  const filtered = template.value.tags.filter((tag) => !tag.id || !tagsToFilter.value.includes(tag.id));
  return filtered.map((tag) => {
    if (!tag.id) return tag;
    return {
      ...tag,
      name: templatesApp.getPublicTagTranslation({ tagId: tag.id }) ?? tag.name,
    };
  });
});

const isNewTemplate = computed(() => {
  if (!template.value || !template.value.created) return false;

  return differenceInDays(new Date(), new Date(template.value.created)) <= 30;
});

const tags = ref<Tags>();
const loadingTags = ref(false);

const addTag = async (tag: Tag) => {
  if (!template.value) return;
  const newTags: Array<Tag> = [...template.value.tags, tag];

  template.value.tags.push(tag);
  const newTemplate = await templatesAPI.saveTemplate({ tpl_id: template.value.id, template: { tags: newTags } });
  template.value = newTemplate;
  onTagsChanged();
};

const removeTag = async (tag: Tag) => {
  if (!template.value) return;
  const tagFound = template.value.tags.findIndex((templateTag) => templateTag.id === tag.id);

  if (tagFound === -1) return;
  template.value.tags.splice(tagFound, 1);

  const newTemplate = await templatesAPI.saveTemplate({
    tpl_id: template.value.id,
    template: { tags: [...template.value.tags] },
  });
  template.value = newTemplate;
  onTagsChanged();
};

const createTag = async (tag: Tag) => {
  if (!template.value) return;
  const newTags: Array<Tag | NewTag> = [...template.value.tags, { color: tag.color, name: tag.name }];

  const newTemplate = await templatesAPI.saveTemplate({ tpl_id: template.value.id, template: { tags: newTags } });
  template.value = newTemplate;

  trackingEventsService.amplitude({
    name: "TPL_GALLERY_TAG_CREATE",
  });

  await fetchTags();

  onTagsChanged();
};

const fetchTags = async () => {
  loadingTags.value = true;
  const fetchedTags = await templatesAPI.getTemplateTags();
  tags.value = fetchedTags;
  loadingTags.value = false;
};

//Preview states
const previewFormat = ref<previewWidthFormats>("desktop");
const onTogglePreviewFormat = (newFormat: previewWidthFormats) => {
  previewFormat.value = newFormat;
};

// Life Cycle
onMounted(async () => {
  await fetchTemplate();
});

//Menu options
enum MenuOptionsKeys {
  // useInNewCampaign,
  duplicateTemplate,
  deleteTemplate,
}
const menuOptions = ref<DataItems>([
  // {
  //   key: MenuOptionsKeys.useInNewCampaign,
  //   icon: MailIcon,
  //   value: t("menuOptions.useInNewCampaign"),
  // },
  {
    key: MenuOptionsKeys.duplicateTemplate,
    icon: markRaw(ClipboardDocumentListIcon),
    value: t("menuOptions.duplicateTemplate"),
  },
  {
    key: MenuOptionsKeys.deleteTemplate,
    icon: TrashIcon,
    theme: "red",
    value: t("menuOptions.deleteTemplate"),
  },
]);

const handlerMenuOptions: Record<MenuOptionsKeys, () => void> = {
  // [MenuOptionsKeys.useInNewCampaign]: createNewCampaign,

  [MenuOptionsKeys.duplicateTemplate]: duplicateTemplate,

  [MenuOptionsKeys.deleteTemplate]: openDeleteTemplateAlertModal,
};
const HandleMenuOptionSelection = (option: DataItem) => {
  if (!templatesUpdate) {
    alertStore.showPermissionDenied(["templates:update"]);
    return;
  }

  handlerMenuOptions[option.key]?.();
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "newTemplate": "Nuevo",
    "editTemplateButton": "Editar plantilla",
    "useTemplateButton": "Usar en nueva plantilla",
    "useInNewCampaignButton": "Usar en nueva campaña",
    "menuOptions": {
      "useInNewCampaign": "Usar en nueva campaña",
      "duplicateTemplate": "Duplicar plantilla",
      "deleteTemplate": "Eliminar plantilla"
    },
    "notifications": {
      "duplicateTemplate": {
        "title": "Plantilla duplicada",
        "text": "La template se duplicó correctamente"
      },
      "deleteTemplate": {
        "title": "Plantilla eliminada",
        "text": "La plantilla se eliminó correctamente"
      },
      "saveTemplateName": {
        "title": "Nombre actualizado",
        "text": "La plantilla fue modificada correctamente"
      }
    },
    "deleteTemplateAlert": {
      "confirmationTitle": "¿Quieres eliminar esta plantilla?",
      "confirmationMessage": "Al hacerlo, eliminas toda la información sobre esta plantilla y no podrás deshacer esta acción.",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    }
  },
  "pt": {
    "newTemplate": "Novo",
    "editTemplateButton": "Editar template",
    "useTemplateButton": "Usar em novo template",
    "useInNewCampaignButton": "Usar en nueva campaña",
    "menuOptions": {
      "useInNewCampaign": "Usar em nova campanha",
      "duplicateTemplate": "Duplicar template",
      "deleteTemplate": "Excluir template"
    },
    "notifications": {
      "duplicateTemplate": {
        "title": "Template duplicado",
        "text": "O template foi duplicado com sucesso"
      },
      "deleteTemplate": {
        "title": "Template excluído",
        "text": "O template foi excluído com sucesso"
      },
      "saveTemplateName": {
        "title": "Nome atualizado",
        "text": "Template alterado com sucesso"
      }
    },
    "deleteTemplateAlert": {
      "confirmationTitle": "Você quer excluir este template?",
      "confirmationMessage": "Ao fazer isso, você exclui todas as informações sobre este template e não poderá desfazer esta ação.",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    }
  }
}
</i18n>
