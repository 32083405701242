<template>
  <div>
    <div class="grid grid-cols-2 gap-6">
      <div data-intercom-target="AutomationsCatalogItemDetails">
        <h3 class="mb-6 mt-0 text-sm leading-5 text-sky-500">
          {{ automation.description }}
        </h3>
        <p class="mb-4 text-sm text-gray-400" v-html="automation.useCase"></p>
        <AlertBox v-if="automation.warning" :theme="automation.warningTheme ? automation.warningTheme : 'info'"
          >{{ automation.warning }}
          <a v-if="automation.warningLink" :href="automation.warningLink" target="_blank" class="underline">{{
            t("seeMore")
          }}</a></AlertBox
        >
        <h3 class="mb-4 text-sm font-medium text-gray-700">{{ t("featuresTitle") }}</h3>
        <div class="mb-6 grid justify-items-start gap-4">
          <IconBullet
            v-if="automation.lifecycle === 'rfm'"
            :icon="HeartIcon"
            :title="t('attributes.rfm')"
            :tooltip="t('attributesTooltip.rfm')"
            color="sky"
          />
          <IconBullet
            v-if="automation.attributes.emails"
            :icon="MailIconOutline"
            :title="t('attributes.emails', automation.attributes.emails)"
            :tooltip="t('attributesTooltip.emails')"
            color="sky"
          />
          <IconBullet
            v-if="automation.attributes.goal"
            :icon="BadgeCheckIcon"
            :title="automation.attributes.goal"
            :tooltip="t('attributesTooltip.goal')"
            color="sky"
          />
          <IconBullet
            v-if="automation.attributes.audience"
            :icon="UserIcon"
            :title="automation.attributes.audience"
            :tooltip="t('attributesTooltip.audience')"
            color="sky"
          />
          <IconBullet
            v-if="automation.attributes.shows"
            :icon="InformationCircleIcon"
            :title="automation.attributes.shows"
            :tooltip="t('attributesTooltip.shows')"
            color="sky"
          />
          <IconBullet
            v-if="automation.attributes.offers"
            :icon="SparklesIcon"
            :title="automation.attributes.offers"
            :tooltip="t('attributesTooltip.offers')"
            color="sky"
          />
          <IconBullet
            v-if="automation.attributes.promotion"
            :icon="GitIconOutline"
            :title="automation.attributes.promotion"
            :tooltip="t('attributesTooltip.promotion')"
            color="sky"
          />
          <IconBullet
            v-if="automation.requires?.length"
            :icon="ViewGridAddIcon"
            :title="t('attributes.worksWith', { requires: requiresAll })"
            :tooltip="t('attributesTooltip.worksWith')"
            color="sky"
          />
        </div>
      </div>

      <div
        class="max-h-[30rem] overflow-y-scroll rounded-md border-2 border-solid border-gray-100 bg-white p-8 py-8"
        data-intercom-target="AutomationsCatalogItemSteps"
      >
        <FeedPanel :items="feedItems" />
      </div>
    </div>

    <div class="mt-5 flex justify-end space-x-4">
      <SimpleButton theme="white" @click="emitBack">
        <template #leading><ChevronLeftIcon class="text-gray-500" /></template>
        {{ t("buttons.back") }}
      </SimpleButton>

      <SimpleButton
        v-if="!brandEditor"
        theme="primary"
        :disabled="restrict"
        :loading="creating"
        data-intercom-target="AutomationsCatalogItemCreate"
        @click="emitNext"
      >
        <template #leading>
          <component :is="restrict ? LockClosedIcon : CheckIcon" />
        </template>
        {{ t("buttons.create") }}
      </SimpleButton>

      <SimpleButton
        v-if="brandEditor"
        theme="primary"
        :disabled="restrict"
        :loading="creating"
        data-intercom-target="AutomationsCatalogBranding"
        @click="emitBrandPreview"
      >
        <template #leading>
          <component :is="restrict ? LockClosedIcon : ChevronRightIcon" />
        </template>
        {{ t("buttons.next") }}
      </SimpleButton>
    </div>
  </div>
</template>

<script setup lang="ts">
// Types
import type { Automation, AutomationStep } from "./catalog/AutomationsCatalog.types";
import type { FeedItem, FeedItems } from "@molecules/FeedPanel.vue";

import { computed } from "vue";
import { useI18n } from "vue-i18n";
import IconBullet from "@atoms/IconBullet.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import FeedPanel from "@molecules/FeedPanel.vue";
import AlertBox from "@atoms/AlertBox.vue";
import {
  LightningBoltIcon,
  FilterIcon,
  ClockIcon,
  GiftIcon,
  BookOpenIcon,
  MailIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  LockClosedIcon,
  CheckIcon,
} from "@heroicons/vue/solid";

import {
  MailIcon as MailIconOutline,
  BadgeCheckIcon,
  GiftIcon as GitIconOutline,
  UserIcon,
  InformationCircleIcon,
  SparklesIcon,
  ViewGridAddIcon,
  HeartIcon,
} from "@heroicons/vue/outline";

const { t } = useI18n();

// Props
const props = defineProps<{
  automation: Automation;
  allowRestricted: boolean;
  creating?: boolean;
  brandEditor: boolean;
}>();

// const restrict = computed(() => !props.allowRestricted && props.automation.restricted);
const restrict = computed(() => false); // Vamos a dejar que peudan crear todos

const requiresAll = computed(() => props.automation.requires?.map((r) => t("integrations." + r)).join(" + "));

const feedIcons = {
  trigger: LightningBoltIcon,
  delay: ClockIcon,
  filter: FilterIcon,
  coupon: GiftIcon,
  transform: BookOpenIcon,
  email: MailIcon,
};

const feedItems: FeedItems = props.automation.steps.map((step: AutomationStep) => {
  return {
    icon: feedIcons[step.type],
    color: "sky",
    title: t("stepTitles." + step.type),
    description: step.detail,
  } as FeedItem;
});

// Emits
const emit = defineEmits<{
  (e: "back"): void;
  (e: "next"): void;
  (e: "brandPreview"): void;
}>();

const emitBack = () => {
  emit("back");
};

const emitNext = () => {
  emit("next");
};

const emitBrandPreview = () => {
  emit("brandPreview");
};
</script>

<i18n lang="json">
{
  "es": {
    "seeMore": "Ver más",
    "featuresTitle": "Características",
    "attributes": {
      "emails": "Envía hasta 1 email por disparo. | Envía hasta {n} emails por disparo.",
      "worksWith": "Funciona con {requires}.",
      "rfm": "Funciona con el ciclo de vida de compra."
    },
    "attributesTooltip": {
      "emails": "¿Cuántos mensajes se enviarán?",
      "goal": "¿Cuál es el objetivo?",
      "audience": "¿A qué audiencia está dirigido?",
      "shows": "¿Qué información contiene el mensaje?",
      "offers": "¿Qué productos dinámicos ofrece?",
      "promotion": "¿Contiene alguna promoción?",
      "worksWith": "¿Qué integraciones son necesarias?",
      "rfm": "Se inicia con los cambios de etapa del ciclo de vida."
    },
    "stepTitles": {
      "coupon": "Cupón",
      "delay": "Espera",
      "email": "Email",
      "filter": "Filtro",
      "transform": "Obtención de datos",
      "trigger": "Disparador"
    },
    "buttons": {
      "back": "Volver",
      "create": "Crear automation",
      "next": "Siguiente"
    }
  },
  "pt": {
    "seeMore": "Ver mais",
    "featuresTitle": "Características",
    "attributes": {
      "emails": "Envia até 1 email por disparo. | Envia até {n} emails por disparo.",
      "worksWith": "Funciona com {requires}.",
      "rfm": "Funciona com o ciclo de vida de compra."
    },
    "attributesTooltip": {
      "emails": "Quantas mensagens serão enviadas?",
      "goal": "Qual é o objetivo?",
      "audience": "A qual público está direcionado?",
      "shows": "Qual informação ou mensagem contém?",
      "offers": "Quais produtos dinâmicos oferece?",
      "promotion": "Contém alguma promoção?",
      "worksWith": "Quais integrações são necessárias?",
      "rfm": "Começa com as mudanças de estágio do ciclo de vida."
    },
    "stepTitles": {
      "coupon": "Cupom",
      "delay": "Espera",
      "email": "Email",
      "filter": "Filtro",
      "transform": "Obtenção de dados",
      "trigger": "Disparador"
    },
    "buttons": {
      "back": "Voltar",
      "create": "Criar automation",
      "next": "Próximo"
    }
  }
}
</i18n>
