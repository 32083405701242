import { useRequests } from "@api/requests";

// Types
import type { CustomFields, OrderCustomFieldsListAPIResponse } from "./automationsOrderCustomFields.types";

export function useAutomationsOrderCustomFields() {
  const request = useRequests();

  async function list(): Promise<CustomFields> {
    const res = await request<OrderCustomFieldsListAPIResponse>({
      url: `/integrations/tiendanube/orders/custom-fields`,
      method: "get",
    });

    return res.data;
  }

  return { list };
}
