<template>
  <div class="grid gap-4">
    <p class="text-sm font-medium text-gray-700">{{ t("title") }}</p>
    <div class="grid grid-cols-2 gap-4">
      <div class="grid gap-2">
        <FormGroup id="body_background" :label="t('options.bodyBackground')" :error="error.body_background">
          <InputColorPicker v-model="localColors.body_background" :palette="limittedColorPalette" readonly force-hex />
        </FormGroup>
        <FormGroup id="button_background" :label="t('options.buttonBackground')" :error="error.button_background">
          <InputColorPicker
            v-model="localColors.button_background"
            :palette="limittedColorPalette"
            readonly
            force-hex
          />
        </FormGroup>
        <FormGroup id="text_link" :label="t('options.textLink')" :error="error.text_link">
          <InputColorPicker v-model="localColors.text_link" :palette="limittedColorPalette" readonly force-hex />
        </FormGroup>
      </div>
      <div class="grid content-baseline gap-2">
        <FormGroup id="text" :label="t('options.text')" :error="error.text">
          <InputColorPicker v-model="localColors.text" :palette="limittedColorPalette" readonly force-hex />
        </FormGroup>
        <FormGroup id="button_text" :label="t('options.buttonText')" :error="error.button_text">
          <InputColorPicker v-model="localColors.button_text" :palette="limittedColorPalette" readonly force-hex />
        </FormGroup>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import FormGroup from "@molecules/FormGroup.vue";
import InputColorPicker from "@atoms/InputColorPicker.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  colors: {
    body_background: string;
    button_background: string;
    button_text: string;
    text_link: string;
    text: string;
  };
  colorPalette: string[];
  error: {
    body_background: string;
    button_background: string;
    button_text: string;
    text_link: string;
    text: string;
  };
}>();

const emit = defineEmits(["update:colors"]);
const localColors = ref({
  ...props.colors,
});

// Limito a 16 por el componente soporta hasta ese número.
const limittedColorPalette = ref<string[]>(props.colorPalette.slice(0, 16));

watch(
  () => ({ ...localColors.value }),
  (newColors) => {
    emit("update:colors", newColors);
  },
  { deep: true },
);

watch(
  () => props.colors,
  () => {
    if (JSON.stringify(props.colors) !== JSON.stringify(localColors.value)) {
      localColors.value = { ...props.colors };
    }
  },
);
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Colores",
    "options": {
      "bodyBackground": "Fondo del e-mail",
      "buttonBackground": "Botones principales",
      "textLink": "Color de links",
      "text": "Texto principal",
      "buttonText": "Texto del botón",
    },
  },
  "pt": {
    "title": "Cores",
    "options": {
      "bodyBackground": "Fundo do e-mail",
      "buttonBackground": "Botões principais",
      "textLink": "Color de links",
      "text": "Texto principal",
      "buttonText": "Texto do botão",
    },
  },
}
</i18n>
