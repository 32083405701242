import app from "@/app";
import { ListView } from "@/core/magnet";
import { _, objHas } from "@/helpers/utils";
import { useNotificationStore } from "@store/notificationsStore/notifications.store";

// Services
import { useTrackingEvents } from "@composables/trackingevents";
import { useFeaturesStore, useIntegrationsStore } from "@store";

export default ListView.extend({
  module: "activity",
  templates: {
    initial: require("../templates/list.hbs"),
    rows: require("../templates/rows.hbs"),
    empty: require("../templates/empty.hbs"),
  },
  searchWrapper: ["*", "*"],
  fetchTimeout: 30 * 1000,
  paginate: true,
  nextPaginationMode: false,
  nextPaginationParseUrl: (next, id) => {
    return "contactevents/scroll/" + id;
  },
  query: {
    filter: {
      type: "SENT",
    },
  },
  events: function () {
    return $.extend({}, ListView.prototype.events, {
      "submit [data-form=duplicate]": "duplicate",
      "click [data-action=archive]": "archive",
      "click [data-action=unarchive]": "unarchive",
      "click [data-action=stop]": "stop",
      "click [data-action=open-duplicate-modal]": "openDuplicateModal",
      "click [data-action=open-lists-modal]": "openListsModal",
      "click [data-action=open-interests-modal]": "openInterestsModal",
      "click [data-action=open-share-modal]": "openShareModal",
      "click [data-action=copy-share-url]": "copyShareUrl",
      "click [data-action=open-export-modal]": "openExportModal",
      "submit [data-form=export]": "export",
      "change [data-action=switch-version]": "switchVersion",
    });
  },
  hasLinkId: function () {
    return _.isDefined(this.query.filter) && _.isDefined(this.query.filter.linkId);
  },
  beforeFetchItems: function () {
    if (this.query.filter.type === "CONVERSION") {
      this.nextPaginationMode = true;
      this.templates.rows = require("../templates/rows-conversion.hbs");
    } else {
      this.nextPaginationMode = false;
      this.templates.rows = require("../templates/rows.hbs");
    }

    if (this.hasLinkId() && parseInt(this.query.filter.linkId, 10) === 0) {
      delete this.query.filter.linkId;
    }
    return this;
  },
  beforeRenderPage: function (callback) {
    if (this.hasLinkId() && this.additionalsData.links) {
      this.additionalsData.links.forEach(
        function (item) {
          if (item.id === parseInt(this.query.filter.linkId, 10)) {
            this.additionalsData.selectedLink = item.url;
            item.skipItem = true;
            this.additionalsData.links.push({
              url: window.lang.metrics.allLinks,
              id: 0,
            });
          }
        }.bind(this),
      );
    }

    callback();
  },
  beforeRender: function (callback) {
    const integrationStore = useIntegrationsStore();
    const isEcommerce = integrationStore.ecommerceIntegrations.length > 0;

    // Get list display preference
    this.showConversionsColumn = isEcommerce;

    // Continue with rendering
    callback("isEcommerce", isEcommerce);
  },
  extraData: function () {
    var account = app.session.attributes.account,
      campaign_id = this.collection.campaignId(),
      topOpensUrl,
      showTopOpens;

    // Check features
    var details = app.session.get("details");
    showTopOpens = false;
    if (details && objHas(details, "account", "features", "SHOW_TOPOPENS")) {
      showTopOpens = true;
    }

    if (showTopOpens) {
      topOpensUrl =
        "https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/application-0-ajozk/service/clients/incoming_webhook/topopens" +
        "?batch_id=" +
        account +
        "_bulk_" +
        campaign_id +
        "&account=" +
        account;
    }

    /// códiugo duplicado de campaign model
    const subjects = [],
      options = this.additionalsData.campaign.options;
    const versionName = (num) => `VERSION_${num}`;
    let i = 1;
    while (options[versionName(i)]) {
      subjects.push({
        key: versionName(i).toLowerCase(),
        number: i,
        subject: options[versionName(i)],
      });
      i++;
    }
    ///

    return {
      showTopOpens: showTopOpens,
      topOpensUrl: topOpensUrl,
      splitABSubjects: subjects,
      showConversionsColumn: this.showConversionsColumn,
    };
  },
  afterRenderPage: function () {
    var campaign = this.additionalsData.campaign;

    // Redirect to contents if the campaign is pending
    if (campaign.state === "SCHEDULED" || campaign.state === "PENDING_APPROVAL") {
      app.router.navigateTo(`metrics/${campaign.id}/contents`);
    }

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },
  openDuplicateModal: function () {
    const isEddie = this.isEddieTemplate();

    if (isEddie) {
      const eddieFeatures = this.getEddieFeatures();
      const trackingEventsService = useTrackingEvents();

      trackingEventsService.amplitude({
        name: "APP_EDDIE_DUPLICATE_NOTIFICATION",
        data: {
          source: "campaign",
          id: this.additionalsData.campaign.id,
          feature_unlayer_optional: eddieFeatures.unlayer_optional,
          feature_unlayer_default: eddieFeatures.unlayer_default,
          feature_hide_legacy_templates: eddieFeatures.hide_legacy_templates,
        },
      });
    }

    this.openModal(require("@/modules/metrics/templates/modals/duplicate.hbs"), {
      data: { attributes: { name: this.additionalsData.campaign.name, isEddie: isEddie } },
    });
  },
  archive: function () {
    $.ajax({
      type: "POST",
      url: "campaigns/" + this.additionalsData.campaign.id + "/archive",
      success: function () {
        app.router.navigateTo("campaigns");
      },
    });
  },
  unarchive: function () {
    $.ajax({
      type: "POST",
      url: "campaigns/" + this.additionalsData.campaign.id + "/unarchive",
      success: function () {
        app.router.navigateTo("campaigns");
      },
    });
  },
  stop: function (e) {
    const self = this;
    var button = $(e.currentTarget);

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    $.ajax({
      type: "POST",
      url: "campaigns/" + this.additionalsData.campaign.id + "/stop",
      success: function () {
        self.displayMessage(window.lang.campaigns.sendingStopped);
        app.router.navigateTo("campaigns");
      },
    });
  },
  duplicate: function (e) {
    var form = e.currentTarget,
      data = $(form).serializeObject(),
      modal = this.getModal();
    e.preventDefault();

    // Validate values
    if (!this.validateFormFields(form)) {
      return this;
    }

    if (this.isEddieTemplate()) {
      const trackingEventsService = useTrackingEvents();
      const eddieFeatures = this.getEddieFeatures();

      if (eddieFeatures) {
        trackingEventsService.amplitude({
          name: "APP_EDDIE_DUPLICATE_ACCEPT",
          data: {
            source: "campaign",
            id: this.additionalsData.campaign.id,
            feature_unlayer_optional: eddieFeatures.unlayer_optional,
            feature_unlayer_default: eddieFeatures.unlayer_default,
            feature_hide_legacy_templates: eddieFeatures.hide_legacy_templates,
          },
        });
      }
    }

    // Performs an AJAX call to the action endpoint
    $.ajax({
      type: "POST",
      url: "campaigns/" + this.additionalsData.campaign.id + "/duplicate",
      data: data,
      success: function (response) {
        modal.modal("hide");
        app.router.navigateTo(`campaigns/${response.data.id}`);
      },
    });
  },

  isEddieTemplate: function () {
    const campaigns = this.additionalsData.campaign;
    if (!campaigns) return undefined;
    return !campaigns.options.UNLAYER_TPL_ID;
  },

  getEddieFeatures: function () {
    const featuresStore = useFeaturesStore();
    const features = featuresStore.features;

    if (!features) return undefined;

    const eddieFeatures = {
      unlayer_optional: features.UNLAYER_OPTIONAL === "1" ? true : false,
      unlayer_default: features.UNLAYER_DEFAULT === "1" ? true : false,
      hide_legacy_templates: features.HIDE_LEGACY_TEMPLATES === "1" ? true : false,
    };
    return eddieFeatures;
  },

  openExportModal: function () {
    this.openModal(require("@/modules/metrics/templates/modals/export.hbs"));
  },
  getQueryData: function () {
    var query = this.query,
      data = {};
    if (query !== undefined && query.filter !== undefined) {
      if (query.filter.type !== undefined) {
        data.action = query.filter.type;
      }
      if (query.filter.linkId !== undefined) {
        data.linkId = query.filter.linkId;
      }
      if (query.filter.version) {
        data.version = query.filter.version;
      }
    }
    if (query.q !== undefined) {
      data.query = query.q;
    }

    return data;
  },
  export: function (e) {
    var view = this,
      modal = this.getModal(),
      data = this.getQueryData();
    e.preventDefault();

    // Export via API
    $.ajax({
      type: "POST",
      data: data,
      url: "campaigns/" + this.collection.query.id + "/actions/export",
      success: function () {
        try {
          const notificationStore = useNotificationStore();
          notificationStore?.fetchTasks();
        } catch {
          // No handler
        } finally {
          app.instances.singleton("tasks", "list").render();
          modal.modal("hide");
          view.displayMessage(window.lang.metrics.feedback.massExported);

          view.reload();
        }
      },
    });
  },
  openShareModal: function () {
    return $.get("campaigns/" + this.collection.query.id + "/share").done(
      function (response) {
        this.openModal(require("../templates/modals/share.hbs"), {
          data: this.item,
          link: `${window.location.origin}/${response.data.replace("&", "?").replace("#", "")}`,
        });
      }.bind(this),
    );
  },
  selectShareLinkText: function () {
    document.getElementById("share").select();
  },
  copyShareUrl: function (e) {
    var button = $(e.currentTarget);
    this.selectShareLinkText();
    if (document.execCommand("copy")) {
      button.text(window.lang.done);
    }
  },

  // Opens the modal to assign lists
  openListsModal: function (e) {
    var selector = app.instances.get("lists", "selector"),
      data = this.getQueryData();
    e.preventDefault();
    selector
      .open({
        selectedItems: [],
        selectorType: "checkbox",
        selectorDescription: window.lang.contacts.selectLists,
        createItem: window.lang.lists.create,
      })
      .on(
        "done",
        function () {
          data.lists = selector.options.selectedItems;
          selector.reset().off("done");
          selector.undelegateEvents();
          if (data.lists.length) {
            this.assign(data, window.lang.metrics.assignedToLists);
          }
        }.bind(this),
      );
  },

  // Performs an assign action
  assign: function (data, message) {
    $.ajax({
      type: "POST",
      url: "campaigns/" + this.additionalsData.campaign.id + "/actions/assign",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify(data),
    }).done(
      function () {
        this.displayMessage(message);
        app.instances.singleton("tasks", "list").render();
      }.bind(this),
    );
  },

  // Opens the modal to assign lists
  openInterestsModal: function (e) {
    var selector = app.instances.get("interests", "selector"),
      data = this.getQueryData();
    e.preventDefault();
    selector
      .open({
        selectedItems: [],
        selectorType: "checkbox",
        selectorDescription: window.lang.contacts.selectInterests,
      })
      .on(
        "done",
        function () {
          data.interests = selector.options.selectedItems;
          selector.reset().off("done");
          selector.undelegateEvents();
          if (data.interests.length) {
            this.assign(data, window.lang.metrics.assignedToInterests);
          }
        }.bind(this),
      );
  },

  switchVersion: function (e) {
    const version = e.currentTarget.value;
    if (version) {
      this.urlFilter({ key: "filter.version", value: version.toLowerCase() });
    } else {
      app.router.navigateTo(this.unfilter({ key: "filter.version" }));
    }
  },
});
