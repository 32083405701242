<template>
  <div class="flex justify-between">
    <div>
      <h4 class="!m-0 text-base text-gray-800">{{ t("transferTitle") }}</h4>
      <p class="my-auto text-sm text-gray-500">
        {{ t("noTransferData") }}
      </p>
    </div>
    <SimpleButton v-if="!loading" :loading="loadingCreate" @click="createTransferData" theme="white" class="my-auto">
      <template #leading>
        <CashIcon class="text-gray-500" />
      </template>
      {{ t("generate") }}
    </SimpleButton>
  </div>
</template>

<script setup lang="ts">
import SimpleButton from "@atoms/SimpleButton.vue";
import { CashIcon } from "@heroicons/vue/solid";

import { useI18n } from "vue-i18n";

withDefaults(
  defineProps<{
    loading: boolean;
    loadingCreate: boolean;
  }>(),
  {}
);

const emit = defineEmits<{
  generateCVU: [void];
  "update:loadingCreate": [boolean];
}>();

const { t } = useI18n();

const createTransferData = () => {
  emit("generateCVU");
  emit("update:loadingCreate", true);
};
</script>

<i18n lang="json">
{
  "es": {
    "generate": "Generar CVU",
    "noTransferData": "Para realizar el pago debes generar el código de transferencia para tu cuenta",
    "transferTitle": "Transferencia por CVU"
  },
  "pt": {
    "generate": "Generar CVU",
    "noTransferData": "Para realizar el pago debes generar el código de transferencia para tu cuenta",
    "transferTitle": "Transferencia por CVU"
  }
}
</i18n>
