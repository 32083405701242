// API
import { useRequests } from "@api/requests";

// Types
import type { InvoicesService } from "@application/ports";
import type {
  GetUnpaidInvoicesAPIResponse,
  GetUnpaidInvoicesAPIParams,
  GetInvoicesAPIResponse,
  GetInvoicesAPIParams,
  RemakeTNInvoiceAPIData,
  GetBankDetailsAPIResponse,
  AssignBankDetailsAPIResponse,
} from "./invoices.types";

// Utils
import { ok, err } from "neverthrow";

export const useInvoiceService = (): InvoicesService => {
  const requestAPIV2 = useRequests();

  const invoicesService: InvoicesService = {
    async getInvoices() {
      try {
        const res = await requestAPIV2<GetInvoicesAPIResponse, undefined, GetInvoicesAPIParams>({
          url: `/invoices`,
          method: "get",
          disableNotifyErrors: true,
          urlParams: {
            limit: 100,
          },
        });

        return ok(res.data);
      } catch (e) {
        return err(undefined);
      }
    },
    async getUnpaidInvoices() {
      try {
        const res = await requestAPIV2<GetUnpaidInvoicesAPIResponse, undefined, GetUnpaidInvoicesAPIParams>({
          url: `/invoices`,
          method: "get",
          disableNotifyErrors: true,
          urlParams: {
            limit: 100,
            "filters.paid": 0,
          },
        });

        return ok(res.data);
      } catch (e) {
        return err(undefined);
      }
    },
    async remakeTNInvoice(params) {
      try {
        await requestAPIV2<undefined, RemakeTNInvoiceAPIData>({
          url: `/invoicepayments/invoices/${params.invoiceId}/remaketn`,
          method: "post",
          data: {
            amount: params.amount,
            month_period: params.month_period,
            mark_as_paid: params.mark_as_paid,
          },
        });

        return ok(undefined);
      } catch (e) {
        return err(undefined);
      }
    },
    async getBankDetails() {
      try {
        const res = await requestAPIV2<GetBankDetailsAPIResponse>({
          url: `/invoicepayments/bankdetails`,
          method: "get",
        });

        return ok(res.data);
      } catch (e) {
        return err(undefined);
      }
    },
    async assignBankDetails() {
      try {
        const res = await requestAPIV2<AssignBankDetailsAPIResponse>({
          url: `/invoicepayments/bankdetails/assign`,
          method: "get",
        });

        return ok(res.data);
      } catch (e) {
        return err(undefined);
      }
    },
  };

  return invoicesService;
};
