<template>
  <div>
    <label for="options" class="">{{ t("label") }}</label>
    <select name="paymentPendingFilterCondition" id="options" v-model="selectedOption" class="form-control">
      <option disabled value="">{{ t("options.select") }}</option>
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.text }}
      </option>
    </select>
    <p class="mt-2">{{ t("description") }}</p>
  </div>
</template>

<script lang="ts" setup>

import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

// Props
const props = defineProps<{
  country?: string;
  selected?: string;
}>();

// States
const { t } = useI18n();

// Opción seleccionada
const selectedOption = ref("");

// Opciones del selector
const options = computed(() => {
  const baseOptions = [
    { value: "mercado-pago-offline", text: t("options.mercadoPagoOffline") },
    { value: "offline", text: t("options.offline") },
  ];

  if (props.country === "ar") {
    baseOptions.unshift({ value: "pago-nube-transfer", text: t("options.pagoNubeTransfer") }); // Pago Nube case 🇦🇷
  }

  if (props.country === "br") {
    baseOptions.push({ value: "pix", text: t("options.pix") }); // Pix case 🇧🇷
  }

  baseOptions.push({ value: "all", text: t("options.all") }); // All case

  return baseOptions;
});

watch(
  () => props.selected,
  (newValue) => {
    if (newValue && options.value.some((option) => option.value === newValue)) {
      selectedOption.value = newValue;
    } else {
      selectedOption.value = "";
    }
  },
  { immediate: true },
);
</script>

<i18n lang="jsonc">
{
  "es": {
    "label": "El medio de pago es",
    "description": "Selecciona una opción para enviar el email.",
    "options": {
      "select": "Selecciona un medio de pago",
      "pagoNubeTransfer": "Transferencia con Pago Nube",
      "mercadoPagoOffline": "Mercado Pago (a convenir)",
      "offline": "Efectivo o Transferencia",
      "pix": "Pix",
      "all": "Continuar con cualquier medio de pago"
    }
  },
  "pt": {
    "label": "A forma de pagamento é",
    "description": "Selecione uma opção para enviar o email.",
    "options": {
      "select": "Selecione um meio de pagamento.",
      "pagoNubeTransfer": "Tranferencia com Nuvem Pago",
      "mercadoPagoOffline": "Mercado Pago (a combinar)",
      "offline": "Dinheiro ou transferência",
      "pix": "Pix",
      "all": "Continuar com qualquer meio de pagamento"
    }
  }
}
</i18n>
