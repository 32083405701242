var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"selectLifecycleStage")), depth0))
    + "</p>\n                        <div class=\"mb-6\">\n                            <div class=\"grid gap-4 grid-cols-4 text-xs\">\n                                <div class=\"grid gap-4\">\n                                    <div data-select-option=\"lifecycles.rfm.champion.in\" class=\"flex items-center flex-wrap justify-between overflow-hidden rounded-lg px-4 py-4 text-teal-700 bg-teal-300 cursor-pointer hover:ring-2 hover:ring-teal-300 ring-offset-2\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"lifecycles"))) && lookupProperty(stack1,"champion")), depth0))
    + "</div>\n                                    <div data-select-option=\"lifecycles.rfm.loyalist.in\" class=\"flex items-center flex-wrap justify-between overflow-hidden rounded-lg px-4 py-4 text-teal-600 bg-teal-200 cursor-pointer hover:ring-2 hover:ring-teal-200 ring-offset-2\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"lifecycles"))) && lookupProperty(stack1,"loyalist")), depth0))
    + "</div>\n                                    <div data-select-option=\"lifecycles.rfm.recent.in\" class=\"flex items-center flex-wrap justify-between overflow-hidden rounded-lg px-4 py-4 text-teal-500 bg-teal-100 cursor-pointer hover:ring-2 hover:ring-teal-100 ring-offset-2\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"lifecycles"))) && lookupProperty(stack1,"recent")), depth0))
    + "</div>\n                                </div>\n                                <div class=\"col-span-2 grid grid-cols-2 gap-4 \">\n                                    <div data-select-option=\"lifecycles.rfm.highpotential.in\" class=\"flex items-center flex-wrap justify-between overflow-hidden rounded-lg px-4 py-4 text-yellow-600 bg-yellow-100 cursor-pointer hover:ring-2 hover:ring-yellow-100 ring-offset-2\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"lifecycles"))) && lookupProperty(stack1,"highpotential")), depth0))
    + "</div>\n                                    <div data-select-option=\"lifecycles.rfm.cantlose.in\" class=\"flex items-center flex-wrap justify-between overflow-hidden rounded-lg px-4 py-4 text-orange-600 bg-orange-100 cursor-pointer hover:ring-2 hover:ring-orange-100 ring-offset-2\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"lifecycles"))) && lookupProperty(stack1,"cantlose")), depth0))
    + "</div>\n                                    <div data-select-option=\"lifecycles.rfm.neednutring.in\" class=\"flex items-center flex-wrap justify-between overflow-hidden rounded-lg px-4 py-4 text-yellow-500 bg-yellow-50 cursor-pointer hover:ring-2 hover:ring-yellow-50 ring-offset-2\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"lifecycles"))) && lookupProperty(stack1,"neednutring")), depth0))
    + "</div>\n                                    <div data-select-option=\"lifecycles.rfm.atrisk.in\" class=\"flex items-center flex-wrap justify-between overflow-hidden rounded-lg px-4 py-4 text-orange-500 bg-orange-50 cursor-pointer hover:ring-2 hover:ring-orange-50 ring-offset-2\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"lifecycles"))) && lookupProperty(stack1,"atrisk")), depth0))
    + "</div>\n                                </div>\n                                <div class=\"grid gap-4\">\n                                    <div data-select-option=\"lifecycles.rfm.abouttolose.in\" class=\"flex items-center flex-wrap justify-between overflow-hidden rounded-lg px-4 py-4 text-red-500 bg-red-100 cursor-pointer hover:ring-2 hover:ring-red-100 ring-offset-2\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"lifecycles"))) && lookupProperty(stack1,"abouttolose")), depth0))
    + "</div>\n                                </div>\n                            </div>\n                        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\" selected>\n"
    + ((stack1 = __default(require("../../../../helpers/handlebars/inKeys.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggersTypes")),{"name":"inKeys","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":45,"column":32},"end":{"line":49,"column":43}}})) != null ? stack1 : "")
    + "                            </option>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + ((stack1 = __default(require("../../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggersTypes")),((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":46,"column":32},"end":{"line":46,"column":104}}})) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + alias1(container.lambda(depth0, depth0))
    + "\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias2,depth0,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"triggerDetails"))) && lookupProperty(stack1,"type")),{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":50},"end":{"line":53,"column":100}}})) != null ? stack1 : "")
    + ">\n                                "
    + alias1(__default(require("../../../../helpers/handlebars/translate.js")).call(alias2,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggersTypes")),depth0,{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":54,"column":32},"end":{"line":54,"column":85}}}))
    + "\n                            </option>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "selected";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <input type=\"hidden\" name='type' value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\" />\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                                <option disabled>-</option>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"id") : depth0),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"triggerDetails"))) && lookupProperty(stack1,"param")),{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":55},"end":{"line":79,"column":107}}})) != null ? stack1 : "")
    + ">\n                                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                                </option>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                                <option value=\"\" disabled>-</option>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"id") : depth0),((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"recurringTrigger"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"options"))) && lookupProperty(stack1,"list_id")),{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":55},"end":{"line":145,"column":102}}})) != null ? stack1 : "")
    + ">\n                                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                                </option>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"format") : depth0),"TEXT",{"name":"is","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":160,"column":40},"end":{"line":167,"column":47}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"id") : depth0),((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"recurringTrigger"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"options"))) && lookupProperty(stack1,"field_id")),{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":63},"end":{"line":163,"column":111}}})) != null ? stack1 : "")
    + ">\n                                            "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                                        </option>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div>\n                        <div class='form-group'>\n                            <label>\n                                Trigger key\n                            </label>\n                            <input name=\"customTriggerKey\" type=\"text\" class='form-control' value='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"customKey") : stack1), depth0))
    + "' maxlength=\"30\" pattern=\"[a-z0-9_]+\" />\n                            <p class=\"input-legend\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggerCustomKeyLegend")), depth0))
    + "\n                        </div>\n                    </div>         \n";
},"28":function(container,depth0,helpers,partials,data) {
    return " checked='checked' ";
},"30":function(container,depth0,helpers,partials,data) {
    return " disabled ";
},"32":function(container,depth0,helpers,partials,data) {
    return "                                <input type=\"hidden\" name=\"execLimitEnabled\" value=\"1\">\n";
},"34":function(container,depth0,helpers,partials,data) {
    return "disabled-checkbox";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"row\">\n                                <div class='col-md-12'>\n                                    <p class=\"\">\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggerExcludeSameContactFor")), depth0))
    + "<input\n                                            class='form-control input-mini' name='execLimitAmount' type='number' min=\"1\"\n                                            value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"amount") : depth0), depth0))
    + "'>\n                                        <select\n                                            class='form-control input-mini'\n                                            name='execLimitUnit'\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isLimitedRecurringTrigger")),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":259,"column":44},"end":{"line":261,"column":52}}})) != null ? stack1 : "")
    + " >\n                                            <option value=\"MINUTES\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"unit") : depth0),"MINUTES",{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":262,"column":68},"end":{"line":262,"column":106}}})) != null ? stack1 : "")
    + ">\n                                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayUnits"))) && lookupProperty(stack1,"plural"))) && lookupProperty(stack1,"MINUTES")), depth0))
    + "</option>\n                                            <option value=\"HOURS\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"unit") : depth0),"HOURS",{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":264,"column":66},"end":{"line":264,"column":102}}})) != null ? stack1 : "")
    + ">\n                                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayUnits"))) && lookupProperty(stack1,"plural"))) && lookupProperty(stack1,"HOURS")), depth0))
    + "</option>\n                                            <option value=\"DAYS\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"unit") : depth0),"DAYS",{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":266,"column":65},"end":{"line":266,"column":100}}})) != null ? stack1 : "")
    + ">\n                                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayUnits"))) && lookupProperty(stack1,"plural"))) && lookupProperty(stack1,"DAYS")), depth0))
    + "</option>\n                                        </select>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isLimitedRecurringTrigger")),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":270,"column":40},"end":{"line":272,"column":47}}})) != null ? stack1 : "")
    + "                                    </p>\n                                </div>\n                            </div>\n";
},"37":function(container,depth0,helpers,partials,data) {
    return "                                                disabled\n                                             ";
},"39":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <input type=\"hidden\" name=\"execLimitUnit\" value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"unit") : depth0), depth0))
    + "\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='modal-dialog' style=\"width: 680px\">\n    <form data-form='save-trigger'>\n        <div class='modal-content'>\n            <div class='modal-header'>\n                <button type='button' class='close' data-dismiss='modal'>\n                    <span aria-hidden='true'>\n                        &times;\n                    </span>\n                </button>\n                <h4 class='modal-title'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"editTrigger")), depth0))
    + "\n                </h4>\n            </div>\n            <div class='modal-body'>\n                <div class='modal-body-padded'>\n                    <div class='form-group'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"rfm") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":37,"column":31}}})) != null ? stack1 : "")
    + "                        <label>\n                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"trigger")), depth0))
    + "\n                        </label>\n                        <select id='selected-trigger' class='form-control' name='type'\n                            data-action=\"show-trigger-details\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"readonly") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":63},"end":{"line":42,"column":109}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"readonly") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":28},"end":{"line":51,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"availableTriggerTypes") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":28},"end":{"line":56,"column":37}}})) != null ? stack1 : "")
    + "                        </select>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"readonly") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":24},"end":{"line":60,"column":31}}})) != null ? stack1 : "")
    + "\n                        <p class=\"input-legend\" id=\"edit-trigger-comments\"></p>\n\n                    </div>\n\n                    <div id='list-trigger-details'>\n                        <div class='form-group'>\n                            <label>\n                                "
    + alias2(__default(require("../../../../helpers/handlebars/capitalize.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"singular")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":69,"column":32},"end":{"line":69,"column":72}}}))
    + "\n                            </label>\n                            <select class='form-control' name='list'>\n                                <option value=\"none_selected\">\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"selectList")), depth0))
    + "\n                                </option>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"lists") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":32},"end":{"line":77,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"lists") : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":32},"end":{"line":82,"column":41}}})) != null ? stack1 : "")
    + "                            </select>\n                        </div>\n                    </div>\n                    <div id='interest-trigger-details'>\n                        <div class='form-group'>\n                            <label>\n                                "
    + alias2(__default(require("../../../../helpers/handlebars/capitalize.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"interests"))) && lookupProperty(stack1,"singular")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":89,"column":32},"end":{"line":89,"column":76}}}))
    + "\n                            </label>\n                            <select class='form-control' name='interest'>\n                                <option value=\"none_selected\">\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"selectInterest")), depth0))
    + "\n                                </option>\n                                <option disabled>-</option>\n                                <option value=\"\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"triggerDetails"))) && lookupProperty(stack1,"param")),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":49},"end":{"line":96,"column":106}}})) != null ? stack1 : "")
    + ">\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"anyInterest")), depth0))
    + "\n                                </option>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"interests") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":32},"end":{"line":101,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"interests") : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":32},"end":{"line":106,"column":41}}})) != null ? stack1 : "")
    + "                            </select>\n                        </div>\n                    </div>\n                    <div id='optin-trigger-details'>\n                        <div class='form-group'>\n                            <label>\n                                "
    + alias2(__default(require("../../../../helpers/handlebars/capitalize.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"singular")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":113,"column":32},"end":{"line":113,"column":73}}}))
    + "\n                            </label>\n                            <select class='form-control' name='optin'>\n                                <option value=\"\">\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"anyOptin")), depth0))
    + "\n                                </option>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"optins") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":119,"column":32},"end":{"line":121,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"optins") : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":32},"end":{"line":126,"column":41}}})) != null ? stack1 : "")
    + "                            </select>\n                        </div>\n                    </div>\n\n                    <div id='recurring-trigger-details'>\n                        <div class='form-group'>\n                            <label>\n                                "
    + alias2(__default(require("../../../../helpers/handlebars/capitalize.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"singular")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":134,"column":32},"end":{"line":134,"column":72}}}))
    + "\n                            </label>\n                            <select class='form-control' name='recurringList'>\n                                <option value=\"\">\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"anyOptin")), depth0))
    + "\n                                </option>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"lists") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":140,"column":32},"end":{"line":142,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"lists") : depth0),{"name":"each","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":32},"end":{"line":148,"column":41}}})) != null ? stack1 : "")
    + "                            </select>\n                            <p class=\"input-legend\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggerOnlyThisList")), depth0))
    + "</p>\n                        </div>\n                        <div class='form-group'>\n                            <div class=\"row\">\n                                <div class='col-md-5'>\n                                    <label>\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggerBirthdayField")), depth0))
    + "\n                                    </label>\n                                    <select class='form-control' name='recurringField'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"fields") : depth0),{"name":"each","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":159,"column":40},"end":{"line":168,"column":49}}})) != null ? stack1 : "")
    + "                                    </select>\n                                </div>\n                                <div class='col-md-5'>\n                                    <label>\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggerBirthdayDateFormat")), depth0))
    + "\n                                    </label>\n                                    <select class='form-control' name='recurringDatePattern'>\n                                        <option value=\"dd/MM\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,"dd/MM",((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"recurringTrigger"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"options"))) && lookupProperty(stack1,"field_date_pattern")),{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":176,"column":62},"end":{"line":177,"column":121}}})) != null ? stack1 : "")
    + ">\n                                            dd/mm (01/03, 25/12)\n                                        </option>\n                                        <option value=\"dd-MM\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,"dd-MM",((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"recurringTrigger"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"options"))) && lookupProperty(stack1,"field_date_pattern")),{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":180,"column":62},"end":{"line":181,"column":121}}})) != null ? stack1 : "")
    + ">\n                                            dd-mm (01-03, 25-12)\n                                        </option>\n                                        <option value=\"d-MM\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,"d-MM",((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"recurringTrigger"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"options"))) && lookupProperty(stack1,"field_date_pattern")),{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":184,"column":61},"end":{"line":185,"column":121}}})) != null ? stack1 : "")
    + ">\n                                            d-mm (1-03, 25-12)\n                                        </option>\n                                        <option value=\"d/MM\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,"d/MM",((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"recurringTrigger"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"options"))) && lookupProperty(stack1,"field_date_pattern")),{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":188,"column":61},"end":{"line":189,"column":121}}})) != null ? stack1 : "")
    + ">\n                                            d/mm (1/03, 25/12)\n                                        </option>\n                                        <option value=\"d-M\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,"d-M",((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"recurringTrigger"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"options"))) && lookupProperty(stack1,"field_date_pattern")),{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":192,"column":60},"end":{"line":193,"column":121}}})) != null ? stack1 : "")
    + ">\n                                            d-m (1-3, 25-12)\n                                        </option>\n                                        <option value=\"d/M\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,"d/M",((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"recurringTrigger"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"options"))) && lookupProperty(stack1,"field_date_pattern")),{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":196,"column":60},"end":{"line":197,"column":121}}})) != null ? stack1 : "")
    + ">\n                                            d/m (1/3, 25/12)\n                                        </option>\n                                    </select>\n                                </div>\n                                 <div class='col-md-2'>\n                                    <label>\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggerBirthdayDaysBefore")), depth0))
    + "\n                                    </label>\n                                    <input name=\"recurringTriggerDaysBefore\" type=\"number\" min=\"0\" max=\"90\" step=\"1\"  class='form-control' value='"
    + alias2(alias1(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"recurringTriggerDaysBefore")), depth0))
    + "'/>\n                                </div>\n                                <div class='col-md-12'>\n                                    <p class=\"input-legend\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggerBirthdayDateFormatLegend")), depth0))
    + "\n                                    </p>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"custom") : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":216,"column":20},"end":{"line":226,"column":27}}})) != null ? stack1 : "")
    + "\n                    <div class='form-group'>\n                        <div class='graphic checkbox'>\n                            <input id='enable-exec-limit' name='execLimitEnabled'\n                                type='checkbox'\n                                value='1'\n                                data-action=\"toggle-exec-limit-details\"\n                                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"execLimit") : stack1)) != null ? lookupProperty(stack1,"enabled") : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":234,"column":32},"end":{"line":234,"column":98}}})) != null ? stack1 : "")
    + "\n                                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isLimitedRecurringTrigger")),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":235,"column":32},"end":{"line":235,"column":88}}})) != null ? stack1 : "")
    + "\n                            >\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isLimitedRecurringTrigger")),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":238,"column":28},"end":{"line":240,"column":35}}})) != null ? stack1 : "")
    + "\n                            <label for='enable-exec-limit' class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isLimitedRecurringTrigger")),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":242,"column":66},"end":{"line":242,"column":129}}})) != null ? stack1 : "")
    + "\">\n                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggerLimitRepetition")), depth0))
    + "\n                            </label>\n                        </div>\n\n\n                        <div id=\"exec-limit-details\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"execLimit") : stack1),{"name":"with","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":249,"column":28},"end":{"line":276,"column":37}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class='modal-footer'>\n                <button type='button' class='btn btn-link' data-dismiss='modal'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n                </button>\n                <button type='submit' class='btn btn-default btn-primary'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accept")), depth0))
    + "\n                </button>\n            </div>\n        </div>\n    </form>\n</div>";
},"useData":true});