import { ListView } from "@/core/magnet";
import ListMixin from "@/mixins/list";
import { _, objHas } from "@/helpers/utils";
import app from "@/app";
import { mountComponent } from "@/helpers/vue";
import CampaignSelector from "@organisms/Campaigns/CampaignSelector/CampaignSelector";

// Application
import { useIntegrationsStore } from "@application";

export default ListView.mix(ListMixin).extend({
  module: "campaigns",
  templates: {
    initial: require("../templates/list.hbs"),
    rows: require("../templates/listRows.hbs"),
    grids: require("../templates/gridRows.hbs"),
    empty: require("../templates/empty.hbs"),
  },
  query: {
    order: "stategroup asc",
  },
  paginate: true,
  limit: 32,
  gender: "F",
  cache: false,
  sortOptions: [
    { column: "name", type: "asc", label: "name" },
    { column: "stategroup", type: "asc", label: "state" },
    { column: "launchDate", type: "desc", label: "launchDate" },
  ],
  events: function () {
    return $.extend({}, ListView.prototype.events, {
      "click [data-action=open-duplicate-modal]": "openDuplicateModal",
      "submit [data-form=duplicate]": "duplicate",
      "click [data-action=toggle-display]": "toggleDisplay",
      "click [data-action=export-campaigns]": "exportCampaigns",
      "click [data-action=open-create-modal]": "openCreateCampaignModal",
      "click .collection-pane a": "stopPropagation",
    });
  },

  stopPropagation: function (e) {
    e.stopPropagation();
  },

  // Sets the initial configuration values
  beforeRender: function (callback) {
    var context;

    const integrationStore = useIntegrationsStore();
    const isEcommerce = integrationStore.ecommerceIntegrations.length > 0;

    this.showConversionsColumn = isEcommerce;

    // Get list display preference
    if (app.session.get("isMasterUser")) {
      this.displayMode = localStorage.getItem("campaigns-list-style") || "grid";
      callback();
    } else {
      context = app.session.get("context");
      if (context) {
        this.displayMode = _.isDefined(context.campaignsListDisplay) ? context.campaignsListDisplay : "grid";
        callback();
      } else {
        app.session.getContext().done(
          function () {
            context = app.session.get("context");
            this.displayMode = _.isDefined(context.campaignsListDisplay) ? context.campaignsListDisplay : "grid";
            callback();
          }.bind(this),
        );
      }
    }
  },

  // Extras
  extraData: function () {
    return { displayMode: this.displayMode };
  },

  // Render rows in grid or list mode
  renderItems: function () {
    var template, style;
    if (this.displayMode === "grid") {
      template = this.templates.grids;
      style = "grid";
    } else {
      template = this.templates.rows;
      style = "table";
    }
    this.$("[data-role=rows]")
      .attr("class", style)
      .append(
        template(
          this.renderData({
            showConversionsColumn: this.showConversionsColumn,
          }),
        ),
      );
  },

  // Runs custom actions after renderization
  afterRenderPage: function () {
    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();

    // If the archived filter is present, highlight the selector
    if (objHas(this.query, "filter", "archived")) {
      this.setActiveArchivedFilter();
    }
  },

  // Runs after rending items
  afterRenderItems: function () {
    return this.renderGroupDividers();
  },

  // Highlights 'Archived' in the state filter
  setActiveArchivedFilter: function () {
    var toggler = this.$('[data-filter-name="filter.state"]'),
      dropdown = toggler.closest("li");

    // Set the toggler as active
    toggler.text(window.lang.campaigns.filters.state.ARCHIVED).closest("a").addClass("active");

    // Set dropdown option as active
    dropdown.find('[data-value="ARCHIVED"]').addClass("active");
  },

  // Renders dividers for created and status groups
  renderGroupDividers: function () {
    var stateGroups = ["DRAFT", "PENDING", "DONE"],
      launchGroups = [0, 1, 2, 3],
      template = '<li class="grouped-list-label">{{name}}</li>',
      order = _.first(this.query.order.split(" ")),
      groups,
      key;

    // Do not add dividers in list mode
    if (this.displayMode !== "grid") {
      return this;
    }

    // Guess group based on order
    if (order === "stategroup") {
      groups = stateGroups;
      key = "state";
    } else if (order === "launchDate") {
      groups = launchGroups;
      key = "launch";
    } else {
      return false;
    }

    // Render dividers
    $(this.renderArea).find(".grouped-list-label").remove();
    groups.forEach(function (group) {
      var html = template.replace("{{name}}", window.lang.campaigns[key + "group"][group]);
      $("[data-" + key + "-group=" + group + "]")
        .first()
        .before(html);
    });
  },

  // Hides or show action buttons depending on the number of items checked
  toggleActions: function () {
    var toolbar = this.$("[data-role=toolbar]"),
      buttons = toolbar.find(".btn"),
      none = toolbar.find("[data-type=none]"),
      justOne = toolbar.find("[data-type=just-one]"),
      onePlus = toolbar.find("[data-type=one-plus]"),
      twoPlus = toolbar.find("[data-type=two-plus]"),
      exportButton = toolbar.find("[data-action=export-campaigns]"),
      checked = this.getChecked();

    // Hide all actions
    buttons.addClass("hide");
    exportButton.removeClass("hide");

    // Hide buttons when no items are checked
    if (checked.length === 0) {
      none.removeClass("hide");
      toolbar.removeClass("edit-mode");
      return this;
    }

    // Show actions when just one item is checked
    if (checked.length === 1) {
      justOne.removeClass("hide");
      onePlus.removeClass("hide");
      toolbar.addClass("edit-mode");
    }

    // Show actions when two or more items are checked
    if (checked.length > 1) {
      onePlus.removeClass("hide");
      twoPlus.removeClass("hide");
      toolbar.addClass("edit-mode");
    }

    if (
      checked.every(function (item) {
        return item.get("archived");
      })
    ) {
      this.$("[data-method=unarchive]").removeClass("hide");
    }

    // With every done and not archived, show archive button
    if (
      checked.every(function (item) {
        return !item.get("archived") && item.get("done");
      })
    ) {
      this.$("[data-method=archive]").removeClass("hide");
    }

    // With every draft, show delete
    if (
      checked.every(function (item) {
        return item.get("draft");
      })
    ) {
      this.$("[data-method=destroy]").removeClass("hide");
    }

    // With every scheduled, pending approval or sending, show stop
    if (
      checked.every(function (item) {
        return item.get("state") === "SCHEDULED" || item.get("state") === "PENDING_APPROVAL";
      })
    ) {
      this.$("[data-method=stop]").removeClass("hide");
    }

    // With every sent, show export
    if (
      checked.every(function (item) {
        return item.get("state") === "SENT";
      })
    ) {
      exportButton.removeClass("hide");
    }
  },

  // Opens the duplicate modal
  openDuplicateModal: function () {
    this.openModal(require("../templates/modals/duplicate.hbs"), {
      lang: window.lang,
      data: this.getFirstChecked(),
    });
  },

  // Duplicates a campaign
  duplicate: function (e) {
    var form = $(e.currentTarget),
      data = form.serializeObject(),
      modal = $(this.modal);
    e.preventDefault();

    // Validate values
    if (!this.validateFormFields(form)) {
      return this;
    }

    // Performs an AJAX call to the action endpoint
    var campaign = _.first(this.getChecked());
    campaign.duplicate(data).done(
      function (response) {
        // Close modal
        modal.modal("hide");

        // Display feedback
        this.displayMessage(window.lang.campaigns.feedback.duplicated);

        // Redirect to the newly created campaign
        app.router.navigateTo(`campaigns/${response.data.id}`);
      }.bind(this),
    );
  },

  // Changes between list and thumbnail view
  toggleDisplay: function (e) {
    var toggler = $(e.currentTarget),
      display = toggler.data("display"),
      template,
      style;

    // Save display mode
    if (app.session.get("isMasterUser")) {
      localStorage.setItem("campaigns-list-style", display);
    } else {
      $.ajax({
        type: "PUT",
        url: "users/me/context",
        data: { campaignsListDisplay: display },
      });
    }
    app.session.set(
      "context",
      $.extend({}, app.session.get("context"), {
        campaignsListDisplay: display,
      }),
    );
    this.displayMode = display;

    // Set button as active
    toggler.addClass("active").siblings().removeClass("active");

    // Rerender the items
    if (this.displayMode === "grid") {
      template = this.templates.grids;
      style = "grid";
    } else {
      template = this.templates.rows;
      style = "table";
    }
    this.$("[data-role=rows]")
      .attr("class", style)
      .html(
        template(
          this.renderData({
            data: this.collection.models,
            showConversionsColumn: this.showConversionsColumn,
          }),
        ),
      );
    this.bindLoadMoreScroll();

    // Render dividers
    this.renderGroupDividers();
  },

  // Export
  exportCampaigns: function () {
    var checked = this.getChecked(),
      params = {},
      feedback;

    // Get params
    if (checked.length) {
      params.campaigns = _.pluck(app.router.current.getChecked(), "id");
      feedback = window.lang.campaigns.feedback.exportSelected;
    } else {
      if (objHas(this.query, "filter", "tags")) {
        params.tags = [this.query.filter.tags];
        feedback = window.lang.campaigns.feedback.exportQuery;
      }
      if (objHas(this.query, "q")) {
        params.query = this.query.q;
        feedback = window.lang.campaigns.feedback.exportQuery;
      }
    }

    // Set feedback for general export
    if (!_.size(params)) {
      feedback = window.lang.campaigns.feedback.exportAll;
    }

    // Send request
    $.post(
      "campaigns/metrics/export",
      params,
      function () {
        this.displayMessage(feedback);
        app.instances.singleton("tasks", "list").render();
      }.bind(this),
    );
  },

  openCreateCampaignModal: function () {
    if (!this.vueCampaignSelectorModal) {
      this.vueCampaignSelectorModal = mountComponent(
        CampaignSelector,
        {
          // freeAccount: true, //TODO: si es free y no esta en trial y no es master user -> pasar a vue
          onCreate: (e) => {
            this.gotoNewCampaign(e.campaignType, !e.useEddie);
          },
        },
        "#vue-campaign-selector",
      );
    }
    this.vueCampaignSelectorModal.openModal();
  },

  gotoNewCampaign: function (type, useUnlayer) {
    app.integrations.amplitude.event("APP_CAMPAIGN_CREATE", { campaign_type: type }, true);
    app.integrations.intercom.event("APP_CAMPAIGN_CREATE", { campaign_type: type });
    switch (type) {
      case "simple":
        app.router.navigateTo(useUnlayer ? "campaigns/new/simple" : "campaigns/new");
        app.integrations.amplitude.event("APP_CAMPAIGN_CREATE_SIMPLE", true);
        app.integrations.intercom.event("APP_CAMPAIGN_CREATE_SIMPLE");
        break;
      case "splitab":
        app.router.navigateTo(useUnlayer ? "campaigns/new/splitab" : "campaigns/newsplitab");
        app.integrations.amplitude.event("APP_CAMPAIGN_CREATE_SPLIT_AB", true);
        app.integrations.intercom.event("APP_CAMPAIGN_CREATE_SPLIT_AB");
        break;
      case "smart":
        app.router.navigateTo(useUnlayer ? "campaigns/new/smart" : "campaigns/newsmart");
        app.integrations.amplitude.event("APP_CAMPAIGN_CREATE_SMART", true);
        app.integrations.intercom.event("APP_CAMPAIGN_CREATE_SMART");
        break;
    }
  },
});
