import { _, formatInteger, objHas, Sequence } from "@/helpers/utils";
import app from "@/app";
import FilterMixin from "@/components/filter/filter";
/**
 * Base Backbone model, collection and views
 *
 * @module Magnet
 */

// Resolves a list of deferreds and executes a callback when done
function resolveDeferreds(deferreds, storage, callback, parameter) {
  var resolve = [],
    resolved = {};

  deferreds = deferreds || [];
  parameter = parameter || false;

  // Iterate the deferreds array
  $.each(deferreds, function (type, deferred) {
    // If the deferred is not a function, add it as resolved
    if (!$.isFunction(deferred)) {
      resolve.push(deferred);
      resolved[type] = deferred;
    }

    // If the deferred is a function
    if ($.isFunction(deferred)) {
      if (storage && storage.getItem && storage.getItem(type) !== null) {
        // Take the resolved value from storage
        resolved[type] = JSON.parse(storage.getItem(type));
        resolve.push(resolved[type]);
      } else {
        // Or add the deferred function for resolution
        resolve.push(
          deferred(parameter).success(function (response) {
            var data;
            if (response === undefined) {
              resolved[type] = response;
              return false;
            }
            data = response.data !== undefined ? response.data : response;
            resolved[type] = data;
            if (storage) {
              storage.setItem(type, JSON.stringify(resolved[type]));
            }
          })
        );
      }
    }
  });

  // Executes the callback when all deferreds are done
  $.when.apply(null, resolve).done(function () {
    callback(resolved);
  });
}

// Magnet Collection
const Collection = window.Backbone.Collection.extend({
  // Resolves the additional data
  resolveAdditionals: function (storage, callback, collection) {
    resolveDeferreds(this.additionals, storage, callback, collection);
  },

  // Resolves the collection relationships
  resolveRelationships: function (storage, callback, collection) {
    resolveDeferreds(this.relationships, storage, callback, collection);
  },

  // Parse the fetched results of the relationships
  parseRelationships: function (relationships) {
    return relationships;
  },

  // Parse the fetched results of the relationships
  parseAdditionals: function (additionals) {
    return additionals;
  }
});

// Magnet Model
const Model = window.Backbone.Model.extend({
  // Destroy override
  destroy: function (options) {
    var params = $.extend(true, {}, { type: "DELETE", url: this.url() }, options);
    return $.ajax(params);
  },

  // Resolves the additional data
  resolveAdditionals: function (storage, callback, model) {
    resolveDeferreds(this.additionals, storage, callback, model);
  },

  // Resolves the model relationships
  resolveRelationships: function (storage, callback, model) {
    resolveDeferreds(this.relationships, storage, callback, model);
  },

  // Parse the fetched results of the relationships
  parseRelationships: function (relationships) {
    return this.sortRelationships(this.checkRelationships(relationships));
  },

  // Parse the fetched results of the relationships
  parseAdditionals: function (additionals) {
    return additionals;
  },

  // Sort the elements of a relationship based on his presence in the model
  sortRelationships: function (relationships) {
    $.each(relationships, function (type, options) {
      relationships[type] = _.sortBy(options, "checked");
    });
    return relationships;
  },

  // Adds the `checked` property to the relationship options present in the model
  checkRelationships: function (relationships) {
    var self = this;
    $.each(relationships, function (type, options) {
      var values = self.get(type);
      if ($.isArray(values)) {
        values = $.map(values, function (element) {
          if ($.isPlainObject(element) && element.id !== undefined) {
            return element.id;
          } else {
            return element;
          }
        });
      } else {
        values = [values];
      }
      $.each(options, function (index, option) {
        if (!$.isPlainObject(option)) {
          option = { id: option };
        }
        if ($.inArray(option.id, values) > -1) {
          option.checked = 1;
        } else {
          option.checked = 0;
        }
        relationships[type][index] = option;
      });
    });
    return relationships;
  }
});

// Magnet Basic View
const BasicView = window.Backbone.View.extend({
  // View type
  type: "basic",

  // Validation rules
  rules: {
    email: /^([A-Za-z0-9_.\-+]+)@([\dA-Za-z.-]+)\.([A-Za-z.]{2,63})$/,
    url: /^(https?:\/\/)?([\dA-Za-z.-]+)\.([A-Za-z.]{2,6})([/\w .-]*)*\/?$/,
    date: /^(19|20)\d\d[-.](0[1-9]|1[012])[-.](0[1-9]|[12][0-9]|3[01])$/,
    int: /^[\d]+$/,
    required: /^$|\s+/
  },

  // Uses a loader
  useLoader: true,

  // Gets the main modal
  getModal: function () {
    return $(this.modal);
  },

  // Performs the needed bindings before renderization
  beforeRender: function (callback) {
    callback();
  },

  // Opens a modal window
  openModal: function (template, data, callback) {
    var modal = $(this.modal);
    data = data || {};

    // Add lang
    if (!objHas(data, "lang")) {
      data.lang = window.lang;
    }

    // If the template name is received, get the template function
    if (typeof template === "string") {
      console.warn(`String templates are deprecated: ${template}`);
    }

    // Render into the modal
    modal.html(template(data));

    // Rebind events
    this.rebind();

    // Show modal
    modal.modal("show");

    // Execute callback
    window.setTimeout(function () {
      // Execute callbacks
      if ($.isFunction(callback)) {
        callback(modal);
      }
    }, 200);
  },

  // Focus on the first input visible in a modal
  focusFirstModalInput: function (e) {
    var modal = $(e.target),
      input = modal.find("input:visible, select:visible, textarea:visible").first(),
      button = modal.find("button[type=submit]");
    if (input.length) {
      input.focus();
    } else {
      button.focus();
    }
  },

  // Rebinds events
  rebind: function () {
    this.delegateEvents($.isFunction(this.events) ? this.events() : this.events);
  },

  // Check if the form fields are valid
  validateFormFields: function (form) {
    var self = this,
      errors = {},
      hasErrors = false,
      fields;

    // Remove error messages from the view
    self.cleanFormErrorMessages(form);

    // Get all fields that needs validation
    fields = $(form).find("[data-validate]").not(":disabled");

    // Validate each field
    fields.each(function () {
      var field = $(this),
        name = field.attr("name"),
        rules = field.data("validate").split(" "),
        //isNumeric = ($.inArray('int', rules) > -1),
        min = field.attr("min") ? parseInt(field.attr("min"), 10) : false,
        max = field.attr("max") ? parseInt(field.attr("max"), 10) : false,
        value = field.val(),
        isEmpty = value === "",
        pattern,
        ruleName;

      // Iterate rules
      $.each(rules, function (index, rule) {
        // Create check from a standard rule
        pattern = new RegExp(self.rules[rule]);
        ruleName = rule.charAt(0).toUpperCase() + rule.substring(1).toLowerCase();

        // Perform required check
        if (isEmpty && rule === "required") {
          errors[name] = window.lang["invalidRequired"];
          hasErrors = true;
        }

        // Perform integer check
        if (!isEmpty && rule === "int" && pattern.test(value)) {
          value = parseInt(value, 10);
          if ((min && value < min) || (max && value > max)) {
            errors[name] = window.lang.invalidRange + ". ";
            if (min) {
              errors[name] += window.lang.invalidRangeMin + " " + min + ". ";
            }
            if (max) {
              errors[name] += window.lang.invalidRangeMax + " " + max + ".";
            }
            hasErrors = true;
          }
        }

        // Perform rule check
        if (!isEmpty && rule !== "required" && !pattern.test(value)) {
          errors[name] = window.lang["invalid" + ruleName];
          hasErrors = true;
        }
      });
    });

    // If errors where found, show errors and cancel the submission
    if (hasErrors) {
      self.showValidationErrors(errors, $(form));
      return false;
    }

    // Return success
    return true;
  },

  // Marks an input as invalid
  showValidationErrors: function (validationErrors, context) {
    var self = this,
      errorTemplate = _.template('<p class="help-block error-message"><%= message %></p>'),
      elements = [],
      first,
      pane,
      tab,
      container,
      errorHtml,
      message = window.lang.checkErrors;
    context = context || self.$el;
    validationErrors = validationErrors || [];

    // Show feedback
    this.displayMessage(message, 3000, "error");

    // Iterate errors array
    $.each(validationErrors, function (error, message) {
      var element,
        name = "";

      // Set complex name
      if (error.includes(".")) {
        $.each(error.split("."), function (index, value) {
          if (index === 0) {
            name += value;
          } else {
            name += "[" + value + "]";
          }
        });
      } else {
        name = error;
      }

      // Select field element
      element = context.find('[name="' + name + '"]');

      // Add error class and message to the element
      if (element.length) {
        container = element.closest(".form-group, .checkbox, .radio").addClass("has-error");
        errorHtml = errorTemplate({ message: message });
        if (element.data("prepend-error")) {
          container.find("label").after(errorHtml);
        } else {
          container.append(errorHtml);
        }
      }

      // Or show a custom message
      if (!element.length) {
        element = context.find('[data-validation-message="' + name + '"]');
        element.removeClass("error-hidden");
      }

      // Add element to the array
      if (element.length) {
        elements.push(element);
      }
    });

    // Focus on the first error
    if (elements.length) {
      first = elements.shift();

      // Trigger the pane
      pane = self.$('[data-target="#' + first.closest(".tab-pane").attr("id") + '"]');
      pane.click();

      // Trigger the tab
      tab = self.$('[data-target="#' + pane.closest(".tab-pane").attr("id") + '"]');
      tab.click();

      // Focus on the field
      $(".content").animate(
        {
          scrollTop: first.offset().top - 256
        },
        500,
        function () {
          first.focus();
        }
      );
    }

    // Enable submit button
    self.enableSubmitButton(context);
  },

  // Sets the initial template based on the templateName value
  setTemplate: function (template) {
    var self = this;

    if (!template) {
      return self;
    }

    // If the template is a function, use it as is
    if (_.isFunction(template)) {
      self.template = template;
      if (self.originalTemplate === undefined) {
        self.originalTemplate = template;
      }
    } else {
      console.warn(`String templates are deprecated: ${template}`);
    }

    return self;
  },

  // Changes the template
  changeTemplate: function (template) {
    return this.setTemplate(template);
  },

  // Cleans a form
  cleanFormErrorMessages: function (form) {
    $(form).find(".error-message").addClass("error-hidden");
    $(form).find(".has-error").removeClass("has-error");
  },

  // Disables the submit button to prevent additional submits
  disableSubmitButton: function (e) {
    var self = this,
      button = $(e.currentTarget).find("button[type=submit]");
    self.disableButton(button);
  },

  // Disables a button and shows a loader
  disableButton: function (button) {
    if (!button.data("notDisable")) {
      button
        .attr({
          "data-original-text": button.html(),
          disabled: "disabled"
        })
        .text(window.lang.loading);
      $("<img>", {
        src: "/img/loader.svg",
        class: "loader"
      }).prependTo(button);
    }
  },

  // Enables the submit button
  enableSubmitButton: function (context) {
    var button = $(context).find("button[disabled]");
    button.find(".loader").remove();
    button.removeAttr("disabled").html(button.data("original-text"));
  },

  // Reloads the current URL
  reload: function () {
    window.Backbone.history.stop();
    window.Backbone.history.start();
  },

  // Turn on or off the loader
  loading: function (on) {
    var self = this;

    // Check if the module uses a loder
    if (!self.useLoader) {
      return self;
    }

    // Turn on
    if (on) {
      // Wait half a second and turn on the loader if a request is still running
      setTimeout(function () {
        if ($.active) {
          if ($(".page").length) {
            window.NProgress.start();
          }

          // Failsafe loader hide
          setTimeout(function () {
            if (window.NProgress.isStarted()) {
              window.NProgress.done();
            }
          }, 5000);
        } else {
          self.loading(false);
        }
      }, 300);
    } else {
      // Turn off the loader if there are not running requests
      setTimeout(function () {
        if (!$.active) {
          if (window.NProgress.isStarted()) {
            window.NProgress.done();
          }
        } else {
          self.loading(false);
        }
      }, 100);
    }

    // Return view instance
    return self;
  },

  // Displays a quick message
  displayMessage: function (message, time, type) {
    var self = this,
      messenger = $(self.messenger);
    time = time || 3000;
    type = type || "info";

    // Ignore if no message is defined
    if (!message) {
      return false;
    }

    // Capitalize message
    message = message.charAt(0).toUpperCase() + message.slice(1);

    // Turn on the messenger
    messenger.find("span").text(message);
    messenger.attr("class", "messenger in " + type);

    // Turn off the messenger
    if (time) {
      setTimeout(function () {
        if (messenger.find("span").text() === message) {
          messenger.removeClass("in");
        }
      }, time);
    }

    // Return view instance
    return self;
  },

  // Displays an alert
  displayAlert: function (message, type) {
    var self = this,
      html =
        '<div class="alert alert-' +
        type +
        '">' +
        message +
        '<button type="button" class="close" data-dismiss="alert"><span>&times;</span></button></div>';
    self.$(".alerts").append(html);
  },

  // Shows the confirm modal
  confirmModal: function (
    confirmText,
    button,
    titleText,
    type,
    flag,
    acceptCallback,
    cancelCallback,
    acceptButtonText,
    cancelButtonText,
    hideCancelButton = false
  ) {
    var confirm = self.$("[data-modal=confirm]"),
      questionBox = confirm.find(".question"),
      titleBox = confirm.find(".modal-title span"),
      acceptButton = confirm.find("#confirm-modal-accept-btn"),
      cancelButton = confirm.find("#confirm-modal-cancel-btn"),
      accepted = false;

    type = type || "warning";
    flag = flag || "data-confirm";
    acceptCallback = acceptCallback || false;
    cancelCallback = cancelCallback || false;

    // Show custom confirm
    questionBox.html(confirmText);
    titleBox.text(titleText || window.lang.confirm).attr("class", type);
    acceptButton.text(acceptButtonText || window.lang.accept);
    cancelButton.text(cancelButtonText || window.lang.cancel);

    if (hideCancelButton) {
      cancelButton.hide();
    } else {
      cancelButton.show();
    }

    confirm.modal("show");

    // If the user accepts, remove the confirm attribute and trigger the click again
    confirm
      .on("submit", "form", function (e) {
        e.preventDefault();
        e.stopPropagation();
        confirm.find("button[disabled]").removeAttr("disabled");
        confirm.off("submit reset", "form");
        accepted = true;
        confirm.modal("hide");
        if (button) {
          button.removeAttr(flag);
        }
        if (!acceptCallback) {
          button.trigger("click");
        }
      })
      .on("hidden.bs.modal", function () {
        confirm.off("submit reset", "form");
        confirm.off("hidden.bs.modal");
        if (!accepted && cancelCallback) {
          cancelCallback(button, confirm);
        }
        if (accepted && acceptCallback) {
          acceptCallback(button, confirm);
        }
      });
  },

  openRestrictedFeatureModal: function (data) {
    data.message = data.message || window.lang.restrictedFeature;
    this.openModal(require("@/modules/layout/templates/modals/restricted.hbs"), data);
  },

  inlineUpdateStart: function (e) {
    var span = $(e.currentTarget),
      fieldName = span.data("field"),
      input = $("input[name=" + fieldName + "]");
    span.hide();
    input.show().focus();
    //input[0].setSelectionRange(strLength, strLength);
    input[0].setSelectionRange(0, 0);
    //FIX: esto solo funciona para edits inline en el header
    input.css("width", $(".section-header").width() + "px");
  },

  inlineUpdateKeyPress: function (e) {
    var input = $(e.currentTarget);
    if (e.key === "Escape") {
      input.val(input.data("original-value"));
      input.blur();
    } else if (e.key === "Enter") {
      input.blur();
    }
  },

  inlineUpdateSave: function (e) {
    var input = $(e.currentTarget),
      value = input.val().trim(),
      originalValue = input.data("original-value"),
      fieldName = input.attr("name"),
      span = $("span[data-field=" + fieldName + "]");

    if (value === "" && input.attr("required") !== undefined) {
      input.val(originalValue);
      value = originalValue;
    }

    if (value !== originalValue) {
      var data = {};
      data[fieldName] = value;
      //this.loading(true);
      this.item.save(data, { patch: true, type: "PUT" }).done(
        function (response) {
          var responseValue = response.data[fieldName];

          input.data("original-value", responseValue);
          input.val(responseValue);
          span.text(responseValue);
          span.removeClass("empty");
          if (responseValue === "" && input.attr("placeholder")) {
            span.text(input.attr("placeholder"));
            span.addClass("empty");
          }

          input.hide();
          span.show();
          //this.loading(false);
          //this.displaySavedMessage();
        }.bind(this)
      );
    } else {
      input.hide();
      span.show();
    }
  },

  navigate: function (e) {
    e.preventDefault();
    const route = e.currentTarget.dataset.navigate;
    app.router.navigateTo(route);
  },

  navigateHref: function (e) {
    if (!(e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      const route = e.currentTarget.attributes.href.value;
      app.router.navigateTo(route);
    }
  }
});

// Magnet Edit View
const EditView = BasicView.extend({
  // View type
  type: "edit",

  // Module name used to instantiate the model
  module: "",

  // Element selector
  el: ".app",

  // Render area
  renderArea: ".page",

  // Modal selector
  modal: "[data-modal=main]",

  // Messenger selector
  messenger: "[data-messenger=main]",

  // Relationships data
  relationshipsData: {},

  // Relationships storage
  cache: window.emulatedStorage || window.localStorage,

  // Additionals data
  additionalsData: {},

  // Module gender
  gender: "M",

  // Avoid creating a model instance
  createInstance: true,

  // Events
  events: {
    "submit form :not(.vue)": "disableSubmitButton",
    "click [data-confirm]": "confirm",
    "submit [data-form=save]": "save",
    "click [data-action=edit]": "edit",
    "click [data-action=delete]": "delete",
    "click [data-action=back]": "back",
    "click [data-action=show]": "show",
    "change input:not([type=radio])": "changedField",
    "change select": "changedField",
    "change textarea": "changedField",
    "change input[type=radio]": "changedRadio",
    "shown.bs.modal": "focusFirstModalInput",
    "click [data-navigate]": "navigate",
    "click a[href^='/']": "navigateHref"
  },

  // Model
  item: null,

  // Fetch params
  fetchParams: null,

  // Save AJAX extra attributes
  saveParams: {},

  // Add validation to save action
  validateOnSave: true,

  // Sets the initial configuration values
  initialize: function () {
    this.setTemplate(this.templates?.initial);
    return this;
  },

  // Performs an action before fetching the model
  beforeFetch: function (params) {
    return params;
  },

  // Creates an instance of the model, it can be overriden
  createModelInstance: function (options) {
    return new app.models[this.module]({ id: options.id });
  },

  // Fetchs and render the model
  render: function (options) {
    var self = this;

    options = options || { id: null };

    // Perform hook
    options = self.beforeFetch(options);

    // Save options
    self.options = options;

    // If the view has a model attached, render as item
    if (self.item && self.item.id === options.id) {
      self.renderItem();
      return self;
    }

    // If the view does not need a model instance, render as page
    if (!self.createInstance) {
      self.renderPage();
      return self;
    }

    // Create a model instance
    self.item = this.createModelInstance(options);

    // Turn on loader
    self.loading(true);

    // If the item has an ID, fetch its properties and render
    if (!self.item.isNew()) {
      self.item
        .fetch(self.fetchParams)
        .success(function () {
          self.renderItem();
        })
        .fail(function (response) {
          if (response.status === 404) {
            app.layout.notFound(response.status);
          }
          if (response.status === 401) {
            return app.session.logout(true);
          }
        });
      return self;
    }

    // If the item is new and has remote defaults, fetch them
    if (self.item.isNew()) {
      // Fetch remote defaults
      if (self.item.remoteDefaults !== undefined) {
        $.ajax({
          url: self.item.remoteDefaults(),
          success: function (response) {
            self.item.set(response.data);
            self.renderItem();
          }
        });
        return self;
      }
    }

    // If the item is new and has no remote defaults, just render
    self.renderItem();

    // Return view instance
    return self;
  },

  // Renders a page
  renderPage: function () {
    var self = this;

    // Perform addtional actions
    self.beforeRender(function () {
      // Render
      $(self.renderArea).html(self.template(self.renderData()));

      // Perform addtional actions
      self.afterRender();
    });
  },

  // Renders an model
  renderItem: function () {
    var self = this;

    // Abort if the item is not defined or cleaned up
    if (!self.item) {
      return false;
    }

    // Get relationships data and render
    self.item.resolveRelationships(
      self.cache,
      function (relationships) {
        // Turn off loader
        self.loading(false);

        // Abort if the item is not defined or cleaned up
        if (!self.item) {
          return false;
        }

        // Parse relationships
        self.item.relationshipsData = self.item.parseRelationships(relationships);

        // If no additional data is needed, render
        if (self.item.additionals === undefined) {
          // Perform additional actions
          self.beforeRender(function () {
            // Render the edit form
            self.$(self.renderArea).html(self.template(self.renderData()));

            // Focus the first form field
            self.focusFirstField();

            // Perform addtional actions
            self.afterRender();

            // Return
            return self;
          });
        }

        // Otherwise, request additional data and render
        if (self.item.additionals) {
          self.item.resolveAdditionals(
            false,
            function (additionals) {
              // Abort if the item is not defined or cleaned up
              if (!self.item) {
                return false;
              }

              // Save additionals
              self.item.additionalsData = self.item.parseAdditionals(additionals);

              // Perform addtional actions
              self.beforeRender(function () {
                // Render the edit form
                self.$(self.renderArea).html(self.template(self.renderData()));

                // Focus the first form field
                self.focusFirstField();

                // Perform addtional actions
                self.afterRender();

                // Return
                return self;
              });
            },
            self.item
          );
        }
      },
      self.item
    );

    // Return for concatenation
    return self;
  },

  // Returns the data to be rendered by the template function
  renderData: function () {
    return {
      data: this.item,
      lang: window.lang,
      config: window.config,
      session: app.session
    };
  },

  // Performs the needed bindings after renderization
  afterRender: function () {
    return this;
  },

  // Focus on the first editable field
  focusFirstField: function () {
    var self = this;
    self.$("input, select, textarea").first(":visible").focus();
  },

  // Gets a form data
  getFormData: function (form) {
    var self = this;
    return self.getFormFieldsObject(form);
  },

  // Gets a object of form fields values
  getFormFieldsObject: function (form, changesOnly) {
    var data,
      flag = "data-not-modified";

    changesOnly = changesOnly !== undefined ? changesOnly : true;

    if (!changesOnly) {
      data = $(form).serializeObject();
      return data;
    }

    // Temporarily disable non changed fields
    $(form)
      .find("input, select, textarea")
      .each(function () {
        var field = $(this);
        if (!field.data("changed")) {
          field.attr("disabled", true).attr(flag, 1);
        }
      });

    // Get the a JSON object from the form fields
    data = $(form).serializeObject();

    // Restore normal status to temporarily disabled fields
    $(form)
      .find("[" + flag + "]")
      .removeAttr("disabled")
      .removeAttr(flag);

    // Return the final object
    return data;
  },

  // Confirm actions
  confirm: function (e) {
    var self = this,
      button = $(e.currentTarget),
      question = button.data("confirm"),
      titleText = button.data("confirm-title"),
      type = button.data("confirm-type"),
      pronoun = window.lang["item" + self.gender],
      confirmText = [question, pronoun, window.lang[this.module].singular].join(" ") + "?";

    // Show confirm modal
    self.confirmModal(confirmText, button, titleText, type);
  },

  // Changes to edit mode
  edit: function () {
    this.changeTemplate(this.templates.edit).renderItem();
  },

  // Changes to show mode
  show: function () {
    this.changeTemplate(this.templates.show).renderItem();
  },

  // Saves or updates a model
  save: function (e) {
    var self = this,
      model = self.item,
      creating = model.isNew(),
      form = e.currentTarget,
      attributes;

    // Prevent sending the form
    e.preventDefault();

    // Get changed attributes
    attributes = self.getFormData(form);

    // Perform before save hooks
    if (creating) {
      if (!self.beforeCreate(attributes, form)) {
        return false;
      }
    } else {
      if (!self.beforeUpdate(attributes, form)) {
        return false;
      }
    }

    // Abort if the form has errors
    if (self.validateOnSave && !self.validateFormFields(form)) {
      return self;
    }

    // Abort if there's nothing to send
    if (!_.size(attributes)) {
      return self.back();
    }

    // Perform API call to save
    return model.save(
      attributes,
      $.extend(
        true,
        {},
        {
          dataType: "json",
          contentType: "application/json",
          data: JSON.stringify(attributes),
          success: function (response) {
            // Clean storage for the module
            if (localStorage.getItem(self.module)) {
              localStorage.removeItem(self.module);
            }

            // Set response data in the model
            if (self.item && response.data !== undefined) {
              self.item.set(response.data);
            }

            // Perform hooks
            if (creating) {
              self.afterCreate(attributes);
            } else {
              self.afterUpdate(attributes);
            }
          },
          error: function (item, xhr, error) {
            self.saveError(item, xhr, error, form);
          }
        },
        self.saveParams
      )
    );
  },

  // Show error feedback after failing a save
  saveError: function (item, xhr, error, form) {
    var self = this,
      message;

    // Parse responseText if responseJSON is undefined
    if (xhr.responseJSON === undefined && xhr.getResponseHeader("Content-Type") === "application/json") {
      xhr.responseJSON = JSON.parse(xhr.responseText);
    }
    // JSON error
    if (xhr.responseJSON !== undefined && xhr.responseJSON.error !== undefined) {
      error = xhr.responseJSON.error;
      message = error.userMessage;
      if (error.incidentCode !== undefined) {
        message += " (" + error.incidentCode + ")";
      }

      // Show validation errors
      if (error.validationErrors !== undefined) {
        return self.showValidationErrors(error.validationErrors, $(form));
      }

      // Logout on unauthorized responses
      if (error.type === "UNAUTHORIZED") {
        return app.session.logout();
      }

      // Show user message on invalid requests
      if (error.type === "INVALID_REQUEST") {
        return self.displayMessage(message, 10000, "error");
      }

      // Alert server internal errors
      if (error.status === 500 || error.status === 503) {
        self.displayMessage(message, 10000, "error");
        return false;
      }
    }

    // Timeout error
    if (status !== undefined && status === "timeout") {
      self.displayMessage(window.lang.requestTimeout, 10000, "error");
    }

    // Show offline network error
    if (!navigator.onLine) {
      self.displayMessage(window.lang.offlineNetwork, 10000, "error");
    }

    // Show generic error
    if (status !== undefined && status === "error") {
      self.displayMessage(window.lang.serverError, 10000, "error");
    }
  },

  // Deletes a model
  delete: function (e) {
    var self = this,
      button = $(e.currentTarget),
      model = self.item;
    e.preventDefault();

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    // Perform API call
    return model.destroy(
      $.extend(
        true,
        {},
        {
          success: function () {
            if (self.cache) {
              self.cache.removeItem(self.module);
            }
            self.item = null;
            self.afterDelete();
          }
        },
        self.destroyParams
      )
    );
  },

  // Runs before updating a model
  // eslint-disable-next-line no-unused-vars
  beforeUpdate: function (attributes, form) {
    return true;
  },

  // Runs before creating a model
  // eslint-disable-next-line no-unused-vars
  beforeCreate: function (attributes, form) {
    return true;
  },

  // Runs after updating a model
  afterUpdate: function (attributes) {
    var self = this;
    self.showUpdatedMessage(attributes);
    self.back();
  },

  // Runs after creating a model
  afterCreate: function (attributes) {
    var self = this;
    self.showCreatedMessage(attributes);
    self.back();
  },

  // Runs after deleting a model
  afterDelete: function () {
    var self = this;
    self.showDeletedMessage();
    self.back();
  },

  // Shows a message when an item is created
  showCreatedMessage: function () {
    var self = this,
      message;
    message = [
      window.lang["article" + self.gender],
      window.lang[this.module]["singular"],
      window.lang["hasBeenCreated" + self.gender]
    ];
    self.displayMessage(message.join(" "));
  },

  // Shows a message when an item is updated
  showUpdatedMessage: function () {
    var self = this,
      message;
    message = [
      window.lang["article" + self.gender],
      window.lang[this.module]["singular"],
      window.lang["hasBeenUpdated" + self.gender]
    ];
    self.displayMessage(message.join(" "));
  },

  // Shows a message when an item is deleted
  showDeletedMessage: function () {
    var self = this,
      message;
    message = [
      window.lang["article" + self.gender],
      window.lang[this.module]["singular"],
      window.lang["hasBeenDeleted" + self.gender]
    ];
    self.displayMessage(message.join(" "));
  },

  // Returns to the previous page
  back: function () {
    return window.history.back();
  },

  // Cleans up the view
  cleanUp: function () {
    var self = this;

    // Reset model
    self.item = null;

    // Stop loader
    self.loading(false);

    // Unbind events
    self.undelegateEvents();
    self.unbindPlugins();

    // Restore original template
    if (self.originalTemplate !== undefined && self.originalTemplate !== self.template) {
      self.setTemplate(self.originalTemplate);
    }

    // Close modal
    $(self.modal).modal("hide");

    // Return true to confirm clean up
    return true;
  },

  // Unbinds plugins
  unbindPlugins: function () {
    return this;
  },

  // Flag a field as changed
  changedField: function (e) {
    $(e.currentTarget).attr("data-changed", 1).closest("form").attr("data-dirty", 1);
  },

  // Flag a radio field as changed
  changedRadio: function (e) {
    var self = this,
      radio = $(e.currentTarget),
      name = radio.attr("name");

    // Removed the changed flag from all radio options
    self.$('[name="' + name + '"]').removeAttr("data-changed");

    // Set the changed flag on the one clicked
    radio.attr("data-changed", 1).closest("form").attr("data-dirty", 1);
  }
});

// Magnet List View
const ListView = BasicView.mix(FilterMixin).extend({
  // Options
  type: "list",
  module: "",
  el: ".app",
  renderArea: ".page",
  limit: 20,
  paginate: false,
  hasFilters: true,
  isFetching: false,
  query: {
    page: 1
  },
  sortOptions: [],
  modal: "[data-modal=main]",
  messenger: "[data-messenger=main]",
  relationshipsData: {},
  additionalsData: {},
  cache: window.emulatedStorage || window.localStorage,
  gender: "M",
  searchWrapper: ["*", "*"],
  fetchTimeout: 10 * 1000,
  nextPaginationMode: false,

  // Events
  events: {
    "submit form": "disableSubmitButton",
    "click [data-confirm]": "confirm",
    "click [data-action=mass]": "mass",
    "click [data-action=load-filter]": "loadFilter",
    "click [data-action=filter]": "filter",
    "click [data-action=remove-filter]": "removeFilter",
    "hidden.bs.dropdown .dropdown": "resetFilterSearch",
    "keyup [data-action=search-filter]": "searchFilter",
    "shown.bs.modal": "focusFirstModalInput",
    "click [data-action=open-search-form]": "openSearchForm",
    "submit [data-form=search]": "search",
    "click [data-action=check-all]": "checkAll",
    "change [data-action=check]": "check",
    "click [data-action=order]": "order",
    "click [data-action=load-more]": "loadMore",
    "click [data-navigate]": "navigate",
    "click a[href^='/']": "navigateHref"
  },

  // Methods
  start: function (params) {
    this.setTemplate(this.templates?.initial);
    this.collection = new app.collections[this.module]();
    this.urlParams = $.extend({}, params);
    this.defaultQuery = $.extend({}, this.query);
    this.query = $.extend({}, this.defaultQuery, this.urlParams);
    this.query.page = 1;
    this.collection.query = this.query;
    return this.fetchAdditionals();
  },
  fetchAdditionals: function () {
    this.collection.resolveAdditionals(
      false,
      function (additionals) {
        this.additionalsData = this.collection.parseAdditionals(additionals);
        this.render();
      }.bind(this),
      this.collection
    );
    return this;
  },
  render: function () {
    this.beforeRender(
      function () {
        if (this.paginate) {
          this.renderPage();
        }
        this.fetchItems();
      }.bind(this)
    );
    return this;
  },
  bindLoadMoreScroll: function () {
    var view = this,
      options;
    if (_.isUndefined(this.scrollOffset)) {
      this.scrollOffset = $(".content").get(0).scrollHeight / 2;
    }
    if (this.scrollOffset < $(".content").height()) {
      this.scrollOffset = $(".content").height();
    }

    // Set scroll plugin options
    options = {
      element: $("[data-action=load-more]").get(0),
      handler: function (direction) {
        if (direction === "down" && !view.isFetching) {
          view.loadMore();
        }
      },
      context: $(".content").get(0),
      offset: this.scrollOffset
    };

    // Destroy the plugin active instance
    if (_.isDefined(this.waypoint)) {
      this.waypoint.destroy();
    }

    // Listen to scroll if the load more button is present
    if (options.element) {
      this.waypoint = new window.Waypoint(options);
    }
  },
  renderPage: function () {
    this.$(this.renderArea).html(this.template(this.renderData()));
    if (this.hasFilters) {
      this.renderFilters();
    }
    this.setActiveState();
    this.afterRenderPage();
    app.layout.updateLayout();
  },
  fetchItems: function () {
    this.showItemsLoader(true);
    this.isFetching = true;
    this.fetchRequest = this.collection
      .fetch({
        data: !this.nextPaginationMode || !this.paging?.next ? this.beforeFetchItems().getParsedParameters() : {},
        timeout: this.fetchTimeout,
        remove: this.paginate ? false : true
      })
      .error(
        function (response) {
          this.isFetching = false;
          this.showItemsLoader(false);
          if (!navigator.onLine) {
            this.displayMessage(window.lang.requestTimeout, 5000, "error");
          }
          if (response.status === 401) {
            return app.session.logout(true);
          }
        }.bind(this)
      )
      .done(
        function (response) {
          this.isFetching = false;
          this.setPaging(
            response.paging ||
              (response.next
                ? { next: response.next, total: response.total, id: response.id }
                : response.data?.next
                ? { next: response.data.next, total: response.data.total, id: response.data.id }
                : null)
          );
          this.setFilters(response.filters);
          this.showItemsLoader(false);
          this.organize();
          if (this.paginate) {
            if (this.collection.length) {
              this.renderItems();
            } else {
              this.renderEmptyState();
            }
            this.setActiveFilters();
            this.afterRenderItems();
            this.bindLoadMoreScroll();
          } else {
            this.renderPage();
            this.setActiveFilters();
            this.afterRenderItems();
          }
        }.bind(this)
      );
    return this;
  },
  organize: function () {
    return this;
  },
  showItemsLoader: function (value) {
    var loading = this.$("[data-role=items-loader]"),
      loadMoreButton = this.$("[data-action=load-more]");
    if (value) {
      loading.show();
      loadMoreButton.hide();
    } else {
      loading.hide();
      loadMoreButton.show();
    }
  },
  beforeFetchItems: function () {
    return this;
  },
  setPaging: function (paging) {
    var area = $(this.renderArea);
    this.paging = paging || { total: 0 };

    if (!this.nextPaginationMode) {
      if (!this.paging.next) {
        area.find("[data-action=load-more]").remove();
      }
    } else {
      if (paging?.id || this.next) {
        this.collection.url = this.nextPaginationParseUrl(this.paging?.next || this.next, this.paging?.id || this.id);
      } else {
        area.find("[data-action=load-more]").remove();
      }
    }

    area
      .find("[data-role=paging-total]")
      .text([formatInteger(this.paging.total), window.lang[this.module].plural].join(" "));
    return this;
  },
  setFilters: function (filters) {
    this.filters = filters;
  },
  setActiveState: function () {
    if (this.hasFilters) {
      this.setActiveOrder();
      this.setActiveSearch();
    }
  },
  renderItems: function () {
    var html = this.templates.rows(this.renderData());
    this.$("[data-role=rows]").append(html);
  },
  renderEmptyState: function () {
    var html = this.templates.empty(this.renderData());
    $("[data-role=content]").html(html);
  },
  loadMore: function () {
    this.query.page++;
    this.fetchItems();
  },
  renderData: function (object) {
    var data = {
      paging: this.paging,
      params: this.query,
      hasFilter: this.hasFilter(),
      data: this.collection.slice((this.query.page - 1) * this.limit, this.query.page * this.limit),
      sortOptions: this.sortOptions,
      relationshipsData: this.relationshipsData,
      additionalsData: this.additionalsData,
      lang: window.lang,
      config: window.config,
      session: app.session,
      extra: this.extraData()
    };
    return $.extend({}, data, object);
  },
  extraData: function () {
    return false;
  },
  hasFilter: function () {
    if (_.isDefined(this.urlParams)) {
      return _.isDefined(this.urlParams.filter) || _.isDefined(this.urlParams.q);
    }
    return false;
  },
  getParsedParameters: function () {
    var params = $.extend({}, this.query) || {};

    // Sets limit and offset based on the page parameter
    params.page = params.page || 1;
    params.limit = params.limit || this.limit;
    params.offset = (params.page - 1) * params.limit;
    delete params.page;

    // Sets sortBy and sortDir based on `order` parameter
    if (_.isDefined(params.order)) {
      const order = params.order.split(" ");
      if (order.length === 2) {
        params.sortBy = order.shift();
        params.sortDir = order.shift();
      } else {
        params.sortBy = order;
        params.sortDir = "asc";
      }
      delete params.order;
    }

    // Transform filters
    if (_.isDefined(params.filter)) {
      _.each(params.filter, function (value, index) {
        if (typeof value === "object") {
          _.each(Object.keys(value), function (value2) {
            params["filters." + index + "." + value2] = value[value2];
          });
        } else {
          params["filters." + index] = value;
        }
      });
      delete params.filter;
    }

    // Return parsed
    return params;
  },
  afterRenderPage: function () {
    return this;
  },
  afterRenderItems: function () {
    return this;
  },
  confirm: function (e) {
    var count = this.getChecked().length,
      button = $(e.currentTarget),
      titleText = button.data("confirm-title");
    this.confirmModal(this.getConfirmationText(count, button), button, titleText);
  },
  getConfirmationText: function (count, button) {
    var noun = button.data("confirm-noun") || this.module,
      strings = [button.data("confirm")];
    if (count > 1) {
      strings.push(window.lang["items" + this.gender]);
      strings.push(count);
      strings.push(window.lang[noun]["plural"]);
    } else {
      strings.push(window.lang["item" + this.gender]);
      strings.push(window.lang[noun]["singular"]);
    }
    return strings.join(" ") + "?";
  },
  check: function (e) {
    var checkbox = $(e.currentTarget);
    if (checkbox.is(":checked")) {
      checkbox.closest("[data-id]").addClass("selected");
    } else {
      checkbox.closest("[data-id]").removeClass("selected");
    }
    this.toggleCheckAll();
    this.toggleActions();
  },
  toggleCheckAll: function () {
    var checkAll = this.$("[data-action=check-all]"),
      checkCount = this.$("[data-role=check-count]"),
      checked = this.$("[data-action=check]:checked"),
      total = this.$(".paging-total"),
      text = "";
    if (checked.length) {
      checkAll.prop("checked", true);
      if (checked.length > 1) {
        text = [checked.length, window.lang[this.module + "Plural"], window.lang["selectedPlural" + this.gender]].join(
          " "
        );
      } else {
        text = [
          checked.length,
          window.lang[this.module + "Singular"],
          window.lang["selectedSingular" + this.gender]
        ].join(" ");
      }
      checkCount.text(text);
      total.addClass("hide");
    } else {
      checkAll.prop("checked", false);
      checkCount.text("");
      total.removeClass("hide");
    }
  },
  checkAll: function (e) {
    var checker = $(e.currentTarget),
      checkboxes = this.$("[data-action=check]");
    if (checker.is(":checked")) {
      checkboxes.prop("checked", true).trigger("change");
    } else {
      checkboxes.prop("checked", false).trigger("change");
    }
    this.toggleActions();
  },
  getChecked: function () {
    var view = this;
    return this.$("[data-action=check]:checked")
      .map(function () {
        return view.collection.get($(this).val());
      })
      .get();
  },
  getFirstChecked: function () {
    return this.getChecked().shift();
  },
  toggleActions: function () {
    var toolbar = this.$("[data-role=toolbar]"),
      none = this.$("[data-type=none]"),
      onePlus = this.$("[data-type=one-plus]"),
      checkboxes = this.$("[data-action=check]");
    if (checkboxes.is(":checked")) {
      none.addClass("hide");
      onePlus.removeClass("hide");
      toolbar.addClass("edit-mode");
    } else {
      none.removeClass("hide");
      onePlus.addClass("hide");
      toolbar.removeClass("edit-mode");
    }
  },
  mass: function (e) {
    var view = this,
      button = $(e.currentTarget),
      params = button.data("params"),
      method = button.data("method"),
      feedback = button.data("feedback"),
      calls = [],
      items = this.getChecked();
    e.preventDefault();

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    // Perform preliminar actions
    this.beforeMass(params);

    // Create calls
    items.forEach(function (item) {
      calls.push({
        item: item,
        method: method,
        params: params,
        success: function (response, call, sequence) {
          view.eachMass({
            id: call.item.id,
            method: call.method,
            response: response,
            params: call.params,
            count: sequence.index + 1,
            length: sequence.total
          });
        }
      });
    });

    // Perform sequence
    const sequence = new Sequence({
      calls: calls,
      completed: function () {
        this.afterMass(params, method);
        this.showMassMessage(items.length, feedback);
        this.reload();
      }.bind(this)
    });
    sequence.execute();
  },
  beforeMass: function () {
    return this;
  },
  eachMass: function (params) {
    this.displayMessage(
      [window.lang.massProgress[params.method], params.count, window.lang.of, params.length].join(" ")
    );
    return this;
  },
  afterMass: function () {
    return this;
  },
  showMassMessage: function (count, message) {
    var mode = "plural";
    if (count === 1) {
      mode = "singular";
      if (message) {
        message = message.substring(0, message.length - 1);
      }
    }
    message = [count, window.lang[this.module][mode], message];
    this.displayMessage(message.join(" "));
  },
  order: function (e) {
    var params = $(e.currentTarget).data("params");
    e.preventDefault();
    this.urlFilter({
      key: "order",
      value: params.column + " " + params.type
    });
  },
  openSearchForm: function (e) {
    var searchForm = $(e.currentTarget).closest("[data-form=search]");
    if (searchForm.is(".open")) {
      searchForm.submit();
    } else {
      searchForm.addClass("open").find("input").focus();
    }
  },
  search: function (e) {
    var input = $(e.currentTarget).find("input"),
      name = input.attr("name"),
      keyword = input.val();
    e.preventDefault();
    if (!keyword.trim()) {
      if (app.router.current.query.q) {
        app.router.navigateTo(window.location.pathname + this.unfilter({ key: "q" }));
      }
      return this;
    }
    this.urlFilter({
      key: name,
      // el | backbone lo toma como un separadaor y convierte el q en array
      value: this.searchWrapper[0] + keyword.trim().replace(/\|/g, "%7C") + this.searchWrapper[1]
    });
    return this;
  },
  urlFilter: function (params, search) {
    search = search || window.location.search;

    var regex = new RegExp("(" + params.key + "=)([^&]+)"),
      hasParam = search.match(regex),
      concat;

    this.collection.reset();

    // Clean up the URL
    search = this.cleanUrl(search);

    // Remove the required parameter
    if (_.isDefined(params.remove) && params.remove) {
      search = this.unfilter({ key: params.remove });
    }

    // Replace or add the parameter
    if (hasParam) {
      search = search.replace(regex, "$1" + params.value);
    } else {
      concat = search.includes("?") ? "&" : "?";
      search = search + concat + params.key + "=" + params.value;
    }

    // Return the updated URL hash
    app.router.navigateTo(window.location.pathname + search);
  },
  removeFilter: function (e) {
    var params = $(e.currentTarget).data("params"),
      search;
    e.preventDefault();
    e.stopPropagation();
    search = this.unfilter({ key: params });
    app.router.navigateTo(window.location.pathname + search);
  },
  unfilter: function (params) {
    var search = window.location.search;
    params.key = _.isArray(params.key) ? params.key : [params.key];
    params.key.forEach(
      function (key) {
        var regex = new RegExp("(" + key + "=)([^&]+)");
        if (search.match(regex)) {
          search = search.replace(regex, "");
          this.removeParameterFromQuery(key);
        }
        search = this.cleanUrl(search);
      }.bind(this)
    );
    return search;
  },
  removeParameterFromQuery: function (parameter) {
    var key, value;
    for (key in this.query) {
      value = this.query[key];

      // Remove simple parameters
      if (_.isString(key) && key === parameter) {
        delete this.query[key];
      }

      // Remove nested parameters with dot notation
      if (_.isObject(value)) {
        _.each(
          value,
          function (subValue, subIndex) {
            if (key + "." + subIndex === parameter) {
              delete this.query[key][subIndex];
            }
          }.bind(this)
        );
      }
    }
  },
  cleanUrl: function (hash) {
    hash = hash.replace(/\?&/, "?");
    hash = hash.replace(/page=([\d]+)/, "page=1");
    if (hash.endsWith("&")) {
      hash = hash.slice(0, -1);
    }
    if (hash.endsWith("?")) {
      hash = hash.slice(0, -1);
    }
    return hash;
  },
  setActiveFilters: function () {
    var view = this;
    _.each(
      this.filters,
      function (value, filter) {
        var text,
          span = this.$(this.renderArea).find('[data-filter-name="filter.' + filter + '"]'),
          reset = this.$(this.renderArea).find('[data-filter-reset="filter.' + filter + '"]'),
          dropdown = span.closest(".dropdown");
        if (!span.length) {
          return false;
        }
        text = value;
        if (filter === "linkId" && objHas(view.additionalsData, "links")) {
          _.each(view.additionalsData.links, function (link) {
            if (link.id == value) {
              text = link.url;
            }
          });
        }
        if (_.isObject(value) && !_.isArray(value) && objHas(value, "name")) {
          text = value.name;
        }
        if (objHas(window.lang, this.module, "filters", filter, value)) {
          text = window.lang[this.module].filters[filter][value];
        }
        span.text(text);
        reset.removeClass("hide");
        dropdown.find("[data-toggle]").addClass("active");
      }.bind(this)
    );
  },
  setActiveOrder: function () {
    var order,
      column = false,
      // = asc,
      sortIndicators = this.$("[data-action=order]"),
      sortButton = this.$("[data-role=order-button]");

    // Get the column and order type
    if (_.isDefined(this.query.order)) {
      order = this.query.order.split(" ");
      if (order.length === 2) {
        column = order.shift();
        //type = order.shift();
      } else {
        column = order;
        //type = 'asc';
      }
    }

    // Update text
    sortIndicators.each(function () {
      var params = $(this).data("params");
      if (params.column === column) {
        sortButton.find("span").text($(this).text());
      }
    });
  },
  setActiveSearch: function () {
    var keyword = this.query.q || "",
      term = keyword.replace(/[*!]/g, "");
    if (term.length) {
      this.$(this.renderArea).find("[data-form=search]").addClass("open").find("input").attr("value", term);
    }
  },
  isEmpty: function () {
    return !this.collection.length && this.query.q === undefined && this.query.filter === undefined;
  },
  cleanUp: function () {
    // Stop current request
    if (_.isDefined(this.fetchRequest) && this.fetchRequest.readyState > 0 && this.fetchRequest.readyState < 4) {
      this.loading(false);
      this.fetchRequest.abort();
    }

    // Stop loader
    this.loading(false);

    // Restore original query
    if (_.isDefined(this.defaultQuery)) {
      this.query = $.extend({}, this.defaultQuery);
    }

    // Undelegate events
    this.undelegateEvents();

    // Restore original template
    if (_.isDefined(this.originalTemplate)) {
      this.setTemplate(this.originalTemplate);
    }

    // Return true to confirm clean up
    return true;
  }
});

// Magnet Selector View
const SelectorView = ListView.extend({
  module: false,
  type: "selector",
  hasFilters: false,
  cache: false,
  limit: 1000,
  shown: false,
  renderArea: "[data-modal=selector]",
  checkboxInputName: "",
  radioInputName: "",
  fetchTimeout: 30 * 1000,
  defaults: {
    inputName: false,
    selectorType: "checkbox"
  },
  events: {
    "submit [data-form=select-items]": "returnSelectedItems",
    "click [data-action=show-prompt]": "showPrompt",
    "click [data-action=select-all]": "selectAll",
    "click [data-action=unselect-all]": "unselectAll",
    "submit [data-form=create-item]": "createItem",
    "click [data-action=hide-prompt]": "hidePrompt",
    'change input[name="items[]"]': "toggleCheck",
    "keydown .finder": "preventFinderSubmit"
  },
  open: function (options) {
    this.options = $.extend(true, {}, this.defaults, options);
    if (objHas(options, "checkboxInputName")) {
      this.checkboxInputName = options.checkboxInputName;
    }
    if (objHas(options, "radioInputName")) {
      this.radioInputName = options.radioInputName;
    }
    this.start($.extend(true, {}, this.options.query));
    this.$(this.renderArea).on(
      "hidden.bs.modal",
      function () {
        this.closed();
      }.bind(this)
    );
    return this;
  },
  organize: function () {
    var selected = this.options.selectedItems;
    if (_.isUndefined(selected) || !_.isArray(selected)) {
      selected = [];
    }
    this.collection.models.map(function (list) {
      if (_.contains(selected, list.id)) {
        list.set("checked", true);
      } else {
        list.set("checked", false);
      }
      return list;
    });
    this.collection.comparator = function (model) {
      return !model.get("checked");
    };
    this.collection.sort();
  },
  afterRenderPage: function () {
    var modal = this.$(this.renderArea);
    if (!this.shown) {
      this.shown = true;
      modal.modal("show").on("hide.bs.modal", function () {
        modal.off("hide.bs.modal");
        this.shown = false;
      });
    }
  },
  extraData: function () {
    return this.options;
  },
  toggleCheck: function (e) {
    var input = $(e.currentTarget),
      checked = [];
    if (this.options.selectorType === "radio") {
      checked = this.collection.findWhere({ checked: true });
      if (checked) {
        checked.set("checked", false);
      }
    }
    checked = this.collection.findWhere({
      id: parseInt(input.attr("value"), 10)
    });
    if (checked) {
      checked.set("checked", input.is(":checked"));
    }
  },
  preventFinderSubmit: function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  },
  selectAll: function () {
    this.collection.each(function (model) {
      model.set("checked", true);
    });
    this.renderPage();
  },
  unselectAll: function () {
    this.collection.each(function (model) {
      model.set("checked", false);
    });
    this.renderPage();
  },
  showPrompt: function (e) {
    e.preventDefault();
    this.$("[data-role=prompt]").removeClass("hidden").find("input").first().focus();
  },
  createItem: function (e) {
    var selector = this,
      form = $(e.currentTarget),
      name = form.serializeObject().name || "",
      item = new app.models[this.module]({ name: name }),
      attributes = { name: name, checked: true };
    e.preventDefault();

    // Validate values
    if (!this.validateFormFields(form)) {
      return this;
    }

    // Create list via AJAX
    item.save(attributes, {
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify(attributes),
      success: function () {
        localStorage.removeItem(selector.module);
        selector.options.selectedItems.unshift(item.get("id"));
        selector.collection.add(item.toJSON());
        selector.collection.sort();
        selector.renderPage();
      },
      error: function (xhr, textStatus, error) {
        error = textStatus.responseJSON.error;
        if (error.validationErrors !== undefined) {
          return selector.showValidationErrors(error.validationErrors, $(selector.renderArea));
        }
      }
    });
  },
  returnSelectedItems: function (e) {
    var template = this.templates.selectorSnippet,
      name = this.checkboxInputName,
      selected = this.collection.where({ checked: true });
    e.preventDefault();
    this.options.selectedItems = _.pluck(selected, "id");

    // Check if at least one item is selected on required forms
    if (!selected.length && this.options.requiredItem !== undefined) {
      alert(this.options.requiredItem);
      this.enableSubmitButton(this.renderArea);
      return false;
    }

    // Set input name
    if (this.options.inputName) {
      name = this.options.inputName;
    } else {
      if (this.options.selectorType === "radio") {
        name = this.radioInputName;
      } else {
        this.checkboxInputName;
      }
    }

    // Render an HTML lists snippet
    this.html = template({
      lang: window.lang,
      data: selected,
      name: name,
      options: this.options
    });

    // Remove errors related to the selector
    if (this.options.inputName) {
      this.$('[data-validation-message="' + this.options.inputName + '"]').addClass("error-hidden");
    }

    // Trigger event and close modal
    this.trigger("done", selected);
    $(this.renderArea).modal("hide");
    this.shown = false;

    // Return instance
    return this;
  },
  closed: function () {
    this.trigger("done", []);
  },
  reset: function () {
    this.collection.reset();
    this.options.selectedItems = [];
    return this;
  },
  hidePrompt: function () {
    this.$("[data-role=prompt]").addClass("hidden");
  }
});

const Magnet = {
  Collection,
  Model,
  BasicView,
  EditView,
  ListView,
  SelectorView
};

export default Magnet;
export { Collection, Model, BasicView, EditView, ListView, SelectorView };
