<template>
  <div class="flex flex-col space-y-8 py-2">
    <h3 class="m-0 text-base font-medium text-gray-700">{{ t("title") }}</h3>
    <p class="text-sm font-normal text-gray-500">
      {{ t("description") }}
    </p>
  </div>
  <div class="mt-6 flex justify-between border-t pb-2 pt-7">
    <a class="flex items-center space-x-1 text-sm font-semibold text-sky-400" @click="moreAction">
      <span>
        {{ t("moreText") }}
      </span>
      <ArrowRightIcon class="h-3.5 w-3.5" />
    </a>
    <div class="flex space-x-2">
      <SimpleButton theme="secondary" @click="openBrandEditor">
        {{ t("actionText") }}
      </SimpleButton>
    </div>
  </div>

  <BrandEditorModal :open="brandEditorModal" @close="closeBrandEditor" @save="saveBrandEditor" />
</template>

<script lang="ts" setup>
import { ref } from "vue";

// Components
import { ArrowRightIcon } from "@heroicons/vue/outline";
import BrandEditorModal from "@/vue/components/organisms/BrandEditor/BrandEditorModal.vue";
import SimpleButton from "@atoms/SimpleButton.vue";

// Language
import { useI18n } from "vue-i18n";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";
import { useOnboardingStore } from "@/vue/stores";

// States
const { t } = useI18n();

const trackingEventsService = useTrackingEvents();
const onboardingStore = useOnboardingStore();
const brandEditorModal = ref(false);

const moreAction = () => {
  window.Intercom("showArticle", 10521706);
};

const openBrandEditor = () => {
  trackOpenBrandEditor();

  brandEditorModal.value = true;
};

const closeBrandEditor = () => {
  brandEditorModal.value = false;
};

const saveBrandEditor = () => {
  onboardingStore.setTaskCompleted({
    id: "brandEditor",
  });

  trackingEventsService.amplitude({
    name: "APP_BRAND_EDITOR_HOME_COMPLETE",
  });

  brandEditorModal.value = false;
};

const trackOpenBrandEditor = () => {
  trackingEventsService.amplitude({
    name: "APP_BRAND_EDITOR_HOME_OPENED",
  });
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Elementos de tu marca",
    "description": "Configura los elementos de tu marca como colores, logotipo o tipografía y aplicalos en los e-mails de tus automations.",
    "moreText": "Leer más sobre configuración de marca",
    "actionText": "Configurar",
  },
  "pt": {
    "title": "Elementos de sua marca",
    "description": "Configure os elementos da sua marca, como cores, logotipo ou tipografia, e aplique-os nos e-mails das suas automações.",
    "moreText": "Leia mais sobre configuração de marca",
    "actionText": "Configurar",
  },
}
</i18n>
