<template>
  <div v-if="showAccordion" class="flex flex-col space-y-7">
    <button
      v-for="(section, index) in sections"
      :key="section.id"
      :class="[
        {
          shadow: selectedSection?.id === section.id,
        },
        'flex w-full flex-col space-y-4 rounded-lg border border-gray-200 px-6 py-4 hover:ring-1 hover:ring-sky-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-sky-500 focus-visible:ring-offset-2',
      ]"
      @click.stop="() => selectSection(section)"
    >
      <div class="flex w-full items-center justify-between px-3">
        <div class="flex items-center space-x-4">
          <div
            :class="[
              section.completed ? 'border-green-400 text-green-400' : 'border-sky-500 text-sky-500',
              'relative flex h-8 w-8 items-center justify-center rounded-full border',
            ]"
          >
            <span class="text-lg">
              {{ index + 1 }}
            </span>
            <div>
              <CheckCircleIcon v-show="section.completed" class="absolute -right-2 -top-2 h-5 w-5" />
            </div>
          </div>
          <h2 class="m-0 text-lg font-semibold text-gray-700">
            {{ section.title }}
          </h2>
        </div>
        <div :class="[section.completed ? 'text-green-400' : 'text-sky-400', 'flex items-center space-x-2']">
          <span v-if="section.completed" class="text-sm font-medium">
            {{ t("completed") }}
          </span>
          <span v-else class="text-sm">
            {{
              t(
                "pendingSteps",
                { count: section.steps.filter((s) => !s.completed).length },
                { plural: section.steps.filter((s) => !s.completed).length },
              )
            }}
          </span>
          <FlagIcon v-show="section.completed" class="h-4 w-4" />
        </div>
      </div>
      <Transition
        leave-active-class="transition-all duration-200 overflow-hidden ease-in-out"
        leave-from-class="opacity-100 max-h-[30rem]"
        leave-to-class="opacity-0 max-h-0"
        enter-active-class="transition-all duration-200 overflow-hidden ease-in-out"
        enter-from-class="opacity-0 max-h-0"
        enter-to-class="opacity-100 max-h-[30rem]"
        mode="out-in"
      >
        <div v-show="selectedSection?.id === section.id" class="flex w-full flex-col space-y-4" @click.stop>
          <AlertBox v-if="section.showReward && section.rewardText && section.rewardButtonText" theme="info">
            <template #icon>
              <RocketSolidIcon class="mr-0.5 mt-1 h-6 w-6 text-sky-700" />
            </template>
            <div class="flex items-center justify-between text-base font-medium">
              <span>
                {{ section.rewardText }}
              </span>
              <SimpleButton
                size="small"
                :disabled="!section.completed"
                :loading="loadingReward"
                @click="() => section.completed && section.rewardId && rewardAction(section.rewardId)"
              >
                {{ section.rewardButtonText }}
              </SimpleButton>
            </div>
          </AlertBox>
          <div
            v-for="step in section.steps"
            :key="step.id"
            :class="[
              selectedStep?.id === step.id ? 'divide-y border border-sky-500' : 'hover:border-sky-400',
              'flex w-full flex-col rounded-lg',
            ]"
          >
            <button
              :class="[
                {
                  'hover:ring-1 hover:ring-sky-400 focus-visible:ring-1 focus-visible:ring-sky-500 focus-visible:ring-offset-2':
                    selectedStep?.id !== step.id && !stepTransitioning,
                },
                'flex w-full items-center justify-between rounded-lg px-4 py-4 focus-visible:outline-none',
              ]"
              @click.stop="() => selectStep(step)"
            >
              <div class="flex items-center justify-between space-x-4">
                <div
                  v-if="step.omitted && !step.completed"
                  class="flex h-7 w-7 items-center justify-center rounded-full bg-green-400 text-center"
                >
                  <ReplyIcon class="h-5 w-5 scale-x-[-1] text-white" />
                </div>
                <div
                  v-else-if="step.completed"
                  class="flex h-7 w-7 items-center justify-center rounded-full bg-green-400 text-center"
                >
                  <CheckIcon class="h-5 w-5 text-white" />
                </div>
                <component v-else :is="step.icon" class="ml-1 h-6 w-6 text-sky-500" />
                <h2 class="m-0 text-base font-medium text-gray-700">
                  {{ step.title }}
                </h2>
                <div class="flex items-center space-x-1 text-gray-500">
                  <ClockIcon class="h-4 w-4" />
                  <span class="text-xs font-normal">{{ step.duration }}</span>
                </div>
              </div>
              <div class="text-gray-400">
                <ChevronDownIcon v-show="selectedStep?.id !== step.id" class="h-6 w-6" />
                <ChevronUpIcon v-show="selectedStep?.id === step.id" class="h-6 w-6" />
              </div>
            </button>
            <Transition
              leave-active-class="transition-all duration-200 overflow-hidden ease-in-out"
              leave-from-class="opacity-100 max-h-[30rem]"
              leave-to-class="opacity-0 max-h-0"
              enter-active-class="transition-all duration-200 overflow-hidden ease-in-out"
              enter-from-class="opacity-0 max-h-0"
              enter-to-class="opacity-100 max-h-[30rem]"
              mode="out-in"
            >
              <div v-if="selectedStep?.id === step.id" class="mx-16 cursor-default divide-y">
                <div class="py-4 text-left">
                  <AlertBox
                    v-if="
                      (!step.completed && step.omitted && step.omittedMessage) ||
                      (step.completed && step.completedMessage)
                    "
                    theme="success"
                    class="mb-4"
                  >
                    <template #icon>
                      <ThumbUpIcon class="h-5 w-5 text-green-700" />
                    </template>
                    <span v-if="!step.completed && step.omitted" class="text-base font-semibold text-green-700">
                      {{ step.omittedMessage }}
                    </span>
                    <span v-else-if="step.completed" class="text-base font-semibold text-green-700">
                      {{ step.completedMessage }}
                    </span>
                  </AlertBox>
                  <SendersSection v-if="step.type === 'senders'" />
                  <IntegrationSection
                    v-else-if="step.type === 'integration'"
                    :recommended-store="step.recommendedStore"
                    :integrated="step.integrated"
                  />
                  <BrandEditorSection v-else-if="step.type === 'branding'" />
                  <VideoSection v-else :step="step" />
                </div>
                <div v-show="step.moreText || (step.allowOmit && step.allowAction)" class="flex justify-between py-8">
                  <a class="flex items-center space-x-1 text-sm font-semibold text-sky-400" @click="step.moreAction">
                    <span>
                      {{ step.moreText }}
                    </span>
                    <ArrowRightIcon class="h-3.5 w-3.5" />
                  </a>
                  <div v-if="step.allowMarkComplete || step.allowOmit || step.allowAction" class="flex space-x-2">
                    <SimpleButton
                      v-show="step.allowMarkComplete"
                      theme="white"
                      :loading="loadingMarkComplete"
                      @click="() => step.markComplete && markComplete(step.markComplete)"
                    >
                      <template #leading>
                        <CheckCircleIcon class="text-gray-500" />
                      </template>
                      {{ step.markCompleteText }}
                    </SimpleButton>
                    <SimpleButton
                      v-show="step.allowOmit"
                      theme="white"
                      :loading="loadingOmit"
                      @click="() => step.omit && omit(step.omit)"
                    >
                      <template #leading>
                        <CheckCircleIcon class="text-gray-500" />
                      </template>
                      {{ step.omitText }}
                    </SimpleButton>
                    <SimpleButton v-show="step.allowAction" :loading="loadingAction" @click="() => action(step.action)">
                      <template v-if="step.actionIcon" #leading>
                        <component :is="step.actionIcon" />
                      </template>
                      {{ step.actionText }}
                    </SimpleButton>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </Transition>
    </button>
  </div>
  <AccordionSkeleton v-else :sections="sections" />
</template>
<script lang="ts" setup>
import { watch, ref, computed } from "vue";

import type { Step, Sections, Section } from "../home.types";

// Components
import AccordionSkeleton from "./AccordionSkeleton.vue";
import SendersSection from "./SendersSection.vue";
import IntegrationSection from "./IntegrationSection.vue";
import BrandEditorSection from "./BrandEditorSection.vue";
import VideoSection from "./VideoSection.vue";
import AlertBox from "@atoms/AlertBox.vue";
import SimpleButton from "@atoms/SimpleButton.vue";

// Icons
import RocketSolidIcon from "@tokens/icons/RocketSolidIcon.vue";
import { ArrowRightIcon, ClockIcon, FlagIcon } from "@heroicons/vue/outline";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CheckCircleIcon,
  ReplyIcon,
  CheckIcon,
  ThumbUpIcon,
} from "@heroicons/vue/solid";

// Notifications
import { useNotifications } from "@composables/notifications";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Store
import { storeToRefs } from "pinia";
import { useOnboardingStore, useSessionStore } from "@store";

// Utils
import { useI18n } from "vue-i18n";

// Domain
import { RewardId } from "@domain/onboarding";
import { getEventActionFromTaskId } from "@domain/onboarding";

const { t } = useI18n();

const onboardingStore = useOnboardingStore();
const sessionStore = useSessionStore();
const trackingEventsService = useTrackingEvents();

const { notify } = useNotifications();

const { rewards } = storeToRefs(onboardingStore);

const props = withDefaults(
  defineProps<{
    sections: Sections;
    loading?: boolean;
  }>(),
  {
    loading: false,
  },
);

const loadingSections = ref(false);

const showAccordion = computed(() => {
  return !props.loading && !loadingSections.value;
});

const getFirstNotCompletedStep = (section: Section): Step | undefined => {
  return section.steps.find((s) => !s.completed && !s.omitted);
};

const getFirstNotCompletedSection = (): Section | undefined => {
  return props.sections.find((s) => !s.completed);
};

const stepTransitioning = ref(false);
const selectedStep = ref<Step>();
const selectedSection = ref<Section>();

const selectSection = (section: Section) => {
  selectedSection.value = selectedSection.value?.id === section.id ? undefined : section;
  selectedStep.value = undefined;
};

const selectStep = (step: Step) => {
  if (stepTransitioning.value) return;

  stepTransitioning.value = true;
  selectedStep.value = selectedStep.value?.id === step.id ? undefined : step;

  const action = getEventActionFromTaskId(step.id);

  trackingEventsService.amplitude({
    name: "APP_HOME_TASK_OPENED",
    data: {
      action: action,
      taskId: step.id,
    },
  });
  setTimeout(() => {
    stepTransitioning.value = false;
  }, 200);
};

const loadingAction = ref(false);
const action = async (actionCallback?: () => void) => {
  if (!actionCallback) return;

  loadingAction.value = true;
  await actionCallback();
  loadingAction.value = false;
};

const loadingOmit = ref(false);
const omit = async (omitCallback?: () => Promise<void>) => {
  if (!omitCallback) return;

  loadingOmit.value = true;
  await omitCallback();
  loadingOmit.value = false;
};

const loadingMarkComplete = ref(false);
const markComplete = async (omitCallback?: () => Promise<void>) => {
  if (!omitCallback) return;

  loadingMarkComplete.value = true;
  await omitCallback();
  loadingMarkComplete.value = false;
};

const loadingReward = ref(false);

const rewardAction = async (rewardId: RewardId) => {
  if (sessionStore.session.isMasterUser) {
    notify({
      title: t("error.rewardMasteruser.title"),
      text: t("error.rewardMasteruser.text"),
      theme: "info",
      timeout: 5000,
    });
    return;
  }

  if (!rewards.value[rewardId]) return;

  loadingReward.value = true;
  await rewards.value[rewardId]?.execute();
  loadingReward.value = true;
};

watch(
  [() => props.sections],
  () => {
    loadingSections.value = true;
    stepTransitioning.value = true;
    selectedSection.value = getFirstNotCompletedSection();

    if (selectedSection.value) {
      selectedStep.value = getFirstNotCompletedStep(selectedSection.value);
    }
    loadingSections.value = false;
    stepTransitioning.value = false;
  },
  { immediate: true },
);
</script>

<i18n lang="jsonc">
{
  "es": {
    "completed": "Completado",
    "pendingSteps": "1 tarea pendiente | {count} tareas pendientes",
    "error": {
      "rewardMasteruser": {
        "title": "Error masteruser",
        "text": "Los masterusers no pueden reclamar las recompensas",
      },
    },
  },
  "pt": {
    "completed": "Concluído",
    "pendingSteps": "1 tarefa pendente | {count} tarefas pendentes",
    "error": {
      "rewardMasteruser": {
        "title": "Erro masteruser",
        "text": "Os masterusers não podem reivindicar as recompensas",
      },
    },
  },
}
</i18n>
