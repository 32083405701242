<template>
  <div class="grid gap-4">
    <ComboBox
      :model-value="selected"
      :data-items="fonts"
      :label="t('title')"
      :placeholder="t('placeholder')"
      :show-key="false"
      @update:model-value="updateFont"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";

// Components
import ComboBox from "@molecules/ComboBox.vue";

// Lang
import { useI18n } from "vue-i18n";

// Types
import type { DataItem as ComboboxDataItem } from "@molecules/ComboBox.vue";

// Domain
import type { DataItem } from "@domain/data";
import type { Font, FontWithAlter } from "@domain/fonts";

type fontFamilyDataItem = DataItem<string, string, Font | FontWithAlter>;
type fontFamilyComboboxDataItem = ComboboxDataItem<Font | FontWithAlter, string, string>;

const props = defineProps<{
  selectedFont: Font | FontWithAlter;
  fonts: fontFamilyComboboxDataItem[];
}>();

const emit = defineEmits<{
  (e: "update:selectedFont", value: fontFamilyDataItem): void;
}>();

// States
const { t } = useI18n();

const fonts = ref(props.fonts);
const selected = ref<fontFamilyComboboxDataItem>();

const updateFont = (value: fontFamilyComboboxDataItem) => {
  selected.value = value;
  emit("update:selectedFont", value as fontFamilyDataItem);
};

const findFont = ({
  overrideFonts,
  overrideFont,
}: {
  overrideFonts?: fontFamilyComboboxDataItem[];
  overrideFont?: Font | FontWithAlter;
}): fontFamilyComboboxDataItem | undefined => {
  const _fonts = overrideFonts || props.fonts;
  const _font = overrideFont || props.selectedFont;

  const fallback = _fonts.find((font) => font.key.toLowerCase() === "fallback_arial") || _fonts[0];

  let foundFont = _fonts.find((font) => font.key.toLowerCase() === _font.id.toLowerCase());
  foundFont = foundFont != null ? foundFont : fallback;

  return foundFont;
};

watch(
  () => props.fonts,
  (newValue) => {
    fonts.value = newValue;

    let foundFont = findFont({
      overrideFonts: newValue,
    });

    if (foundFont) updateFont(foundFont);
  },
  { immediate: true },
);

watch(
  () => props.selectedFont,
  (newValue) => {
    let foundFont = findFont({
      overrideFont: newValue,
    });

    selected.value = foundFont;
  },
);
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Tipo de letra",
    "placeholder": "Selecciona una fuente",
  },
  "pt": {
    "title": "Fonte",
    "placeholder": "Selecione uma fonte",
  },
}
</i18n>
