<template>
  <div class="grid gap-2">
    <p class="text-sm font-medium text-gray-700">{{ t("title") }}</p>
    <SimpleInput
      :model-value="webStoreUrl"
      type="text"
      :placeholder="t('placeholder')"
      :has-error="hasError"
      @update:model-value="onUpdate"
    />
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from "vue";
import SimpleInput from "@atoms/SimpleInput.vue";
import { useI18n } from "vue-i18n";

// Props
defineProps<{
  webStoreUrl: string;
  hasError: boolean;
}>();

// Events
const emit = defineEmits<{
  (e: "update:web-store-url", value: string): void;
}>();

// i18n
const { t } = useI18n();

// Emitir evento cuando cambia el valor
const onUpdate = (value: string) => {
  emit("update:web-store-url", value);
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Link a tu tienda",
    "placeholder": "https://",
  },
  "pt": {
    "title": "Link para sua loja",
    "placeholder": "https://",
  },
}
</i18n>
