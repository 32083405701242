<template>
  <div class="grid gap-2">
    <div class="grid grid-cols-2 items-center gap-4">
      <p class="text-sm font-medium text-gray-700">{{ t("title") }}</p>
      <FormSwitch :model-value="useFooterLinks" @update:modelValue="updateUseFooterLinks" />
    </div>

    <transition-group
      leave-active-class="transition duration-300 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0 transform translate-x-10"
      enter-active-class="transition duration-300 ease-in-out"
      enter-to-class="opacity-100"
      enter-from-class="opacity-0 transform translate-x-10"
    >
      <div
        v-for="(link, index) in footerLinksOptions"
        v-show="useFooterLinks"
        :key="index"
        class="flex items-center gap-4"
      >
        <div class="flex flex-1 gap-4">
          <div class="w-full">
            <SimpleInput
              v-model="link.key"
              maxlength="25"
              class="w-full"
              type="text"
              :placeholder="t('placeholder.title')"
              :has-error="useFooterLinks && hasError && errors.$each.$response.$errors[index].key.length > 0"
            />
          </div>
          <div class="w-full">
            <SimpleInput
              v-model="link.value"
              class="w-full"
              type="text"
              :placeholder="t('placeholder.https')"
              :has-error="useFooterLinks && hasError && errors.$each.$response.$errors[index].value.length > 0"
            />
          </div>
        </div>
        <IconButton
          :label="index === 0 ? t('button.addIcon') : t('button.trashIcon')"
          :theme="index === 0 ? 'success' : 'danger'"
          class="ml-2"
          :class="{
            'pointer-events-none opacity-50':
              (footerLinksOptions.length >= maxLinks && index === 0) || (footerLinksOptions.length <= 1 && index !== 0),
          }"
          @click="index === 0 ? addLink() : removeLink(index)"
        >
          <PlusCircleIcon v-if="index === 0" />
          <TrashIcon v-else />
        </IconButton>
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

// Lang
import { useI18n } from "vue-i18n";

// Components
import { PlusCircleIcon, TrashIcon } from "@heroicons/vue/outline";
import FormSwitch from "@molecules/FormSwitch.vue";
import IconButton from "@atoms/IconButton.vue";
import SimpleInput from "@atoms/SimpleInput.vue";

// Props
const props = defineProps<{
  footerLinks: Array<{ key: string; value: string }>;
  maxLinks?: number;
  useFooterLinks: boolean;
  errors: any;
  hasError: boolean;
}>();

// Events
const emit = defineEmits<{
  (e: "update:footer-links", value: Array<{ key: string; value: string }>): void;
  (e: "update:use-footer-links", value: boolean): void;
}>();

const updateUseFooterLinks = (value: boolean) => {
  emit("update:use-footer-links", value);
};

// i18n
const { t } = useI18n();

// States
const maxLinks = props.maxLinks ?? 4;
const footerLinksOptions = computed(() => {
  if (props.footerLinks.length > 0) {
    return props.footerLinks;
  }
  return [];
});

// Methods
const addLink = () => {
  if (footerLinksOptions.value.length < maxLinks) {
    footerLinksOptions.value.push({ key: "", value: "" });
    emit("update:footer-links", footerLinksOptions.value);
  }
};

const removeLink = (index: number) => {
  if (footerLinksOptions.value.length > 1) {
    const updatedLinks = [...footerLinksOptions.value];
    updatedLinks.splice(index, 1);
    emit("update:footer-links", updatedLinks);
  }
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Links adicionales",
    "placeholder": {
      "title": "Título",
      "https": "https://",
    },
    "button": {
      "addIcon": "Agregar link",
      "trashIcon": "Eliminar link",
    },
  },
  "pt": {
    "title": "Links adicionais",
    "placeholder": {
      "title": "Título",
      "https": "https://",
    },
    "button": {
      "addIcon": "Agregar link",
      "trashIcon": "Eliminar link",
    },
  },
}
</i18n>
