<template>
  <div class="grid gap-4">
    <FormGroup id="templateLogo" :label="t('title')" :error="error">
      <InputUpload
        file-types=".png, .jpg, .jpeg, .gif"
        :file-name="fileName"
        :loading="loading"
        :error="error"
        @clear="onClear"
        @update:model-value="onUpdate"
      />
    </FormGroup>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

// Components
import FormGroup from "@molecules/FormGroup.vue";
import InputUpload from "@molecules/InputUpload.vue";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Props
defineProps<{
  fileName: string;
  loading: boolean;
  error: string;
}>();

const trackingEventsService = useTrackingEvents();

// Events
const emit = defineEmits<{
  (e: "clear"): void;
  (e: "update:model-value", value: File): void;
}>();

// i18n
const { t } = useI18n();

// Methods
const onClear = () => emit("clear");
const onUpdate = (value: File) => {
  emit("update:model-value", value);
  trackInputLoad();
};

const trackInputLoad = () => {
  trackingEventsService.amplitude({
    name: "APP_BRAND_EDITOR_IMAGE_UPLOADED",
  });
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Imagen de tu logo",
  },
  "pt": {
    "title": "Imagem do seu logotipo",
  },
}
</i18n>
