<template>
  <div class="flex flex-col space-y-8 py-2">
    <h3 class="m-0 text-base font-medium text-gray-700" v-html="t('title')" />
    <SendersManager showArticle />
  </div>
</template>

<script lang="ts" setup>
// Components
import SendersManager from "@organisms/Senders/SendersManager.vue";

// Utils
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "es": {
    "title": "El remitente es quien envía la comunicación. Para usarlo correctamente necesitas una dirección de correo de dominio propio con el nombre de tu tienda o empresa (ej. info{'@'}tutienda.com) desde donde enviar correos.<br/>No podrás registrar una dirección de servicio público (ej. {'@'}gmail.com, {'@'}hotmail.com)."
  },
  "pt": {
    "title": "O remetente é quem envia a comunicação. Para utilizá-lo corretamente, você precisa de um endereço de e-mail de domínio próprio com o nome da sua loja ou empresa (ex.: info{'@'}sualoja.com) para enviar e-mails.<br/>Não será possível registrar um endereço de domínio público (ex.: {'@'}gmail.com, {'@'}hotmail.com)."
  }
}
</i18n>
