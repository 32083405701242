<template>
  <div class="relative flex flex-col rounded-md px-6 sm:flex sm:items-start sm:justify-between">
    <ShowDataPanel
      v-if="autoGenerateTransferData || loading || transferData"
      :transferData="transferData"
      :loading="loading"
      class="mb-10 mt-5 w-full"
    />
    <GenerateDataPanel
      v-else-if="allowManualGenerateTransferData"
      :transferData="transferData"
      :loading="loading"
      v-model:loadingCreate="loadingCreating"
      class="my-10 w-full"
      @generateCVU="createTransferData"
    />
    <BillingInfoPanel
      v-if="showBuyPlan"
      v-model:open="billingInfoOpen"
      :panel-mode="false"
      @update-info="updateBillingInfoLoaded"
      @no-info="openEmptyBillingInfo"
      class="w-full text-left"
    />
    <div v-if="showBuyPlan" class="my-8 flex w-full justify-end">
      <SimpleButton :loading="isConfirming" :disabled="!billingInfoLoaded" @click="confirm">
        {{ t("confirm") }}
      </SimpleButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

import GenerateDataPanel from "./GenerateDataPanel.vue";
import ShowDataPanel from "./ShowDataPanel.vue";
import BillingInfoPanel from "@organisms/BillingInfo/BillingInfoPanel.vue";
import SimpleButton from "@atoms/SimpleButton.vue";

import { useI18n } from "vue-i18n";
// Store
import { useSessionStore } from "@store";
import { storeToRefs } from "pinia";

// Services
import { useInvoiceService } from "@services";

const props = withDefaults(
  defineProps<{
    autoGenerateTransferData?: boolean;
    allowManualGenerateTransferData: boolean;
    showBuyPlan?: boolean;
  }>(),
  {
    autoGenerateTransferData: false,
    showBuyPlan: false,
  },
);

const emit = defineEmits<{
  confirm: [void];
}>();

const { t } = useI18n();

const invoicesService = useInvoiceService();
const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);
const isMasterUser = session.value.isMasterUser;

const loading = ref(false);
const loadingCreating = ref(false);
const transferData = ref<{
  cvu: string;
  alias: string;
}>();

const isConfirming = ref(false);
const billingInfoOpen = ref(false);
const billingInfoLoaded = ref(isMasterUser);
const updateBillingInfoLoaded = () => {
  billingInfoLoaded.value = true;
};
const openEmptyBillingInfo = () => {
  if (isMasterUser) return;
  billingInfoOpen.value = true;
};
const confirm = () => {
  isConfirming.value = true;
  emit("confirm");
};

const getTransferData = async () => {
  loading.value = true;

  const bankDetails = await invoicesService.getBankDetails();

  if (bankDetails.isErr()) {
    loading.value = false;
    return;
  }

  transferData.value = {
    cvu: bankDetails.value.cvu,
    alias: bankDetails.value.alias,
  };

  loading.value = false;
};

const createTransferData = async () => {
  loadingCreating.value = true;

  const bankDetails = await invoicesService.assignBankDetails();

  if (bankDetails.isErr()) {
    loadingCreating.value = false;
    return;
  }

  transferData.value = {
    cvu: bankDetails.value.cvu,
    alias: bankDetails.value.alias,
  };

  loadingCreating.value = false;
};

const getOrCreateTransferData = async () => {
  if (props.autoGenerateTransferData) {
    loading.value = true;
    await createTransferData();
    loading.value = false;
  } else {
    await getTransferData();
  }
};

onMounted(() => {
  getOrCreateTransferData();
});
</script>

<i18n lang="json">
{
  "es": {
    "confirm": "Confirmar"
  },
  "pt": {
    "confirm": "Confirmar"
  }
}
</i18n>
