var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "              <div class=\"clear-search\" data-action=\"remove-filter\" data-params='[\"q\"]'>\n                <i class=\"fa fa-close\"></i>\n              </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <ol class=\"breadcrumb\">\n        <li>\n          <a data-action=\"remove-filter\" data-params='[\"filter.location\"]'>\n            <i class=\"fa fa-home\"></i>\n          </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"breadcrumb") : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":8},"end":{"line":102,"column":17}}})) != null ? stack1 : "")
    + "      </ol>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":94,"column":12},"end":{"line":100,"column":19}}})) != null ? stack1 : "")
    + "          </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <a data-action=\"filter\" data-params='{\"key\": \"filter.location\", \"value\": \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\"}'>\n                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n              </a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "      <ol class=\"breadcrumb\">\n        <li>\n          <i class=\"fa fa-home\"></i>\n        </li>\n      </ol>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"mx-10\">\n        <div class=\"hint-box hint-warning\" style=\"margin-bottom: 2rem;\">\n          <h4>\n            "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"images"))) && lookupProperty(stack1,"migration")), depth0)) != null ? stack1 : "")
    + "\n          </h4>\n        </div>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class=\"images section\">\n  <div class=\"section-header !pb-16\">\n    <h1>\n      "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"images"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":5,"column":45}}}))
    + "\n    </h1>\n    <div class=\"filters pt-8\">\n      <ul class=\"nav nav-pills\">\n        <li class=\"search\">\n          <form data-role=\"search\" data-form=\"search\">\n            <button class=\"btn btn-default\" type=\"button\" data-action=\"open-search-form\">\n              <i class=\"filter-icon pi-filter-search\"></i>\n            </button>\n            <input\n              type=\"text\"\n              placeholder=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"search")), depth0))
    + "...\"\n              class=\"form-control\"\n              name=\"q\"\n              value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"q") : stack1), depth0))
    + "\"\n            />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"q") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "          </form>\n        </li>\n      </ul>\n    </div>\n  </div>\n  <div class=\"toolbar\" data-role=\"toolbar\">\n    <button\n      class=\"btn btn-primary btn-disabled\"\n      data-action=\"open-create-image-modal\"\n      data-type=\"none\"\n      data-require-permission=\"images:create\"\n      disabled\n    >\n      <i class=\"fa fa-arrow-up\"></i>\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"images"))) && lookupProperty(stack1,"addImage")), depth0))
    + "\n    </button>\n    <button\n      class=\"btn btn-default btn-disabled\"\n      data-action=\"open-create-folder-modal\"\n      data-type=\"none\"\n      data-require-permission=\"images:create\"\n      disabled\n    >\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"images"))) && lookupProperty(stack1,"addFolder")), depth0))
    + "\n    </button>\n    <button\n      class=\"btn btn-default hide\"\n      data-action=\"open-rename-modal\"\n      data-type=\"just-one\"\n      data-require-permission=\"images:update\"\n    >\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"images"))) && lookupProperty(stack1,"renameFile")), depth0))
    + "\n    </button>\n    <button\n      class=\"btn btn-default hide\"\n      data-action=\"open-move-modal\"\n      data-type=\"one-plus\"\n      data-require-permission=\"images:update\"\n    >\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"images"))) && lookupProperty(stack1,"moveToFolder")), depth0))
    + "\n    </button>\n    <button\n      class=\"btn btn-danger hide\"\n      data-type=\"one-plus\"\n      data-action=\"mass\"\n      data-method=\"destroy\"\n      data-feedback=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"massDestroyF")), depth0))
    + "\"\n      data-confirm=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmDelete")), depth0))
    + "\"\n      data-require-permission=\"templates:delete\"\n    >\n      "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "\n    </button>\n    <div class=\"paging-selected\" data-role=\"check-count\"></div>\n  </div>\n  <div class=\"list-controls\">\n    <div class=\"check-all\">\n      <input id=\"check-all\" type=\"checkbox\" data-action=\"check-all\" />\n      <label for=\"check-all\" class=\"iconic\"></label>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"breadcrumb") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":85,"column":4},"end":{"line":110,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"paging-total\" data-role=\"paging-total\"></div>\n  </div>\n  <div class=\"content padded\" data-role=\"content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"unlayerEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":4},"end":{"line":122,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"list\">\n      <ul class=\"grid\" data-role=\"rows\"></ul>\n      <div class=\"loading-box\" data-role=\"items-loader\"></div>\n      <button class=\"btn btn-primary load-more\" data-action=\"load-more\">\n        "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"loadMore")), depth0))
    + "\n      </button>\n    </div>\n  </div>\n</div>";
},"useData":true});