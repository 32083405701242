<template>
  <transition
    enter-active-class="duration-300 ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-300 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    mode="out-in"
  >
    <div v-if="(card || isLoading) && !isUpdatingCard">
      <CreditCardPanel
        :is-loading="isLoading"
        :is-update="mode === 'update'"
        :disabled="isConfirming"
        :card="card"
        :updatable="
          gateway === 'stripeus' ||
          gateway === 'stripebr' ||
          gateway === 'stripemx' ||
          gateway === 'pagouno' ||
          gateway === 'tnpagouno'
        "
        :disallow-delete="disallowDelete"
        @delete-card="deleteCard"
        @update-card="isUpdatingCard = true"
      />
      <BillingInfoPanel
        v-if="!isLoading && mode !== 'update'"
        v-model:open="billingInfoOpen"
        :panel-mode="false"
        @update-info="updateBillingInfoLoaded"
        @no-info="openEmptyBillingInfo"
        class="my-8"
      />
      <div v-show="!billingInfoOpen" v-if="!isLoading && mode !== 'update'" class="mt-6 flex justify-end space-x-4">
        <SimpleButton
          :loading="isConfirming"
          :disabled="!billingInfoLoaded || card?.status !== 'valid'"
          @click="confirm"
        >
          <template #leading>
            <CreditCardIcon />
          </template>
          {{ t("confirmPayment") }}
        </SimpleButton>
      </div>
    </div>
    <CreditCardCreatorMercadoPago
      v-else-if="gateway === 'mp' && (mode !== 'update' || isUpdatingCard)"
      :cancelable="mode === 'update'"
      @card-created="cardCreated"
      @cancel="isUpdatingCard = false"
    />
    <CreditCardCreatorPagoUno
      v-else-if="(gateway === 'pagouno' || gateway === 'tnpagouno') && (mode !== 'update' || isUpdatingCard)"
      :is-tn-pago-uno="gateway === 'tnpagouno'"
      :cancelable="mode === 'update'"
      @card-created="cardCreated"
      @cancel="isUpdatingCard = false"
    />
    <CreditCardCreatorStripe
      v-else-if="
        (gateway === 'stripeus' || gateway === 'stripebr' || gateway === 'stripemx') &&
        (mode !== 'update' || isUpdatingCard)
      "
      :cancelable="mode === 'update'"
      :country="gateway === 'stripeus' ? 'us' : gateway === 'stripebr' ? 'br' : 'mx'"
      :paymethod="paymethod"
      @card-created="cardCreated"
      @cancel="isUpdatingCard = false"
    />
    <div v-else-if="!card && mode === 'update'" class="mx-7 mt-2 sm:flex sm:items-center sm:justify-between">
      <AlertBox v-if="showPaymentSuspensionWarning" theme="warning" class="flex-grow">{{
        t("paymentSuspensionWarning")
      }}</AlertBox>
      <div v-else class="max-w-xl">
        <div>
          <h4 class="m-0 text-base text-gray-800">{{ t("cardPaymentTitle") }}</h4>
          <p class="my-auto text-sm text-gray-500">
            {{ t("cardPaymentText") }}
          </p>
        </div>
      </div>
      <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
        <SimpleButton @click="isUpdatingCard = true" theme="white">
          <template #leading>
            <CreditCardIcon class="text-gray-500" />
          </template>
          {{ t("saveCard") }}
        </SimpleButton>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { ref, Ref, computed, onMounted, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { useCreditCards } from "@api/modules/creditcards";
import { CreditCard } from "@api/models/CreditCard";
import { CreditCardIcon } from "@heroicons/vue/solid";
import SimpleButton from "@atoms/SimpleButton.vue";
import CreditCardPanel from "@molecules/CreditCardPanel.vue";
import AlertBox from "@atoms/AlertBox.vue";
import CreditCardCreatorMercadoPago from "@organisms/CreditCards/CreditCardCreatorMercadoPago.vue";
import CreditCardCreatorPagoUno from "@organisms/CreditCards/CreditCardCreatorPagoUno.vue";
import CreditCardCreatorStripe from "@organisms/CreditCards/CreditCardCreatorStripe.vue";
import BillingInfoPanel from "@organisms/BillingInfo/BillingInfoPanel.vue";

// Store
import { storeToRefs } from "pinia";
import { useAlertStore, useSessionStore } from "@store";

const alertStore = useAlertStore();
const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

const props = defineProps({
  gateway: {
    type: String as PropType<"mp" | "pagouno" | "tnpagouno" | "stripeus" | "stripebr" | "stripemx">,
    required: false,
    default: "tnpagouno",
    validator: (value: string) => ["mp", "pagouno", "tnpagouno", "stripeus", "stripebr", "stripemx"].includes(value)
  },
  mode: {
    type: String as PropType<"subscription" | "pack" | "update">,
    default: "subscription",
    validator: (value: string) => ["subscription", "pack", "update"].includes(value)
  },
  paymethod: {
    type: String,
    required: false,
    default: undefined
  },
  disallowDelete: {
    type: Boolean,
    required: false,
    default: false
  }
});

const emit = defineEmits<{
  confirm: [void];
}>();

const { t } = useI18n();
const CreditCards = useCreditCards();

const isMasterUser = session.value.isMasterUser;
const isUpdatingCard = ref(false);
const isConfirming = ref(true);
const isLoading = ref(true);
const card: Ref<CreditCard | null> = ref(null);
const showPaymentSuspensionWarning = computed(() => ["stripeus", "stripebr", "stripemx"].includes(props.gateway));

const loadCard = async () => {
  const cards = await CreditCards.list(isLoading);
  if (cards.length > 0) {
    card.value = cards[0];
  } else {
    card.value = null;
    if (props.mode !== "update") isUpdatingCard.value = true;
  }
};

const billingInfoOpen = ref(false);
const billingInfoLoaded = ref(session.value.isMasterUser);
const openEmptyBillingInfo = () => {
  if (isMasterUser) return;
  billingInfoOpen.value = true;
};
const updateBillingInfoLoaded = () => {
  billingInfoLoaded.value = true;
};

const cardCreated = async (newCard) => {
  card.value = newCard;
  isUpdatingCard.value = false;
  await alertStore.update();
};

const deleteCard = async () => {
  try {
    if (card.value) CreditCards.remove(card.value.id);
    card.value = null;
    await alertStore.update();
  } catch (error) {
    console.error(error);
  }
};

const confirm = () => {
  isConfirming.value = true;
  emit("confirm");
};

onMounted(() => {
  loadCard();
  isConfirming.value = false;
});
</script>

<i18n lang="json">
{
  "es": {
    "saveCard": "Asociar tarjeta",
    "confirmPayment": "Confirmar pago",
    "cardPaymentTitle": "Pago automático",
    "cardPaymentText": "Carga una tarjeta de crédito para que tus pagos se realicen de manera automática",
    "paymentSuspensionWarning": "Para evitar la suspensión del servicio, por favor configura una nueva tarjeta de crédito."
  },
  "pt": {
    "saveCard": "Associar cartão",
    "confirmPayment": "Confirmar pagamento",
    "cardPaymentTitle": "Pagamento automático",
    "cardPaymentText": "Adicione um cartão de crédito para que seus pagamentos sejam realizados automaticamente",
    "paymentSuspensionWarning": "Para evitar a suspensão do serviço, por favor configure um novo cartão de crédito."
  }
}
</i18n>
