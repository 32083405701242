import { markRaw } from "vue";

import { ViewGridIcon, ClipboardListIcon, TemplateIcon, MailIcon, LightningBoltIcon } from "@heroicons/vue/outline";
import {
  ViewGridIcon as SolidViewGridIcon,
  ClipboardListIcon as SolidClipboardListIcon,
  TemplateIcon as SolidTemplateIcon,
  MailIcon as SolidMailIcon,
  LightningBoltIcon as SolidLightningBoltIcon,
  BriefcaseIcon as SolidBriefcaseIcon,
} from "@heroicons/vue/solid";

import UsersIcon from "@tokens/icons/UsersIcon.vue";
import UsersSolidIcon from "@tokens/icons/UsersSolidIcon.vue";
import PaintBrushIcon from "@tokens/icons/PaintBrush.vue";
import PaintBrushSolidIcon from "@tokens/icons/PaintBrushSolid.vue";
import PhotoIcon from "@tokens/icons/PhotoIcon.vue";
import PhotoSolidIcon from "@tokens/icons/PhotoSolidIcon.vue";

import { CogIcon, PuzzleIcon, CreditCardIcon } from "@heroicons/vue/solid";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore, useFeaturesStore } from "@store";

// Domain
import type { Routes, RoutesIds } from "./routes.types";

// i18n
import { getI18nInstance } from "@locales/i18n";
import esMessages from "./i18n/routes.es.json";
import ptMessages from "./i18n/routes.pt.json";

export const getNavigation = (
  {
    legacyTemplates,
  }: {
    legacyTemplates: boolean;
  } = { legacyTemplates: false },
): Routes => {
  const { t, mergeLocaleMessage } = getI18nInstance().global;
  mergeLocaleMessage("es", esMessages);
  mergeLocaleMessage("pt", ptMessages);

  return [
    {
      route: "dashboard",
      name: t("routes.dashboard"),
      path: "/dashboard",
      icon: ViewGridIcon,
      currentIcon: SolidViewGridIcon,
    },
    {
      route: "audience",
      name: t("routes.audience"),
      path: "/lists",
      icon: markRaw(UsersIcon),
      currentIcon: markRaw(UsersSolidIcon),
    },
    {
      route: "optins",
      name: t("routes.optins"),
      path: "/optins",
      icon: ClipboardListIcon,
      currentIcon: SolidClipboardListIcon,
    },
    {
      route: "gallery",
      name: t("routes.gallery"),
      path: "/gallery/public",
      icon: TemplateIcon,
      currentIcon: SolidTemplateIcon,
    },
    ...((legacyTemplates
      ? [
          {
            route: "designs",
            name: t("routes.designs"),
            path: "/templates",
            icon: markRaw(PaintBrushIcon),
            currentIcon: markRaw(PaintBrushSolidIcon),
          },
          {
            route: "images",
            name: t("routes.images"),
            path: "/images",
            icon: markRaw(PhotoIcon),
            currentIcon: markRaw(PhotoSolidIcon),
          },
        ]
      : []) as Routes),
    { route: "campaigns", name: t("routes.campaigns"), path: "/campaigns", icon: MailIcon, currentIcon: SolidMailIcon },
    {
      route: "automations",
      name: t("routes.automations"),
      path: "/automations",
      icon: LightningBoltIcon,
      currentIcon: SolidLightningBoltIcon,
    },
  ];
};

export const getRouteIdByPath = (pathname: string): RoutesIds | undefined => {
  if (pathname.includes("home")) return "home";
  if (pathname.includes("dashboard")) return "dashboard";
  if (
    pathname.includes("lists") ||
    pathname.includes("fields") ||
    pathname.includes("interests") ||
    pathname.includes("contacts")
  )
    return "audience";
  if (pathname.includes("optins")) return "optins";
  if (pathname.includes("campaigns")) return "campaigns";
  if (pathname.includes("automations")) return "automations";
  if (pathname.includes("gallery")) return "gallery";
  if (pathname.includes("images")) return "images";
  if (pathname.includes("templates")) return "designs";
};

export function getWebUIv2Path(path: string): string | undefined {
  if (path.includes("/home")) return "/home";
  if (path.includes("/dashboard")) return "/dashboard";
  if (path.includes("/lists")) return "/audience/lists";
  if (path.includes("/fields")) return "/audience/fields";
  if (path.includes("/interests")) return "/audience/interests";
  if (path.includes("/contacts")) return "/audience/contacts";
  if (path.includes("/optins")) return "/optins";
  if (path.includes("/campaigns")) return "/campaigns";
  if (path.includes("/automations")) return "/automations";
  if (path.includes("/gallery")) return "/gallery";

  return undefined;
}

export const getProfileNavigation = () => {
  const { t, mergeLocaleMessage } = getI18nInstance().global;
  const sessionStore = useSessionStore();
  const { session } = storeToRefs(sessionStore);
  mergeLocaleMessage("es", esMessages);
  mergeLocaleMessage("pt", ptMessages);

  const accountUpdate = sessionStore.hasPermission("account:update");
  const accountPlan = sessionStore.hasPermission("account:plan");

  const showPlan = !session.value?.account.parent && (session.value?.isMasterUser || accountPlan);
  const showSettings = session.value?.isMasterUser || accountUpdate;
  const showIntegrations = session.value?.isMasterUser || accountUpdate;
  const showAccounts = session.value?.account.container;

  return [
    ...(showSettings ? [{ route: "settings", name: t("routes.settings"), path: "/settings", icon: CogIcon }] : []),
    ...(showIntegrations
      ? [{ route: "integrations", name: t("routes.integrations"), path: "/integrations", icon: PuzzleIcon }]
      : []),
    ...(showPlan ? [{ route: "plan", name: t("routes.plan"), path: "/plan", icon: CreditCardIcon }] : []),
    ...(showAccounts
      ? [{ route: "accounts", name: t("routes.accounts"), path: "/accounts", icon: SolidBriefcaseIcon }]
      : []),
  ];
};

export function shouldRedirectToNewUI(path: string): boolean {
  const featuresStore = useFeaturesStore();
  const { features } = storeToRefs(featuresStore);

  if (path.includes("/home")) return features.value?.NEW_UI_HOME === "1";
  if (path.includes("/dashboard")) return features.value?.NEW_UI_DASHBOARD === "1";
  if (path.includes("/lists")) return features.value?.NEW_UI_AUDIENCE === "1";
  if (path.includes("/fields")) return features.value?.NEW_UI_AUDIENCE === "1";
  if (path.includes("/interests")) return features.value?.NEW_UI_AUDIENCE === "1";
  if (path.includes("/contacts")) return features.value?.NEW_UI_AUDIENCE === "1";
  if (path.includes("/optins")) return features.value?.NEW_UI_OPTINS === "1";
  if (path.includes("/campaigns")) return features.value?.NEW_UI_CAMPAIGNS === "1";
  if (path.includes("/automations")) return features.value?.NEW_UI_AUTOMATIONS === "1";
  if (path.includes("/gallery")) return features.value?.NEW_UI_GALLERY === "1";

  return false;
}
