// import axios from "axios";

// Utils
import { err } from "neverthrow";
// import { ok, err } from "neverthrow";
// import { ensureAxiosError } from "@helpers/error";
// import { fromUnixTime } from "date-fns";

// Types
import type { LivestormService } from "@application/ports";
// import type {
//   GetEventsAPIResponse,
//   GetEventsErrorAPIResponse,
//   GetEventsAPIParams,
//   GetSessionsAPIParams,
//   GetEventAPIResponse,
//   GetSessionsAPIResponse,
// } from "./livestorm.types";

// const amplitudeAPI = axios.create({
//   timeout: 20000,
//   baseURL: "https://api.livestorm.co/v1",
// });

export const useLivestormService = (): LivestormService => {
  // amplitudeAPI.defaults.headers.common = {
  //   Authorization: window.config.livestormKey as string,
  // };

  const liveStormService: LivestormService = {
    async getEvents(params) {
      return err({
        type: "NO_DATA",
      });

      // const apiParams: GetEventsAPIParams = {
      //   filter: {
      //     scheduling_status: params?.schedulingStatus,
      //   },
      // };

      // try {
      //   const events = await amplitudeAPI.request<GetEventsAPIResponse>({
      //     url: `/events`,
      //     method: "get",
      //     params: apiParams,
      //   });

      //   return ok(
      //     events.data.data.map((e) => ({
      //       id: e.id,
      //       title: e.attributes.title,
      //       language: e.attributes.language,
      //       schedulingStatus: e.attributes.scheduling_status,
      //       description: e.attributes.description,
      //       public: e.attributes.show_in_company_page,
      //       owner: {
      //         avatar: e.attributes.owner.attributes.avatar_link,
      //         name: `${e.attributes.owner.attributes.first_name} ${e.attributes.owner.attributes.last_name}`,
      //       },
      //     })),
      //   );
      // } catch (e) {
      //   const data = ensureAxiosError(e).response?.data as GetEventsErrorAPIResponse;

      //   if (data.error[0].code === "401") {
      //     return err({
      //       type: "UNAUTHORIZED",
      //     });
      //   }

      //   return err({
      //     type: "NO_DATA",
      //   });
      // }
    },
    async getEvent(params) {
      return err({
        type: "NO_DATA",
      });
      // try {
      //   const event = await amplitudeAPI.request<GetEventAPIResponse>({
      //     url: `/events/${params.id}`,
      //     method: "get",
      //   });

      //   return ok({
      //     id: event.data.data.id,
      //     title: event.data.data.attributes.title,
      //     schedulingStatus: event.data.data.attributes.scheduling_status,
      //     language: event.data.data.attributes.language,
      //     description: event.data.data.attributes.description,
      //     public: event.data.data.attributes.show_in_company_page,
      //     owner: {
      //       avatar: event.data.data.attributes.owner.attributes.avatar_link,
      //       name: `${event.data.data.attributes.owner.attributes.first_name} ${event.data.data.attributes.owner.attributes.last_name}`,
      //     },
      //   });
      // } catch (e) {
      //   const data = ensureAxiosError(e).response?.data as GetEventsErrorAPIResponse;

      //   if (data.error[0].code === "401") {
      //     return err({
      //       type: "UNAUTHORIZED",
      //     });
      //   }

      //   return err({
      //     type: "NO_DATA",
      //   });
      // }
    },
    async getSessions(params) {
      return err({
        type: "NO_DATA",
      });
      // const apiParams: GetSessionsAPIParams = {
      //   filter: {
      //     status: params.schedulingStatus,
      //   },
      // };

      // try {
      //   const sessions = await amplitudeAPI.request<GetSessionsAPIResponse>({
      //     url: `/sessions`,
      //     method: "get",
      //     params: apiParams,
      //   });

      //   return ok(
      //     sessions.data.data.map((s) => ({
      //       id: s.id,
      //       nextEventDate: fromUnixTime(s.attributes.estimated_started_at),
      //       status: s.attributes.status,
      //       link: s.attributes.room_link,
      //       eventId: s.attributes.event_id,
      //     })),
      //   );
      // } catch (e) {
      //   const data = ensureAxiosError(e).response?.data as GetEventsErrorAPIResponse;

      //   if (data.error[0].code === "401") {
      //     return err({
      //       type: "UNAUTHORIZED",
      //     });
      //   }

      //   return err({
      //     type: "NO_DATA",
      //   });
      // }
    },
  };

  return liveStormService;
};
