import { usePagingRequests, useRequests } from "@api/requests";
import qs from "qs";
import { isEqual } from "lodash";

// Types
import type {
  GetParams,
  GetAPIParams,
  GetCampaignsAsyncGenerator,
  CampaignAPIResponse,
  CampaignsService,
  DuplicateAPIResponse,
  TagsAPIResponse,
} from "./campaigns.types";
import type { SuccessAPIOffsetPaging } from "../../models/requestWrappers";
import type { Campaigns } from "@domain/campaigns";

export function useCampaigns(): CampaignsService {
  const requestPaging = usePagingRequests();
  const request = useRequests();

  let getCampaignsGenerator: GetCampaignsAsyncGenerator | undefined = undefined;
  let apiParams: GetParams | undefined = undefined;
  let getController: AbortController = new AbortController();

  const cleanLastGet = () => {
    apiParams = undefined;
    getController = new AbortController();
  };

  return {
    async get(options) {
      try {
        let isNewRequest = false;
        if (!isEqual(apiParams, options) || !getCampaignsGenerator) {
          isNewRequest = true;
          getController.abort();
          getController = new AbortController();

          apiParams = options;
          getCampaignsGenerator = requestPaging<Campaigns, unknown, GetAPIParams>(
            {
              url: `/campaigns`,
              method: "get",
              signal: getController.signal,
              urlParams: {
                q: apiParams?.search ? `*${apiParams?.search}*` : undefined,
                sortBy: apiParams?.sortBy ?? "stategroup",
                sortDir: apiParams?.sortDir ?? "asc",
                "filters.tags": apiParams.tag,
                "filters.state": apiParams.state,
                "filters.type": apiParams.type,
                "filters.archived": apiParams.archived,
              },
            },
            { limit: apiParams?.limit ?? 20, offset: apiParams?.offset ?? 0 },
            true,
          );
        }

        if (!getCampaignsGenerator || getCampaignsGenerator === undefined) {
          cleanLastGet();
          return {
            done: true,
            value: [],
            total: undefined,
            isNewRequest,
          };
        }

        const data = (await getCampaignsGenerator.next()) as IteratorResult<SuccessAPIOffsetPaging<Campaigns>>;

        if (data.done || !data.value) {
          cleanLastGet();
          return {
            done: true,
            value: [],
            total: undefined,
            isNewRequest,
          };
        }

        return {
          done: false,
          total: data.value.paging.total,
          value: data.value.data,
          isNewRequest,
        };
      } catch (error) {
        cleanLastGet();
        return {
          done: true,
          value: [],
          total: undefined,
          isNewRequest: false,
        };
      }
    },
    async getId(id) {
      const res = await request<CampaignAPIResponse>({
        url: `/campaigns/${id}`,
        method: "get",
      }); 
      return res.data;
    },
    async tags() {
      const res = await request<TagsAPIResponse>({
        url: "/campaigns/tags",
        method: "get",
      });

      return res.data;
    },
    async duplicate(params) {
      const res = await request<DuplicateAPIResponse>({
        url: `/campaigns/${params.id}/duplicate`,
        data: qs.stringify({
          name: params.name,
        }),
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        disableNotifyErrors: !params.showError,
      });

      return res.data;
    },
    async stop(params) {
      await request({
        url: `/campaigns/${params.id}/stop`,
        method: "post",
      });
    },
    async export(params) {
      const form = new URLSearchParams();
      params.ids?.forEach((id) => {
        form.append("campaigns[]", id);
      });
      params.tags?.forEach((tag) => {
        form.append("tags[]", tag);
      });
      if (params.query) {
        form.append("query", `*${params.query}*`);
      }

      const res = await request({
        url: `/campaigns/metrics/export`,
        method: "post",
        data: form,
      });

      return res.task;
    },
    async archive(params) {
      await request({
        url: `/campaigns/${params.id}/archive`,
        method: "post",
      });
    },
    async unarchive(params) {
      await request({
        url: `/campaigns/${params.id}/unarchive`,
        method: "post",
      });
    },
    async delete(params) {
      await request({
        url: `/campaigns/${params.id}`,
        method: "delete",
      });
    },
  };
}
