<template>
  <div class="relative my-0 px-7 pb-4">
    <transition
      leave-active-class="transition-all transform duration-100 overflow-hidden"
      leave-from-class="max-h-full"
      leave-to-class="max-h-0"
      enter-active-class="transition-all transform duration-500 overflow-hidden"
      enter-to-class="max-h-full"
      enter-from-class="max-h-0"
    >
      <div v-show="configIsOpen" class="fixed left-0 right-0 z-10 mx-auto w-full space-y-5 bg-white shadow-lg">
        <div ref="configRef" class="flex">
          <div class="w-full">
            <ShipmentInfo
              v-show="selectedTab.id == 'shipmentInfo'"
              v-model:senderManagerIsOpen="senderManagerIsOpen"
              :template-type="templateType"
              :editor-instance="editorInstance"
              :sender-id="senderId"
              :selected-sender="selectedSender"
              :relation="relation"
              :subject="subject"
              :subjects="subjectsLocal"
              :pre-header="preHeader"
              :fields="fields"
              :account-features="accountFeatures"
              @update:senderId="updateSenderId"
              @update:selected-sender="updateSender"
              @update:subject="updateSubject"
              @update:subjects="updateSubjectsLocal"
              @update:pre-header="updatePreHeader"
              @suggester-toggle="changeForceOpen"
            />
            <MonitoringOptions
              v-show="selectedTab.id == 'monitoringOptions'"
              :monitor-openings="monitorOpenings"
              :monitor-clicks="monitorClicks"
              :track-analytics="trackAnalytics"
              :campaign-name="campaignName"
              @update:monitor-openings="updateMonitorOpenings"
              @update:monitor-clicks="updateMonitorClicks"
              @update:track-analytics="updateTrackAnalytics"
              @update:campaign-name="updateCampaignNameLocal"
            />
            <CustomSender
              v-if="selectedTab.id == 'customSender'"
              :to-recipient="toRecipientLocal"
              :bcc-recipient="bccRecipientLocal"
              @update:to-recipient="updateToRecipientLocal"
              @update:bcc-recipient="updateBCCRecipientLocal"
            />
            <AdvanceOptions
              v-if="selectedTab.id == 'advanceOptions'"
              v-model:unsubscribeContactsModalIsOpen="unsubscribeContactsModalIsOpen"
              :include-unsubscribed-contacts="ignoreUnsubscribeStatus"
              :sample-context="sampleContext"
              :supports-branding="supportsBranding"
              @update:sample-context="updateSampleContext"
              @update:include-unsubscribed-contacts="updateIgnoreUnsubscribeStatus"
              @update:supports-branding="updateSupportsBranding"
            />
          </div>
          <div class="w-96 space-y-4 bg-gray-50 pb-4">
            <div class="my-4 mb-0 px-7">
              <Button class="w-full" theme="white" size="small" @click="closeConfiguration">
                <template #leading><CheckIcon class="text-black" /></template>
                {{ t("doneButton") }}
              </Button>
            </div>
            <div class="w-full border-t border-gray-300" />
            <VerticalTabsMenu v-model:selected-tab="selectedTab" :tabs="tabs" />
          </div>
        </div>
      </div>
    </transition>

    <div class="flex justify-between pt-5">
      <div data-intercom-target="EditorEmailHeader">
        <div class="flex space-x-3 text-sm">
          <div class="inline space-x-3 text-gray-600 hover:text-gray-900" @click="openConfiguration">
            <span
              v-if="relation?.relationType != 'campaign_testab'"
              class="font-medium"
              :class="!subject && 'font-semibold opacity-50'"
            >
              {{ subject || t("subjectPlaceholder") }}
            </span>
            <div v-else class="group relative inline cursor-default">
              <div class="inline-flex align-middle group-hover:text-sky-400">
                <BeakerIcon class="mr-1 h-5 w-5" />
                <span ref="vueSubjectsRef" class="font-medium">{{ t("subjectsPlaceholder") }}</span>
              </div>
              <div
                ref="vueSubjectsPopperRef"
                class="invisible absolute left-0 top-5 z-10 box-content min-w-full max-w-4xl cursor-auto rounded-md bg-white px-5 py-2 shadow-md group-hover:visible"
              >
                <div v-if="subjectsLocal.filter((subject) => subject !== '').length > 0">
                  <span v-for="(subjectAB, index) in subjectsLocal" :key="index" class="block w-max max-w-full py-2">{{
                    subjectAB
                  }}</span>
                </div>
                <TextParagraph v-else class="mx-1 my-4 text-gray-400">
                  {{ t("subjectsContentPlaceholder") }}
                </TextParagraph>
              </div>
            </div>
            <span class="text-gray-400">-</span>
            <span v-show="templateType === 'unlayer'" :class="!preHeader && 'opacity-50'">
              {{ preHeader || t("preheaderPlaceholder") }}
            </span>
          </div>
        </div>
        <div class="mt-2 flex space-x-4 text-sm">
          <span class="inline space-x-4 text-gray-600 hover:text-gray-900" @click="openConfiguration">
            <span :class="!selectedSender?.email && 'opacity-50'">
              <span v-if="selectedSender?.email">
                <span class="font-medium">{{ t("from") }}</span>
                {{ `${selectedSender?.name} &lt;${selectedSender?.email}>` }}
              </span>
              <span v-else class="font-semibold">{{ t("senderPlaceholder") }}</span>
            </span>
            <span :class="!selectedSender?.reply_to && 'invisible'">
              <span v-if="selectedSender?.reply_to" class="font-medium opacity-100">{{ t("replyTo") }} </span>
              {{ selectedSender?.reply_to }}
            </span>
          </span>
        </div>
      </div>
      <Button
        theme="white"
        class="mb-auto ml-4 flex-shrink-0"
        data-intercom-target="EditorTemplateConfigButton"
        :disabled="disabled"
        @click="openConfiguration"
      >
        <template #leading><CogIcon class="text-black" /></template>
        {{ t("openConfigurationButton") }}
      </Button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

//Components
import Button from "@atoms/SimpleButton.vue";
import VerticalTabsMenu from "@molecules/VerticalTabsMenu/VerticalTabsMenu.vue";
import ShipmentInfo from "./components/tabs/ShipmentInfo.vue";
import MonitoringOptions from "./components/tabs/MonitoringOptions.vue";
import CustomSender from "./components/tabs/CustomSender.vue";
import AdvanceOptions from "./components/tabs/AdvanceOptions.vue";
import TextParagraph from "@atoms/TextParagraph.vue";

//Icon
import { CheckIcon } from "@heroicons/vue/solid";
import { AtSymbolIcon, CursorClickIcon, UsersIcon, KeyIcon, CogIcon, BeakerIcon } from "@heroicons/vue/outline";

//utils
import { email as emailValidator } from "@vuelidate/validators";
import { onClickOutside } from "@vueuse/core";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

//I18n
import { useI18n } from "vue-i18n";

//Types
import type { Tabs, Tab } from "@molecules/VerticalTabsMenu/VerticalTabsMenu.vue";
import type { Sender } from "@/vue/types/Senders";
import type { Fields } from "@/vue/api/modules/fields";
import type { Relation } from "../../TemplateEditor.vue";
import type { TabIds } from "./TemplateConfiguration.types";
import type { AccountFeatures } from "@/vue/types/account";
import type { EditorType } from "@/vue/types/Templates";

const { t } = useI18n();
const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

//Props
const props = withDefaults(
  defineProps<{
    templateType: EditorType;
    disabled?: boolean;
    editorInstance?: UnlayerInstance;
    senderId?: string;
    selectedSender?: Sender;
    subject: string;
    subjects?: Array<string>;
    preHeader: string;
    fields?: Fields;
    relation?: Relation | undefined;
    monitorOpenings: boolean;
    monitorClicks: boolean;
    trackAnalytics: boolean;
    campaignName: string;
    toRecipient?: string;
    bccRecipient?: string;
    ignoreUnsubscribeStatus?: boolean;
    supportsBranding?: boolean;
    sampleContext?: string;
    accountFeatures?: AccountFeatures;
  }>(),
  {
    disabled: false,
    senderId: undefined,
    editorInstance: undefined,
    selectedSender: undefined,
    subject: "",
    subjects: () => [],
    preHeader: "",
    fields: () => [],
    relation: undefined,
    toRecipient: "",
    bccRecipient: "",
    ignoreUnsubscribeStatus: false,
    supportsBranding: false,
    sampleContext: undefined,
    accountFeatures: undefined,
  },
);

//Emits
const emit = defineEmits<{
  (e: "update:senderId", value: string): void;
  (e: "update:selectedSender", value: Sender): void;
  (e: "update:subject", value: string): void;
  (e: "update:subjects", value: Array<string>): void;
  (e: "update:preHeader", value: string): void;
  (e: "openConfig"): void;
  (e: "closeConfig"): void;
  (e: "update:monitorOpenings", value: boolean): void;
  (e: "update:monitorClicks", value: boolean): void;
  (e: "update:trackAnalytics", value: boolean): void;
  (e: "update:campaignName", value: string): void;
  (e: "update:toRecipient", value: string): void;
  (e: "update:bccRecipient", value: string): void;
  (e: "update:ignoreUnsubscribeStatus", value: boolean): void;
  (e: "update:supportsBranding", value: boolean): void;
  (e: "update:sampleContext", value: string): void;
}>();

const updateSenderId = (newSenderId) => emit("update:senderId", newSenderId);
const updateSender = (newSender) => emit("update:selectedSender", newSender);
const updateSubject = (newSubject: string) => emit("update:subject", newSubject);
const updateSubjects = (newSubjects: Array<string>) => emit("update:subjects", newSubjects);
const updatePreHeader = (newPreHeader: string) => emit("update:preHeader", newPreHeader);
const updateMonitorOpenings = (value: boolean) => emit("update:monitorOpenings", value);
const updateMonitorClicks = (value: boolean) => emit("update:monitorClicks", value);
const updateTrackAnalytics = (value: boolean) => emit("update:trackAnalytics", value);
const updateCampaignName = (value: string) => emit("update:campaignName", value);
const updateToRecipient = (value: string) => emit("update:toRecipient", value);
const updateBCCRecipient = (value: string) => emit("update:bccRecipient", value);
const updateIgnoreUnsubscribeStatus = (value: boolean) => emit("update:ignoreUnsubscribeStatus", value);
const updateSupportsBranding = (value: boolean) => emit("update:supportsBranding", value);
const updateSampleContext = (value: string) => emit("update:sampleContext", value);

//Const
const tabs: Tabs<TabIds> = [
  {
    id: "shipmentInfo",
    name: t("tabs.shipmentInfo"),
    icon: AtSymbolIcon,
  },
  {
    id: "monitoringOptions",
    name: t("tabs.monitoringOptions"),
    icon: CursorClickIcon,
  },
];

const addTabAutomation = () => {
  tabs.push({
    id: "customSender",
    name: t("tabs.customSender"),
    icon: UsersIcon,
  });
};

const addAdvanceOptions = () => {
  tabs.push({
    id: "advanceOptions",
    name: t("tabs.advanceOptions"),
    icon: KeyIcon,
  });
};
const isAutomation = () => props.relation?.relationType == "automation";

if (isAutomation()) {
  addTabAutomation();
  if (session.value?.isMasterUser) addAdvanceOptions();
}

//Flags
const configIsOpen = ref(false);
const openConfiguration = () => {
  configIsOpen.value = true;
  emit("openConfig");
};

//State
const subjectsLocal = ref(props.subjects);
const updateSubjectsLocal = (value: Array<string>) => (subjectsLocal.value = value);

const toRecipientLocal = ref(props.toRecipient);
const updateToRecipientLocal = (value: string) => (toRecipientLocal.value = value);
const bccRecipientLocal = ref(props.bccRecipient);
const updateBCCRecipientLocal = (value: string) => (bccRecipientLocal.value = value);
const campaignNameLocal = ref(props.campaignName);
const updateCampaignNameLocal = (value: string) => {
  campaignNameLocal.value = value;
};

const selectedTab = ref<Tab<TabIds>>(tabs[0]);

const senderManagerIsOpen = ref(false);
const unsubscribeContactsModalIsOpen = ref(false);

//Refs
const forceOpen = ref(false);
const changeForceOpen = (value: boolean) => {
  forceOpen.value = value;
};
const configRef = ref(null);
onClickOutside(configRef, () => {
  if (forceOpen.value || senderManagerIsOpen.value || unsubscribeContactsModalIsOpen.value) return;
  closeConfiguration();
});

//Methods
const filterEmptySubjects = () => {
  subjectsLocal.value = subjectsLocal.value.filter((subject) => subject !== "");
};

const closeConfiguration = () => {
  if (!configIsOpen.value) return;
  configIsOpen.value = false;
  selectedTab.value = tabs[0];
  if (props.relation?.relationType == "campaign_testab") {
    filterEmptySubjects();
    updateSubjects(subjectsLocal.value);
  }
  emitRecipients();
  emit("closeConfig");
};

const isValidEmail = (email: string) => Boolean(emailValidator.$validator(email, null, null));

const emitRecipients = () => {
  if (toRecipientLocal.value && isValidEmail(toRecipientLocal.value)) {
    updateToRecipient(toRecipientLocal.value);
  } else {
    updateToRecipient("");
  }

  if (bccRecipientLocal.value && isValidEmail(bccRecipientLocal.value)) {
    updateBCCRecipient(bccRecipientLocal.value);
  } else {
    updateBCCRecipient("");
  }

  if (props.trackAnalytics && campaignNameLocal.value) {
    updateCampaignName(campaignNameLocal.value);
  }
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "doneButton": "Listo",
    "subjectPlaceholder": "Completa el asunto con una frase llamativa que incite a abrir el email.",
    "subjectsPlaceholder": "Campaña Test A/B con múltiples asuntos",
    "subjectsContentPlaceholder": "Aún no se han definido los asuntos",
    "preheaderPlaceholder": "Puedes utilizar un preheader para detallar más información.",
    "senderPlaceholder": "Selecciona un remitente para este email",
    "from": "De:",
    "replyTo": "Responder a:",
    "openConfigurationButton": "Configurar",
    "tabs": {
      "shipmentInfo": "Información de envío",
      "monitoringOptions": "Opciones de monitoreo",
      "customSender": "Personalizar destinatario",
      "advanceOptions": "Avanzado [master users]"
    }
  },
  "pt": {
    "doneButton": "Concluído",
    "subjectPlaceholder": "Complete o assunto com uma frase que chame a atenção e que estimule a abertura do email.",
    "subjectsPlaceholder": "Campanha Teste A/B com múltiplos assuntos",
    "subjectsContentPlaceholder": "Ainda não foram definidos os assuntos",
    "preheaderPlaceholder": "Você pode utilizar um preheader para dar mais informações.",
    "senderPlaceholder": "Selecione um remetente para este email",
    "from": "De:",
    "replyTo": "Responder a:",
    "openConfigurationButton": "Configurar",
    "tabs": {
      "shipmentInfo": "Informação de envio",
      "monitoringOptions": "Opções de monitoramento",
      "customSender": "Personalizar destinatário",
      "advanceOptions": "Avanzado [master users]"
    }
  }
}
</i18n>
