import { _ } from "@/helpers/utils";
import app from "@/app";
import layout1 from "../templates/layout1.hbs";
import layout2 from "../templates/layout2.hbs";
import notFoundTpl from "../templates/notFound.hbs";
import { createApp } from "vue";
import { mountComponent } from "@/helpers/vue";
import GlobalComponentsOld from "@organisms/GlobalComponents/GlobalComponentsOld.vue";
import { getI18nInstance } from "@locales/i18n";
import { usePinia } from "@store";

import Layout from "@templates/Layout/Layout.vue";

// Application
import { useLocalStorageApp } from "@application";

export default window.Backbone.View.extend({
  // Element selector
  el: ".app",

  // Events
  events: {
    "click [data-action=switch-to-new-layout]": "switchToNewLayout",
    "click [data-action=toggle-sidebar]": "toggleSidebar",
    "click .main": "closeSidebar",
    "keyup .finder": "filterInstantSearch",
    "click [data-role=items-selector] button": "openItemsSelector",
    "change [data-toggle=optional]": "toggleOptionalInputs",
    "click [data-section]": "changeSection",
    "change .has-error .form-control": "removeValidationErrorMessage",
    "click [data-action=goto-account]": "gotoAccount",
    "click [data-action=goto-dashboard]": "gotoDashboard",
    "click a[href^='/']": "navigateHref",
  },

  navigateHref: function (e) {
    if (!(e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      const route = e.currentTarget.attributes.href.value;
      app.router.navigateTo(route);
    }
  },

  // Custom settings
  settings: {
    breakpoint: 992,
  },

  // Custom initialization method
  // initialize: function () {
  //   var self = this;

  //   // Set main template
  //   self.template = layout1;
  // },

  // Renders the app layout
  render: function () {
    const self = this;
    // const localStorageApp = useLocalStorageApp();
    // const useNewLayoutLS = localStorageApp.getGlobal({
    //   id: "useNewLayout"
    // });

    // Set main template
    // const isMasterUser = app.session?.attributes?.isMasterUser;

    // const allowUseNewLayout = app.session.attributes?.details?.account?.features.USE_NEW_LAYOUT == "1";
    // const allowSwitchLayout = app.session.attributes?.details?.account?.features.ALLOW_TOGGLE_LAYOUT == "1";
    // const userSelectedNewLayout = useNewLayoutLS === true;

    // const masterUserLayoutConfig = isMasterUser && userSelectedNewLayout;
    // const userLayoutConfig = !isMasterUser && allowUseNewLayout && (allowSwitchLayout ? userSelectedNewLayout : true);

    // const shouldApplyNewLayout = masterUserLayoutConfig || userLayoutConfig;
    const shouldApplyNewLayout = true;
    self.shouldApplyNewLayout = true;

    if (shouldApplyNewLayout) {
      self.template = layout2;
      const body = document.querySelector("body");
      body.classList.add("newLayout");
    } else {
      self.template = layout1;
      if (window.Canny) {
        window.Canny("initChangelog", {
          appID: "5b5261f2ca89de4e79c4296b",
          position: "bottom",
          align: "left",
        });
      }
    }

    const data = app.session.attributes;
    const displayName =
      // eslint-disable-next-line prettier/prettier
      data.account && data.details?.account?.name?.toLowerCase() === String(data.account).toLowerCase()
        ? data.details.account.name
        : `${data.details?.account?.name} (${data.account})`;

    const unlayerEnabled =
      app.session.attributes.details?.account?.features?.UNLAYER_OPTIONAL === "1" ||
      app.session.attributes.details?.account?.features?.UNLAYER_DEFAULT === "1";

    const hideLegacyTemplates = app.session.attributes.details?.account?.features?.HIDE_LEGACY_TEMPLATES === "1";

    const html = self.template({
      data,
      lang: window.lang,
      config: window.config,
      displayName,
      unlayerEnabled,
      hideLegacyTemplates,
      // allowSwitchLayout: allowSwitchLayout || isMasterUser
      allowSwitchLayout: false,
    });

    self.$el.removeClass("login");
    self.$el.html(html);
    self.afterRender();
    $("html").removeClass("has-pattern");
    $(".modal-backdrop").remove();
    if (window.config.whiteLabel) {
      document.title = window.lang.pageTitleWhiteLabel;
    } else {
      document.title = window.lang.pageTitle;
    }
    if (app.session.get("isGuest")) {
      self.renderedForGuest = true;
    }
  },

  // Runs custom actions after renderization
  afterRender: function () {
    var self = this;

    // Hide sections if the user has not the right permissions
    self.hideForbiddenSections();

    // Hide sections if the account has not the right features
    self.hideForbiddenFeatures();

    //hide splash on dashboard
    self.updateMobileSplash();

    // Remove loading cover
    if (app.session.get("inAccount") && !window.shouldCompleteDetails) {
      self.removeCover();
    }

    // Add responsiveness
    $(window)
      .resize(function () {
        self.addResponsiveLayout();
      })
      .resize();

    // Set modal bindings
    $(document).on("show.bs.modal", ".modal", function (e) {
      setTimeout(function () {
        self.scrollTop(e);
      }, 350);
    });

    // render trial countdown view
    app.instances.singleton("trial", "index").render();

    //Vue notification toasts
    if (self.shouldApplyNewLayout) {
      mountComponent(Layout, {}, "#vue-sidebar-navigation");
    } else {
      createApp(GlobalComponentsOld).use(getI18nInstance()).use(usePinia()).mount("#vue-global-components");
    }
  },

  // Test if the current window width is under the mobile breakpoint
  isDesktop: function () {
    var windowWidth = $(window).width();
    return windowWidth > window.config.breakpoint;
  },

  isMobile: function () {
    var windowWidth = $(window).width();
    return windowWidth < 640;
  },

  switchToNewLayout: function (e) {
    const localStorageApp = useLocalStorageApp();
    localStorageApp.saveGlobal({ id: "useNewLayout", value: true });

    window.app.router.changeAccount(app.session.attributes.account);
  },

  // Toggles the sidebar
  toggleSidebar: function (e) {
    e.preventDefault();
    e.stopPropagation();
    $("body").toggleClass("sidebar-active");
  },

  // Closes the sidebar
  closeSidebar: function () {
    $("body").removeClass("sidebar-active");
  },

  // Shows the loading cover
  showCover: function () {
    $(".cover").fadeIn();
  },

  // Removes the loading cover
  removeCover: function () {
    setTimeout(function () {
      $(".cover").fadeOut();
    }, 1500);
  },

  // Adds responsive behaviour on resize
  addResponsiveLayout: function () {
    var content = $(".content"),
      sidescroll = $(".sidescroll"),
      windowHeight = $(window).height(),
      brandHeight = $(".brand").height(),
      fixedElements = [
        ".header",
        ".alerts",
        ".section-header",
        ".toolbar",
        ".list-controls",
        ".breadcrumbs",
        ".paging",
      ],
      fixedHeight = 0;

    // Iterate fixed elements to calculate their total height
    $.each(fixedElements, function (index, value) {
      fixedHeight = fixedHeight + $(value).outerHeight();
    });

    // Add custom CSS to the scrollable content
    content.css({
      height: windowHeight - fixedHeight,
    });

    // Add custom CSS to the scrollable sidebar
    sidescroll.css({
      height: windowHeight - brandHeight,
    });

    // Close sidebar on small viewports
    if (!this.isDesktop()) {
      this.closeSidebar();
    }

    if (this.isMobile()) {
      $(".main > .header").hide();
    } else {
      $(".main > .header").show();
    }

    this.updateMobileSplash();
  },

  updateMobileSplash: function () {
    const route = app.router.currentRoute();

    if (route.includes("dashboard")) {
      $(".mobile-splash").addClass("hidden");
    } else {
      $(".mobile-splash").removeClass("hidden");
    }
  },

  // Sets a section link as active
  setActiveSection: function () {
    var self = this,
      elements = $("[data-section]");

    let currentSection = app.router.currentRoute();
    currentSection = currentSection.split("/")[0];

    if (currentSection === "metrics") {
      currentSection = "campaigns";
    }
    if (currentSection === "") {
      currentSection = "dashboard";
    }

    // Unset the active section
    self.$(".sidebar").find(".active").removeClass("active");

    // Loop the sections
    elements.each(function () {
      var element = $(this);

      // If the section is active, mark the item and its parent as active
      if (currentSection === element.data("section")) {
        self.activeSection = element.data("section");
        element.closest("li").addClass("active").siblings().removeClass("open");
        element.parents("li").addClass("open");
      }
    });
  },

  // Updates the layout
  updateLayout: function () {
    var self = this;

    // Hide forbidden elements
    self.hideForbiddenElements();

    // Trigger resize
    $(window).resize();
  },

  // Hides the unaccesible sections groups or rewrites the main section link
  hideForbiddenSections: function () {
    var elements = $("[data-permission]");

    // Loop the sections groups
    elements.each(function () {
      var element = $(this),
        permission = element.data("permission");

      // Check if the user has permission for this section
      if (!app.session.hasPermission(permission)) {
        // Hide the group
        if (!element.data("always-allowed")) {
          element.closest("li").addClass("hidden");
        }
      }
    });
  },

  // Hides the unaccesible sections groups
  hideForbiddenFeatures: function () {
    var elements = $("[data-feature]");

    // Loop the sections groups
    elements.each(function () {
      var element = $(this),
        feature = element.data("feature");

      // Check if the account has the required feature for this section
      if (app.session.get("details").account?.features && !app.session.get("details").account?.features[feature]) {
        // Hide the group
        if (!element.data("always-allowed")) {
          element.closest("li").addClass("hidden");
        }
      }
    });
  },

  // Hides buttons and tabs restricted for the user
  hideForbiddenElements: function () {
    var elements = $("[data-require-permission]");

    // Loop elements that require special access
    elements.each(function () {
      var element = $(this),
        permissions = element.data("require-permission").split(" ");

      // If the user has not the right permission, hide the element
      $.each(permissions, function (index, permission) {
        if (!app.session.hasPermission(permission)) {
          element.addClass("hidden");
        }
      });
    });
  },

  // Hide forbidden elements on child accounts
  hideParentsElements: function () {
    if (app.session.get("currentAccount").isChild) {
      $("[data-section=plan]").closest("li").addClass("hide");
      $("[data-parents-only]").addClass("hidden");
    }
  },

  // Hide forbidden elements on non agency accounts
  hideAgencyElements: function () {
    if (!app.session.get("currentAccount").container) {
      $("[data-section=accounts]").closest("li").addClass("hide");
    }
  },

  // Filters the list of items based in the searched keyword
  filterInstantSearch: function (e) {
    var finder = $(e.currentTarget),
      items = $(finder.data("results")),
      noResults = $(finder.data("no-results")),
      search = finder.val().toLowerCase(),
      results = false;

    // Hide elements not matching the keyword
    items.each(function () {
      var item = $(this),
        value = item.data("value");
      if (typeof value === "number") {
        value = value + "";
      }
      if (typeof value === "string") {
        value = value.toLowerCase();
      }
      if (value && value.indexOf(search) >= 0) {
        item.removeClass("hide");
        results = true;
      } else {
        item.addClass("hide");
      }
    });

    // If there ar not matching elements, show the `not item found` message
    if (results) {
      noResults.addClass("hide");
    } else {
      noResults.removeClass("hide");
    }
  },

  // Scroll up elements
  scrollTop: function (e) {
    var modal = $(e.target);
    modal.find(".list-selector").scrollTop(0);
  },

  // Toggles optional inputs
  toggleOptionalInputs: function (e) {
    var toggler = $(e.currentTarget),
      target = $(toggler.data("target")),
      cancel = $(toggler.data("cancel")),
      input = target.find("input, select");

    // Manage cancel option
    if (cancel.length) {
      cancel.addClass("disabled");
      cancel.find("input, select").attr("disabled", true);
    }

    // Shows or hides the optional fields
    if (toggler.is(":checked")) {
      target.removeClass("disabled");
      input.removeAttr("disabled");
      if (input.val() === "" || input.val() === "0") {
        input.attr("value", input.data("predefined-value"));
        input.attr("data-changed", 1);
      }
    } else {
      target.addClass("disabled");
      input.removeAttr("data-changed");
      if (input.data("default-value") !== undefined) {
        input.removeAttr("disabled").attr("value", input.data("default-value"));
      } else {
        input.attr("disabled", true);
      }
    }
  },

  // Opens an items selector
  openItemsSelector: function (e) {
    var trigger = $(e.currentTarget),
      container = trigger.closest("[data-role=items-selector]"),
      target = container.find("[data-role=items-names]"),
      options = trigger.data(),
      selector;
    e.preventDefault();
    selector = app.instances.get(options.selectorView, "selector");
    selector.open(options).on("done", function (selectedItems) {
      target.html(selector.html);
      trigger.attr("data-selected-items", JSON.stringify(selector.options.selectedItems));
      trigger.data("selected-items", selector.options.selectedItems);
      container.find(".error-message").addClass("error-hidden");
      selector.off("done");
      selector.undelegateEvents();
      selector.reset();
      if (_.isDefined(options.callback)) {
        app.router.current[options.callback](selectedItems);
      }
    });
  },

  // Changes section
  changeSection: function (e) {
    var self = this,
      link = $(e.currentTarget),
      destSection = link.data("section"),
      section = self.activeSection;

    // Prevent navigation when the view change flag is true
    if (window.preventViewChange) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    // Reload section when the active section and the required route are equal
    if (link.parents(".active").length && section === destSection) {
      e.preventDefault();
      e.stopPropagation();
      app.router.navigateTo(destSection);
      window.Backbone.history.stop();
      window.Backbone.history.start();
    }
  },

  // Close open modals
  closeOpenModals: function () {
    $(".modal:visible").modal("hide");
    if (window.editor !== undefined && !window.editor.isDirty) {
      window.editor.close();
    }
  },

  // Shows the 404 page
  notFound: function (status) {
    var template = notFoundTpl;

    status = status || 404;
    $(".page").html(template({ lang: window.lang, status: status }));
  },

  // Removes the validation error when an input changes
  removeValidationErrorMessage: function (e) {
    var control = $(e.currentTarget).closest(".has-error");
    control.removeClass("has-error").find(".error-message").remove();
  },

  gotoAccount: function (e) {
    e.preventDefault();
    this.changeAccount(e);
  },

  gotoDashboard: function (e) {
    e.preventDefault();
    app.router.navigateTo("dashboard");
    this.updateMobileSplash();
  },

  changeAccount: function (e) {
    const account = $(e.currentTarget).data("code");
    app.router.changeAccount(account);
  },
});
