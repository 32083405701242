/* eslint-disable vue/one-component-per-file */
import { EditView } from "@/core/magnet";
import Eddie from "@plugins/eddie/eddie.js";
import EmailTemplatesModel from "@/modules/emailtemplates/model";
import FromsMixin from "@/mixins/froms";
import { _, objHas } from "@/helpers/utils";
import app from "@/app";
import { createApp } from "vue";
import SimpleSubjectInput from "@/components/vue/SimpleSubjectInput";
import SenderSelector from "@/components/vue/SenderSelector";
import { getI18nInstance } from "@locales/i18n";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";

import { usePinia } from "@store";
import { useOnboardingStore } from "@store";

export default EditView.mix(FromsMixin).extend({
  module: "automations",
  templates: {
    initial: require("../templates/content.hbs"),
  },
  cache: false,
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "click [data-action=open-send-test-modal]": "openSendTestModal",
      "submit [data-form=send-test]": "sendTest",
      "change [name=from]": "changeFrom",
      "submit [data-form=add-from]": "addFrom",
      "submit [data-form=verify-from]": "verifyFrom",
      "click [data-action=open-update-from-modal]": "openUpdateFromModal",
      "submit [data-form=update-from]": "updateFrom",
      "click [data-action=open-send-instructions-modal]": "openSendInstructionsModal",
      "submit [data-form=send-instructions]": "sendInstructions",
      "shown.bs.tab [data-toggle=tab]": "afterTabChange",
      "click [data-action=insert-field]": "insertField",
      "click [data-action=save]": "saveContents",
      "click [data-action=open-social-header-modal]": "openSocialHeaderModal",
      "submit [data-form=social-header]": "saveSocialHeader",
      "click [data-action=change-preview]": "changePreview",
      "click [data-action=open-eddie-content-modal]": "openEddieModal",
      "click [data-action=goto-content-tab]": "gotoContentTab",
      "change [data-action=switch-template]": "switchTemplate",

      "click [data-action=enable]": "enable",
      "click [data-action=disable]": "disable",
      "click [data-action=cancel]": "cancel",
      "click [data-action=duplicate]": "duplicate",

      "blur input[data-action=edit-inline]": "inlineUpdateSave",
      "keyup input[data-action=edit-inline]": "inlineUpdateKeyPress",
      "click span[data-action=edit-inline]": "inlineUpdateStart",

      "click [data-action=delete]": "openDeleteModal",
      "submit [data-form=delete-automation]": "delete",

      "click [data-action=duplicate-eddie]": "duplicateEddie",
    });
  },

  // Saves the content fields
  saveContents: function (e) {
    var self = this;

    var data = this.getFormData("[data-form=save-contents]");

    var tpl = {};
    if (data.fromName) {
      tpl.from = {};
      tpl.from.name = data.fromName;
    }
    if (data.from) {
      tpl.from = tpl.from || {};
      tpl.from.email = data.from;
    }
    if ("replyTo" in data) {
      //si es vacio tambien lo quiero pisar
      tpl.reply_to = tpl.reply_to || {};
      tpl.reply_to.email = data.replyTo;
    }
    if (data.senderId) {
      tpl.sender_id = data.senderId;
    }
    if (data.subject) {
      tpl.subject = data.subject;
    }
    if (data.preheader) {
      tpl.preheader = data.preheader;
    }

    if (data.substitution_data_url) {
      tpl.substitution_data_url = data.substitution_data_url;
    }

    this.selectedEmailTemplate.save(tpl).done(function () {
      if (e) {
        // Cuando no hay evento es porque vino por el sendTest
        self.displayMessage(window.lang.campaigns.feedback.contentSaved);

        self.$("[data-form=save-contents]").trigger("change").removeAttr("data-dirty");

        // Allow navigation
        window.onbeforeunload = null;
      }
    });
  },

  enable: function (e) {
    var self = this,
      model = self.item;
    e.preventDefault();

    this.saveContents();

    if (this.item.isEddieBlocked()) {
      this.openRestrictedFeatureModal({
        title: window.lang.automations.eddieBlockedTitle,
        message: window.lang.automations.eddieBlockedMessage,
      });
      return false;
    }

    if (this.enableRestricted /*&& !app.session.get('isMasterUser')*/) {
      if (this.item.additionalsData.plan.state.includes("BLOCKED")) {
        this.openRestrictedFeatureModal({
          title: window.lang.automations.enableAutomation,
          message: window.lang.automations.accountBlocked,
        });
      } else if (
        this.item.additionalsData.plan.type !== "MONTHLY" &&
        !this.item.additionalsData.plan.type.includes("CHILD")
      ) {
        this.openRestrictedFeatureModal({
          title: window.lang.automations.enableAutomation,
          message: window.lang.automations.monthlyPlanRequired,
        });
      }
      return false;
    }

    // Perform API call
    return model.enable({
      success: function (res) {
        self.displayMessage(window.lang.automations.automationEnabled);
        self.reload();

        const onboardingStore = useOnboardingStore();

        const eventPromises = res.data?.options?.types?.map((type) => {
          return onboardingStore.submitUserEvent({
            action: `automation.${type}.enabled`,
            data: {
              id: res.data.id,
              name: res.data.name,
            },
          });
        });

        Promise.all([
          onboardingStore.submitUserEvent({
            action: "automation.enabled",
            data: {
              id: res.data.id,
              name: res.data.name,
            },
          }),
          ...eventPromises,
        ]);
      },
      error: function (err) {
        if (
          err.responseJSON &&
          err.responseJSON.error &&
          ["validation_error", "invalid_operation"].includes(err.responseJSON.error.type)
        ) {
          self.displayMessage(window.lang.automations.enableError, 8000, "error");
        } else {
          self.displayMessage(window.lang.automations.enableErrorUnknown, 8000, "error");
        }
        return false;
      },
    });
  },

  disable: function (e) {
    var self = this,
      model = self.item;
    e.preventDefault();

    var confirmText = window.lang.automations.confirmDisable,
      button = null,
      titleText = window.lang.automations.disable,
      type = "info",
      flag = null;

    this.confirmModal(
      confirmText,
      button,
      titleText,
      type,
      flag,
      function () {
        // Perform API call
        return model.pause({
          success: function () {
            self.displayMessage(window.lang.automations.automationDisabled);
            self.reload();
          },
        });
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this),
    );
  },

  cancel: function (e) {
    var self = this,
      model = self.item;
    e.preventDefault();

    var confirmText = window.lang.automations.confirmCancel,
      button = null,
      titleText = window.lang.automations.cancel,
      type = "warning",
      flag = null;

    this.confirmModal(
      confirmText,
      button,
      titleText,
      type,
      flag,
      function () {
        return model.cancel({
          success: function () {
            self.displayMessage(window.lang.automations.automationCanceled);
          },
        });
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this),
    );
  },

  // Returns the data to be rendered by the template function
  renderData: function () {
    return {
      data: this.item,
      template: this.selectedEmailTemplate,
      automationTemplates: this.emailTemplates,
      multiContent: this.emailTemplates.length > 1,
      enableRestricted: this.enableRestricted,
      lang: window.lang,
      config: window.config,
      session: app.session,
    };
  },

  // Renders the preview box
  renderPreview: function () {
    this.$(".preview-wrapper").html(require("../templates/preview.hbs")(this.renderData()));
  },

  // Render iframe with timeout
  renderIframePreviewWithTimeout: function () {
    if (this.getPreviewIframe()) {
      this.renderIframePreview();
    } else {
      this.previewTimeout = setTimeout(
        function () {
          this.renderIframePreviewWithTimeout();
        }.bind(this),
        2000,
      );
    }
  },

  // Gets the iframe URL
  getPreviewIframeUrl: function () {
    return "emailtemplates/" + this.selectedEmailTemplate.get("id") + "/preview?token=" + app.session.attributes.token;
  },

  // Returns the preview iframe
  getPreviewIframe: function () {
    return document.getElementById("content-preview");
  },

  // Sets the preview HTML
  renderIframePreview: function () {
    var self = this,
      loadingBox = self.$(".loading-box"),
      noContentBox = self.$(".no-content"),
      previewBox = self.$("[data-role=preview]"),
      iframe = self.getPreviewIframe(),
      url = self.getPreviewIframeUrl();

    // Remove no content box
    noContentBox.addClass("hidden");

    // Hide iframe
    iframe.style.height = 0;

    // Show iframe container
    previewBox.removeClass("hidden");

    // Display loader
    loadingBox.removeClass("hidden");

    $("#content-error").hide();
    $("[data-action=open-send-test-modal]").prop("disabled", false);
    $("[data-action=enable]").prop("disabled", false);

    $.ajax({
      type: "GET",
      url: url,
      dataType: "html",
      timeout: 20000,
      success: (res) => {
        if (!iframe.contentDocument) return;

        iframe.contentDocument.open();
        iframe.contentDocument.write(res);
        iframe.contentDocument.close();

        //limpio links vacios
        const links = iframe.contentDocument.querySelectorAll("a[href=''], a[href='#']");
        links.forEach((link) => {
          link.removeAttribute("href");
        });

        self.resizeIframe();
        loadingBox.addClass("hidden");

        $(iframe).on("load", () => {
          $(iframe).off("load");
          self.resizeIframe();
        });
      },
      error: () => {
        loadingBox.addClass("hidden");
        $("#content-error").show();
        $("[data-action=open-send-test-modal]").prop("disabled", true);
        $("[data-action=enable]").prop("disabled", true);
      },
    });
  },

  // Resizes the preview iframe
  resizeIframe: function () {
    var self = this,
      iframe = self.getPreviewIframe();
    iframe.style.height = 0;
    iframe.style.height = iframe.contentWindow.document.body.scrollHeight + "px";
  },

  // Performs the needed bindings before renderization
  beforeRender: function (callback) {
    this.enableRestricted = false;
    if (objHas(this.item, "additionalsData", "plan", "state")) {
      if (this.item.additionalsData.plan.state.includes("BLOCKED")) {
        this.enableRestricted = true;
      }
    }
    if (
      app.getTrial() &&
      !app.getTrial().inTrial &&
      this.item.additionalsData.plan.type !== "MONTHLY" &&
      !this.item.additionalsData.plan.type.includes("CHILD") &&
      this.item.attributes.options &&
      this.item.attributes.options.restricted
    ) {
      this.enableRestricted = true;
    }

    this.loadEmailTemplates(
      function () {
        //this.updateEmailStepsPreviews();

        if (this.options.template) {
          this.selectedEmailTemplate = this.getEmailTemplate(this.options.template);
        } else {
          // Select first email template if not defined
          this.selectedEmailTemplate = this.getEmailTemplate();

          app.router.updateRoute(`automations/${this.item.get("id")}/contents/${this.selectedEmailTemplate.get("id")}`);
        }

        callback();
      }.bind(this),
    );
  },

  // Load email templates used by the automation and calls callback when finished
  loadEmailTemplates: function (callback) {
    var templates = [];
    var steps = this.item.get("steps");
    var deferreds = [];

    for (var i = 0; i < steps.length; i++) {
      if (steps[i].type === "email") {
        var emailtpl = new EmailTemplatesModel({
          id: steps[i].data.template_id,
        });
        templates.push(emailtpl);
        deferreds.push(emailtpl.fetch());
      }
    }

    this.emailTemplates = templates;

    // Executes the callback when all deferreds are done
    $.when.apply(null, deferreds).done(function () {
      callback();
    });
  },

  getEmailTemplate: function (id) {
    var models = this.emailTemplates;

    if (_.isUndefined(id)) {
      return models[0];
    }

    for (var i = 0; i < models.length; i++) {
      if (models[i].get("id") === id) {
        return models[i];
      }
    }
  },

  switchTemplate: function (e) {
    var tplId = e.currentTarget.value;
    this.saveContents();
    app.router.navigateTo("#automations/" + this.item.get("id") + "/contents/" + tplId);
  },

  // Runs custom actions after renderization
  afterRender: function () {
    createApp(SimpleSubjectInput, {
      // eslint-disable-next-line vue/component-definition-name-casing
      name: "subject",
      subject: this.selectedEmailTemplate.attributes.subject || "",
      fields: this.item.additionalsData.fields,
      placeholder: window.lang.campaigns.subjectPlaceholder,
    }).mount("#vue-subject");

    if (app.session.attributes.details.account.features.SENDERSV2) {
      createApp(SenderSelector, {
        senderId: this.selectedEmailTemplate.attributes.sender_id,
      })
        .use(getI18nInstance())
        .use(usePinia())
        .mount("#vue-sender-selector");
    }

    this.$("[name=from]").trigger("change").removeAttr("data-changed");
    this.$("[data-form=save-contents]").trigger("change").removeAttr("data-dirty");
    //this.$('[data-action=save]').attr('disabled', 'disabled');

    this.renderPreview();
    this.renderIframePreviewWithTimeout();

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },

  openSendTestModal: function () {
    var attributes = this.getFormData(this.editForm);

    var form = $("[data-form=save-contents]").serializeObject();

    if (!form.from || !form.fromName || !form.subject) {
      this.displayMessage(window.lang.campaigns.sendTestValidationFailed, 5000, "error");
      return false;
    }

    this.saveContents();

    if (this.beforeCreate(attributes, this.editForm)) {
      $.getJSON(
        "campaigns/testcontacts",
        function (response) {
          this.openModal(
            require("../templates/modals/sendTest.hbs"),
            $.extend({}, this.renderData(), { testRecipients: response.data }),
          );
        }.bind(this),
      );
    }
  },

  sendTest: function (e) {
    var form = e.currentTarget,
      emails = $(form).serializeObject().emails,
      errors = {},
      data;
    e.preventDefault();

    // Validate edit form
    if (!this.validateFormFields(this.editForm)) {
      return false;
    }

    // Replace spaces, semicolons and new lines by commas and split
    emails = _.compact(
      emails
        .replace(/[ |;]/g, ",")
        .replace(/\r?\n|\r/g, ",")
        .split(","),
    );

    // // Remove previos errors
    this.cleanFormErrorMessages(form);

    // Validate emails
    emails.forEach(
      function (email) {
        if (!this.rules.email.test(email)) {
          errors.emails = window.lang.drips.invalidEmails;
        }
      }.bind(this),
    );
    if (_.size(errors)) {
      return this.showValidationErrors(errors, $(form));
    }

    // Create payload
    data = { emails: emails };

    this.selectedEmailTemplate.sendTest(data).done(
      function () {
        this.displayMessage(window.lang.campaigns.feedback.testCampaignSent);
        this.getModal().modal("hide");
      }.bind(this),
    );
  },

  // Returns to the previous page
  back: function () {
    app.router.navigateTo("automations");
  },

  // Opens a modal to set social header information
  openSocialHeaderModal: function (e) {
    e.preventDefault();
    this.openModal(require("../templates/modals/socialHeader.hbs"), this.renderData());
  },

  // Save advanced options
  saveSocialHeader: function (e) {
    var form = $(e.currentTarget),
      options = {},
      modal = $(this.modal);
    e.preventDefault();

    // Get options
    form.find("input").each(function () {
      if ($(this).attr("type") === "checkbox") {
        options[$(this).attr("name")] = $(this).is(":checked") ? 1 : 0;
      } else {
        options[$(this).attr("name")] = $(this).val();
      }
    });

    $("#preheader").val(options.preheader).attr("data-changed", "1");

    this.selectedEmailTemplate.set("preheader", options.preheader);

    modal.modal("hide");
  },

  getTitle: function () {
    return this.item.attributes.name;
  },

  // getJsonContent: function () {
  //     return JSON.parse(this.selectedEmailTemplate.additionalsData.jsonContent.content);
  // },

  // Changes the preview
  changePreview: function (e) {
    var self = this,
      button = $(e.currentTarget),
      device = button.data("device"),
      preview = self.$(".content-preview");

    // Add active class
    button.addClass("active").siblings().removeClass("active");

    // Add or remove the mobile class
    if (device === "mobile") {
      preview.addClass("mobile");
    } else {
      preview.removeClass("mobile");
    }

    // Resize iframe
    setTimeout(() => self.resizeIframe(), 1000);
  },

  // Opens a modal to edit the content with Eddie
  openEddieModal: function () {
    var self = this,
      tutorial = true,
      title = self.getTitle() || "";

    self.loading(true);

    // Get tutorial value from user session
    if (app.session.get("context") !== undefined && app.session.get("context").eddieTutorial !== undefined) {
      tutorial = parseInt(app.session.get("context").eddieTutorial);
    }

    this.selectedEmailTemplate.loadJsonContent().done(function (res) {
      var content = JSON.parse(res.data.content);

      // Hide tutorial when content is defined or the user is master
      if (content || app.session.get("userType") === "MASTER") {
        tutorial = false;
      }

      // Set content as dirty
      self.isDirty = true;

      const account = app.session.get("account");

      // Create a new Eddie editor
      window.editor = new Eddie({
        // Title
        title: title,

        // User's account ID
        accountId: app.session.get("account"),

        // User's token
        token: app.session.get("token"),

        // Tags format
        useLowercaseTags: true,
        conditionalRegions: true,

        // Language
        lang: app.lang,

        // Default content
        content: content,

        // Show or hide tutorial
        tutorial: tutorial,

        // Show dynamic products regions
        showDynamicProducts: true,

        allowFileUpload:
          ["acumar", "acumarinterna"].includes(account) ||
          (app.getTrial() && app.getTrial().inTrial) ||
          app.session.getPlanType() === "MONTHLY" ||
          app.session.getPlanType().includes("CHILD"),

        // Callback to be executed when done
        callback: function (editor, html, json) {
          self.saveContent(html, json);
        },

        // Callback to be executed in intervals
        intervalCallback: function () {
          //self.saveContent(html, JSON.parse(json));
        },

        // Initial callback
        initCallback: function () {
          self.loading(false);
        },

        // Callback to be executed when cancelled
        cancelCallback: function (editor) {
          // Ask the user if he want to lose the changes
          if (editor.isDirty) {
            if (!confirm(window.lang.confirmLoseChanges)) {
              return false;
            }
          }

          // Turn off loader
          self.loading(false);

          // Close editor
          return true;
        },
      });
    });

    // Return instance
    return self;
  },

  saveContent: function (html, json) {
    var self = this;

    html = html || false;
    json = json || false;

    // Return if no HTML content is defined
    if (!html) {
      return self;
    }

    // Show loading feedback
    if (window.editor !== undefined && window.editor.shouldClose) {
      self.displayMessage("Guardando...");
    }

    // Prevent view changes until the content is saved
    window.preventViewChange = true;

    // Save JSON and HTML
    if (json) {
      self.saving = { json: json, html: html };
      return self.updateContent(html, json);
    }
  },

  // Saves the content
  updateContent: function (html, json) {
    var self = this;

    //Envuelvo el html generado por eddie
    var envelopedHtml = require("../templates/htmlwrapper.hbs")({
      content: html,
      lang: window.lang.automations.htmlwrapper,
      locale: window.lang.automations.htmlwrapper.lang + "_" + app.session.attributes.details.account.country,
    });

    self.selectedEmailTemplate.saveJsonContent(json).done(function () {
      //self.item.additionalsData.jsonContent.content = json;

      self.selectedEmailTemplate.saveHtmlContent(envelopedHtml).done(function (response) {
        // Turn off loader
        self.loading(false);

        // Enable navigation
        window.preventViewChange = false;

        // Close modal window
        self.isDirty = false;
        $(self.modal).modal("hide");

        // Close Eddie modal
        if (window.editor !== undefined && window.editor.shouldClose) {
          self.displayMessage(window.lang.campaigns.feedback.contentSaved);
          window.editor.close();
        }
        self.afterContentSave(response);
      });
    });
  },

  // After save hook
  afterContentSave: function () {
    if (this.getPreviewIframe()) {
      this.renderIframePreview();
    }
    return this;
  },

  // Inserts a field in ths subject input
  insertField: function (e) {
    var tag = $(e.currentTarget).data("tag"),
      subject = $("[name=subject]"),
      prefix = "contact.";
    e.preventDefault();
    tag = tag.toLowerCase();
    if (tag === "lname") tag = "last_name";
    if (tag === "fname") tag = "first_name";
    window.insertText("${" + prefix + tag + "}", subject);
  },

  // Runs after verifying a from
  verifiedCallback: function (response) {
    var from = response.data,
      html = this.renderFromOption(from);
    this.currentSelect.children().last().before(html);
    this.currentSelect.val(from.email).trigger("change");
  },

  // Cleans up the view
  cleanUp: function () {
    var self = this;

    // If the window is open, close it
    if (window.editor !== undefined) {
      // Save eddie content first
      if (!window.editor.closed && window.editor.isDirty) {
        window.editor.save();
      }

      // Close editor
      window.editor.close();
      delete window.editor;
    }

    // Reset model
    self.item = null;

    // Stop loader
    self.loading(false);

    // Unbind events
    self.undelegateEvents();
    self.unbindPlugins();

    // Restore original template
    if (self.originalTemplate !== undefined && self.originalTemplate !== self.template) {
      self.setTemplate(self.originalTemplate);
    }

    // Close modal
    $(self.modal).modal("hide");

    if (this.previewTimeout) {
      clearTimeout(this.previewTimeout);
    }

    // Return true to confirm clean up
    return true;
  },

  openDeleteModal: function (e) {
    e.preventDefault();

    this.openModal(require("@/modules/automations/templates/modals/delete.hbs"), {});
  },

  delete: function (e) {
    var self = this;
    var model = self.item;
    var deletedMessage = (deletedMessage = [
      window.lang["articleM"],
      window.lang.automations.singular,
      window.lang["hasBeenDeletedM"],
    ].join(" "));

    e.preventDefault();

    model.destroy({
      success: () => {
        self.displayMessage(deletedMessage);
        app.router.navigateTo("automations");
        self.reload();
      },
      error: (error) => {
        console.log(error);
      },
    });
  },

  duplicate: async function () {
    if (this.item.isEddieTemplate()) {
      const trackingEventsService = useTrackingEvents();
      const eddieFeatures = this.item.getEddieFeatures();
      const cantDuplicate =  !eddieFeatures.allow_eddie_duplicate;

      trackingEventsService.amplitude({
        name: "APP_EDDIE_DUPLICATE_NOTIFICATION",
        data: {
          source: "automation",
          id: this.item.id,
          feature_unlayer_optional: eddieFeatures.unlayer_optional,
          feature_unlayer_default: eddieFeatures.unlayer_default,
          feature_hide_legacy_templates: eddieFeatures.hide_legacy_templates,
        },
      });

      this.openModal(require("@/modules/layout/templates/modals/eddieDeprecate.hbs"), {cantDuplicate});
    }

    if (!this.item.isEddieTemplate()) {
      this.item.duplicate().done(function (response) {
        app.router.navigateTo(`automations/${response.data.id}`);
      });
    }
  },

  duplicateEddie: function () {
    const trackingEventsService = useTrackingEvents();
    const eddieFeatures = this.item.getEddieFeatures();

    trackingEventsService.amplitude({
      name: "APP_EDDIE_DUPLICATE_ACCEPT",
      data: {
        source: "automation",
        id: this.item.id,
        feature_unlayer_optional: eddieFeatures.unlayer_optional,
        feature_unlayer_default: eddieFeatures.unlayer_default,
        feature_hide_legacy_templates: eddieFeatures.hide_legacy_templates,
      },
    });

    this.item.duplicate().done(function (response) {
      app.router.navigateTo(`automations/${response.data.id}`);
    });
  },
});
