// Compares values from an array of a key within the objects of an array
export default function (key, values, collection, options) {

  if (!collection || !collection.length || !Array.isArray(values)) {
    return options.inverse(this);
  }

  const found = collection.some((obj) => values.includes(obj[key]));

  if (found) return options.fn(this);
  return options.inverse(this);
}
