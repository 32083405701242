<template>
  <div class="space-y-6 p-6 pr-16 xl:pr-40 2xl:pr-80">
    <div>
      <FormSwitch
        :model-value="includeUnsubscribedContacts"
        :label="t('unsubscribedContacts.title')"
        :description="t('unsubscribedContacts.description')"
        reverse
        @update:model-value="onToggleIncludeUnsubscribedContacts"
      />
      <AlertBox v-if="includeUnsubscribedContacts" theme="warning" class="mt-2">{{
        t("unsubscribedContacts.alert")
      }}</AlertBox>
    </div>
    <div>
      <FormSwitch
        :model-value="supportsBranding"
        :label="t('branding.title')"
        :description="t('branding.description')"
        reverse
        @update:model-value="onToggleSupportsBranding"
      />
    </div>
    <FormInput
      :model-value="sampleContext"
      hint="Contexto a utilizar en la generación de vistas previas y envios de prueba."
      label="Sample context"
      @update:model-value="updateSampleContext"
    ></FormInput>
    <ConfirmationModal
      id="unsubscribeContactsModal"
      v-bind="unsubscribeContactsModal"
      v-model:dismissValue="unsubscribeContactsModal.dismissValue"
      @cancel="onCancelUnsubscribeContacts"
      @accept="onAcceptUnsubscribeContacts"
    />
  </div>
</template>
<script lang="ts" setup>
import { reactive } from "vue";

//Components
import FormSwitch from "@molecules/FormSwitch.vue";
import FormInput from "@molecules/FormInput.vue";
import AlertBox from "@atoms/AlertBox.vue";
import ConfirmationModal from "@molecules/ConfirmationModal.vue";

//I18n
import { useI18n } from "vue-i18n";

//Types
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";

const { t } = useI18n();

// Props
const props = withDefaults(
  defineProps<{
    sampleContext?: string;
    includeUnsubscribedContacts?: boolean;
    unsubscribeContactsModalIsOpen: boolean;
    supportsBranding: boolean;
  }>(),
  {
    sampleContext: undefined,
    includeUnsubscribedContacts: false,
    supportsBranding: false,
  },
);

// Emits
const emit = defineEmits<{
  (e: "update:sampleContext", value: string): void;
  (e: "update:includeUnsubscribedContacts", value: boolean): void;
  (e: "update:unsubscribeContactsModalIsOpen", value: boolean): void;
  (e: "update:supportsBranding", value: boolean): void;
}>();

const updateIncludeUnsubscribedContacts = (value: boolean) => emit("update:includeUnsubscribedContacts", value);
const updateSampleContext = (value: string) => emit("update:sampleContext", value);
const updateSupportsBranding = (value: boolean) => emit("update:supportsBranding", value);

//State
const unsubscribeContactsModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("unsubscribeContactsModal.confirmationTitle"),
  message: t("unsubscribeContactsModal.confirmationMessage"),
  acceptText: t("unsubscribeContactsModal.confirmButton"),
  cancelText: t("unsubscribeContactsModal.cancelButton"),
  dismissText: t("unsubscribeContactsModal.dismissText"),
  dismissValue: false,
  checkRequired: true,
  severity: "warning",
});
const restartUnsubscribeContactsModal = () => {
  unsubscribeContactsModal.dismissValue = false;
};

const closeUnsubscribeContactsModal = () => {
  emit("update:unsubscribeContactsModalIsOpen", false);
  restartUnsubscribeContactsModal();
  unsubscribeContactsModal.open = false;
};
const openUnsubscribeContactsModal = () => {
  emit("update:unsubscribeContactsModalIsOpen", true);
  unsubscribeContactsModal.open = true;
};

//Methods
const onToggleSupportsBranding = () => {
  if (!props.supportsBranding) {
    updateSupportsBranding(true);
  } else {
    updateSupportsBranding(false);
  }
};

const onToggleIncludeUnsubscribedContacts = () => {
  if (!props.includeUnsubscribedContacts) {
    openUnsubscribeContactsModal();
  } else {
    updateIncludeUnsubscribedContacts(false);
  }
};

const onCancelUnsubscribeContacts = () => {
  closeUnsubscribeContactsModal();
  updateIncludeUnsubscribedContacts(false);
};

const onAcceptUnsubscribeContacts = () => {
  closeUnsubscribeContactsModal();
  updateIncludeUnsubscribedContacts(true);
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "unsubscribedContacts": {
      "title": "Incluir a los contactos desuscriptos",
      "description": "Habilita esta opción para enviar también a contactos desuscriptos.",
      "alert": "Asegúrate de no incluir contenido de marketing, y que tus contactos desean recibir tus correos. Las malas prácticas de email marketing afectan tu reputación y entregabilidad"
    },
    "branding": {
      "title": "Habilitar marca",
      "description": "Activa esta opción para indicar que la plantilla admite la aplicación de la marca."
    },
    "unsubscribeContactsModal": {
      "confirmationTitle": "¿Incluir contactos desuscriptos?",
      "confirmationMessage": "Asegurate de no incluir contenido de marketing y que tus contactos desean recibir tus correos. Las malas prácticas afectan tu reputación y pueden dañar seriamente la entregabilidad de tus correos electrónicos. Enviar un correo electrónico no solicitado también es un delito en muchos países; deberías consultar con un experto legal para asegurarte de cumplir con todas las leyes y regulaciones aplicables. Por favor, asegúrese de haber leído y entendido estas pautas. Si notamos que estás haciendo un mal uso de nuestros productos o servicios, nos reservamos el derecho de retirar el acceso estos por completo.",
      "confirmButton": "Incluir contactos desuscriptos",
      "dismissText": "He leído y entiendo las recomendaciones mencionadas.",
      "cancelButton": "Cancelar"
    }
  },
  "pt": {
    "unsubscribedContacts": {
      "title": "Incluir no envio os contatos descadastrados",
      "description": "Habilite esta opção para incluir os contatos no envio, mesmo que descadastrados",
      "alert": "Não inclua conteúdo de marketing e garanta que os seus contatos desejam receber seus emails. As más praticas afetam sua reputação e entregabilidade"
    },
    "branding": {
      "title": "Habilitar marca",
      "description": "Ative esta opção para indicar que o modelo suporta a aplicação da marca."
    },
    "unsubscribeContactsModal": {
      "confirmationTitle": "¿Incluir contactos desuscriptos?",
      "confirmationMessage": "Asegurate de no incluir contenido de marketing y que tus contactos desean recibir tus correos. Las malas prácticas de email marketing afectan tu reputación y pueden dañar seriamente la entregabilidad de tus correos electrónicos. Enviar un correo electrónico no solicitado también es un delito en muchos países; deberías consultar con un experto legal para asegurarte de cumplir con todas las leyes y regulaciones aplicables. Por favor, asegúrese de haber leído y entendido estas pautas. Si notamos que estás haciendo un mal uso de nuestros productos o servicios, nos reservamos el derecho de retirar el acceso estos por completo.",
      "confirmButton": "Incluir contactos desuscriptos",
      "dismissText": "He leído y entiendo las recomendaciones mencionadas.",
      "cancelButton": "Cancelar"
    }
  }
}
</i18n>
