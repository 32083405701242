<template>
  <ModalDialog :open="open" @close="emitClose">
    <div class="max-w-5xl" style="min-width: 15rem">
      <BrandEditor v-bind="$props" @save="emitSave" />
    </div>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";

// Components
import BrandEditor from "./BrandEditor.vue";
import ModalDialog from "@molecules/ModalDialog.vue";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";

//Props
withDefaults(
  defineProps<{
    open: boolean;
  }>(),
  {
    open: false,
  },
);

const getRouteEvent = () => {
  const route = window.location.pathname;
  const parts = route.split("/").filter(Boolean);
  return parts[0];
};

const routeEvent = ref(getRouteEvent());
const trackingEventsService = useTrackingEvents();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "save"): void;
}>();

const emitClose = () => {
  trackCloseModal();

  emit("close");
};

const emitSave = () => {
  emit("save");
};

const trackCloseModal = () => {
  trackingEventsService.amplitude({
    name: "APP_BRAND_EDITOR_CLOSED",
    data: {
      ui_path: routeEvent.value,
    },
  });
};
</script>
