<template>
  <div>
    <div class="mb-8 pl-1 text-left">
      <div class="flex items-center space-x-4">
        <DialogTitle as="h3" class="m-0 text-lg font-semibold leading-6 text-gray-600">
          {{ t("title") }}
        </DialogTitle>
      </div>
    </div>

    <BrandEditorSkeleton v-if="loadingBrand" />

    <div v-if="!loadingBrand" class="grid grid-cols-2 gap-6">
      <div id="brandEditorInner" class="grid max-h-[38rem] gap-6 overflow-y-scroll pl-1 pr-6">
        <div class="text-sm text-gray-400">
          <p>
            {{ t("description") }}
          </p>
        </div>

        <div v-if="isTiendanube && hasBrandCurrent" class="grid gap-4">
          <p class="text-sm font-medium text-gray-700">
            {{ t("import.title") }}
          </p>
          <div>
            <SimpleButton theme="white" size="small" :loading="loadingImport" @click="importBrand">
              <template #leading>
                <CloudDownloadIcon class="text-gray-700" />
              </template>
              {{ t("import.button") }}
            </SimpleButton>
          </div>
        </div>

        <hr v-if="isTiendanube && hasBrandCurrent" class="my-1 border-gray-200" />

        <div class="grid gap-8">
          <div class="grid gap-4">
            <div class="grid grid-cols-2 gap-4">
              <InputLogo
                :file-name="brandEdition.logo"
                :loading="uploadingLogo"
                :error="v$.logo.$error ? t('validation.logo') : ''"
                @clear="clearFile"
                @update:model-value="updateImageFile"
              />

              <SelectFontFamily
                :selected-font="brandEdition.font_family"
                :fonts="availableFonts"
                @update:selected-font="updateFontFamily"
              />
            </div>
          </div>

          <div class="grid gap-4">
            <ColorPickers
              v-model:colors="brandEdition.colors"
              :color-palette="brandEdition.color_palette"
              :error="{
                body_background: v$.colors.body_background.$error ? t('validation.colors') : '',
                button_background: v$.colors.button_background.$error ? t('validation.colors') : '',
                button_text: v$.colors.button_text.$error ? t('validation.colors') : '',
                text_link: v$.colors.text_link.$error ? t('validation.colors') : '',
                text: v$.colors.text.$error ? t('validation.colors') : '',
              }"
            />
          </div>

          <div class="grid gap-4">
            <p class="text-sm font-medium text-gray-700">{{ t("config.linkTitle") }}</p>

            <SelectContactOptions
              :selected="brandEdition.button_contact_info"
              :options="brandEdition.contact_info_options"
              :error="v$.button_contact_info.$error ? t('validation.contact_info') : ''"
              @update:selected-option="updateContactInfoSelected"
              @update:selected="updateButtonContactInfo"
            />

            <InputWebStore
              :web-store-url="brandEdition.web_store_url"
              :has-error="v$.web_store_url.$error"
              @update:web-store-url="updateWebStoreUrl"
            />

            <FooterLinks
              :footer-links="brandEdition.footer_links"
              :max-links="4"
              :use-footer-links="brandEdition.use_footer_links"
              :errors="v$.footer_links"
              :has-error="v$.footer_links.$error"
              @update:footer-links="updateFooterLinks"
              @update:use-footer-links="updateUseFooterLinks"
            />

            <SocialMediaLinks
              :social-media-selected="brandEdition.social_media_selected"
              :social-media-options="brandEdition.social_media_options"
              :social-media-filtered="socialMediaLinkFiltered"
              :max-links="8"
              :use-social-media-links="brandEdition.use_social_media_links"
              :errors="v$.social_media_selected"
              :has-error="v$.social_media_selected.$error"
              @update:social-media-links="updateSocialMediaLinks"
              @update:use-social-media-links="updateUseSocialMediaLinks"
            />
          </div>
        </div>
      </div>

      <div>
        <BrandEditorTemplatePreview :brand="brandEdition" />
      </div>
    </div>

    <div v-if="!loadingBrand" class="mt-5 flex justify-end space-x-4">
      <SimpleButton v-if="allowBack" theme="white" @click="emitBack">
        <template #leading>
          <ChevronLeftIcon class="text-gray-500" />
        </template>
        {{ t("buttons.back") }}
      </SimpleButton>

      <SimpleButton v-if="allowSave" theme="primary" :loading="loadingSave" @click="saveBrand">
        <template #leading>
          <CheckIcon class="h-5 w-5 text-white" />
        </template>
        {{ t("buttons.save") }}
      </SimpleButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref } from "vue";

// API
import { useAccountFiles } from "@api/modules/accountfiles";
import { useBrand } from "@/vue/api/modules/brand/brandEditor";
import { useAccountConfig } from "@api/modules/account";
import { useTiendanubeBranding } from "@/vue/api/modules/brand/storeBrandEditor";

// Components
import { CloudDownloadIcon, CheckIcon, ChevronLeftIcon } from "@heroicons/vue/outline";
import BrandEditorTemplatePreview from "./BrandEditorTemplatePreview.vue";
import BrandEditorSkeleton from "./BrandEditorSkeleton.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import { useDefaultFonts } from "@/vue/composables/fonts";
import { DialogTitle } from "@headlessui/vue";

// Components Config Panel
import ColorPickers from "./ConfigPanel/ColorPickers.vue";
import FooterLinks from "./ConfigPanel/FooterLinks.vue";
import InputLogo from "./ConfigPanel/InputLogo.vue";
import InputWebStore from "./ConfigPanel/InputWebStore.vue";
import SelectContactOptions from "./ConfigPanel/SelectContactOptions.vue";
import SelectFontFamily from "./ConfigPanel/SelectFontFamily.vue";
import SocialMediaLinks from "./ConfigPanel/SocialMediaLinks.vue";

// Core
import useVuelidate from "@vuelidate/core";

// Helpers
import { toDataItems } from "@/vue/helpers/data";

// Lang
import { useI18n } from "vue-i18n";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Session
import { storeToRefs } from "pinia";

// Store
import { useSessionStore, useIntegrationsStore } from "@store";

// Types
import type { BrandEdition, ContactInfoTypes } from "@/vue/types/brandEditor.types";
import type { DataItem } from "@domain/data";
import type { DataItem as ComboboxDataItem } from "@molecules/ComboBox.vue";

import type { Font, FontWithAlter } from "@domain/fonts";
import type { FileAPIResponse } from "@/vue/api/modules/brand/brandEditor.types";

// Utils
import { addStyleSheetURL } from "@/vue/helpers/DOM";
import { helpers, required } from "@vuelidate/validators";
import { useNotifications } from "@/vue/composables/notifications";

type fontFamilyDataItem = DataItem<string, string, Font | FontWithAlter>;
type fontFamilyComboboxDataItem = ComboboxDataItem<Font | FontWithAlter, string, string>;

// Props
withDefaults(
  defineProps<{
    allowSave?: boolean;
    allowBack?: boolean;
  }>(),
  {
    allowSave: true,
    allowBack: false,
  },
);

const emit = defineEmits<{
  (e: "save"): void;
  (e: "back"): void;
}>();

// States
const { t } = useI18n();
const { notify } = useNotifications();
const accountConfig = useAccountConfig();
const accountFiles = useAccountFiles();
const availableFonts = ref<fontFamilyDataItem[]>([]);

// Brand Edition
const brandEdition = ref<BrandEdition>({
  logo: "",
  colors: {
    body_background: "#F5F5F5",
    button_background: "#F57179",
    button_text: "#FFFFFF",
    text_link: "#0068A5",
    text: "#000000",
  },
  color_palette: [
    "#000000",
    "#FFFFFF",
    "#FF1900",
    "#F47365",
    "#FFB243",
    "#FFE623",
    "#6EFF2A",
    "#1BC7B1",
    "#00BEFF",
    "#2E81FF",
    "#5D61FF",
    "#FF89CF",
    "#FC3CAD",
    "#BF3DCE",
    "#8E00A7",
    "rgba(0,0,0,0)",
  ],
  font_family: {
    id: "fallback_arial",
    name: "Arial",
    value: "arial,sans-serif",
  },
  contact_info: [],
  contact_info_options: [
    {
      key: "whatsapp",
      value: t("config.contactOptions.options.whatsapp"),
    },
    {
      key: "email",
      value: t("config.contactOptions.options.email"),
    },
    {
      key: "phone",
      value: t("config.contactOptions.options.phone"),
    },
    {
      key: "web",
      value: t("config.contactOptions.options.web"),
    },
  ],
  button_contact_info: { key: "whatsapp", value: "" },
  web_store_url: "",
  footer_links: [
    {
      key: t("config.footerLinksOptions.who"),
      value: "",
    },
    {
      key: t("config.footerLinksOptions.how"),
      value: "",
    },
    {
      key: t("config.footerLinksOptions.ask"),
      value: "",
    },
  ],
  use_footer_links: true,
  social_medias: [],
  social_media_options: [
    {
      key: "facebook",
      value: t("config.socialMediaLinks.options.facebook"),
    },
    {
      key: "instagram",
      value: t("config.socialMediaLinks.options.instagram"),
    },
    {
      key: "linkedin",
      value: t("config.socialMediaLinks.options.linkedin"),
    },
    {
      key: "pinterest",
      value: t("config.socialMediaLinks.options.pinterest"),
    },
    {
      key: "tiktok",
      value: t("config.socialMediaLinks.options.tiktok"),
    },
    {
      key: "vimeo",
      value: t("config.socialMediaLinks.options.vimeo"),
    },
    {
      key: "whatsapp",
      value: t("config.socialMediaLinks.options.whatsapp"),
    },
    {
      key: "x",
      value: t("config.socialMediaLinks.options.x"),
    },
    {
      key: "youtube",
      value: t("config.socialMediaLinks.options.youtube"),
    },
  ],
  social_media_selected: [
    {
      key: "facebook",
      value: "",
    },
    {
      key: "instagram",
      value: "",
    },
    {
      key: "whatsapp",
      value: "",
    },
  ],
  use_social_media_links: true,
});

const isValidUrl = (value: string): boolean => {
  if (!value) return false;
  const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/i;
  return regex.test(value);
};

const rules = computed(() => ({
  logo: {
    required,
    isValidUrl,
  },
  colors: {
    body_background: {
      required,
    },
    button_background: {
      required,
    },
    button_text: {
      required,
    },
    text_link: {
      required,
    },
    text: {
      required,
    },
  },
  button_contact_info: {
    key: { required },
    value: {
      required,
    },
  },
  web_store_url: {
    required,
    isValidUrl,
  },
  footer_links: {
    $each: helpers.forEach({
      key: { required },
      value: {
        required,
        isValidUrl,
      },
    }),
  },
  social_media_selected: {
    $each: helpers.forEach({
      key: { required },
      value: {
        required,
        isValidUrl,
      },
    }),
  },
}));

const v$ = useVuelidate(rules, brandEdition);
const trackingEventsService = useTrackingEvents();

const BrandAPI = useBrand();
const TiendanubeBrandingAPI = useTiendanubeBranding();

// Session
const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

const userId = computed(() => session?.value?.account?.username ?? "");

// Tiendanube
const integrationStore = useIntegrationsStore();
const isTiendanube = ref<boolean>(integrationStore.isTiendanube);

// Flags
const hasBrandCurrent = ref(false);
const loadingBrand = ref(true);
const loadingImport = ref(false);
const loadingSave = ref(false);
const uploadingLogo = ref(false);

// API - Methods
const fetchBrandCurrent = async () => {
  try {
    const res = await BrandAPI.current();

    if (res && Object.keys(res).length > 0) {
      const buttonContactInfo: DataItem<ContactInfoTypes> = {
        key: res.button_contact_info?.type ?? brandEdition.value.button_contact_info.key,
        value: res.button_contact_info?.value ?? brandEdition.value.button_contact_info.value,
      };

      brandEdition.value = {
        ...brandEdition.value,
        logo: res.logo,
        colors: res.colors,
        color_palette: res.color_palette,
        font_family: {
          id: res.font_family.label,
          name: res.font_family.value,
          value: res.font_family.value,
          url: res.font_family.url,
        },
        web_store_url: res.web_store_url,
        contact_info: toDataItems(res.contact_info, "type", "value"),
        button_contact_info: buttonContactInfo,
      };

      if (res.footer_links.length > 0) {
        brandEdition.value.footer_links = toDataItems(res.footer_links, "name", "value");
      }

      brandEdition.value.use_footer_links = res.footer_links.length > 0;

      if (res.social_media_links.length > 0) {
        brandEdition.value.social_media_selected = toDataItems(res.social_media_links, "platform", "url");
      }

      brandEdition.value.use_social_media_links = res.social_media_links.length > 0;

      hasBrandCurrent.value = true;

      return;
    }

    hasBrandCurrent.value = false;
    await fetchTiendanubeBranding();
  } catch (e) {
    console.log(e);
    await fetchTiendanubeBranding();
  } finally {
    loadingBrand.value = false;
  }
};

const fetchTiendanubeBranding = async () => {
  if (!isTiendanube.value) return;

  try {
    const res = await TiendanubeBrandingAPI.get();

    if (res && Object.keys(res).length > 0) {
      const contactInfo = toDataItems(res.contact_info, "type", "value");
      const contactInfoSelected = contactInfo.find((item) => item.key === brandEdition.value.button_contact_info.key);

      brandEdition.value = {
        ...brandEdition.value,

        logo: res.logo,

        colors: res.colors,
        color_palette: res.color_palette,

        contact_info: contactInfo,
        button_contact_info: contactInfoSelected ?? brandEdition.value.button_contact_info,

        web_store_url: res.web_store_url,
      };

      const fallbackFont =
        availableFonts.value.find((font) => font.key.toLowerCase() === "fallback_arial") || availableFonts.value[0];

      let fontFound = availableFonts.value.find((font) => font.key.toLowerCase() === res.font_family.toLowerCase());
      fontFound = fontFound != null ? fontFound : fallbackFont;

      if (fontFound != null) {
        updateFontFamily(fontFound);
      }

      if (res.social_media_links.length > 0) {
        brandEdition.value.social_media_selected = toDataItems(res.social_media_links, "platform", "url");
        brandEdition.value.use_social_media_links = true;
      }
    }
  } catch (e) {
    console.log(e);
  } finally {
    loadingBrand.value = false;
  }
};

const importBrand = async () => {
  try {
    loadingImport.value = true;
    await fetchTiendanubeBranding();
    trackingEventsService.amplitude({
      name: "APP_BRAND_EDITOR_IMPORT",
    });
  } catch (e) {
    updateUseSocialMediaLinks;
    console.log(e);
  } finally {
    loadingImport.value = false;
  }
};

const saveBrand = async () => {
  try {
    if (brandEdition.value.footer_links.every((link) => link.value === "")) {
      brandEdition.value.use_footer_links = false;
      brandEdition.value.footer_links = [];
    }

    if (brandEdition.value.social_media_selected.every((link) => link.value === "")) {
      brandEdition.value.use_social_media_links = false;
      brandEdition.value.social_media_selected = [];
    }

    if (brandEdition.value.footer_links.some((link) => link.value === "")) {
      brandEdition.value.footer_links = brandEdition.value.footer_links.filter((link) => link.value !== "");
    }

    if (brandEdition.value.social_media_selected.some((link) => link.value === "")) {
      brandEdition.value.social_media_selected = brandEdition.value.social_media_selected.filter(
        (link) => link.value !== "",
      );
    }

    const validation = await v$.value.$validate();
    if (!validation) {
      scrollToBottom();

      notify({
        title: t("notifications.error.title"),
        text: t("notifications.error.text"),
        theme: "error",
        timeout: 5000,
      });

      return;
    }

    loadingSave.value = true;

    // CONTACT INFO CASE
    const contactInfo = brandEdition.value.contact_info;
    const buttonContactInfo = brandEdition.value.button_contact_info;

    const existingContact = contactInfo.find((item) => item.key === buttonContactInfo.key);
    if (existingContact) {
      existingContact.value = buttonContactInfo.value;
      if (existingContact.data) {
        existingContact.data.value = buttonContactInfo.value;
      }
    } else {
      contactInfo.push({
        key: buttonContactInfo.key,
        value: buttonContactInfo.value,
        data: {
          type: buttonContactInfo.key,
          value: buttonContactInfo.value,
        },
      });
    }

    const useFooterLinks = brandEdition.value.use_footer_links;
    const useSocialMediaLinks = brandEdition.value.use_social_media_links;

    const body = {
      logo: brandEdition.value.logo,
      colors: brandEdition.value.colors,
      color_palette: brandEdition.value.color_palette,
      font_family: {
        label: brandEdition.value.font_family.id,
        value: brandEdition.value.font_family.value || "",
        url: brandEdition.value.font_family.url,
      },
      web_store_url: brandEdition.value.web_store_url,
      footer_links: useFooterLinks ? transformFooterLinks(brandEdition.value.footer_links) : [],
      contact_info: transformContactInfo<ContactInfoTypes>(contactInfo),
      button_contact_info: transformContactInfoSelected<ContactInfoTypes>(brandEdition.value.button_contact_info),
      social_media_links: useSocialMediaLinks
        ? transformSocialMediaLinks(brandEdition.value.social_media_selected)
        : [],
    };

    await BrandAPI.save({ body: body, userId: userId.value });

    trackSaveBrand();

    notify({
      title: t("notifications.success.title"),
      text: t("notifications.success.text"),
      theme: "success",
      timeout: 5000,
    });

    emit("save");
  } catch (e) {
    console.log(e);
  } finally {
    loadingSave.value = false;
  }
};

const scrollToBottom = () => {
  const element = document.getElementById("brandEditorInner");

  nextTick(() => {
    if (element != null) {
      element.scrollTo({
        top: element.scrollHeight,
        behavior: "smooth",
      });
    }
  });
};

// AMPLITUDE
const trackSaveBrand = () => {
  trackingEventsService.amplitude({
    name: "APP_BRAND_EDITOR_SAVED",
  });
};

// INPUT LOGO
const uploadImage = async (file): Promise<FileAPIResponse> => {
  const res: FileAPIResponse = (await accountFiles.postImages({
    file,
    folder: "/imageTest",
  })) as unknown as FileAPIResponse;
  return res;
};

const clearFile = () => {
  brandEdition.value.logo = "";
};

const updateImageFile = async (newFile: unknown) => {
  uploadingLogo.value = true;
  try {
    const uploadedFile = await uploadImage(newFile);
    brandEdition.value.logo = uploadedFile.data.location.absolute;
  } catch (e) {
    console.log("Error", e);
  } finally {
    uploadingLogo.value = false;
  }
};

// FONT FAMILY
const getDefaultFonts = (): fontFamilyComboboxDataItem[] => {
  const defaultFonts = useDefaultFonts();

  const defaultFontsDataItems: fontFamilyComboboxDataItem[] = defaultFonts.map<fontFamilyComboboxDataItem>((font) => {
    const dataItem: fontFamilyComboboxDataItem = {
      key: font.id,
      value: font.name || "",
      data: font,
    };

    if (font.value)
      dataItem.options = {
        font: {
          fontFamily: font.value,
        },
      };

    return dataItem;
  });

  return defaultFontsDataItems;
};

const fetchAvailableFonts = async () => {
  const accountConfigRes = await accountConfig.get();

  let accountFontsDataItems: fontFamilyComboboxDataItem[] = [];

  if (accountConfigRes.fonts) {
    accountFontsDataItems = toDataItems<string, string, Font | FontWithAlter>(
      accountConfigRes.fonts,
      "id",
      "value",
    ) as fontFamilyComboboxDataItem[];
  }

  accountConfigRes.fonts?.forEach((font) => {
    if (font.url && !document.querySelector(`link[href="${font.url}"]`)) {
      addStyleSheetURL(font.url);
    }
  });

  const defaultFonts = getDefaultFonts();

  availableFonts.value = mergeAndOrderFonts(accountFontsDataItems, defaultFonts);
};

const mergeAndOrderFonts = (accountFonts: fontFamilyComboboxDataItem[], defaultFonts: fontFamilyComboboxDataItem[]) => {
  const combinedFonts = [
    ...accountFonts,
    ...defaultFonts.filter((defaultFont) => !accountFonts.some((accountFont) => accountFont.key === defaultFont.key)),
  ];

  return combinedFonts.sort((fontA, fontB) => {
    if (fontA.data?.disabled && !fontB.data?.disabled) return 1;
    if (!fontA.data?.disabled && fontB.data?.disabled) return -1;
    return 0;
  });
};

const updateFontFamily = (font: DataItem<string, string, Font | FontWithAlter>) => {
  brandEdition.value.font_family = {
    id: font.key,
    name: font.value,
    value: font.value,
    url: font.data?.url ?? "",
  };
};

// CONTACT INFO
const updateContactInfoSelected = (value: DataItem<ContactInfoTypes>) => {
  const contactInfo = brandEdition.value.contact_info;

  const contactInfoMatched = contactInfo.find((item) => item.key === value.key);

  if (!contactInfoMatched) {
    brandEdition.value.button_contact_info = {
      key: value.key,
      value: "",
    };
  }

  if (contactInfoMatched) {
    brandEdition.value.button_contact_info = contactInfoMatched;
  }
};

const updateButtonContactInfo = (item: DataItem<ContactInfoTypes>) => {
  brandEdition.value.button_contact_info = item;
};

// WEB STORE URL
const updateWebStoreUrl = (value: string) => {
  brandEdition.value.web_store_url = value;
};

// FOOTER LINKS
const updateFooterLinks = (links: DataItem[]) => {
  brandEdition.value.footer_links = links;
  v$.value.footer_links.$reset();
};

const updateUseFooterLinks = (value: boolean) => {
  brandEdition.value.use_footer_links = value;

  if (value && brandEdition.value.footer_links.length === 0) {
    brandEdition.value.footer_links = [
      {
        key: t("config.footerLinksOptions.who"),
        value: "",
      },
      {
        key: t("config.footerLinksOptions.how"),
        value: "",
      },
      {
        key: t("config.footerLinksOptions.ask"),
        value: "",
      },
    ];
    v$.value.footer_links.$reset();
  }
};

// SOCIAL MEDIA LINKS
const updateSocialMediaLinks = (links: DataItem[]) => {
  brandEdition.value.social_media_selected = links;
  v$.value.social_media_selected.$reset();
};

const updateUseSocialMediaLinks = (value: boolean) => {
  brandEdition.value.use_social_media_links = value;

  if (value && brandEdition.value.social_media_selected.length === 0) {
    brandEdition.value.social_media_selected = [
      {
        key: "facebook",
        value: "",
      },
      {
        key: "instagram",
        value: "",
      },
      {
        key: "whatsapp",
        value: "",
      },
    ];
    v$.value.social_media_selected.$reset();
  }
};

const socialMediaLinkFiltered = computed(() => {
  return brandEdition.value.social_media_options.filter((option) => {
    return !brandEdition.value.social_media_selected.some((selected) => {
      return option.key === selected.key;
    });
  });
});

// DATA ITEM TRANSFORMS
const transformContactInfo = <KeyType = string, ValueType = string>(
  dataItems: DataItem<KeyType, ValueType>[],
): { type: KeyType; value: ValueType }[] => {
  return dataItems.map((dataItem) => ({
    type: dataItem.key,
    value: dataItem.value,
  }));
};

const transformContactInfoSelected = <KeyType = string, ValueType = string>(
  dataItems: DataItem<KeyType, ValueType>,
): { type: KeyType; value: ValueType } => {
  return {
    type: dataItems.key,
    value: dataItems.value,
  };
};

const transformFooterLinks = <KeyType = string, ValueType = string>(
  dataItems: DataItem<KeyType, ValueType>[],
): { name: KeyType; value: ValueType }[] => {
  return dataItems.map(({ key, value }) => ({
    name: key,
    value: value,
  }));
};

const transformSocialMediaLinks = <KeyType = string, ValueType = string>(
  dataItems: DataItem<KeyType, ValueType>[],
): { platform: KeyType; url: ValueType }[] => {
  return dataItems.map(({ key, value }) => ({
    platform: key,
    url: value,
  }));
};

const emitBack = () => {
  emit("back");
};

onMounted(async () => {
  await fetchBrandCurrent();
  await fetchAvailableFonts();
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Elementos de tu marca",
    "description": "Configura los elementos de tu marca como colores, logotipo o tipo de letra y aplicalos en los e-mails de tus automations.",
    "import": {
      "title": "Importa tu marca desde Tiendanube",
      "button": "Importar",
    },
    "config": {
      "linkTitle": "Links",
      "contactOptions": {
        "title": "Botón de contacto",
        "options": {
          "whatsapp": "Whatsapp",
          "email": "Email",
          "phone": "Teléfono",
          "web": "Web",
        },
      },
      "footerLinksOptions": {
        "who": "¿Quiénes somos?",
        "how": "¿Cómo comprar?",
        "ask": "Preguntas frecuentes",
      },
      "socialMediaLinks": {
        "options": {
          "facebook": "Facebook",
          "instagram": "Instagram",
          "linkedin": "Linkedin",
          "pinterest": "Pinterest",
          "tiktok": "Tiktok",
          "vimeo": "Vimeo",
          "whatsapp": "Whatsapp",
          "x": "X (ex Twitter)",
          "youtube": "Youtube",
        },
      },
    },
    "buttons": {
      "save": "Guardar marca",
      "back": "Volver",
    },
    "validation": {
      "logo": "El logotipo es obligatorio",
      "colors": "Selecciona un color",
      "contact_info": "Selecciona una forma de contacto",
      "footer_links": "Selecciona al menos un enlace",
    },
    "notifications": {
      "success": {
        "title": "Guardado con éxito",
        "text": "La marca se guardó correctamente.",
      },
      "error": {
        "title": "Algo salió mal",
        "text": "Completá los campos correctamente e intenta de nuevo.",
      },
    },
  },
  "pt": {
    "title": "Elementos de sua marca",
    "description": "Configure os elementos da sua marca, como cores, logotipo ou fonte, e aplique-os nos e-mails das suas automações.",
    "import": {
      "title": "Importe sua marca da Tiendanube",
      "button": "Importar",
    },
    "config": {
      "linkTitle": "Links",
      "contactOptions": {
        "title": "Botão de contato",
        "options": {
          "whatsapp": "Whatsapp",
          "email": "Email",
          "phone": "Teléfono",
          "web": "Web",
        },
      },
      "footerLinksOptions": {
        "who": "Quem somos?",
        "how": "Como comprar?",
        "ask": "Perguntas frequentes",
      },
      "socialMediaLinks": {
        "options": {
          "facebook": "Facebook",
          "instagram": "Instagram",
          "linkedin": "Linkedin",
          "pinterest": "Pinterest",
          "tiktok": "Tiktok",
          "vimeo": "Vimeo",
          "whatsapp": "Whatsapp",
          "x": "X (ex Twitter)",
          "youtube": "Youtube",
        },
      },
    },
    "buttons": {
      "save": "Salvar",
      "back": "Voltar",
    },
    "validation": {
      "logo": "O logotipo é obrigatório",
      "colors": "Selecione uma cor",
      "contact_info": "Selecione um modo de contato",
      "footer_links": "Selecione pelo menos um link",
    },
    "notifications": {
      "success": {
        "title": "Salvo com sucesso",
        "text": "Sua marca foi guardada com sucesso.",
      },
      "error": {
        "title": "Alguma coisa deu errado",
        "text": "Complete os campos corretamente e tente novamente.",
      },
    },
  },
}
</i18n>
