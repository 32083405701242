<template>
  <div class="flex flex-col space-y-4 text-start">
    <div class="flex flex-col space-y-4 bg-gray-50 px-8 py-6">
      <h4 class="!m-0 text-base text-gray-900">{{ t("transferTitle") }}</h4>
      <div class="flex items-center space-x-4">
        <div class="rounded bg-gray-100 p-4">
          <CashIcon class="h-8 w-8" />
        </div>
        <div class="flex flex-col space-y-3">
          <p v-if="!loading && transferData" class="!m-0 items-center space-x-1 text-sm text-gray-500">
            <span class="font-semibold text-gray-900"> {{ t("recipient") }}: </span>
            <span> LINKEDSTORE ARGENTINA S.R.L. (CUIT: 30-71201935-9) </span>
          </p>
          <div v-else class="h-3 w-40 animate-pulse rounded-full bg-gray-100" />
          <div class="flex space-x-4">
            <div>
              <p v-if="!loading && transferData" class="!m-0 flex items-center space-x-1 text-sm text-gray-500">
                <span class="font-semibold text-gray-900"> {{ t("cvu") }}: </span>
                <span>
                  {{ transferData.cvu }}
                </span>
                <IconButton
                  :label="cvuLabel"
                  size="min"
                  @click="copyCVU"
                  @mouseenter="resetCVULabel"
                  class="!bg-transparent"
                >
                  <DocumentDuplicateIcon class="h-5 w-5 text-gray-500" />
                </IconButton>
              </p>
              <div v-else class="h-3 w-40 animate-pulse rounded-full bg-gray-100" />
            </div>
            <div>
              <p
                v-if="!loading && transferData && transferData.alias !== ''"
                class="!m-0 flex items-center space-x-1 text-sm text-gray-500"
              >
                <span class="font-semibold text-gray-900"> {{ t("alias") }}: </span>
                <span>
                  {{ transferData.alias }}
                </span>
                <IconButton
                  :label="aliasLabel"
                  size="min"
                  @click="copyAlias"
                  @mouseenter="resetAliasLabel"
                  class="!bg-transparent"
                >
                  <DocumentDuplicateIcon class="h-5 w-5 text-gray-500" />
                </IconButton>
              </p>
              <div v-else-if="loading" class="h-3 w-40 animate-pulse rounded-full bg-gray-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <AlertBox v-if="!loading">
      <div class="flex justify-between space-x-4">
        <div class="font-medium">
          <i18n-t keypath="hint">
            <template #email>
              <a href="mailto:administracion@perfit.com.ar" class="text-sky-700 underline">
                administracion@perfit.com.ar
              </a>
            </template>
          </i18n-t>
        </div>
        <button class="flex flex-shrink-0 items-center space-x-2 font-semibold hover:text-sky-700" @click="openChat">
          <span>
            {{ t("hintAction") }}
          </span>
          <ArrowNarrowRightIcon class="h-4 w-4" />
        </button>
      </div>
    </AlertBox>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from "vue";

import IconButton from "@atoms/IconButton.vue";
import AlertBox from "@atoms/AlertBox.vue";

import { CashIcon, ArrowNarrowRightIcon } from "@heroicons/vue/solid";
import { DocumentDuplicateIcon } from "@heroicons/vue/outline";

import Intercom from "@helpers/intercom";
import { useI18n } from "vue-i18n";

const props = withDefaults(
  defineProps<{
    loading: boolean;
    transferData?: {
      cvu: string;
      alias: string;
    };
  }>(),
  {},
);

const { t } = useI18n();

const copyToClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text);
};

const cvuCopied = ref(false);
const cvuLabel = computed(() => (cvuCopied.value ? t("copied") : t("copy")));

const copyCVU = async () => {
  if (!props.transferData?.cvu) return;

  await copyToClipboard(props.transferData?.cvu);
  cvuCopied.value = true;
};

const resetCVULabel = async () => {
  cvuCopied.value = false;
};

const aliasCopied = ref(false);
const aliasLabel = computed(() => (aliasCopied.value ? t("copied") : t("copy")));

const copyAlias = async () => {
  if (!props.transferData?.alias) return;

  await copyToClipboard(props.transferData?.alias);
  aliasCopied.value = true;
};

const resetAliasLabel = async () => {
  aliasCopied.value = false;
};

const openChat = () => {
  Intercom.showNewMessage(t("contactMessage"));
};
</script>
<i18n lang="json">
{
  "es": {
    "transferTitle": "Datos de transferencia",
    "hint": "Si efectúas retenciones, envíanos las constancias por chat o email a {email} para evitar interrupciones en el servicio.",
    "hintAction": "Abrir chat",
    "cvu": "CVU",
    "alias": "Alias",
    "recipient": "Destinatario",
    "copy": "Copiar",
    "copied": "Copiado!",
    "generate": "Generar CVU",
    "noTransferData": "Para realizar el pago debes generar el código de transferencia para tu cuenta",
    "contactMessage": "¡Hola! Envío el comprobante de pago por transferencia con CVU."
  },
  "pt": {
    "transferTitle": "Datos de transferencia",
    "hint": "Si efectúas retenciones, envíanos las constancias por chat o email a {email} para evitar interrupciones en el servicio.",
    "hintAction": "Abrir chat",
    "cvu": "CVU",
    "alias": "Alias",
    "recipient": "Destinatario",
    "copy": "Copiar",
    "copied": "Copiado!",
    "generate": "Generar CVU",
    "noTransferData": "Para realizar el pago debes generar el código de transferencia para tu cuenta",
    "contactMessage": "¡Hola! Envío el comprobante de pago por transferencia con CVU."
  }
}
</i18n>
