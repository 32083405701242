// Utils
import { isString } from "lodash";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore, getLegacyParseSession } from "@store";

// Domain
import type { Session } from "@domain/session";
import type { LocalStorageObject, LocalStorageApplication } from "./localStorage.types";

export const useLocalStorageApp = (): LocalStorageApplication => {
  const statesIdsMap = {
    private_gallery_tab: "perfit_states",
    campaigns_tab: "perfit_states",
    private_gallery_searches: "perfit_saved_searches",
    audience_lists_searches: "perfit_saved_searches",
    audience_contacts_searches: "perfit_saved_searches",
    audience_interests_searches: "perfit_saved_searches",
    audience_fields_searches: "perfit_saved_searches",
    optins_searches: "perfit_saved_searches",
    campaigns_searches: "perfit_saved_searches",
    automations_searches: "perfit_saved_searches",
    audience_lists_sort_by: "perfit_sort_by",
    audience_contacts_sort_by: "perfit_sort_by",
    optins_sort_by: "perfit_sort_by",
    campaigns_sort_by: "perfit_sort_by",
    automations_sort_by: "perfit_sort_by",
    accounts_selected: "perfit_accounts_selected",
    userConfig: "perfitUserConfig",
    isEcommerce: "isEcommerce",
    dashboardData: "dashboardData",
    APP_BANNER_TRIAL_OFFER: "bannerEvents",
    APP_BANNER_TRIAL_REQUEST: "bannerEvents",
    APP_BANNER_FREE_EXCEEDED: "bannerEvents",
    APP_BANNER_TRIAL_ACTIVE: "bannerEvents",
    APP_BANNER_TRIAL_EXPIRED: "bannerEvents",
  };

  const allowedMasterStates = [
    "private_gallery_tab",
    "campaigns_tab",
    "private_gallery_searches",
    "audience_lists_searches",
    "audience_contacts_searches",
    "audience_interests_searches",
    "audience_fields_searches",
    "optins_searches",
    "campaigns_searches",
    "automations_searches",
    "audience_lists_sort_by",
    "audience_contacts_sort_by",
    "optins_sort_by",
    "campaigns_sort_by",
    "automations_sort_by",
  ];

  const onlyMasterStates = ["perfit_accounts_selected"];

  const getIdLocalStorageById = (id: string) => {
    const isMasterUser = session?.isMasterUser;
    if (isMasterUser && (allowedMasterStates.includes(id) || onlyMasterStates.includes(id))) {
      return `master_${id}`;
    }

    return statesIdsMap?.[id] ?? "perfitUnknown";
  };

  let session: Session | undefined;

  try {
    const sessionStore = useSessionStore();
    const { session: storeSession } = storeToRefs(sessionStore);
    session = storeSession.value;
  } catch {
    session = getLegacyParseSession();
  }

  const account = session?.account?.code ?? "";

  const savedSearchesService: LocalStorageApplication = {
    get: <V>({ id }) => {
      const isMasterUser = session?.isMasterUser;

      if (onlyMasterStates.includes(id) && !isMasterUser) {
        return undefined;
      }

      const lsValue = localStorage.getItem(getIdLocalStorageById(id));
      if (!lsValue) return undefined;

      if (isMasterUser) {
        const item: V = JSON.parse(lsValue);

        return item;
      } else {
        const item: LocalStorageObject<V> = JSON.parse(lsValue);

        return item?.[account]?.[id] as V;
      }
    },
    getGlobal: <V>({ id }) => {
      const lsValue = localStorage.getItem(id);
      if (!lsValue) return undefined;

      try {
        const item: LocalStorageObject<V> = JSON.parse(lsValue);

        return item as V;
      } catch (e) {
        return lsValue as V;
      }
    },
    saveGlobal: <V>({ id, value }) => {
      const newItemString = isString(value) ? value : JSON.stringify(value);

      localStorage.setItem(id, newItemString);
    },
    save: <V>({ id, value }) => {
      try {
        const isMasterUser = session?.isMasterUser;

        if (onlyMasterStates.includes(id) && !isMasterUser) {
          return undefined;
        }

        let newItemString: string = "";
        if (isMasterUser) {
          newItemString = JSON.stringify(value);
        } else {
          const lsValue = localStorage.getItem(getIdLocalStorageById(id));
          let item: LocalStorageObject<V> = lsValue ? JSON.parse(lsValue) : {};
          item[account] = item[account] || {};
          item[account][id] = value;
          newItemString = JSON.stringify(item);
        }

        localStorage.setItem(getIdLocalStorageById(id), newItemString);
      } catch (error) {
        const isMasterUser = session?.isMasterUser;
        savedSearchesService.clear({ id: getIdLocalStorageById(id) });

        let newItemString: string = "";
        if (isMasterUser) {
          newItemString = JSON.stringify(value);
        } else {
          newItemString = JSON.stringify({
            [account]: {
              [id]: value,
            },
          });
        }

        localStorage.setItem(getIdLocalStorageById(id), newItemString);
      }
    },
    clear: ({ id }) => {
      localStorage.removeItem(id);
    },
  };

  return savedSearchesService;
};
