import type { ISender } from "./Senders";
import type { Conditions } from "./conditions";
import type { Tags, Tag, NewTag } from "./tag";

export type RelationType = "automation" | "campaign_simple" | "campaign_testab" | "campaign_smart" | "transactional";
export type AuditModeType = "campaign" | "automation" | "automation_active" | "transactional" | "no_audit";

export interface AuditError {
  id: string;
  icon?: string;
  title: string;
  description: string;
  severity: "ERROR" | "WARNING";
  location?: {
    collection: string;
    id: string;
  };
  tool?: {
    type: string;
  };
}

export type AuditErrors = Array<AuditError>;

export type AuditResults = {
  mode: AuditModeType;
  status: "PASS" | "WARNING" | "FAIL";
  errors: AuditErrors;
};

export type TemplateRulesIds = "footerRule" | "insertTrackingTag";
export type TemplateRule = (template: Template) => Template;
export type TemplateRules = Array<TemplateRule>;

export enum editorType {
  "unlayer" = "unlayer",
  "html" = "html",
}

export type EditorType = "unlayer" | "html";

export type TemplateContents = {
  json?: string;
  html?: string;
  text?: string;
};

export interface TemplateOptions {
  override_to?: string;
  bcc?: string;
  ignore_unsubscribe_status?: boolean;
  sample_context?: string;
  ignore_audit_alert?: boolean;
  supports_branding?: boolean;
  subjects?: Array<string>;
  conditions?: Conditions;
  duplicated?: boolean;
}

export interface TemplateAutoSave {
  json: string;
  html: string;
  preheader: string;
  subject: string;
  text: string;
  timestamp: DateString;
  user_id: string;
}

interface TemplateHistory {
  id: string;
  timestamp: DateString;
  user_id: string;
}

export type TemplateHistories = Array<TemplateHistory>;
export type TemplateAutoSaveHistories = Array<TemplateHistory>;

export interface Template {
  id: string;
  name: string;
  tags: Tags;
  thumb: string;
  updated: DateString;
  updated_by: string;
  created: DateString;
  sender_id: string;
  subject: string;
  preheader: string;
  type: EditorType;
  relation_type: "automation" | "campaign_simple" | "campaign_testab" | "campaign_smart" | "transactional";
  relation_id: string;
  js_params: {
    integration: string; // "tiendanube" -> A definir
    requires: Array<{ type: string; key: string; options: { discount: number; type: string } | Record<string, never> }>;
  };
  contents: TemplateContents;
  autosave: TemplateAutoSave;
  history: TemplateHistories;
  autosave_history: TemplateAutoSaveHistories;
  audit_results: AuditResults;
  tracking: {
    utm: {
      enabled: boolean;
      campaign: string;
      source: "Perfit";
      medium: "email";
    };
    click: boolean;
    open: boolean;
  };
  options: TemplateOptions;
}
export type TemplateToSave = Omit<Template, "tags"> & { tags: Array<Tag | NewTag> };

export type Templates = Array<Template>;

export interface TemplateItem {
  created: DateString;
  id: string;
  name: string;
  relation_id: string;
  relation_type: "automation" | "campaign_simple" | "campaign_testab" | "campaign_smart" | "transactional";
  tags: Tags;
  thumb: UrlString;
  type: string;
  updated: DateString;
  updated_by: string;
  subject: string;
  preheader: string;
}

export type TemplateList = Array<TemplateItem>;
export interface PublicTemplatePreview {
  html: string;
  subject: string;
  preheader: string;
  preview_as: string;
}

export interface HistoryTemplateRestore {
  json?: string;
  html?: string;
  text?: string;
  preheader: string;
  subject: string;
  type: EditorType;
}

export interface TemplatePreview {
  html: string;
  subject: string;
  preheader: string;
  sender: ISender;
}

export const isTemplatePreview = (tpl: TemplatePreview | PublicTemplatePreview): tpl is TemplatePreview => {
  return (tpl as TemplatePreview)?.sender !== undefined;
};

export interface HistoryTemplate {
  account: string;
  contents: TemplateContents;
  created: DateString;
  id: string;
  preheader: string;
  sender_id: string;
  subject: string;
  template_id: string;
  type: EditorType;
}

export interface TemplateHistoryPreview {
  html: string;
  subject: string;
  preheader: string;
}

export interface TemplatePreviewTestAB extends TemplatePreview {
  html: string;
  subject: string;
  preheader: string;
  subjects: Array<string>;
  sender: ISender;
}

export interface TestEmails {
  last: Array<string>;
  others: Array<string>;
}

export interface ShareLink {
  hash: string;
}

// Template JSON

export type Values<V = Record<string, unknown>> = {
  displayCondition?: {
    after: string;
    before: string;
    description: string;
    label: string;
  } & Record<string, unknown>;
} & V;

export interface Row {
  id: string;
  cells: Array<number>;
  columns: Columns;
  values: Values;
}

export type Rows = Array<Row>;
export interface Column {
  id: string;
  contents: Contents;
  values: Values;
}
export type Columns = Array<Column>;

export interface Content<V = Record<string, unknown>> {
  id: string;
  type: string;
  slug: string;
  values: Values<V>;
}

export type Contents<V = Record<string, unknown>> = Array<Content<V>>;
export type ContentIdentifiers = Omit<Content, "values">;
export interface TemplateContentJSON {
  body: {
    id: string;
    rows: Rows;
  };
}
export interface TemplateBlock {
  id?: string;
  userId: string;
  displayMode: "email";
  category: string;
  tags: string[];
  data: any;
  custom: boolean;
}
