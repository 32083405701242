import { ListView } from "@/core/magnet";
import { objHas } from "@/helpers/utils";
import app from "@/app";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";

import { useOnboardingStore, useIntegrationsStore } from "@store";

export default ListView.extend({
  module: "automationsactivity",
  templates: {
    initial: require("../templates/list.hbs"),
    rows: require("../templates/rows.hbs"),
    empty: require("../templates/empty.hbs"),
  },
  searchWrapper: ["", ""],
  fetchTimeout: 30 * 1000,
  paginate: true,
  nextPaginationMode: true,
  nextPaginationParseUrl: (next) => {
    return next.replace(/\/automations\/[^/]+\//, "automations/");
  },
  events: function () {
    return $.extend({}, ListView.prototype.events, {
      "click [data-action=enable]": "enable",
      "click [data-action=disable]": "disable",
      "click [data-action=cancel]": "cancel",
      "click [data-action=cantActivateLifeCycle]": "cantActivateLifeCycle",
      "change select[data-action=step-filter]": "stepFilter",
      "click [data-action=duplicate]": "duplicate",
      "blur input[data-action=edit-inline]": "inlineUpdateSave",
      "keyup input[data-action=edit-inline]": "inlineUpdateKeyPress",
      "click span[data-action=edit-inline]": "inlineUpdateStart",
      "click [data-action=back]": "back",
      "click [data-action=show-execution-info]": "showExecutionInfo",

      "click [data-action=delete]": "openDeleteModal",
      "submit [data-form=delete-automation]": "delete",

      "click [data-action=duplicate-eddie]": "duplicateEddie",
    });
  },

  beforeRender: function (callback) {
    this.enableRestricted = false;
    if (objHas(this, "additionalsData", "plan", "state")) {
      if (this.additionalsData.plan.state.includes("BLOCKED")) {
        this.enableRestricted = true;
      }
    }
    if (
      !app.getTrial().inTrial &&
      this.additionalsData.plan.type !== "MONTHLY" &&
      !this.additionalsData.plan.type.includes("CHILD") &&
      this.additionalsData.automation.options &&
      this.additionalsData.automation.options.restricted
    ) {
      this.enableRestricted = true;
    }

    this.additionalsData.enableRestricted = this.enableRestricted;

    var emailSteps = [];
    var steps = this.additionalsData.automation.steps;
    for (var i = 0; i < steps.length; i++) {
      if (steps[i].type === "email" || steps[i].type === "msg_email") {
        emailSteps.push(steps[i].id);

        if (steps[i].type === "msg_email") {
          this.additionalsData.hasTemplatesApiV3 = true;
        }
      }
    }
    if (emailSteps.length > 1) {
      this.additionalsData.emailSteps = emailSteps;
    }
    if (emailSteps.length === 0) {
      this.additionalsData.noEmailSteps = true;
    }

    this.item = new app.models.automations(this.additionalsData.automation);

    const integrationStore = useIntegrationsStore();
    const isEcommerce = integrationStore.ecommerceIntegrations.length > 0;

    this.additionalsData.showConversionsColumn = isEcommerce;

    callback();
  },

  afterRenderPage: function () {
    if (this.query.filter && this.query.filter.email) {
      $("input.finder").val(this.query.filter.email);
    }

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },

  afterRenderItems: function () {
    this.$('[data-toggle="tooltip"]').tooltip({
      container: "body",
      placement: "bottom",
    });
  },

  enable: function (e) {
    var self = this;
    e.preventDefault();

    if (this.item.isEddieBlocked()) {
      this.openRestrictedFeatureModal({
        title: window.lang.automations.eddieBlockedTitle,
        message: window.lang.automations.eddieBlockedMessage,
      });
      return false;
    }

    if (!this.item.isAutomationValidatedCoupon()) {
      this.openRestrictedFeatureModal({
        title: window.lang.automations.invalidCouponConfigTitle,
        message: window.lang.automations.invalidCouponConfigText,
      });
      return false;
    }

    if (!this.item.isAutomationOrderCustomFieldsFilterValid()) {
      this.openRestrictedFeatureModal({
        title: window.lang.automations.invalidOrderCustomConfigFilterTitle,
        message: window.lang.automations.invalidOrderCustomConfigFilterText,
      });
      return false;
    }

    if (!this.item.isAutomationValidatedPaymentPendingFilter()) {
      this.openRestrictedFeatureModal({
        title: window.lang.automations.invalidPaymentPendingFilterTitle,
        message: window.lang.automations.invalidPaymentPendingFilterText,
      });
      return false;
    }

    if (this.enableRestricted /*&& !app.session.get('isMasterUser')*/) {
      if (this.additionalsData.plan.state.includes("BLOCKED")) {
        this.openRestrictedFeatureModal({
          title: window.lang.automations.enableAutomation,
          message: window.lang.automations.accountBlocked,
        });
      } else if (this.additionalsData.plan.type !== "MONTHLY" && !this.additionalsData.plan.type.includes("CHILD")) {
        this.openRestrictedFeatureModal({
          title: window.lang.automations.enableAutomation,
          message: window.lang.automations.monthlyPlanRequired,
        });
      }
      return false;
    }

    //model = new app.models.automations({ id: this.additionalsData.automation.id });

    // Perform API call
    return this.item.enable({
      success: function (res) {
        self.displayMessage(window.lang.automations.automationEnabled);
        self.reload();

        const onboardingStore = useOnboardingStore();

        const eventPromises = res.data?.options?.types?.map((type) => {
          return onboardingStore.submitUserEvent({
            action: `automation.${type}.enabled`,
            data: {
              id: res.data.id,
              name: res.data.name,
            },
          });
        });

        Promise.all([
          onboardingStore.submitUserEvent({
            action: "automation.enabled",
            data: {
              id: res.data.id,
              name: res.data.name,
            },
          }),
          ...eventPromises,
        ]);
      },
      error: function (err) {
        if (
          err.responseJSON &&
          err.responseJSON.error &&
          ["validation_error", "invalid_operation"].includes(err.responseJSON.error.type)
        ) {
          self.displayMessage(window.lang.automations.enableError, 8000, "error");
        } else {
          self.displayMessage(window.lang.automations.enableErrorUnknown, 8000, "error");
        }
        return false;
      },
    });
  },

  disable: function (e) {
    var self = this,
      model = self.item;
    e.preventDefault();

    var confirmText = window.lang.automations.confirmDisable,
      button = null,
      titleText = window.lang.automations.disable,
      type = "info",
      flag = null;

    this.confirmModal(
      confirmText,
      button,
      titleText,
      type,
      flag,
      function () {
        // Perform API call
        return model.pause({
          success: function () {
            self.displayMessage(window.lang.automations.automationDisabled);
            self.reload();
          },
        });
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this),
    );
  },

  cancel: function (e) {
    var self = this,
      model = self.item;
    e.preventDefault();

    var confirmText = window.lang.automations.confirmCancel,
      button = null,
      titleText = window.lang.automations.cancel,
      type = "warning",
      flag = null;

    this.confirmModal(
      confirmText,
      button,
      titleText,
      type,
      flag,
      function () {
        return model.cancel({
          success: function () {
            self.displayMessage(window.lang.automations.automationCanceled);
          },
        });
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this),
    );
  },

  openDeleteModal: function (e) {
    e.preventDefault();

    const data = {
      message:
        [window.lang.confirmDelete, window.lang["itemM"], window.lang.automations.singular].join(" ") +
        "? " +
        window.lang.confirmDeleteContent,
    };

    this.openModal(require("@/modules/automations/templates/modals/delete.hbs"), {});
  },

  delete: function (e) {
    var self = this,
      model = self.item,
      confirmText =
        [window.lang.confirmDelete, window.lang["itemM"], window.lang.automations.singular].join(" ") +
        "? " +
        window.lang.confirmDeleteContent,
      button = null,
      titleText = window.lang.automations.cancel,
      type = "warning",
      flag = null,
      deletedMessage = [window.lang["articleM"], window.lang.automations.singular, window.lang["hasBeenDeletedM"]].join(
        " ",
      );

    e.preventDefault();

    model.destroy({
      success: () => {
        self.displayMessage(deletedMessage);
        app.router.navigateTo("automations");
        self.reload();
      },
      error: (error) => {
        console.log(error);
      },
    });
  },

  cantActivateLifeCycle: function () {
    var self = this;
    return self.displayMessage(window.lang.automations.enableErrorLifeCycle, 8000, "error");
  },

  stepFilter: function (e) {
    var step = $(e.currentTarget).val();

    this.urlFilter({ key: "filter.step_id", value: step });
  },

  duplicate: async function () {
    if (this.item.isEddieTemplate()) {
      const trackingEventsService = useTrackingEvents();
      const eddieFeatures = this.item.getEddieFeatures();
      const cantDuplicate =  !eddieFeatures.allow_eddie_duplicate;

      trackingEventsService.amplitude({
        name: "APP_EDDIE_DUPLICATE_NOTIFICATION",
        data: {
          source: "automation",
          id: this.item.id,
          feature_unlayer_optional: eddieFeatures.unlayer_optional,
          feature_unlayer_default: eddieFeatures.unlayer_default,
          feature_hide_legacy_templates: eddieFeatures.hide_legacy_templates,
        },
      });

      this.openModal(require("@/modules/layout/templates/modals/eddieDeprecate.hbs"), {cantDuplicate});
    }

    if (!this.item.isEddieTemplate()) {
      this.item.duplicate().done(function (response) {
        app.router.navigateTo(`automations/${response.data.id}`);
      });
    }
  },

  duplicateEddie: function () {
    const trackingEventsService = useTrackingEvents();
    const eddieFeatures = this.item.getEddieFeatures();

    trackingEventsService.amplitude({
      name: "APP_EDDIE_DUPLICATE_ACCEPT",
      data: {
        source: "automation",
        id: this.item.id,
        feature_unlayer_optional: eddieFeatures.unlayer_optional,
        feature_unlayer_default: eddieFeatures.unlayer_default,
        feature_hide_legacy_templates: eddieFeatures.hide_legacy_templates,
      },
    });

    this.item.duplicate().done(function (response) {
      app.router.navigateTo(`automations/${response.data.id}`);
    });
  },

  // Returns to the previous page
  back: function () {
    app.router.navigateTo("automations");
  },

  showExecutionInfo: function (e) {
    e.preventDefault();
    const btn = $(e.currentTarget);
    const id = btn.data("id");

    $.get(
      `automationexecutions/${id}`,
      function (response) {
        this.openModal(require("../templates/modals/execInfo.hbs"), {});

        this.jsEditor = window.ace.edit("json");
        this.jsEditor.setValue(JSON.stringify(response.data, null, 2));
        this.jsEditor.session.setMode("ace/mode/json");
        this.jsEditor.setShowPrintMargin(false);
        this.jsEditor.setReadOnly(true);
        //disable warnings (async/await issues)
        this.jsEditor.getSession().setUseWorker(false);
      }.bind(this),
    );
  },
});
