import { useRequestsAPIV3 } from "@api/requests";

// Types
import { Brand, BrandEditorRequest, SaveParams } from "./brandEditor.types";

export function useBrand() {
  const request = useRequestsAPIV3();

  async function current(): Promise<Brand | undefined> {
    try {
      const res = await request<BrandEditorRequest>({
        url: `/branding/current`,
        method: "get",
        disableNotifyErrors: true,
      });
      return res.data.data;
    } catch (e : any) {
      if (e.response.status === 404) {
        return undefined;
      }
      throw e;
    }
  }

  async function save(saveParams: SaveParams): Promise<Brand> {
    try {
      const res = await request<BrandEditorRequest>({
        url: `/branding/current`,
        method: "put",
        headers: {
          "x-user-id": saveParams.userId,
        },
        data: saveParams.body,
        disableNotifyErrors: true,
      });
      return res.data.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  return { current, save };
}
