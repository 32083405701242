// Types
import type { Locales, UnlayerLocales } from "@domain/unlayer";

export const unlayerMessages: UnlayerTranslationRecord = {
  es: {
    utm_link_error_title: "Enlaces incluyen parámetros utm",
    utm_link_error_description:
      "La opción de monitoreo mediante Google Analytics se encuentra activa por lo que se inserten automáticamente los parámetros utm. Sin embargo, algunos enlaces ya incluyen parámetros utm. Sugerimos revisarlos para evitar insertar parámetros utm por duplicado.",
    shortener_link_error_title: "Enlaces con dominios no permitidos",
    shortener_link_error_description:
      "El dominio de este enlace no puede ser utilizado ya que puede ocasionar problemas en la entrega de los emails.",
    missing_social_link_title: "Enlaces de redes sociales vacíos",
    missing_social_link_description: "Los botones no funcionarán cuando el usuario los pulse si no tienen enlace.",
    footer_tool_audit_display_condition_title: "Condición",
    footer_tool_audit_display_condition_description:
      "El footer o la fila del footer tienen una condición de visualización",
    footer_tool_audit_contrast_title: "Contraste bajo en footer",
    footer_tool_audit_contrast_description:
      "El contraste entre el color de fondo y el color de texto o enlace de desuscripción del footer es demasiado bajo y puede no verse con suficiente claridad.",
    missing_unsubscribe_link_title: "Enlace de desuscripción",
    missing_unsubscribe_link_description:
      "Es necesario insertar un footer que incluya la opción de cancelar la suscripción.",
    invalid_url_title: "Enlace inválido",
    invalid_url_description: "El enlace no es válido",
    filebutton_empty_link_title: "Archivo adjunto incompleto",
    filebutton_empty_link_description: "No se ha subido un archivo en el botón de descarga.",
    button_empty_links_title: "Enlaces de botones vacíos",
    button_empty_links_description: "Los botones no funcionarán cuando el usuario los pulse si no tienen enlace.",
    missing_subject: "Asunto incompleto",
    missing_subject_description: "Es necesario definir el asunto para poder enviar este email.",
    missing_subjects_testab: "Asuntos incompletos",
    missing_subjects_testab_description:
      "Es necesario definir al menos 2 asuntos en las campañas test A/B para poder ser enviada.",
    missing_sender: "Remitente incompleto",
    missing_sender_description: "Es necesario seleccionar un remitente para poder enviar este email.",
    footer_display_condition: "Condición de visualización en footer",
    footer_display_condition_description: "No se permite agregar condiciones de visualización en el footer.",
    limit_size_html_gmail: "Es recomendable reducir el tamaño",
    limit_size_html_gmail_description:
      "Es posible que el contenido se vea incompleto en algunas casillas de correo. Gmail suele recortar los contenidos grandes. Es recomendable reducir la cantidad de bloques, elementos, texto o contenido para evitarlo.",
    "option_groups.action.title": "Enlace",
    "editor.action_type.label": "Tipo de enlace",
    "editor.line_height.label": "Interlineado",
    "editor.container_padding.label": "Margen interno",
    "editor.padding.label": "Margen interno",
    "option_groups.responsive_design.title": "Comportamiento responsive",
    "labels.merge_tags": "Códigos de reemplazo",
    "editor.link.body": "Contenido",
    "editor.link.target": "Abrir en",
    "content_tools.social": "Redes",
    "content_tools.heading": "Título",
    "editor.image.auto_width": "Ancho automático",
    "editor.image.auto_width_switch_off":
      "Desactiva el ancho automático para ajustar manualmente el tamaño de la imagen",
    "editor.image.full_width_mobile": "Ancho total en dispositivos móviles",
    "option_groups.all.title": "Ver más",
    "buttons.add_display_condition": "Añadir condición de visualización",
    "modals.display_conditions.title": "Seleccionar condición de visualización",
    "option_groups.display_condition.title": "Condición de visualización",
    "labels.display_conditions": "Condiciones de visualización",
    "tools.tooltip.drag_on_canvas": "Arrastra para insertar",
    "tabs.audit.missing_tool_name": "Revisar problema",
    "shapes.round": "Redondeado",
    "tabs.audit.rules.image.alt_text.title": "Falta texto alternativo en imágenes",
    "tabs.audit.rules.image.alt_text.description":
      "Sin texto alternativo, la personas con problemas de visión que dependan de lectores de pantalla no podrán acceder a la información de las imágenes.",
    "editor.image.image_url": "Imagen",
    "labels.auto_off": "Automático",
    "labels.auto_on": "Automático",
  },
  pt: {
    utm_link_error_title: "Os links incluem parâmetros utm",
    utm_link_error_description:
      "A opção de monitoramento através do Google Analytics está ativada e isso faz com que os parâmetros utm sejam inseridos automaticamente. No entanto, alguns links já incluem parâmetros utm. Sugerimos revisá-los para evitar a inserção de parâmetros utm em duplicidade.",
    shortener_link_error_title: "Links com domínios não permitidos",
    shortener_link_error_description:
      "O domínio deste link não pode ser usado pois pode causar problemas na entrega de e-mails.",
    missing_social_link_title: "Links de redes sociais vazios.",
    missing_social_link_description: "Os botões não funcionarão quando o usuário clicar e não tiver links.",
    footer_tool_audit_display_condition_title: "PT Condición",
    footer_tool_audit_display_condition_description:
      "PT El footer o la fila del footer tienen una condición de visualización",
    footer_tool_audit_contrast_title: "Contraste baixo no footer",
    footer_tool_audit_contrast_description:
      "O contraste entre a cor de fundo e a cor do texto ou do link para cancelar a inscrição é muito baixo e pode não ser vista com claridade suficiente.",
    missing_unsubscribe_link_title: "Link para descadastrar",
    missing_unsubscribe_link_description:
      "É necessário adicionar um footer que inclua a opção de cancelar a inscrição.",
    invalid_url_title: "Link inválido",
    invalid_url_description: "O link não é válido",
    filebutton_empty_link_title: "Arquivo anexo faltando",
    filebutton_empty_link_description: "Nenhum arquivo foi carregado no botão de download.",
    button_empty_links_title: "Os links dos botões estão vazios",
    button_empty_links_description: "Sem os links, os botões não funcionarão quando o usuário clicar sobre eles.",
    missing_subject: "Assunto incompleto",
    missing_subject_description: "É necessário definir os assuntos.",
    missing_subjects_testab: "Assuntos incompletos",
    missing_subjects_testab_description: "É necessário definir ao menos 2 assuntos nas campanhas de test A/B.",
    missing_sender: "Remetente incompleto",
    missing_sender_description: "É necessário selecionar um remetente.",
    footer_display_condition: "Condição no footer",
    footer_display_condition_description: "Não é permitido adicionar condições de exibição no footer",
    limit_size_html_gmail: "É recomendável reduzir o tamanho do template",
    limit_size_html_gmail_description:
      "É possível que o conteúdo não seja corretamente visualizado em algumas caixas de email. Gmail costuma recortar conteúdos muito grandes. É recomendável reduzir a quantidade de blocos, elementos, texto ou conteúdo para evitar que isso aconteça.",
    "option_groups.action.title": "Links",
    "editor.action_type.label": "Tipo de link",
    "editor.line_height.label": "Espaçamento entre linhas",
    "editor.container_padding.label": "Margem interna",
    "editor.padding.label": "Margem interna",
    "option_groups.responsive_design.title": "Comportamento responsive",
    "labels.merge_tags": "Códigos de substituição",
    "editor.link.body": "Conteúdo",
    "editor.link.target": "Abrir em",
    "content_tools.social": "Redes",
    "content_tools.heading": "Título",
    "tools.tooltip.drag_on_canvas": "Arraste para inserir",
    "tabs.audit.missing_tool_name": "Revisar problema",
    "shapes.round": "Arredondado",
    "tabs.audit.rules.image.alt_text.title": "Falta texto alternativo em imagens",
    "tabs.audit.rules.image.alt_text.description":
      "Sem texto alternativo, pessoas com problemas de visão que dependem de leitores de tela não conseguirão acessar as informações das imagens.",
    "editor.image.image_url": "Imagem",
    "labels.auto_off": "Automático",
    "labels.auto_on": "Automático",
  },
};

export const transformLocaleToUnlayerLocale = (locale: Locales): UnlayerLocales => {
  const mappedLocales: Record<Locales, UnlayerLocales> = {
    es: "es-ES",
    pt: "pt-BR",
    en: "en-US",
    "es-AR": "es-ES",
    "es-BO": "es-ES",
    "es-BR": "es-ES",
    "es-CA": "es-ES",
    "es-CL": "es-ES",
    "es-CO": "es-ES",
    "es-CR": "es-ES",
    "es-ES": "es-ES",
    "es-EC": "es-ES",
    "es-GT": "es-ES",
    "es-HN": "es-ES",
    "es-MX": "es-ES",
    "es-PA": "es-ES",
    "es-PE": "es-ES",
    "es-PY": "es-ES",
    "es-UY": "es-ES",
    "es-PT": "es-ES",
    "es-SV": "es-ES",
    "es-US": "es-ES",
    "es-VE": "es-ES",
    "pt-AR": "pt-BR",
    "pt-BR": "pt-BR",
    "pt-PT": "pt-PT",
    "en-AR": "en-US",
    "en-US": "en-US",
    "en-BR": "en-US",
    "en-MX": "en-US",
  };
  return mappedLocales[locale];
};
