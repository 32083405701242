<template>
  <div v-if="sender.auth.status === 'PUBLIC_DOMAIN'">
    <AlertBox class="mb-4" theme="warning">
      <i18n-t keypath="instructions.PUBLIC_DOMAIN">
        <template #domain>
          <strong>{{ sender.domain }}</strong>
        </template>
        <template #readmore>
          <a href="#" @click.prevent="openArticle('8719023')">
            {{ t("readmore") }}
          </a>
        </template>
      </i18n-t>
    </AlertBox>

    <TextParagraph>
      <i18n-t keypath="publicDomain.cantUse">
        <strong>{{ sender.domain }}</strong>
      </i18n-t>
    </TextParagraph>

    <!-- <TextParagraph>
      <i18n-t keypath="publicDomain.fallbackEmail">
        <strong>{{ sender.effective_email }}</strong>
      </i18n-t>
    </TextParagraph>

    <TextParagraph>
      <i18n-t keypath="publicDomain.replyToAddress">
        <strong>{{ sender.reply_to }}</strong>
      </i18n-t>
    </TextParagraph> -->

    <TextParagraph>
      <i18n-t keypath="contactUs">
        <template #helpcenter>
          <a href="https://docs.myperfit.com/articles/3275033" target="_blank">
            {{ t("revalidate.helpCenter") }}
          </a>
        </template>
        <template #supportteam>
          <a href="#" @click.prevent="contactSupport('changeNeeded')">
            {{ t("revalidate.supportTeam") }}
          </a>
        </template>
      </i18n-t>
    </TextParagraph>
  </div>
  <div v-else-if="sender.auth.status !== 'UNKNOWN'">
    <AlertBox
      class="mb-4"
      theme="warning"
      v-if="sender.auth.status === 'CHANGE_SUGGESTED' || sender.auth.status === 'CHANGE_REQUIRED'"
    >
      <i18n-t :keypath="`instructions.${sender.auth.status}`">
        <template #domain>
          <strong>{{ sender.domain }}</strong>
        </template>
        <template #readmore>
          <a href="#" @click.prevent="openArticle('8719023')">
            {{ t("readmore") }}
          </a>
        </template>
      </i18n-t>
    </AlertBox>

    <TextParagraph
      v-if="
        sender.auth.status !== 'OK' &&
        sender.auth.status !== 'CHANGE_SUGGESTED' &&
        sender.auth.status !== 'CHANGE_REQUIRED'
      "
    >
      <i18n-t :keypath="`instructions.${sender.auth.status}`">
        <template #domain>
          <strong>{{ sender.domain }}</strong>
        </template>
      </i18n-t>
    </TextParagraph>

    <TextParagraph v-if="sender.auth.status !== 'OK'">
      <i18n-t keypath="contactUs">
        <template #helpcenter>
          <a href="https://docs.myperfit.com/articles/1146685" target="_blank">
            {{ t("revalidate.helpCenter") }}
          </a>
        </template>
        <template #supportteam>
          <a href="#" @click.prevent="contactSupport('changeNeeded')">
            {{ t("revalidate.supportTeam") }}
          </a>
        </template>
      </i18n-t>
    </TextParagraph>

    <TextParagraph v-if="sender.auth.status !== 'OK'" class="mt-4">{{
      t("revalidate.sendInstructionsText")
    }}</TextParagraph>

    <div v-if="sender.auth.status === 'OK'" class="mb-4">
      <AlertBox theme="success">
        <i18n-t keypath="instructions.OK">
          <template #domain>
            <strong>{{ sender.domain }}</strong>
          </template>
        </i18n-t>
      </AlertBox>
    </div>

    <SimpleTable>
      <template #headers>
        <SimpleTableHeader class="w-[20%]">{{ t("record.type") }}</SimpleTableHeader>
        <SimpleTableHeader class="w-[35%]">{{ t("record.name") }} </SimpleTableHeader>
        <SimpleTableHeader class="w-[35%]">{{ t("record.value") }} </SimpleTableHeader>
        <SimpleTableHeader right-aligned class="w-[10%]">
          {{ t("record.status") }}
        </SimpleTableHeader>
      </template>

      <template #rows>
        <tr v-for="record in records" :key="record.id" class="relative">
          <SimpleTableCell class="px-6 py-3 uppercase">{{ record.type }}</SimpleTableCell>
          <SimpleTableCell class="px-6 py-3">
            <div class="item-center flex justify-between space-x-3">
              <div class="my-1.5">
                {{ record.name }}
              </div>
              <ClipboardButton v-if="record.result !== 'PASS'" :text="record.name"></ClipboardButton>
            </div>
          </SimpleTableCell>
          <SimpleTableCell class="px-6 py-3">
            <div
              :ref="(element) => createTooltip(element as Element, record.value)"
              class="item-center flex justify-between space-x-3"
            >
              <span class="my-1.5 w-80 max-w-full truncate">
                {{ record.value }}
              </span>
              <ClipboardButton v-if="record.result !== 'PASS'" :text="record.value"></ClipboardButton>
            </div>
          </SimpleTableCell>
          <SimpleTableCell right-aligned class="px-6 py-3">
            <SimpleBadge :theme="record.result === 'PASS' ? 'green' : 'yellow'">
              {{ t(`status.${record.result}`) }}
            </SimpleBadge>
          </SimpleTableCell>
        </tr>
      </template>
    </SimpleTable>

    <div v-if="sender.auth.status !== 'OK'" class="mt-4">
      <AlertBox v-if="revalidationFailed" class="mt-4">
        {{ t("revalidate.bePatient") }}
      </AlertBox>
    </div>
  </div>
  <div v-else>
    <AlertBox theme="danger">
      <i18n-t keypath="instructions.UNKNOWN">
        <template #domain>
          <strong>{{ sender.domain }}</strong>
        </template>
      </i18n-t>
      <template #actions>
        <i18n-t keypath="unkwnownError">
          <template #retry>
            <a href="#" @click.prevent="revalidate">
              {{ t("retry") }}
            </a>
          </template>
          <template #supportteam>
            <a href="#" @click.prevent="contactSupport('unknownError')">
              {{ t("revalidate.supportTeam") }}
            </a>
          </template>
        </i18n-t>
      </template>
    </AlertBox>
  </div>

  <div class="mt-6 flex justify-between space-x-4">
    <SimpleButton theme="white" @click="$emit('back')">
      <template #leading><ChevronLeftIcon /></template>
      {{ t("back") }}
    </SimpleButton>
    <div class="space-x-4">
      <SimpleButton
        v-if="sender.auth.status.includes('CHANGE')"
        theme="white"
        @click="openSendInstructionsModal = true"
      >
        <template #leading><MailIcon /></template>
        {{ t("sendInstructions") }}
      </SimpleButton>
      <SimpleButton
        v-if="sender.auth.status.includes('CHANGE') || sender.auth.status === 'OK'"
        theme="secondary"
        :loading="isLoading"
        @click="revalidate"
      >
        <template #leading><RefreshIcon /></template>
        {{ t("refreshRecords") }}
      </SimpleButton>
    </div>
  </div>
  <SenderEmailInstructionsModal
    v-if="sender.auth.status.includes('CHANGE')"
    :sender="sender"
    :is-open="openSendInstructionsModal"
    @close="openSendInstructionsModal = false"
    @instructions-sent="openSendInstructionsModal = false"
  />
</template>

<script lang="ts">
import { reactive, watchEffect, ref, defineComponent } from "vue";
import type { PropType } from "vue";

import { useI18n } from "vue-i18n";
import { v4 as uuid } from "uuid";
import { RefreshIcon, MailIcon, ChevronLeftIcon } from "@heroicons/vue/solid";

import { useNotifications } from "@composables/notifications";
import { useSenders } from "@api/modules/senders/senders";
import { Sender } from "@/vue/types/Senders";
import Intercom from "@helpers/intercom";
import { useTooltip } from "@composables/tooltips";
import { useAlertStore } from "@store";

import SimpleButton from "@atoms/SimpleButton.vue";
import SimpleBadge from "@atoms/SimpleBadge";
import SimpleTableCell from "@atoms/SimpleTableCell.vue";
import SimpleTableHeader from "@atoms/SimpleTableHeader.vue";
import AlertBox from "@atoms/AlertBox.vue";
import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleTable from "@molecules/SimpleTable.vue";
import ClipboardButton from "@molecules/ClipboardButton.vue";
import SenderEmailInstructionsModal from "@organisms/Senders/SenderEmailInstructionsModal.vue";

type DNSRecord = {
  id: string;
  type: string;
  name: string;
  value: string;
  result: string;
};

export default defineComponent({
  name: "SenderAuthDetails",
  components: {
    SenderEmailInstructionsModal,
    TextParagraph,
    SimpleButton,
    ClipboardButton,
    SimpleBadge,
    SimpleTable,
    SimpleTableCell,
    SimpleTableHeader,
    AlertBox,
    ChevronLeftIcon,
    RefreshIcon,
    MailIcon,
  },
  props: {
    sender: {
      type: Object as PropType<Sender>,
      required: true,
    },
  },

  emits: ["back", "revalidated"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const { notify } = useNotifications();
    const records: Array<DNSRecord> = reactive([]);
    const openSendInstructionsModal = ref(false);

    watchEffect(() => {
      while (records.pop());

      if (props.sender.auth.dkim) {
        records.push({
          ...props.sender.auth.dkim?.record,
          result: props.sender.auth.dkim.result,
          id: uuid(),
        });
      }
      if (props.sender.auth.return_path) {
        records.push({
          ...props.sender.auth.return_path?.record,
          result: props.sender.auth.return_path.result,
          id: uuid(),
        });
      }
      if (props.sender.auth.dmarc) {
        records.push({
          ...props.sender.auth.dmarc?.record,
          result: props.sender.auth.dmarc.result === "UNSET" ? "FAIL" : "PASS",
          id: uuid(),
        });
      }
    });

    const createTooltip = (ref: Element | null, text: string) => {
      if (!ref) return;

      useTooltip(ref, text, {
        placement: "bottom",
        popperOptions: {
          modifiers: [
            {
              name: "preventOverflow",
              options: {
                altAxis: true,
                tether: false,
                boundary: "clippingParents",
              },
            },
            {
              name: "flip",
              options: {
                allowedAutoPlacements: ["bottom", "top"],
                fallbackPlacements: ["bottom", "top"],
                altBoundary: true,
              },
            },
          ],
        },
      });
    };

    const contactSupport = (messageKey: string) => {
      Intercom.showNewMessage(t(`helpMessage.${messageKey}`, { domain: props.sender.domain }));
    };

    const openArticle = (article: string) => {
      window.Intercom("showArticle", article);
    };

    const sendersAPI = useSenders();
    const alertStore = useAlertStore();

    const isLoading = ref(false);
    const revalidationFailed = ref(false);
    const revalidate = async () => {
      try {
        isLoading.value = true;

        const sender = await sendersAPI.revalidate({ sender_id: props.sender.id });
        await alertStore.update();
        isLoading.value = false;

        emit("revalidated", sender);
        if (sender.auth.status === "OK") {
          notify({
            title: t("notify.revalidated.title"),
            text: t("notify.revalidated.text", { domain: sender.domain }),
          });
        } else {
          revalidationFailed.value = true;
        }
      } catch (err) {
        console.error(err);
      }
    };

    return {
      t,
      createTooltip,
      contactSupport,
      records,
      revalidate,
      isLoading,
      openSendInstructionsModal,
      revalidationFailed,
      openArticle,
    };
  },
});
</script>

<i18n lang="json">
{
  "es": {
    "refreshRecords": "Revalidar autenticación",
    "sendInstructions": "Enviar instrucciones",
    "readmore": "Leer más",
    "record": {
      "type": "Tipo",
      "name": "Nombre",
      "value": "Valor",
      "status": "Estado"
    },
    "status": {
      "PASS": "Configurado",
      "FAIL": "Pendiente",
      "NONE": "Pendiente"
    },
    "helpMessage": {
      "domainRegistrar": "Hola! Quisiera conocer más detalles y costo de registrar un dominio para mis envíos.",
      "unknownError": "Hola! Estoy teniendo problemas para verificar el dominio {domain}, podrían ayudarme?",
      "changeNeeded": "Hola! Tengo dudas sobre cómo configurar correctamente un dominio para usar en mis envíos. ¿Podrían ayudarme?"
    },
    "publicDomain": {
      "cantUse": "Los dominios públicos como {0} no pueden ser usados como remitentes de tus envíos, ya que no es posible autenticarlos correctamente.",
      "fallbackEmail": "Como dirección de remitente se utilizará una dirección de email de un dominio compartido genérico, de la forma de {0}.",
      "replyToAddress": "La dirección de email {0} será utilizada como dirección de respuesta en los correos que envíes, para que puedas recibir todas las respuestas de tus contactos allí.",
      "registrar": "Si no cuentas con un dominio propio, podemos registrar y configurar un nuevo dominio para usar en tus envíos. Para conocer más detalles y costos no dudes en contactarnos.",
      "registrarContactUs": "Consultar por registro de dominio"
    },
    "instructions": {
      "OK": "¡Excelente! El dominio {domain} está autenticado correctamente para utilizar como remitente.",
      "CHANGE_REQUIRED": "Es necesario autenticar el dominio {domain} para poder utilizarlo como remitente. Para hacerlo, necesitarás acceso al panel de administración de tu proveedor de dominos (como GoDaddy o Cloudflare) y crear los registros indicados. Completa la configuración indicada antes de febrero 2024 para estar preparado para las nuevas políticas de envío que implementarán Gmail y Yahoo. {readmore}",
      "CHANGE_SUGGESTED": "Es necesario completar la autenticarción del dominio {domain} para utilizarlo como remitente. Para hacerlo, necesitarás acceso al panel de administración de tu proveedor de dominos (como GoDaddy o Cloudflare) y crear los registros indicados. Completa la configuración indicada antes de febrero 2024 para estar preparado para las nuevas políticas de envío que implementarán Gmail y Yahoo. {readmore}",
      "UNKNOWN": "Ocurrió un error al intentar verificar la configuración del dominio {domain}.",
      "PUBLIC_DOMAIN": "La dirección de e-mail que utilices para el registro no puede ser de dominio público (p. ej. Google). Debes usar una dirección de dominio propio para estar en conformidad con las políticas de envío de Gmail y Yahoo. {readmore}"
    },
    "unkwnownError": "{retry} o contacta al {supportteam} para verificarlo.",
    "retry": "Intenta nuevamente",
    "contactUs": "Si tienes dudas sobre cómo conseguir un dominio propio, visita nuestro {helpcenter}, o contacta al {supportteam}.",
    "revalidate": {
      "bePatient": "Es posible que los cambios demoren algunas horas en actualizar correctamente. Intenta nuevamente más tarde.",
      "helpCenter": "centro de ayuda",
      "supportTeam": "equipo de soporte",
      "sendInstructionsText": "También puedes enviar estas instrucciones a la persona que administra tu dominio para que realice el cambio."
    },
    "notify": {
      "revalidated": {
        "title": "Remitente autenticado",
        "text": "El dominio {domain} fue autenticado correctamente."
      }
    }
  },
  "pt": {
    "refreshRecords": "Revalidar autenticação",
    "sendInstructions": "Enviar instruções",
    "readmore": "Ver mais",
    "record": {
      "type": "Tipo",
      "name": "Nome",
      "value": "Valor",
      "status": "Estado"
    },
    "status": {
      "PASS": "Configurado",
      "FAIL": "Pendente",
      "NONE": "Pendente"
    },
    "helpMessage": {
      "domainRegistrar": "Oi! Queria conhecer mais detalhes e valores para registrar um domínio para meus envíos.",
      "unknownError": "Oi! Estou com problemas para verificar o domínio {domain}, vocês podem me ajudar?",
      "changeNeeded": "Oi! Tenho dúvida sobre como configurar corretamente um domínio para usar em meus envíos. Vocês podem me ajudar?"
    },
    "publicDomain": {
      "cantUse": "Os domínios públicos como {0} não podem ser usados como remetentes de seus envios, pois não é possível autenticá-los corretamente.",
      "fallbackEmail": "Como endereço de remetente, será utilizada uma direção de e-mail de um domínio compartilhado genérico, como {0}.",
      "replyToAddress": "O endereço de e-mail {0} será utilizado como endereço de resposta, para que você possa receber todas as respostas dos seus contatos.",
      "registrar": "Se você não tem um domínio própio, podemos registrar e configurar um novo domínio para usar nos seus envíos. Para conhecer mais detalhes e custos estamos à tua disposição.",
      "registrarContactUs": "Consultar por registro de domínio"
    },
    "instructions": {
      "OK": "Excelente! O domínio {domain} está autenticado corretamente para ser utilizado como remetente.",
      "CHANGE_REQUIRED": "É necessário autenticar o domínio {domain} para poder utilizá-lo como remetente. Para isso, você precisa ter acesso ao painel do administrador do teu provedor de domínios (como GoDaddy ou Registros.br) e criar os seguientes registros. Complete a configuração indicada antes de fevereiro de 2024 para estar preparado para as novas políticas de envio que serão implementadas pelo Gmail e Yahoo. {readmore}",
      "CHANGE_SUGGESTED": "É necessário completar a autenticação do domínio {domain} para poder utilizá-lo como remetente. Para isso, você precisa ter acesso ao painel do administrador do teu provedor de domínios (como GoDaddy ou Registros.br) e criar os seguientes registros. Complete a configuração indicada antes de fevereiro de 2024 para estar preparado para as novas políticas de envio que serão implementadas pelo Gmail e Yahoo. {readmore}",
      "UNKNOWN": "Ocurreu um error ao verificar a configuração do domínio {domain}.",
      "PUBLIC_DOMAIN": "O endereço de e-mail que você utilizar para o registro não pode ser de domínio público (por exemplo, Google). Você deve usar um endereço de domínio próprio para estar em conformidade com as políticas de envio do Gmail e Yahoo. {readmore}"
    },
    "unkwnownError": "{retry} ou contate nossa {supportteam} para verificá-lo.",
    "retry": "Tente novamente",
    "contactUs": "Se tiver dúvidas sobre como obter um domínio próprio, visite nosso {helpcenter}, ou contate nossa {supportteam}.",
    "revalidate": {
      "bePatient": "É possível que as modificações demorem algumas horas para  que se atualizem corretamente. Tente novamente mais tarde.",
      "helpCenter": "centro de ajuda",
      "supportTeam": "equipe de suporte",
      "sendInstructionsText": "Você também pode enviar essas instruções para a pessoa que administra o seu domínio para que ela faça as modificações necessárias."
    },
    "notify": {
      "revalidated": {
        "title": "Remetente autenticado",
        "text": "O domínio {domain} foi autenticado corretamente."
      }
    }
  }
}
</i18n>
