import { Model } from "@/core/magnet";
import app from "@/app";
import { _ } from "@/helpers/utils";
const moment = window.moment;
import { setLegacyParseSession } from "@store";
import { setI18nLocale } from "@locales/i18n";
import { setAuth, setAuthAPIV3, setAccount, setAccountAPIV3 } from "@api/requests";

export default Model.extend({
  accountsFilter: {},
  parse: function (response) {
    return response.data;
  },
  relationships: {
    account: function () {
      return $.ajax({
        url: "accounts",
        headers: {
          "Account-Prefix": false,
        },
        data: {
          limit: 100,
        },
      });
    },
  },

  // Custom initialization method
  initialize: function () {
    var self = this;

    // Set session as started
    self.set("started", true);

    // Save last activity
    self.set("lastActivity", Math.floor(Date.now() / 1000));

    // Set AJAX defaults
    self.setAjaxDefaults();

    // Set permissions
    self.setPermissions();

    // Set timezone
    self.setTimezone();

    setAccount(this.attributes.account);
    setAccountAPIV3(this.attributes.account);
    setAuth(this.attributes.token);
    setAuthAPIV3(this.attributes.token);

    let locale = app.lang;
    if (this.attributes.details?.account) {
      locale += "-" + this.attributes.details.account.country.toUpperCase();
    }

    setI18nLocale(locale);

    setLegacyParseSession({ ...this.attributes, lang: app.lang });
  },

  reload: function (callback) {
    $.ajax({
      url: "session",
      headers: {
        "Account-Prefix": false,
      },
      success: function (response) {
        app.session.set("details", response.data.details);
        callback();
      },
    });
  },

  // Sets permissions
  setPermissions: function (acl) {
    var self = this,
      attributes = self.toJSON(),
      permissions = {},
      canChangeAccount = false,
      inAccount = true,
      isMasterUser = false;

    acl = acl || attributes.acl;

    // Save data in the session model
    if (self.get("isGuest")) {
      self.set("permissions", []);
      self.set("canChangeAccount", false);
      self.set("inAccount", true);
      self.set("isMasterUser", false);
      return false;
    }

    // If the user has no permissions at all, logout
    if (_.size(acl) === 0) {
      self.logout();
      return false;
    }

    // If the user has more than one ACL list, can change account
    if (_.size(acl) > 1) {
      canChangeAccount = true;
    }

    // Set master user flag
    if (self.attributes.account === "*") {
      inAccount = false;
    }

    // Set permissions object
    $.each(acl, function (account, list) {
      if (account === "*") {
        isMasterUser = true;
        canChangeAccount = true;
        window.localStorage.setItem("api-lb-test-rand", "-1");
      }
      permissions[account] = {};
      $.each(list, function (index, value) {
        var acl = value.split(":"),
          module = acl.shift(),
          action = acl.shift();
        permissions[account][module] = permissions[account][module] || [];
        permissions[account][module].push(action);
      });
    });

    // Save data in the session model
    self.set("permissions", permissions);
    self.set("canChangeAccount", canChangeAccount);
    self.set("inAccount", inAccount);
    self.set("isMasterUser", isMasterUser);
    self.set("isGuest", false);
  },

  // Checks if the logged user has some permission
  hasPermission: function () {
    var self = this,
      account = self.get("account"),
      permissions = $.extend(true, {}, self.get("permissions")),
      isMasterUser = self.get("isMasterUser"),
      isGuest = self.get("isGuest"),
      acl = arguments,
      module,
      action;

    // If is a guest has no permissions
    if (isGuest) {
      return false;
    }

    // Split the permission if module and action are in the same string
    if (acl.length === 1) {
      acl = acl[0].split(":");
    }
    module = acl[0];
    action = acl[1];

    // If the user is master, set the default account
    if (isMasterUser) {
      account = "*";
    }

    // If the user has no permissions at all, logout
    if (!permissions) {
      self.logout();
      return false;
    }

    // Return if the user has the permission or not
    return $.inArray(action, permissions[account][module]) > -1;
  },

  // Sets the timezone
  setTimezone: function (tz) {
    const attributes = this.toJSON();

    if (tz) {
      window.config.timezone = tz;

      // Create a new session object
      const newSession = {
        token: this.get("token"),
        user: this.get("user"),
        mainAccount: this.get("mainAccount"),
        userType: this.get("userType"),
        account: this.get("account"),
        details: this.get("details"),
      };

      let sharedSession = {
        token: self.item.get("token"),
        tokenExpirationDate: expires,
        accountId: account,
        language: app.lang === "pt" ? "pt" : "es",
        isMasterUser: self.item.get("userType") === "MASTER",
      };

      if (newSession.details?.user?.timezone) {
        newSession.details.user.timezone = tz;
      }

      // Updates session to include the selected account
      const encodedCookie = encodeURIComponent(JSON.stringify(newSession));
      let encodedShared = encodeURIComponent(JSON.stringify(sharedSession));

      const expires = moment().add(1, "days").toDate().toUTCString();

      const domain = window.location.hostname.includes("localhost") ? "localhost" : window.config.domain;
      const environment = window.config.environment;

      document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = `session.${environment}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      // ! compatibility with first try -- todo: Deprecate
      document.cookie = `session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;

      document.cookie = `session=${encodedCookie}; expires=${expires}; path=/;`;
      // Cookie for communication between apps
      document.cookie = `session.${environment}=${encodedShared}; expires=${expires}; domain=${domain}; path=/;`;
    } else if (
      attributes.details !== undefined &&
      attributes.details.user !== undefined &&
      attributes.details.user.timezone !== undefined
    ) {
      window.config.timezone = attributes.details.user.timezone;
    } else {
      window.config.timezone = "America/Argentina/Buenos_Aires";
    }
  },

  // Set token as a default parameter for all AJAX requests
  setAjaxDefaults: function () {
    var self = this;
    if (!self.get("isGuest")) {
      $.ajaxSetup({
        headers: {
          "X-Auth-Token": self.get("token"),
        },
      });
    }
  },

  // Logs out a user and removes the session cookie
  logout: function (sessionExpired) {
    const domain = window.location.hostname.includes("localhost") ? "localhost" : window.config.domain;
    const environment = window.config.environment;

    $.removeCookie(`session.${environment}`, { domain });

    // ! deprecate - compatibility first try
    $.removeCookie(`session`, { domain });

    $.removeCookie("session");

    localStorage.removeItem("acl");
    localStorage.removeItem("trial");
    if (sessionExpired) {
      $.cookie("expired", "1");
    }
    window.location = "/";
  },

  // Get user context
  getContext: function () {
    var self = this;

    // Do not request context for master users
    if (self.get("userType") === "MASTER") {
      return false;
    }

    // Get context data
    return $.ajax({
      url: "users/me/context",
      success: function (response) {
        if (response.data === undefined) {
          return false;
        }
        self.set("context", response.data);
      },
    });
  },

  getPlanType: function () {
    return this.attributes.details.plan ? this.attributes.details.plan.type : this.attributes.details.account.plan.type;
  },
});
