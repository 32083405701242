<template>
  <Menu as="div">
    <MenuButton as="template">
      <slot name="button" />
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        :class="[
          { 'divide-y': showChangeAccount || navigation.length > 0 },
          'absolute bottom-0 left-20 z-50 w-64 rounded bg-white shadow-xl ring-1 ring-black ring-opacity-10 focus:outline-none',
        ]"
      >
        <div :class="[{ 'border-b': !showChangeAccount && navigation.length === 0 }, 'flex flex-col pb-2 pt-4']">
          <h1 class="m-0 truncate px-4 text-base font-semibold text-gray-500">
            {{ session.account.name }}
          </h1>
          <span v-if="session?.isMasterUser" class="pl-4 text-sm text-gray-500">{{ userName }}</span>
          <a v-else :href="`/settings/${session?.account.code}/users/me`" class="pl-4 text-sm text-gray-500">{{
            userName
          }}</a>

          <!-- <MenuItem v-show="allowChangeLayout" v-slot="{ active }" class="group">
            <button
              :class="[
                { 'bg-sky-100': active },
                'group mt-4 flex space-x-2 py-2 pl-4 text-sm focus:outline-none focus:ring-2 focus:ring-sky-400'
              ]"
              @click="changeLayout"
            >
              <SparklesIcon class="h-5 w-5 text-gray-400 group-hover:text-sky-500" />
              <span class="text-gray-600 group-hover:text-sky-500">{{ t("useOldInterface") }}</span>
            </button>
          </MenuItem> -->
        </div>
        <MenuItem v-show="showChangeAccount" v-slot="{ active }" class="group">
          <div class="py-2">
            <button
              :class="[
                { 'bg-sky-100': active },
                'group flex w-full space-x-2 px-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-sky-400',
              ]"
              @click="openAccountSelector"
            >
              <SwitchHorizontalIcon class="h-5 w-5 text-gray-400 group-hover:text-sky-500" />
              <span class="text-gray-600 group-hover:text-sky-500">{{ t("changeAccountButton") }}</span>
            </button>
          </div>
        </MenuItem>
        <div :class="[{ 'py-2': navigation.length > 0 }, 'flex flex-col']">
          <MenuItem v-for="item in navigation" :key="item.name" v-slot="{ active }" class="group">
            <a
              :href="item.path"
              :class="[
                { 'bg-sky-100': active },
                'justify-left group flex items-center space-x-2 px-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-sky-400',
              ]"
              @click="close"
            >
              <component
                :is="item.icon"
                class="h-5 w-5 shrink-0 text-gray-400 group-hover:text-sky-500"
                aria-hidden="true"
              />
              <span class="text-gray-600 group-hover:text-sky-500">{{ item.name }}</span>
            </a>
          </MenuItem>
        </div>
        <MenuItem v-slot="{ active }" class="group">
          <div class="py-2">
            <button
              :class="[
                { 'bg-sky-100': active },
                'flex w-full space-x-2 px-4 py-2 text-sm text-red-600 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-sky-400',
              ]"
              @click="logout"
            >
              <LogoutIcon class="h-5 w-5" />
              <span>{{ t("closeSession") }}</span>
            </button>
          </div>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script lang="ts" setup>
import { computed, ref, watch, watchEffect } from "vue";

import { getProfileNavigation } from "../routes";

//Components
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

// Icon
import { SwitchHorizontalIcon, LogoutIcon /*SparklesIcon*/ } from "@heroicons/vue/solid";

// Utils
import { useI18n } from "vue-i18n";

// Application
// import { useLocalStorageApp } from "@application";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

//API
import { useAccountFeatures } from "@api/modules/account";
import type { AccountFeatures } from "@/vue/types/account";

const emit = defineEmits<{
  openAccountSelector: [];
  close: [];
}>();

const { t } = useI18n();

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

const featuresAPI = useAccountFeatures();
const navigation = ref(getProfileNavigation());
// const localStorageApp = useLocalStorageApp();

watch([session], () => {
  navigation.value = getProfileNavigation();
});

const userName = computed(() => {
  if (session.value?.isMasterUser) return `${session.value?.account.username}@perfit`;
  if (["admin", "storeadmin"].includes(session.value?.account.username.toLocaleLowerCase()))
    return `${session.value?.user.name} (${session.value?.account.username})`;
  return session.value?.account.username;
});

const close = () => {
  emit("close");
};
const openAccountSelector = () => {
  emit("openAccountSelector");
  close();
};

const accountFeatures = ref<AccountFeatures>();
// const allowChangeLayout = ref(false);
const showChangeAccount = ref(false);

// const changeLayout = async () => {
//   localStorageApp.saveGlobal({ id: "useNewLayout", value: false });

//   window?.app?.router?.changeAccount(session.value?.account.code);
// };

const logout = () => {
  window?.app?.session?.logout();
};

watchEffect(async () => {
  accountFeatures.value = await featuresAPI.get({ account: session.value?.account.code });

  // allowChangeLayout.value =
  //   session.value?.isMasterUser ||
  //   (accountFeatures.value.USE_NEW_LAYOUT === "1" && accountFeatures.value.ALLOW_TOGGLE_LAYOUT === "1");

  showChangeAccount.value =
    Object.entries(session.value.acl).length > 1 ||
    (session.value?.isMasterUser && session.value.account.code !== "") ||
    false;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "changeAccountButton": "Cambiar de cuenta",
    "closeSession": "Cerrar sesión",
    "useOldInterface": "Usar antigua interfaz"
  },
  "pt": {
    "changeAccountButton": "Trocar de conta",
    "closeSession": "Encerrar sessão",
    "useOldInterface": "Usar a interface antiga"
  }
}
</i18n>
