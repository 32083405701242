<template>
  <div v-if="!showBrandEditor">
    <div class="grid grid-cols-2 gap-6">
      <div data-intercom-target="AutomationsBrandEditor">
        <h3 class="mb-6 mt-0 text-sm leading-5 text-sky-500">
          {{ t(`title_${isTiendanube ? "on" : "off"}`) }}
        </h3>
        <p class="mb-4 text-sm text-gray-400">
          {{ t(`description_${isTiendanube ? "on" : "off"}`) }}
        </p>

        <SimpleButton theme="secondary" @click="openBrandEditor">
          <template #leading>
            <PaintBrushIcon :size="20" />
          </template>
          {{ t("buttons.modal") }}
        </SimpleButton>
      </div>

      <BrandEditorTemplatePreview :brand="brandEdition" />
    </div>

    <div class="mt-5 flex justify-end space-x-4">
      <SimpleButton theme="white" @click="emitBack">
        <template #leading>
          <ChevronLeftIcon class="text-gray-500" />
        </template>
        {{ t("buttons.back") }}
      </SimpleButton>

      <SimpleButton theme="primary" data-intercom-target="AutomationsBrandEditorCreate" @click="emitCreate">
        <template #leading>
          <CheckIcon />
        </template>
        {{ t("buttons.create") }}
      </SimpleButton>
    </div>
  </div>
  <div v-else>
    <BrandEditor v-if="showBrandEditor" :allow-back="brand != null" @save="closeBrandEditor" @back="backBrandEditor" />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import SimpleButton from "@atoms/SimpleButton.vue";
import { ChevronLeftIcon, CheckIcon } from "@heroicons/vue/solid";
import BrandEditorTemplatePreview from "../BrandEditor/BrandEditorTemplatePreview.vue";
import { BrandEdition, ContactInfoTypes } from "@/vue/types/brandEditor.types";
import { useIntegrationsStore } from "@/vue/stores";
import { useTrackingEvents } from "@/vue/composables/trackingevents";
import BrandEditor from "@/vue/components/organisms/BrandEditor/BrandEditor.vue";
import { Brand } from "@/vue/api/modules/brand/brandEditor.types";
import { DataItem } from "@/vue/types/data";
import { toDataItems } from "@/vue/helpers/data";
import PaintBrushIcon from "@tokens/icons/PaintBrush.vue";

const { t } = useI18n();
const trackingEventsService = useTrackingEvents();

const integrationStore = useIntegrationsStore();
const isTiendanube = ref<boolean>(integrationStore.isTiendanube);

const showBrandEditor = ref(false);

const brandEdition = ref<BrandEdition>({
  logo: "",
  colors: {
    body_background: "#F5F5F5",
    button_background: "#F57179",
    button_text: "#FFFFFF",
    text_link: "#0068A5",
    text: "#000000",
  },
  color_palette: [
    "#000000",
    "#FFFFFF",
    "#FF1900",
    "#F47365",
    "#FFB243",
    "#FFE623",
    "#6EFF2A",
    "#1BC7B1",
    "#00BEFF",
    "#2E81FF",
    "#5D61FF",
    "#FF89CF",
    "#FC3CAD",
    "#BF3DCE",
    "#8E00A7",
    "rgba(0,0,0,0)",
  ],
  font_family: {
    id: "fallback_arial",
    name: "Arial",
    value: "arial,sans-serif",
  },
  contact_info: [],
  contact_info_options: [
    {
      key: "whatsapp",
      value: t("config.contactOptions.options.whatsapp"),
    },
    {
      key: "email",
      value: t("config.contactOptions.options.email"),
    },
    {
      key: "phone",
      value: t("config.contactOptions.options.phone"),
    },
    {
      key: "web",
      value: t("config.contactOptions.options.web"),
    },
  ],
  button_contact_info: { key: "whatsapp", value: "" },
  web_store_url: "",
  footer_links: [
    {
      key: t("config.footerLinksoptions.who"),
      value: "",
    },
    {
      key: t("config.footerLinksoptions.how"),
      value: "",
    },
    {
      key: t("config.footerLinksoptions.ask"),
      value: "",
    },
  ],
  use_footer_links: true,
  social_medias: [],
  social_media_options: [
    {
      key: "facebook",
      value: t("config.socialMediaLinks.options.facebook"),
    },
    {
      key: "instagram",
      value: t("config.socialMediaLinks.options.instagram"),
    },
    {
      key: "linkedin",
      value: t("config.socialMediaLinks.options.linkedin"),
    },
    {
      key: "pinterest",
      value: t("config.socialMediaLinks.options.pinterest"),
    },
    {
      key: "tiktok",
      value: t("config.socialMediaLinks.options.tiktok"),
    },
    {
      key: "vimeo",
      value: t("config.socialMediaLinks.options.vimeo"),
    },
    {
      key: "whatsapp",
      value: t("config.socialMediaLinks.options.whatsapp"),
    },
    {
      key: "x",
      value: t("config.socialMediaLinks.options.x"),
    },
    {
      key: "youtube",
      value: t("config.socialMediaLinks.options.youtube"),
    },
  ],
  social_media_selected: [
    {
      key: "facebook",
      value: "",
    },
    {
      key: "instagram",
      value: "",
    },
    {
      key: "whatsapp",
      value: "",
    },
  ],
  use_social_media_links: true,
});

// Props
const props = defineProps<{
  brand: Brand | null;
}>();

const emit = defineEmits<{
  (e: "back"): void;
  (e: "create"): void;
  (e: "updateBrand"): void;
}>();

const emitBack = () => {
  emit("back");
};

const emitCreate = () => {
  emit("create");
};

const openBrandEditor = () => {
  showBrandEditor.value = true;

  trackingEventsService.amplitude({
    name: "APP_BRAND_EDITOR_AUTOMATIONS_OPENED",
  });
};

const closeBrandEditor = () => {
  showBrandEditor.value = false;
  emit("updateBrand");
};

const backBrandEditor = () => {
  showBrandEditor.value = false;
};

const updateBrandEdition = () => {
  showBrandEditor.value = props.brand == null;

  if (props.brand != null) {
    const buttonContactInfo: DataItem<ContactInfoTypes> = {
      key: props.brand.button_contact_info?.type ?? brandEdition.value.button_contact_info.key,
      value: props.brand.button_contact_info?.value ?? brandEdition.value.button_contact_info.value,
    };

    brandEdition.value = {
      ...brandEdition.value,
      logo: props.brand.logo,
      colors: props.brand.colors,
      color_palette: props.brand.color_palette,
      font_family: {
        id: props.brand.font_family.label,
        name: props.brand.font_family.value,
        value: props.brand.font_family.value,
        url: props.brand.font_family.url,
      },
      web_store_url: props.brand.web_store_url,
      contact_info: toDataItems(props.brand.contact_info, "type", "value"),
      button_contact_info: buttonContactInfo,
    };

    if (props.brand.footer_links.length > 0) {
      brandEdition.value.footer_links = toDataItems(props.brand.footer_links, "name", "value");
    }

    brandEdition.value.use_footer_links = props.brand.footer_links.length > 0;

    if (props.brand.social_media_links.length > 0) {
      brandEdition.value.social_media_selected = toDataItems(props.brand.social_media_links, "platform", "url");
    }

    brandEdition.value.use_social_media_links = props.brand.social_media_links.length > 0;
  }
};

watch(() => props.brand, updateBrandEdition, { immediate: true });
</script>

<i18n lang="json">
{
  "es": {
    "title_on": "¡Importamos los elementos de tu marca! El automation tendrá el estilo de tu tienda.",
    "title_off": "¡Configura tu marca y ahorra tiempo! Aplica al automation el estilo de tu tienda.",
    "description_on": "El logo, colores y tipo de letra de tu marca hacen que tus e-mails sean reconocibles. Puedes modificar los elementos importados desde las configuraciones de marca.",
    "description_off": "Configura los elementos de tu marca como el logo, colores o tipo de letra y aplicalos en los e-mails de tus automations. Si no lo haces, se aplicará el estilo predeterminado.",
    "subtitle": "Configura los elementos de tu marca",
    "buttons": {
      "back": "Volver",
      "create": "Crear automation",
      "modal": "Configurar marca"
    },
    "config": {
      "linkTitle": "Links",
      "contactOptions": {
        "title": "Botón de contacto",
        "options": {
          "whatsapp": "Whatsapp",
          "email": "Email",
          "phone": "Teléfono",
          "web": "Web"
        }
      },
      "footerLinksoptions": {
        "who": "¿Quiénes somos?",
        "how": "¿Cómo comprar?",
        "ask": "Preguntas frecuentes"
      },
      "socialMediaLinks": {
        "options": {
          "facebook": "Facebook",
          "instagram": "Instagram",
          "linkedin": "Linkedin",
          "pinterest": "Pinterest",
          "tiktok": "Tiktok",
          "vimeo": "Vimeo",
          "whatsapp": "Whatsapp",
          "x": "X (ex Twitter)",
          "youtube": "Youtube"
        }
      }
    }
  },
  "pt": {
    "title_on": "Importamos os elementos da sua marca! A automação terá o estilo da sua loja.",
    "title_off": "Configure sua marca e economize tempo! Aplique o estilo da sua loja nas automações.",
    "description_on": "O logo, as cores e a fonte da sua marca fazem com que seus e-mails sejam reconhecíveis. Você pode modificar os elementos importados nas configurações da marca.",
    "description_off": "Configure os elementos da sua marca, como o logo, cores ou fonte, e aplique-os nos e-mails das suas automações. Caso não faça isso, será aplicado o estilo padrão.",
    "subtitle": "Configure os elementos da sua marca",
    "buttons": {
      "back": "Voltar",
      "create": "Criar automação",
      "modal": "Configurar marca"
    },
    "config": {
      "linkTitle": "Links",
      "contactOptions": {
        "title": "Botão de contato",
        "options": {
          "whatsapp": "Whatsapp",
          "email": "Email",
          "phone": "Teléfono",
          "web": "Web"
        }
      },
      "footerLinksoptions": {
        "who": "Quem somos?",
        "how": "Como comprar?",
        "ask": "Perguntas frequentes"
      },
      "socialMediaLinks": {
        "options": {
          "facebook": "Facebook",
          "instagram": "Instagram",
          "linkedin": "Linkedin",
          "pinterest": "Pinterest",
          "tiktok": "Tiktok",
          "vimeo": "Vimeo",
          "whatsapp": "Whatsapp",
          "x": "X (ex Twitter)",
          "youtube": "Youtube"
        }
      }
    }
  }
}
</i18n>
