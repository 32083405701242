import app from "@/app";
import { Model } from "@/core/magnet";
const moment = window.moment;

// Services
import { useTrackingEvents } from "@composables/trackingevents";
import { useFeaturesStore } from "@store";

export default Model.extend({
  urlRoot: function () {
    if (app.session.get("isGuest")) {
      return "shares/" + app.session.get("account") + "/campaigns/" + app.session.get("token");
    }
    return "campaigns";
  },
  parse: function (response) {
    var campaign = response.data;
    switch (campaign.state) {
      case "SCHEDULED":
      case "PENDING_APPROVAL":
        campaign.mode = "PENDING";
        break;
      case "SENDING":
        campaign.mode = campaign.metrics.sent === 0 ? "PENDING" : "SENT";
        // campaign.mode = "PENDING";
        break;
      case "SENT":
      case "CANCELLED":
        campaign.mode = "SENT";
        break;
      default:
        campaign.mode = "DRAFT";
    }
    if (campaign.type === "SMART") {
      campaign.smartOptions = JSON.parse(campaign.options["JSON"]);
    }

    if (campaign.metrics) {
      if (campaign.metrics.conversions && campaign.metrics.opened) {
        campaign.metrics.conversionRate =
          Math.round((10000 * campaign.metrics.conversions) / campaign.metrics.opened) / 100;
      } else {
        campaign.metrics.conversionRate = 0;
      }
    }

    return campaign;
  },
  relationships: {
    actions: [{ id: "SENT" }, { id: "OPENED" }, { id: "CLICKED" }],
  },
  additionals: {
    metrics: function (model) {
      let filter = "";
      if (model.attributes.version) {
        filter = "?filters.version=" + model.attributes.version.toLowerCase();
      }
      if (app.session.get("isGuest")) {
        return $.get(model.getGuestUrlRoot("/metrics" + filter));
      }
      return $.get("campaigns/" + model.id + "/metrics" + filter);
    },
    timeline: function (model) {
      let filters = [];
      if (model.attributes.version) {
        filters.push(`filters.version=${model.attributes.version.toLowerCase()}`);
      }
      if (model.attributes.type === "SMART") {
        filters.push(
          `filters.dateTo=${moment(model.attributes.smartOptions.to).add(24, "hours").toDate().toISOString()}`,
        );
      }

      let query = filters ? "?" + filters.join("&") : "";

      if (app.session.get("isGuest")) {
        return $.get(model.getGuestUrlRoot("/timeline" + query));
      }
      return $.get("campaigns/" + model.id + "/timeline" + query);
    },
    links: function (model) {
      if (app.session.get("isGuest")) {
        return $.get(model.getGuestUrlRoot("/links/metrics"));
      }
      return $.get("campaigns/" + model.id + "/links/metrics");
    },
  },

  // Returns a guest URL
  getGuestUrlRoot: function (url) {
    var session = app.session;
    return ["shares/", session.get("account"), "/campaigns/", session.get("token"), "/", this.id, url].join("");
  },

  // Shares a campaign
  share: function () {
    return $.get("campaigns/" + this.id + "/share");
  },

  // Duplicates a campaign
  duplicate: function (data) {
    if (this.isEddieTemplate()) {
      const trackingEventsService = useTrackingEvents();
      const eddieFeatures = this.getEddieFeatures();

      if (eddieFeatures) {
        trackingEventsService.amplitude({
          name: "APP_EDDIE_DUPLICATE_ACCEPT",
          data: {
            source: "campaign",
            id: this?.id,
            feature_unlayer_optional: eddieFeatures.unlayer_optional,
            feature_unlayer_default: eddieFeatures.unlayer_default,
            feature_hide_legacy_templates: eddieFeatures.hide_legacy_templates,
          },
        });
      }
    }

    return $.post("campaigns/" + this.id + "/duplicate", data);
  },

  // Archives a campaign
  archive: function () {
    return $.post("campaigns/" + this.id + "/archive");
  },

  // Unarchives a campaign
  unarchive: function () {
    return $.post("campaigns/" + this.id + "/unarchive");
  },

  // Stops a campaign
  stop: function () {
    const res = $.post("campaigns/" + this.id + "/stop");
    app.integrations.amplitude.event(
      "APP_CAMPAIGN_STOPPED",
      {
        name: this.attributes.name,
        id: this.attributes.id,
      },
      true,
    );
    app.integrations.intercom.event("APP_CAMPAIGN_STOPPED", {
      name: this.attributes.name,
      id: this.attributes.id,
    });
    return res;
  },

  // Approves the campaign
  approve: function () {
    return $.post("campaigns/" + this.id + "/approve");
  },

  // Cancels the campaign
  cancel: function () {
    const res = $.post("campaigns/" + this.id + "/cancel");
    app.integrations.amplitude.event(
      "APP_CAMPAIGN_CANCELLED",
      {
        name: this.attributes.name,
        id: this.attributes.id,
      },
      true,
    );
    app.integrations.intercom.event("APP_CAMPAIGN_CANCELLED", {
      name: this.attributes.name,
      id: this.attributes.id,
    });
    return res;
  },

  splitABSubjects() {
    const subjects = [],
      options = this.attributes.options;

    const versionName = (num) => `VERSION_${num}`;

    let i = 1;
    while (options[versionName(i)]) {
      subjects.push({
        key: versionName(i).toLowerCase(),
        number: i,
        subject: options[versionName(i)],
      });
      i++;
    }

    return subjects;
  },

  isEddieTemplate: function () {
    const options = this.attributes.options;
    return !options.UNLAYER_TPL_ID;
  },

  getEddieFeatures: function () {
    const featuresStore = useFeaturesStore();
    const features = featuresStore.features;

    if (!features) return undefined;

    const eddieFeatures = {
      unlayer_optional: features.UNLAYER_OPTIONAL === "1" ? true : false,
      unlayer_default: features.UNLAYER_DEFAULT === "1" ? true : false,
      hide_legacy_templates: features.HIDE_LEGACY_TEMPLATES === "1" ? true : false,
    };
    return eddieFeatures;
  },
});
