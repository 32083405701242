<template>
  <div>
    <NotificationsContainer />
    <div
      v-show="showChangeAccount"
      :class="[alertStore.message ? 'top-[4.5rem]' : 'top-4', 'fixed right-8 z-60 flex space-x-2']"
    >
      <ChangeAccountButton v-if="showChangeAccount" @click="openSelectAccount" />
      <span v-if="showAccountCode" class="text-sm text-gray-100">
        {{ session.account.code }}
      </span>
    </div>
    <ChangeAccountModal v-if="showChangeAccount" v-model:open="selectAccountIsOpen" />
    <PermissionDeniedModal
      :open="alertStore.permissionDenied"
      :permissions="alertStore.permissionsNeeded"
      @close="alertStore.closePermissionDenied"
    />
    <AccountFormModal />
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from "vue";

// Components
import AccountFormModal from "@organisms/AccountFormModal/AccountFormModal.vue";
import NotificationsContainer from "@molecules/NotificationsContainer.vue";
import ChangeAccountButton from "@organisms/ChangeAccount/ChangeAccountButton.vue";
import ChangeAccountModal from "@organisms/ChangeAccount/ChangeAccountModal.vue";
import PermissionDeniedModal from "@molecules/PermissionDeniedModal.vue";

// Application
import { useLocalStorageApp } from "@application";

import Intercom from "@helpers/intercom";

// Store
import { useTrackingEvents } from "@/vue/composables/trackingevents";
import { storeToRefs } from "pinia";
import {
  useAccountStore,
  useUserConfigStore,
  useSessionStore,
  useAlertStore,
  useFeaturesStore,
  useIntegrationsStore,
  useSendersStore,
  useGlobalStore,
  useUserStore,
  useUserActivityStore,
} from "@store";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const userStore = useUserStore();
const globalStore = useGlobalStore();
const sendersStore = useSendersStore();
const userConfigStore = useUserConfigStore();
const accountStore = useAccountStore();
const alertStore = useAlertStore();
const sessionStore = useSessionStore();
const featuresStore = useFeaturesStore();
const integrationsStore = useIntegrationsStore();
const userActivityStore = useUserActivityStore();

const localStorageApp = useLocalStorageApp();

const trackingEventsService = useTrackingEvents();

const { session } = storeToRefs(sessionStore);
const { showChangeAccount } = storeToRefs(globalStore);
const usingNewLayout = ref(false);
const showAccountCode = ref(false);

const selectAccountIsOpen = ref(false);
const openSelectAccount = () => {
  selectAccountIsOpen.value = true;
};

const checkIntercomParams = () => {
  const url = new URL(location.href);

  const params = url.searchParams;
  const intercomMessage = params.get("intercom_message");

  const validIntercomMessages = ["BLOCKED_CONTACTS_EXCEEDED_FREE", "BLOCKED_ABUSE"];

  if (!intercomMessage || !validIntercomMessages.includes(intercomMessage)) return;

  Intercom.showNewMessage(t(`intercomMessages.${intercomMessage}`));
};

onMounted(async () => {
  if (["", "*"].includes(session.value?.account?.code)) return;

  globalStore.updateState();

  await Promise.all([
    userConfigStore.getUserConfig(),
    featuresStore.fetchFeatures(),
    integrationsStore.fetchIntegrations(),
    sendersStore.fetchSenders(),
    userActivityStore.fetchEvents(),
    accountStore.fetchAccountData(),
    userStore.fetchUserData(),
    userStore.fetchUsers(),
    sessionStore.fetchPlan(),
  ]);

  const accountsLoggedIn = localStorageApp.getGlobal<string[]>({ id: "ACCOUNTS_LOGGED_IN" }) ?? [];

  await userConfigStore.getUserConfig();
  // await featuresStore.fetchFeatures();
  // const features = featuresStore.getFeatures();

  // usingNewLayout.value = features?.USE_NEW_LAYOUT === "1";
  usingNewLayout.value = true;

  if (usingNewLayout.value) {
    trackingEventsService.dispatchAll({
      name: "APP_NEW_UI_LOADED",
    });
  }

  showAccountCode.value = !showChangeAccount.value && accountsLoggedIn.length >= 2;

  checkIntercomParams();
});
</script>

<i18n lang="json">
{
  "es": {
    "intercomMessages": {
      "BLOCKED_CONTACTS_EXCEEDED_FREE": "Hola! Quisiera coordinar una demo con un especialista y una prueba gratuita de todas las funcionalidades que ofrece Perfit.",
      "BLOCKED_ABUSE": "Hola! Quiero más información sobre el proceso de auditoria en mi cuenta."
    }
  },
  "pt": {
    "intercomMessages": {
      "BLOCKED_CONTACTS_EXCEEDED_FREE": "Olá, gostaria de agendar uma demonstração com um especialista e experimentar gratuitamente todos os recursos que a Perfit tem a oferecer.",
      "BLOCKED_ABUSE": "Olá! Gostaria de mais informações sobre o processo de auditoria em minha conta."
    }
  }
}
</i18n>
