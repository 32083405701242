// Store
import { storeToRefs } from "pinia";
import { useSessionStore, useFeaturesStore, useIntegrationsStore } from "@store";
import { Session } from "@domain/session";
import { AccountFeatures } from "@domain/account";

export interface UserProperties {
  feature_home_access?: boolean;
  feature_brand_editor?: boolean;
  is_tiendanube?: boolean;
  account_creation_date?: DateString;
  account_last_login?: DateString;
  is_agency?: boolean;
}

export interface Event {
  name: string;
  data?: any;
  properties?: UserProperties;
  includeMasterUsers?: boolean;
}

const buildUserProperties = (
  properties: UserProperties | undefined,
  features: AccountFeatures | undefined,
  session: Session,
) => {
  const integrationStore = useIntegrationsStore();
  const userProperties = {
    ...properties,
    is_ecommerce: !!integrationStore?.ecommerceIntegrations?.[0],
    feature_home_access: properties?.feature_home_access ?? features?.ALLOW_HOME_ACCESS === "1",
    feature_brand_editor: properties?.feature_brand_editor ?? features?.USE_BRAND_EDITOR === "1",
    is_tiendanube: properties?.is_tiendanube ?? integrationStore.isTiendanube,
    account_creation_date: properties?.account_creation_date ?? session.account.created,
    account_last_login: properties?.account_last_login ?? session.account.lastLogin,
    is_agency: properties?.is_agency ?? session.account.container,
  };

  Object.keys(userProperties).forEach((key) => {
    if (userProperties[key] === undefined) {
      delete userProperties[key];
    }
  });

  return userProperties;
};

export function useTrackingEvents() {
  const sessionStore = useSessionStore();
  const featuresStore = useFeaturesStore();
  const { session } = storeToRefs(sessionStore);

  function amplitude({ name, data, includeMasterUsers, properties }: Event) {
    if (window.amplitude == undefined) return;
    if (!includeMasterUsers && session.value?.isMasterUser) return;

    const eventData = { ...data };
    if (session.value?.isMasterUser) {
      eventData.master_user = session.value?.account.username;
    } else {
      eventData.account_user = session.value?.account.username;
    }

    const features = featuresStore.getFeatures();

    const amplitudeInstance = window.amplitude.getInstance();

    const userProperties = buildUserProperties(properties, features, session.value);
    amplitudeInstance.setUserProperties(userProperties);

    amplitudeInstance.logEvent(name, eventData);
  }

  function intercom({ name, data }: Event) {
    if (window.Intercom == undefined) return;
    // a intercom nunca mando eventos de master user
    if (session.value?.isMasterUser) return;

    window.Intercom("trackEvent", name, data);

    setTimeout(function () {
      window.Intercom("update");
    }, 1000);
  }

  function dispatchAll(event: Event) {
    amplitude(event);
    intercom(event);
  }

  return {
    amplitude,
    intercom,
    dispatchAll,
  };
}
