<template>
  <ModalDialog :title="t('title')" :open="isOpen" @close="close">
    <div class="w-[30rem] max-w-lg">
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="transform translate-x-20 opacity-0"
        enter-to-class="transform translate-x-0 opacity-100"
        leave-active-class="duration-300 ease-in"
        leave-from-class="transform translate-x-0 opacity-100"
        leave-to-class="transform -translate-x-20 opacity-0"
        mode="out-in"
        @after-enter="codeInputRef?.focus()"
      >
        <form v-if="currentStep === 1" class="space-y-6" @submit.prevent="submit">
          <FormInput
            id="email"
            v-model.lowercase.trim="email"
            :label="t('email.label')"
            :placeholder="t('email.placeholder')"
            :hint="t('email.hint')"
            :error="v$.email.$errors[0]?.$message"
          />
          <FormInput
            id="name"
            v-model.trim="name"
            :label="t('name.label')"
            :placeholder="t('name.placeholder')"
            :hint="t('name.hint')"
            :error="v$.name.$errors[0]?.$message"
          />
          <AlertBox v-if="!isMasterUser" theme="info">
            {{ t("info.willSendVerificationCode") }}
          </AlertBox>
          <AlertBox v-else theme="info">
            {{ t("info.masterUser") }}
          </AlertBox>
          <input type="submit" class="hidden" />
        </form>

        <form v-else class="space-y-6" @submit.prevent="submit">
          <FormInput
            id="code"
            ref="codeInputRef"
            v-model="code"
            :label="t('code.label')"
            :placeholder="t('code.placeholder')"
            :hint="isMasterUser ? t('code.hintMasterUser', { email }) : t('code.hint', { email })"
            :error="v$.code.$errors[0]?.$message"
            @input="verificationError = false"
          />
          <AlertBox v-if="isMasterUser" theme="info">
            {{ t("info.masterUser") }}
          </AlertBox>
          <AlertBox v-else-if="errorsCount < 3" theme="info">
            {{ t("info.checkFolders") }}
            <template #actions>
              <a href="#" @click.prevent="resendVerificationCode">{{ t("resend") }}</a>
            </template>
          </AlertBox>
          <AlertBox v-else theme="warning">
            {{ t("troubles") }}
            <template #actions>
              <a href="#" @click.prevent="contactSupport">{{ t("contactSupport") }}</a>
            </template>
          </AlertBox>
        </form>
      </transition>
    </div>
    <template #buttons>
      <SimpleButton :loading="currentStep === 1 ? isVerifying : isCreating" @click="submit">
        <template v-if="currentStep === 2" #leading><BadgeCheckIcon /></template>
        {{ currentStep === 1 ? t("next") : t("verify") }}
        <template v-if="currentStep === 1" #trailing><ChevronRightIcon /></template
      ></SimpleButton>

      <SimpleButton theme="white" @click="close">{{ t("cancel") }}</SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import { reactive, ref, toRefs, watch } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { ChevronRightIcon, BadgeCheckIcon } from "@heroicons/vue/solid";

import { useNotifications } from "@composables/notifications";
import { useSenders } from "@api/modules/senders/senders";
import { helpers } from "@vuelidate/validators";
import { required, email, maxLength } from "@helpers/validators";
import Intercom from "@helpers/intercom";

import AlertBox from "@atoms/AlertBox.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import ModalDialog from "@molecules/ModalDialog.vue";
import FormInput from "@molecules/FormInput.vue";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Store
import { storeToRefs } from "pinia";
import { useAlertStore, useSessionStore } from "@store";

export default {
  name: "SenderCreatorModal",
  components: {
    ModalDialog,
    SimpleButton,
    FormInput,
    AlertBox,
    ChevronRightIcon,
    BadgeCheckIcon,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["senderCreated", "close"],
  setup(props, { emit }) {
    const currentStep = ref(1);
    const { t } = useI18n();
    const { notify } = useNotifications();
    const trackingEventsService = useTrackingEvents();
    const codeInputRef = ref<InstanceType<typeof FormInput>>();
    const isVerifying = ref(false);
    const isCreating = ref(false);
    const hasEmailChanged = ref(false);

    const sessionStore = useSessionStore();
    const { session } = storeToRefs(sessionStore);

    const isMasterUser = session.value?.isMasterUser;

    const initialState = {
      name: t("name.default", { account: session.value?.account.name }),
      email: "",
      code: "",
    };

    const state = reactive({ ...initialState });
    const verificationError = ref(false);
    const errorsCount = ref(0);

    const resetForm = () => {
      currentStep.value = 1;
      Object.assign(state, initialState);
      v$.value.$reset();
      verificationError.value = false;
      errorsCount.value = 0;
    };

    watch(
      () => props.isOpen,
      (value, prev) => {
        if (value && !prev) {
          resetForm();
        }
      },
    );

    const rules = {
      name: {
        required: required(t("name.errors.required")),
        maxLength: maxLength(50, t("name.errors.maxLength", { length: 50 })),
      },
      email: {
        required: required(t("email.errors.required")),
        email: email(t("email.errors.email")),
        maxLength: maxLength(50, t("email.errors.maxLength", { length: 50 })),
      },
      code: {
        required: required(() => t("code.errors.required", { email: state.email })),
        verified: helpers.withMessage(t("code.errors.invalid"), () => !verificationError.value),
      },
    };
    const v$ = useVuelidate(rules, state); // v$ es un Ref

    const close = () => {
      emit("close");
    };

    const sendersAPI = useSenders();
    const alertStore = useAlertStore();

    const sendVerificationCode = async () => {
      isVerifying.value = true;

      await sendersAPI.verify({
        name: state.name,
        email: state.email,
      });

      isVerifying.value = false;

      notify({
        title: t("notify.emailSent.title"),
        text: t("notify.emailSent.text", { email: state.email }),
      });
    };

    const trackEmailSenderFieldCompleted = () => {
      trackingEventsService.amplitude({
        name: "APP_SENDER_EMAIL_FIELD_COMPLETED",
        data: {
          ui_path: routeEvent.value,
        },
      });
    };

    const trackSenderNextButtonClicked = () => {
      trackingEventsService.amplitude({
        name: "APP_SENDER_NEXT_BUTTON_CLICKED",
        data: {
          ui_path: routeEvent.value,
        },
      });
    };

    const trackSenderVerifyButtonClicked = () => {
      trackingEventsService.amplitude({
        name: "APP_SENDER_VERIFY_BUTTON_CLICKED",
        data: {
          ui_path: routeEvent.value,
        },
      });
    };

    const getRouteEvent = () => {
      const route = window.location.pathname;
      const parts = route.split("/").filter(Boolean);
      return parts[0];
    };

    const routeEvent = ref(getRouteEvent());

    watch(
      () => state.email,
      () => {
        if (!hasEmailChanged.value) {
          trackEmailSenderFieldCompleted();
          hasEmailChanged.value = true;
        }
      },
    );

    const resendVerificationCode = async () => {
      try {
        await sendVerificationCode();
        errorsCount.value++;
      } catch (error) {
        console.error(error);
      }
    };

    const submit = async () => {
      if (isVerifying.value || isCreating.value) return;

      if (currentStep.value === 1) {
        // Submit step 1
        trackSenderNextButtonClicked();

        v$.value.name.$touch();
        v$.value.email.$touch();
        if (!v$.value.name.$invalid && !v$.value.email.$invalid) {
          try {
            if (!isMasterUser) await sendVerificationCode();
            currentStep.value = 2;
          } catch (error) {
            console.error(error);
          }
        }
      } else {
        // Submit step 2
        trackSenderVerifyButtonClicked();
        v$.value.code.$touch();
        if (!v$.value.code.$invalid) {
          try {
            isCreating.value = true;
            const newSender = await sendersAPI.create({
              name: state.name,
              email: state.email,
              code: state.code,
            });
            await alertStore.update();
            isCreating.value = false;

            trackingEventsService.amplitude({
              name: "APP_SENDER_CREATED",
              data: {
                name: state.name,
                email: state.email,
                code: state.code,
              },
            });

            notify({
              title: t("notify.senderCreated.title"),
              text: t("notify.senderCreated.text", { email: state.email }),
            });

            emit("senderCreated", newSender);
            close();
          } catch (error: any) {
            if (error.response?.status === 400) {
              verificationError.value = true;
              errorsCount.value++;
            }
            console.error(error);
          }
        }
      }
    };

    const contactSupport = () => {
      Intercom.showNewMessage(t(`helpMessage`, { email: state.email }));
    };

    return {
      ...toRefs(state),
      currentStep,
      submit,
      close,
      v$,
      t,
      isVerifying,
      isCreating,
      verificationError,
      resendVerificationCode,
      errorsCount,
      contactSupport,
      codeInputRef,
      isMasterUser,
    };
  },
};
</script>

<i18n>
{
  "es": {
    "title": "Registrar remitente",
    "verify": "Verificar",
    "name": {
      "label": "Nombre del remitente",
      "placeholder": "Tu nombre o empresa",
      "default": "{account}",
      "hint":"Nombre con el que llegarán tus envíos, se mostrará a los destinatarios y puedes editarlo luego.",
      "errors": {
        "required":"Debes indicar el nombre que verán tus contactos.",
        "maxLength": "La longitud máxima para el nombre es de {length} caracteres."
      }
    },
    "email": {
      "label": "Dirección de e-mail",
      "placeholder": "email{'@'}dominio.com",
      "hint":"Dirección asociada a tus envíos, se mostrará como remitente a los destinatarios cuando reciban tus e-mails.",
      "errors": {
        "required":"Debes indicar la dirección de email a usar como remitente.",
        "email": "Debes indicar una dirección de email válida.",
        "maxLength": "La longitud máxima para el email es de {length} caracteres."
      }
    },
    "code": {
      "label": "Código de verificación",
      "placeholder": "xxxxxx",
      "hint": "Ingresa el código que enviamos a {email}.",
      "hintMasterUser": "Ingresa el token de master users del día.",
      "errors": {
        "required": "Debes ingresar el código enviado a {email}.",
        "invalid": "El código ingresado es incorrecto."
      }
    },
    "info":{
      "willSendVerificationCode": "Enviaremos el código de verificación a la dirección de e-mail que ingreses.",
      "masterUser": "Por estar logueado usando un master user no se enviará ningún código de verificación 😎",
      "checkFolders": "Si no encuentras el correo con el código de verificación en tu bandeja de entrada revisa la carpeta de notificaciones, promociones o correo no deseado."
    },
    "resend": "Volver a enviar código de verificación",
    "troubles":"¿Tienes problemas para verificar tu remitente?",
    "contactSupport":"Contactar al equipo de soporte",
    "helpMessage": "Hola! Necesito ayuda para verificar el remitente {email}",
    "notify": {
      "emailSent": { "title":"Código de verificación enviado", "text":"Revisa la bandeja de entrada de {email}." },
      "senderCreated": { "title":"Remitente verificado", "text": "" }
    }
  },
  "pt": {
    "title": "Registrar remetente",
    "verify": "Verificar",
    "name": {
      "label": "Nome do remetente",
      "placeholder": "Nome da sua empresa",
      "default": "{account}",
      "hint": "Nome que aparecerá em seus envios, será mostrado aos destinatários e poderá ser editado posteriormente.",
      "errors": {
        "required": "Você deve indicar o nome que ficará visível para os seus contatos.",
        "maxLength": "O tamanho máximo para o nome é de {length} caracteres."
      }
    },
    "email": {
      "label": "Endereço de e-mail",
      "placeholder": "email{'@'}dominio.com",
      "hint": "Endereço associado aos seus envios, será exibido como remetente para os destinatários quando receberem seus e-mails.",
      "errors": {
        "required": "Você deve indicar um endereço de email para usar como remetente.",
        "email": "Você deve indicar um endereço de email válido.",
        "maxLength": "O tamanho máximo para o email é de {length} caracteres."
      }
    },
    "code": {
      "label": "Código de verificação",
      "placeholder": "xxxxxx",
      "hint": "Insira o código que enviamos para {email}.",
      "hintMasterUser": "Inserir o token de master users do día.",
      "errors": {
        "required": "Você deve inserir o código enviado a {email}.",
        "invalid": "O código inserido está incorreto."
      }
    },
    "info": {
      "willSendVerificationCode": "Enviaremos o código de verificação para o endereço de e-mail que você informar.",
      "masterUser": "Por ter feito o login usando um master user não se enviará nenhum código de verificação 😎",
      "checkFolders": "Se não encontrar o e-mail com o código de verificação na sua caixa de entrada, verifique a pasta de notificações, promoções ou spam."
    },
    "resend": "Reenviar o código de verificação",
    "troubles": "Está com problemas para verificar seu remetente?",
    "contactSupport": "Contate nossa equipe de suporte",
    "helpMessage": "Oi! Preciso de ajuda para verificar o remetente {email}",
    "notify": {
      "emailSent": {
        "title": "Código de verificação enviado",
        "text": "Revise a caixa de entrada de {email}."
      },
      "senderCreated": {
        "title": "Remetente verificado",
        "text": ""
      }
    }
  }
}

</i18n>
