<template>
  <ModalDialog
    without-overflow
    :open="open && !loading"
    :title="selectingEditor ? t('editorType.title') : t('header.title')"
    :help-id="!selectingEditor ? '6840772' : undefined"
    @close="closeModal"
  >
    <transition
      enter-active-class="duration-100 ease-out"
      leave-active-class="duration-100 ease-in"
      enter-to-class="transform translate-x-0 opacity-100"
      leave-from-class="transform translate-x-0 opacity-100"
      :enter-from-class="'transform opacity-0 ' + (selectingEditor ? 'translate-x-20' : '-translate-x-20')"
      :leave-to-class="'transform opacity-0 ' + (selectingEditor ? '-translate-x-20' : 'translate-x-20')"
      mode="out-in"
    >
      <div v-if="!selectingEditor">
        <div class="mt-4 grid grid-cols-1 gap-4 md:grid-cols-3">
          <GridCardIcon
            :title="t('campaignType.simpleCampaign.title')"
            :description="t('campaignType.simpleCampaign.description')"
            :icon="MailIcon"
            @click="selectEditorModal('simple')"
          />
          <div class="group">
            <GridCardIcon
              :title="t('campaignType.testABCampaign.title')"
              :description="t('campaignType.testABCampaign.description')"
              :icon="BeakerIcon"
              :disabled="limitFeatures"
              @click="selectEditorModal('splitab')"
            />
            <div
              v-if="limitFeatures"
              class="invisible absolute -ml-1 -mt-6 transition duration-150 ease-in-out group-hover:visible group-hover:-translate-y-1 group-hover:scale-105"
            >
              <PlanUpgradePopover v-if="showUpgradePopover" ref="PlanUpgradePopoverRef" feature="campaigns" />
            </div>
          </div>

          <div class="group">
            <GridCardIcon
              :title="t('campaignType.smartCampaign.title')"
              :description="t('campaignType.smartCampaign.description')"
              :icon="CalendarIcon"
              :disabled="limitFeatures"
              @click="selectEditorModal('smart')"
            />
            <div
              v-if="limitFeatures"
              class="invisible absolute -ml-1 -mt-6 transition duration-150 ease-in-out group-hover:visible group-hover:-translate-y-1 group-hover:scale-105"
            >
              <PlanUpgradePopover v-if="showUpgradePopover" ref="PlanUpgradePopoverRef" feature="campaigns" />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="grid max-w-4xl grid-cols-2 gap-6">
          <div class="flex flex-col justify-between">
            <div>
              <div class="flex items-center gap-2">
                <span class="mr-2 text-base font-medium text-sky-400">{{ t("editorType.unlayer.title") }} </span>
                <SimpleBadge dot theme="green">
                  {{ t("editorType.unlayer.badge") }}
                </SimpleBadge>
              </div>
              <p class="mt-2 text-sm text-gray-500">{{ t("editorType.unlayer.description") }}</p>
              <div class="mt-4">
                <IconBullet
                  v-for="bullet in bullets"
                  :key="bullet.text"
                  class="mt-1"
                  :icon="InformationCircleIcon"
                  :title="bullet.text"
                  :tooltip="bullet.tooltip"
                  color="green"
                />
                <a href="" class="mt-1 inline-flex" @click.prevent="openArticle('6827777')">
                  <span class="group inline-flex content-center gap-1.5">
                    <InformationCircleIcon class="h-5 w-5 self-center text-sky-500" aria-hidden="true" />
                    <span class="text-sm text-sky-400 group-hover:text-sky-500">
                      {{ t("editorType.unlayer.bullets.seeMore") }}
                    </span>
                  </span>
                </a>
              </div>
            </div>
            <div class="pt-4">
              <FormCheckBox
                v-if="false"
                v-model="setDefault"
                class="mt-2"
                :label="t('editorType.unlayer.checkbox')"
                :disabled="false"
              />
              <SimpleButton class="mt-4" theme="primary" @click="useUnlayer()">
                <template #leading>
                  <CheckIcon class="h-5 w-5 text-white" />
                </template>
                {{ t("editorType.unlayer.selectEditor") }}
              </SimpleButton>
            </div>
          </div>
          <div class="flex flex-col justify-between">
            <div>
              <span class="mr-2 text-base font-medium text-sky-400">{{ t("editorType.eddie.title") }} </span>
              <p class="mt-2 text-sm text-gray-500">{{ t("editorType.eddie.description") }}</p>
              <AlertBox theme="warning" class="mt-6">
                <p>
                  <strong>{{ t("editorType.eddie.alertBoxSecondDescription") }}</strong>
                </p>
                <br />
                <p>{{ t("editorType.eddie.alertBoxFirstDescription") }}</p>
                <template #actions>
                  {{ t("editorType.eddie.docText") }}
                  <a href="" @click.prevent="openArticle('6922138')"> {{ t("editorType.eddie.docLink") }}</a
                  >.
                </template>
              </AlertBox>
            </div>
            <div>
              <SimpleButton class="mt-6" theme="white" @click="useEddie()">
                <!-- <template #leading>
                  <CheckIcon class="h-5 w-5 text-white" />
                </template> -->
                {{ t("editorType.eddie.selectEditor") }}
              </SimpleButton>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";

// Components
import SimpleBadge from "@atoms/SimpleBadge/SimpleBadge.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import AlertBox from "@atoms/AlertBox.vue";
import ModalDialog from "@molecules/ModalDialog.vue";
import GridCardIcon from "@molecules/GridCardIcon.vue";
import IconBullet from "@atoms/IconBullet.vue";
import FormCheckBox from "@molecules/FormCheckBox.vue";
import PlanUpgradePopover from "@molecules/PlanUpgradePopover.vue";

//API
import { useAccountFeatures } from "@api/modules/account";
import { useAccountPlan } from "@api/modules/accountplan";
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// Icons
import { BeakerIcon, CheckIcon, MailIcon, CalendarIcon } from "@heroicons/vue/solid";
import { InformationCircleIcon } from "@heroicons/vue/outline";

// Types
type CampaignType = "simple" | "splitab" | "smart";
interface CreateType {
  campaignType: CampaignType;
  useEddie?: boolean;
}
interface Bullet {
  text: string;
  tooltip?: string;
}

const trackingEventsService = useTrackingEvents();

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

// States
const { t } = useI18n();
const selectingEditor = ref(false);
const campaignType = ref<CampaignType>();
const setDefault = ref(false);
const open = ref(false);
const selectEditor = ref(false);
const defaultEddie = ref(false);
const limitFeatures = ref(false);
const showUpgradePopover = ref(false);
const loading = ref(false);

const AccountFeatures = useAccountFeatures();
const AccountPlan = useAccountPlan();

//Consts
const bullets: Array<Bullet> = [
  {
    text: t("editorType.unlayer.bullets.feature1"),
    tooltip: t("editorType.unlayer.bullets.tooltip1"),
  },
  {
    text: t("editorType.unlayer.bullets.feature2"),
    tooltip: t("editorType.unlayer.bullets.tooltip2"),
  },
  {
    text: t("editorType.unlayer.bullets.feature3"),
    tooltip: t("editorType.unlayer.bullets.tooltip3"),
  },
  {
    text: t("editorType.unlayer.bullets.feature4"),
    tooltip: t("editorType.unlayer.bullets.tooltip4"),
  },
  {
    text: t("editorType.unlayer.bullets.feature5"),
    tooltip: t("editorType.unlayer.bullets.tooltip5"),
  },
  {
    text: t("editorType.unlayer.bullets.feature6"),
    tooltip: t("editorType.unlayer.bullets.tooltip6"),
  },
];

// Emits
const emit = defineEmits<{
  (e: "create", createType: CreateType): void;
}>();

// Methods
const openModal = () => {
  selectingEditor.value = false;
  open.value = true;
};

const closeModal = () => {
  open.value = false;
};

const selectEditorModal = (campaign: CampaignType) => {
  if (!selectEditor.value) {
    emit("create", { campaignType: campaign, useEddie: defaultEddie.value });
    closeModal();
    return;
  }
  selectingEditor.value = true;
  campaignType.value = campaign;
  return;
};

const useUnlayer = async () => {
  trackingEventsService.dispatchAll({ name: "APP_CAMPAIGN_SELECT_UNLAYER" });
  if (!campaignType.value) return;
  emit("create", { campaignType: campaignType.value, useEddie: false });
  if (setDefault.value) {
    await AccountFeatures.put({ key: "UNLAYER_DEFAULT", value: "1" });
  }
  closeModal();
};

const useEddie = () => {
  trackingEventsService.dispatchAll({ name: "APP_CAMPAIGN_SELECT_EDDIE" });
  if (!campaignType.value) return;
  emit("create", { campaignType: campaignType.value, useEddie: true });
  closeModal();
};

const openArticle = (id: string) => {
  window.Intercom("showArticle", id);
};

//Lifecycle
onMounted(async () => {
  loading.value = true;
  const features = await AccountFeatures.get();

  if (features.UNLAYER_DEFAULT === "1") {
    //uso siempre unlayer
    selectEditor.value = false;
  } else if (features.UNLAYER_OPTIONAL === "1") {
    //puedo elegir unlayer o eddie
    selectEditor.value = true;
  } else {
    //uso siempre eddie
    selectEditor.value = false;
    defaultEddie.value = true;
  }

  const accountPlan = await AccountPlan.get();
  const isMasterUser = session.value?.isMasterUser;
  const isFree = accountPlan.type === "FREE";
  const inTrial = !!accountPlan.trialEnds;

  limitFeatures.value = isFree && !inTrial && !isMasterUser;
  // limitFeatures.value = true;
  showUpgradePopover.value = features.UNLAYER_DEFAULT === "1" || features.UNLAYER_OPTIONAL === "1";
  loading.value = false;
});

//Expose
defineExpose({
  openModal,
  closeModal,
});
</script>

<i18n lang="json">
{
  "es": {
    "header": {
      "title": "Selecciona un tipo de campaña"
    },
    "campaignType": {
      "simpleCampaign": {
        "title": "Campaña simple",
        "description": "Envía una comunicación masiva a tus contactos. Puedes hacer un reenvío en forma automática luego de un tiempo a quienes no hayan abierto o hecho click."
      },
      "testABCampaign": {
        "title": "Campaña test A/B",
        "description": "Define hasta cuatro variantes de asuntos y analiza cuál obtiene los mejores resultados. Cada contacto recibirá una de las variantes de asunto en forma aleatoria."
      },
      "smartCampaign": {
        "title": "Campaña inteligente",
        "description": "Selecciona un periodo de tiempo para enviarle a cada contacto en el día y horario que suele tener mayor actividad, aumentando la posibilidad de aperturas."
      }
    },
    "editorType": {
      "title": "¿Con cuál editor de plantillas deseas trabajar?",
      "unlayer": {
        "title": "Nuevo editor de plantillas",
        "badge": "Recomendado",
        "description": "Crea tu campaña usando el nuevo editor y accede a múltiples mejoras en la edición de tus emails.",
        "bullets": {
          "feature1": "Edición drag & drop para crear tus plantillas.",
          "tooltip1": "Incorpora nuevos bloques y elementos, o cambia su ubicación arrastrándolos y soltándolos.",
          "feature2": "Inserción mejorada de productos desde tu tienda.",
          "tooltip2": "Ahora es mucho más fácil buscar tus productos, seleccionar entre las distintas variantes e imágenes, aplicar estilos y contar con más opciones para organizar la información. ",
          "feature3": "Personalización y vista previa en formato mobile.",
          "tooltip3": "Edita tu diseño en modo desktop o mobile, con estilos diferenciados, y controlando la visibilidad de los bloques y elementos según cada dispositivo.",
          "feature4": "Almacenamiento de bloques reutilizables.",
          "tooltip4": "Guarda tus propios bloques para utilizarlos fácilmente en otras plantillas.",
          "feature5": "Edición de imágenes: recortes, filtros y más!",
          "tooltip5": "Personaliza tus imágenes con filtros, marcos, stickers, acción de rotar, recortar, redimensionar, y mucho más.",
          "feature6": "Historial de versiones y autoguardado.",
          "tooltip6": "Nunca más perderás tus cambios, y también podrás recuperar a una versión guardada previamente.",
          "seeMore": "Conoce todas las mejoras del nuevo editor."
        },
        "checkbox": "Usar siempre como predeterminado.",
        "selectEditor": "Usar el nuevo editor"
      },
      "eddie": {
        "title": "Editor de plantillas antiguo",
        "description": "Diseña tu email bloque por bloque, eligiendo de una variedad reducida de regiones predefinidas.",
        "alertBoxFirstDescription": "Te recomendamos que empieces a conocer el nuevo editor para aprovechar todas las mejoras y nuevas herramientas.",
        "alertBoxSecondDescription": "Este editor dejará de estar disponible durante marzo 2025.",
        "docText": "Conoce todos los detalles en",
        "docLink": "esta guía",
        "selectEditor": "Usar el editor antiguo"
      }
    }
  },
  "pt": {
    "header": {
      "title": "Selecione um tipo de campanha"
    },
    "campaignType": {
      "simpleCampaign": {
        "title": "Campanha simples",
        "description": "Envie uma comunicação massiva para seus contatos. Você pode reenviar automaticamente depois de um tempo para aqueles que não abriram ou não clicaram."
      },
      "testABCampaign": {
        "title": "Campanha teste A/B",
        "description": "Defina até quatro variantes de assuntos e analise qual delas obtém os melhores resultados. Cada contato receberá uma das variantes de assunto de forma aleatória."
      },
      "smartCampaign": {
        "title": "Campanha inteligente",
        "description": "Selecione um intervalo de horas para enviar a cada contato no dia e hora que normalmente tem mais atividade, aumentando a possibilidade de aberturas."
      }
    },
    "editorType": {
      "title": "Com qual editor de template você deseja trabalhar?",
      "unlayer": {
        "title": "Novo editor de templates",
        "badge": "Recomendado",
        "description": "Crie sua campanha usando o novo editor e acesse a diversas melhorias na edição de seus emails.",
        "bullets": {
          "feature1": "Edição drag & drop para criar seus templates.",
          "tooltip1": "Incorpore novos blocos e elementos ou mude sua localização arrastando e soltando.",
          "feature2": "Inclusão de produtos melhorada desde sua loja.",
          "tooltip2": "Agora é muito mais fácil procurar seus produtos, selecionar entre as diferentes variantes e imagens, aplicar estilos e contar com mais opções para organizar as informações.",
          "feature3": "Personalização e pré-visualização em formato mobile.",
          "tooltip3": "Edite seu template em modo desktop ou mobile com diversos estilos, controlando a visualização dos blocos e elementos segundo cada dispositivo.",
          "feature4": "Armazenamento de blocos reutilizáveis.",
          "tooltip4": "Salve seus próprios blocos para utilizá-los facilmente em outros templates.",
          "feature5": "Edição de imagens: recortes, filtros e mais!",
          "tooltip5": "Personalize suas imagens com filtros, armações, stickers, ação de girar, recortar, redimensionar e muito mais.",
          "feature6": "Histórico de versões e salvamento automático.",
          "tooltip6": "Nunca mais perderá suas modificações e também poderá recuperar uma versão salva anteriormente.",
          "seeMore": "Conheça todas as melhorias do novo editor."
        },
        "checkbox": "Usar sempre como predeterminado.",
        "selectEditor": "Usar o novo editor"
      },
      "eddie": {
        "title": "Editor de templates antigo",
        "description": "Desenhe seu email bloco por bloco, escolhendo de uma variedade reduzida de regiões predefinidas.",
        "alertBoxFirstDescription": "Recomendamos que você comece a conhecer o novo editor para aproveitar todas as melhorias e novas ferramentas.",
        "alertBoxSecondDescription": "Este editor deixará de estar disponível a partir do dia 1º de março de 2025.",
        "docText": "Saiba todos os detalhes em",
        "docLink": "este guia",
        "selectEditor": "Usar o editor antigo"
      }
    }
  }
}
</i18n>
