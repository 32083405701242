<template>
  <div
    v-show="alertStore.message"
    :class="[
      {
        'bg-sky-50': message?.messageType === 'info',
        'bg-yellow-50': message?.messageType === 'warning',
        'bg-red-50': message?.messageType === 'blocked',
      },
      'fixed top-0 z-50 flex h-12 w-full space-x-4 overflow-hidden pl-[5.8rem] pr-8 text-center shadow',
    ]"
  >
    <div class="flex flex-auto items-center justify-center space-x-2">
      <component
        :is="message?.icon"
        :class="[
          {
            'text-sky-400': message?.messageType === 'info',
            'text-yellow-400': message?.messageType === 'warning',
            'text-red-400': message?.messageType === 'blocked',
          },
          'h-6 w-6 shrink-0 rounded-md',
        ]"
      />

      <p
        :class="[
          {
            'text-sky-700': message?.messageType === 'info',
            'text-yellow-700': message?.messageType === 'warning',
            'text-red-700': message?.messageType === 'blocked',
          },
          'rounded text-sm font-medium',
        ]"
      >
        <i18n-t :keypath="`alertType.${message?.type}`">
          <template #first>
            <span class="font-bold">
              {{
                t(
                  `alertType.${message?.type}_FIRST`,
                  {
                    days: trialDays,
                  },
                  {
                    plural: trialDays,
                  },
                )
              }}
            </span>
          </template>
          <template #help>
            <a
              target="_blank"
              @click="Intercom.openArticle(t(`alertType.${message?.type}_HELP_ID`))"
              class="text-inherit underline hover:text-inherit hover:underline focus:text-inherit focus:underline active:text-inherit active:underline active:focus:text-inherit active:focus:underline"
            >
              {{ t(`alertType.${message?.type}_HELP`) }}
            </a>
          </template>
          <template #payload>
            <span class="font-semibold">
              {{ message?.payload }}
            </span>
          </template>
        </i18n-t>
      </p>
    </div>
    <div class="flex flex-none items-center space-x-4 py-1">
      <SimpleButton
        v-if="message?.buttonText"
        :theme="getButtonTheme(message?.messageType)"
        size="small"
        @click="() => actionHandler(message?.type)"
        class="my-auto py-1 font-medium"
      >
        {{ message?.buttonText }}
      </SimpleButton>
      <button
        v-if="alertStore.message?.dismissable"
        :class="[
          {
            'bg-sky-50 text-sky-700 hover:text-sky-800': message?.messageType === 'info',
            'bg-yellow-50 text-yellow-700 hover:text-yellow-800': message?.messageType === 'warning',
            'bg-red-50 text-red-700 hover:text-red-800': message?.messageType === 'blocked',
          },
        ]"
        @click="() => dismissAlert(alertStore.message?.type)"
      >
        <XIcon class="h-6 w-6" />
      </button>
    </div>
    <PlanUpgradeLockedAccountsModal :open="showPlanUpgradeModal" @close="closePlanUpgradeModal" />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";

// Components
import SimpleButton from "@atoms/SimpleButton.vue";
import PlanUpgradeLockedAccountsModal from "@organisms/Plan/PlanUpgradeLockedAccounts/PlanUpgradeLockedAccountsModal.vue";

// Icon
import { XIcon } from "@heroicons/vue/outline";
import { ExclamationCircleIcon, ClockIcon, BanIcon, CreditCardIcon } from "@heroicons/vue/solid";

// Store
import { useAlertStore, useSessionStore } from "@store";

// Application
import { useRouterApp, useLocalStorageApp } from "@application";
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// I18n
import { useI18n } from "vue-i18n";

// Utils
import Intercom from "@helpers/intercom";
import { differenceInDays } from "date-fns";

// Domain
import type { AlertTypes } from "@domain/statusAlert";
import { isDismissableAlertType } from "@domain/statusAlert";
import type { Message } from "./statusBar.types";

const { t } = useI18n();

const alertStore = useAlertStore();
const sessionStore = useSessionStore();
const localStorage = useLocalStorageApp();
const trackingEventsService = useTrackingEvents();

const routerApp = useRouterApp();

const hasPlan = computed(() => sessionStore.plan?.subscription !== undefined);

const dismissAlert = (type: AlertTypes | undefined) => {
  if (!type) return;

  if (isDismissableAlertType(type)) {
    alertStore.dismissMessage(type);
  }
};

const getMessageType = (type: AlertTypes): "warning" | "info" | "blocked" => {
  const warning = [
    "BLOCKED_CONTACTS_EXCEEDED",
    "BLOCKED_CONTACTS_EXCEEDED_FREE",
    "BLOCKED_CONTACTS_EXCEEDED_DEMO_FREE",
    "PAYMENT_CARD_REQUIRED",
    "PAYMENT_CARD_EXPIRED",
    "BILLING_INFO_REQUIRED",
    "SCHEDULED_CANCEL_PLAN",
  ];
  const blocked = [
    "BLOCKED_ACCOUNT_DISABLED",
    "BLOCKED_EXPIRED_INVOICES",
    "BLOCKED_ABUSE",
    "ALL_SENDERS_STATUS_NOT_OK",
  ];

  if (warning.includes(type)) return "warning";
  if (blocked.includes(type)) return "blocked";

  return "info";
};

const getIcon = (type: AlertTypes) => {
  const clockTypes: AlertTypes[] = ["TRIAL_ACTIVE", "TRIAL_EXPIRED"];
  const banTypes: AlertTypes[] = ["BLOCKED_ACCOUNT_DISABLED", "BLOCKED_ABUSE"];
  const creditCardTypes: AlertTypes[] = ["BLOCKED_EXPIRED_INVOICES", "PAYMENT_CARD_REQUIRED", "PAYMENT_CARD_EXPIRED"];

  if (clockTypes.includes(type)) return ClockIcon;
  if (banTypes.includes(type)) return BanIcon;
  if (creditCardTypes.includes(type)) return CreditCardIcon;

  return ExclamationCircleIcon;
};

const getButtonTheme = (messageType?: "info" | "warning" | "blocked"): "primary" | "yellow" | "danger" => {
  if (messageType === "blocked") return "danger";
  if (messageType === "warning") return "yellow";
  return "primary";
};

const trialDays = computed(() => {
  if (message.value?.type !== "TRIAL_ACTIVE" || !sessionStore.plan?.trialEnds) return undefined;
  const days = differenceInDays(new Date(sessionStore.plan?.trialEnds), new Date());

  return days;
});

const message = computed<Message | undefined>(() => {
  if (!alertStore.message || !alertStore.message?.type) return;
  const noActionMessages = ["BLOCKED_ACCOUNT_DISABLED"];

  if (alertStore.message.type === "TRIAL_ACTIVE" && sessionStore.plan?.trialEnds) {
    return {
      icon: getIcon(alertStore.message.type),
      messageType: getMessageType(alertStore.message.type),
      dismissable: alertStore.message.dismissable,
      type: alertStore.message.type,
      text: t(`alertType.${alertStore.message.type}`),
      buttonText: noActionMessages.includes(alertStore.message.type)
        ? undefined
        : t(`alertTypeButton.${alertStore.message.type}`),
    };
  }

  return {
    icon: getIcon(alertStore.message.type),
    messageType: getMessageType(alertStore.message.type),
    dismissable: alertStore.message.dismissable,
    type: alertStore.message.type,
    text: t(`alertType.${alertStore.message.type}`),
    buttonText: noActionMessages.includes(alertStore.message.type)
      ? undefined
      : t(`alertTypeButton.${alertStore.message.type}`),
    payload: alertStore.message.payload,
  };
});

const showPlanUpgradeModal = ref(false);
const openPlanUpgradeModal = () => {
  showPlanUpgradeModal.value = true;
};
const closePlanUpgradeModal = () => {
  showPlanUpgradeModal.value = false;
};

const actionHandler = (type: AlertTypes | undefined) => {
  if (!type) return;

  const goToPlan: AlertTypes[] = [
    "BLOCKED_EXPIRED_INVOICES",
    "PAYMENT_CARD_REQUIRED",
    "PAYMENT_CARD_EXPIRED",
    "BILLING_INFO_REQUIRED",
    "INVOICE_ABOUT_TO_EXPIRE",
    "SCHEDULED_CANCEL_PLAN",
  ];
  const goToPlanBuy: AlertTypes[] = [
    "BLOCKED_CONTACTS_EXCEEDED_DEMO_FREE",
    "BLOCKED_CONTACTS_EXCEEDED_FREE",
    "TRIAL_ACTIVE",
    "TRIAL_EXPIRED",
  ];
  const goToSettings: AlertTypes[] = ["ALL_SENDERS_STATUS_NOT_OK"];

  if (type === "BLOCKED_CONTACTS_EXCEEDED_FREE") {
    const trialRequested = localStorage.get<boolean>({
      id: "APP_BANNER_TRIAL_REQUEST",
    });

    if (!trialRequested) {
      trackingEventsService.amplitude({
        name: "APP_BANNER_TRIAL_REQUEST",
      });
      localStorage.save<boolean>({
        id: "APP_BANNER_TRIAL_REQUEST",
        value: true,
      });
    }

    Intercom.showNewMessage(t(`intercomMessages.BLOCKED_CONTACTS_EXCEEDED_FREE`));
    return;
  }

  if (type === "BLOCKED_ABUSE") {
    Intercom.showNewMessage(t(`intercomMessages.BLOCKED_ABUSE`));
    return;
  }

  if (type === "FREE_ACCOUNT") {
    Intercom.showNewMessage(t(`intercomMessages.FREE_ACCOUNT`));
    return;
  }

  if (type === "BLOCKED_CONTACTS_EXCEEDED" && hasPlan.value) {
    // openPlanUpgradeModal();
    routerApp.navigate({
      path: "/plan?product_tour_id=306625",
    });
    return;
  }
  if (type === "BLOCKED_CONTACTS_EXCEEDED" && !hasPlan.value) {
    routerApp.navigate({
      path: "/plan?product_tour_id=306625",
    });
    return;
  }

  if (goToSettings.includes(type)) {
    routerApp.navigate({
      path: "/settings",
    });
    return;
  }

  if (goToPlanBuy.includes(type)) {
    routerApp.navigate({
      path: "/plan?product_tour_id=306625",
    });
    return;
  }

  if (goToPlan.includes(type)) {
    routerApp.navigate({
      path: "/plan",
    });
    return;
  }
};

onMounted(async () => {
  await alertStore.update();
});
</script>

<i18n lang="json">
{
  "es": {
    "alertType": {
      "BLOCKED_CONTACTS_EXCEEDED_FIRST": "¡Tu cuenta está creciendo!",
      "BLOCKED_CONTACTS_EXCEEDED": "{first} Superaste el límite de contactos. Puedes aumentar tu plan para seguir enviando. 🚀",
      "BLOCKED_CONTACTS_EXCEEDED_DEMO_FREE_FIRST": "El límite de contactos del plan gratuito fue superado.",
      "BLOCKED_CONTACTS_EXCEEDED_DEMO_FREE": "{first} Para seguir enviando puedes contratar un plan.",
      "BLOCKED_CONTACTS_EXCEEDED_FREE_FIRST": "El límite de contactos del plan gratuito fue superado.",
      "BLOCKED_CONTACTS_EXCEEDED_FREE": "{first} Escríbenos para solicitar una prueba gratuita 👨‍💻",
      "BLOCKED_EXPIRED_INVOICES_FIRST": "Existen pagos pendientes.",
      "BLOCKED_EXPIRED_INVOICES": "{first} Revisa el historial de facturación para continuar enviando.",
      "BLOCKED_ACCOUNT_DISABLED_FIRST": "Cuenta deshabilitada.",
      "BLOCKED_ACCOUNT_DISABLED": "{first} Ponte en contacto con el responsable de la cuenta o con el equipo de soporte.",
      "BLOCKED_ABUSE_FIRST": "Los envios están temporalmente suspendidos.",
      "BLOCKED_ABUSE": "{first} Contáctanos para conocer como proceder.",
      "TRIAL_EXPIRED_FIRST": "Tu período de prueba ha finalizado.",
      "TRIAL_EXPIRED": "{first} Para seguir enviando, debes contratar un plan.",
      "TRIAL_ACTIVE_FIRST": "Resta 1 día para finalizar el período de prueba. | Restan {days} días para finalizar el período de prueba.",
      "TRIAL_ACTIVE": "{first} Luego puedes contratar un plan para continuar enviando.",
      "PAYMENT_CARD_REQUIRED_FIRST": "No contamos con un medio de pago configurado.",
      "PAYMENT_CARD_REQUIRED": "{first} Evita la suspensión del servicio ingresando una tarjeta de crédito.",
      "PAYMENT_CARD_EXPIRED_FIRST": "Tu tarjeta de crédito ha vencido.",
      "PAYMENT_CARD_EXPIRED": "{first} Para evitar la suspensión del servicio configura una nueva tarjeta de crédito.",
      "BILLING_INFO_REQUIRED_FIRST": "Información de facturación.",
      "BILLING_INFO_REQUIRED": "{first} Necesitamos relevar algunos datos sobre tu compañia.",
      "FREE_ACCOUNT_FIRST": "Cuenta Gratuita",
      "FREE_ACCOUNT": "{first}",
      "INVOICE_ABOUT_TO_EXPIRE_FIRST": "Pagos pendientes.",
      "INVOICE_ABOUT_TO_EXPIRE": "{first} Existen vencimientos dentro de los próximos 5 días. Evita el bloqueo de tus envíos haciendo un pago anticipado.",
      // "ALL_SENDERS_STATUS_NOT_OK": "Es importante contar con un remitente de dominio propio y correctamente autenticado."
      "ALL_SENDERS_STATUS_NOT_OK_FIRST": "Acción necesaria:",
      "ALL_SENDERS_STATUS_NOT_OK": "Registra un remitente: Para hacer envíos debes contar con un remitente registrado y verificado. {help}",
      "ALL_SENDERS_STATUS_NOT_OK_HELP": "¿Por qué?",
      "ALL_SENDERS_STATUS_NOT_OK_HELP_ID": "8719023",
      "SCHEDULED_CANCEL_PLAN_FIRST": "Cancelación de suscripción programada.",
      "SCHEDULED_CANCEL_PLAN": "{first} Tu plan está vigente hasta el día {payload}. Puedes continuar usando tu cuenta hasta esta fecha."
    },
    "alertTypeButton": {
      "BLOCKED_CONTACTS_EXCEEDED": "Aumentar plan",
      "BLOCKED_CONTACTS_EXCEEDED_FREE": "Solicitar prueba",
      "BLOCKED_CONTACTS_EXCEEDED_DEMO_FREE": "Aumentar plan",
      "BLOCKED_EXPIRED_INVOICES": "Ver pagos pendientes",
      "BLOCKED_ABUSE": "Hablar con soporte",
      "TRIAL_EXPIRED": "Contratar plan",
      "TRIAL_ACTIVE": "Contratar plan",
      "PAYMENT_CARD_REQUIRED": "Configurar tarjeta",
      "PAYMENT_CARD_EXPIRED": "Configurar tarjeta",
      "BILLING_INFO_REQUIRED": "Completar datos",
      "FREE_ACCOUNT": "Solicitar demo",
      "ALL_SENDERS_STATUS_NOT_OK": "Registrar",
      "INVOICE_ABOUT_TO_EXPIRE": "Ver pagos pendientes",
      "SCHEDULED_CANCEL_PLAN": "Configurar plan"
    },
    "intercomMessages": {
      "BLOCKED_CONTACTS_EXCEEDED_FREE": "Hola! Quisiera coordinar una demo con un especialista y una prueba gratuita de todas las funcionalidades que ofrece Perfit.",
      "FREE_ACCOUNT": "Hola! Quisiera coordinar una demo con un especialista y una prueba gratuita de todas las funcionalidades que ofrece Perfit.",
      "BLOCKED_ABUSE": "Hola! Quiero más información sobre el proceso de auditoria en mi cuenta."
    }
  },
  "pt": {
    "alertType": {
      "BLOCKED_CONTACTS_EXCEEDED_FIRST": "Sua conta está crescendo!",
      "BLOCKED_CONTACTS_EXCEEDED": "{first} O seu limite de contatos foi ultrapassado. Você precisa atualizar seu plano para continuar enviando 🚀",
      "BLOCKED_CONTACTS_EXCEEDED_DEMO_FREE_FIRST": "Você excedeu o limite de contatos.",
      "BLOCKED_CONTACTS_EXCEEDED_DEMO_FREE": "{first} Para continuar enviando, você precisa contratar um plano.",
      "BLOCKED_CONTACTS_EXCEEDED_FREE_FIRST": "Você excedeu o limite de contatos.",
      "BLOCKED_CONTACTS_EXCEEDED_FREE": "{first} Entre em contato conosco para solicitar seu teste gratuito 👨‍💻",
      "BLOCKED_EXPIRED_INVOICES_FIRST": "Existem pagamentos pendentes.",
      "BLOCKED_EXPIRED_INVOICES": "{first} Verifique o histórico de faturamento para continuar enviando.",
      "BLOCKED_ACCOUNT_DISABLED_FIRST": "Conta desabilitada.",
      "BLOCKED_ACCOUNT_DISABLED": "{first} Entre em contato com o responsável pela conta ou com a equipe de suporte.",
      "BLOCKED_ABUSE_FIRST": "Os envios estão temporariamente suspensos.",
      "BLOCKED_ABUSE": "{first} Entre em contato conosco para saber como proceder.",
      "TRIAL_EXPIRED_FIRST": "Seu período de teste expirou.",
      "TRIAL_EXPIRED": "{first} Para continuar enviando, você precisa contratar um plano.",
      "TRIAL_ACTIVE_FIRST": "Resta 1 dia para finalizar o período de teste. | Restam {days} dias para finalizar o período de teste.",
      "TRIAL_ACTIVE": "{first} Depois disso, você pode contratar um plano para continuar enviando.",
      "PAYMENT_CARD_REQUIRED_FIRST": "Não temos um meio de pagamento configurado.",
      "PAYMENT_CARD_REQUIRED": "{first} Evite a suspensão do serviço inserindo um cartão de crédito.",
      "PAYMENT_CARD_EXPIRED_FIRST": "Seu cartão de crédito expirou.",
      "PAYMENT_CARD_EXPIRED": "{first} Para evitar a suspensão do serviço, configure um novo cartão de crédito.",
      "BILLING_INFO_REQUIRED_FIRST": "Informações de faturamento.",
      "BILLING_INFO_REQUIRED": "{first} Precisamos alguns dados sobre a sua empresa.",
      "FREE_ACCOUNT_FIRST": "Cuenta Gratuita",
      "FREE_ACCOUNT": "{first}",
      "INVOICE_ABOUT_TO_EXPIRE_FIRST": "Pagamentos pendentes.",
      "INVOICE_ABOUT_TO_EXPIRE": "{first} Existem vencimentos nos próximos 5 dias. Evite o bloqueio de seus envios fazendo um pagamento antecipado.",
      "ALL_SENDERS_STATUS_NOT_OK_FIRST": "Ação necessária:",
      "ALL_SENDERS_STATUS_NOT_OK": "Registra um remetente: Você deve registrar e verificar um remetente com domínio próprio para realizar envios. {help}",
      "ALL_SENDERS_STATUS_NOT_OK_HELP": "Veja o motivo",
      "ALL_SENDERS_STATUS_NOT_OK_HELP_ID": "8719023",
      "SCHEDULED_CANCEL_PLAN_FIRST": "Cancelamento de assinatura agendado.",
      "SCHEDULED_CANCEL_PLAN": "{first} Seu plano está vigente até o dia {payload}. Você pode continuar usando sua conta até essa data."
    },
    "alertTypeButton": {
      "BLOCKED_CONTACTS_EXCEEDED": "Aumentar plano",
      "BLOCKED_CONTACTS_EXCEEDED_FREE": "Pedir teste",
      "BLOCKED_CONTACTS_EXCEEDED_DEMO_FREE": "Aumentar plano",
      "BLOCKED_EXPIRED_INVOICES": "Ver pagamentos pendientes",
      "BLOCKED_ABUSE": "Falar com suporte",
      "TRIAL_EXPIRED": "Contratar plano",
      "TRIAL_ACTIVE": "Contratar plano",
      "PAYMENT_CARD_REQUIRED": "Configurar cartão",
      "PAYMENT_CARD_EXPIRED": "Configurar cartão",
      "BILLING_INFO_REQUIRED": "Completar dados",
      "FREE_ACCOUNT": "Solicitar demo",
      "ALL_SENDERS_STATUS_NOT_OK": "Registrar",
      "INVOICE_ABOUT_TO_EXPIRE": "Ver pagamentos pendientes",
      "SCHEDULED_CANCEL_PLAN": "Configurar plano"
    },
    "intercomMessages": {
      "BLOCKED_CONTACTS_EXCEEDED_FREE": "Olá, gostaria de agendar uma demonstração com um especialista e experimentar gratuitamente todos os recursos que a Perfit tem a oferecer.",
      "FREE_ACCOUNT": "Olá, gostaria de agendar uma demonstração com um especialista e experimentar gratuitamente todos os recursos que a Perfit tem a oferecer.",
      "BLOCKED_ABUSE": "Olá! Gostaria de mais informações sobre o processo de auditoria em minha conta."
    }
  }
}
</i18n>
