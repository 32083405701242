<template>
  <ModalDialog without-overflow show-close-button :title="t('title')" :open="open" @close="close">
    <div class="w-[30rem]">
      <FormInput
        v-model="campaignName"
        v-show="!hasEddieTemplate || !duplicateDisabled"
        :label="t('label')"
        :placeholder="t('placeholder')"
        :error="nameLengthError ? t('nameLengthError') : undefined"
      />
      <AlertBox v-if="hasEddieTemplate" theme="warning" class="mt-4">
        <span class="font-bold">{{ duplicateDisabled ? t("cantDuplicate.title") : t("canDuplicate.title") }}</span>
        <div>
          {{ duplicateDisabled ? t("cantDuplicate.description") : t("canDuplicate.description") }}
        </div>
      </AlertBox>
    </div>
    <template #buttons>
      <SimpleButton
        :loading="loadingAccept"
        :disabled="campaignName.length === 0 || nameLengthError || (duplicateDisabled && hasEddieTemplate)"
        @click="duplicate"
      >
        <template #leading>
          <CheckIcon class="h-5 w-5" aria-hidden="true" />
        </template>
        {{ t("acceptButton") }}
      </SimpleButton>
      <SimpleButton theme="white" @click="close">{{ t("cancelButton") }}</SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { ref, watchEffect, computed, onMounted } from "vue";

// Components
import AlertBox from "@atoms/AlertBox.vue";
import ModalDialog from "@molecules/ModalDialog.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";

// Icon
import { CheckIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";
import { Campaign } from "@/vue/types/campaigns";

// Services
import { useTrackingEvents } from "@composables/trackingevents";
import { useFeaturesStore } from "@store";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    open: boolean;
    loadingAccept: boolean;
    campaign?: Campaign;
    hasEddieTemplate?: boolean;
  }>(),
  {
    hasEddieTemplate: false,
  },
);

// States
const featuresStore = useFeaturesStore();
const trackingEventsService = useTrackingEvents();

// Emits
const emit = defineEmits<{
  "update:open": [boolean];
  duplicate: [string];
}>();

const close = () => {
  emit("update:open", false);
};

const campaignName = ref("");
const nameLengthError = computed(() => {
  return campaignName.value.length > 50;
});

watchEffect(() => {
  campaignName.value = t("textCopy", { name: props.campaign?.name ?? "" });
});

const getEddieFeatures = () => {
  const features = featuresStore.features;

  if (!features) return undefined;

  const eddieFeatures = {
    unlayer_optional: features.UNLAYER_OPTIONAL === "1" ? true : false,
    unlayer_default: features.UNLAYER_DEFAULT === "1" ? true : false,
    hide_legacy_templates: features.HIDE_LEGACY_TEMPLATES === "1" ? true : false,
  };
  return eddieFeatures;
};

const duplicate = async () => {
  if (props.hasEddieTemplate) {
    const eddieFeatures = getEddieFeatures();

    if (eddieFeatures) {
      trackingEventsService.amplitude({
        name: "APP_EDDIE_DUPLICATE_ACCEPT",
        data: {
          source: "campaign",
          id: props.campaign?.id,
          feature_unlayer_optional: eddieFeatures.unlayer_optional,
          feature_unlayer_default: eddieFeatures.unlayer_default,
          feature_hide_legacy_templates: eddieFeatures.hide_legacy_templates,
        },
      });
    }
  }

  emit("duplicate", campaignName.value);
};

const duplicateDisabled = ref(false);

onMounted(async () => {
  // console.log("hasEddieTemplate",props.hasEddieTemplate);
  // if (props.hasEddieTemplate) {
    await featuresStore.fetchFeatures();
    const features = featuresStore.getFeatures();

    duplicateDisabled.value = !(features?.ALLOW_EDDIE_DUPLICATE === "1");
  // }
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Duplicar",
    "label": "Nombre",
    "placeholder": "Nombre de la campaña",
    "cancelButton": "Cancelar",
    "acceptButton": "Duplicar",
    "textCopy": "Copia de {name}",
    "nameLengthError": "El nombre no puede superar los 50 caracteres",
    "cantDuplicate": {
      "title": "No es posible duplicar",
      "description": "Esta campaña utiliza el antiguo editor de emails, el cual dejó de estar disponible. Es necesario crear una nueva campaña utilizando el nuevo editor."
    },
    "canDuplicate": {
      "title": "Recomendamos no duplicar",
      "description": "Esta campaña utiliza el antiguo editor de emails, el cual dejará de estar disponible. Te sugerimos crear una nueva campaña utilizando el nuevo editor para aprovechar todas las mejoras y nuevas herramientas."
    }
  },
  "pt": {
    "title": "Duplicar",
    "label": "Nome",
    "placeholder": "Nome da campanha",
    "cancelButton": "Cancelar",
    "acceptButton": "Duplicar",
    "textCopy": "Cópia de {name}",
    "nameLengthError": "O nome não pode exceder 50 caracteres",
    "cantDuplicate": {
      "title": "Conteúdo não suportado",
      "description": "Esta campanha utiliza o editor antigo de e-mails, que deixou de estar disponível. É necessário criar uma nova campanha utilizando o novo editor."
    },
    "canDuplicate": {
      "title": "Recomendamos no duplicar",
      "description": "Esta campanha utiliza o editor antigo de e-mails, que deixará de estar disponível. É recomendado criar uma nova campanha utilizando o novo editor para aproveitar todas as melhorias e novas ferramentas."
    }
  }
}
</i18n>
