var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='automations section show'>\n  <div class='section-header'>\n    <h1>\n      <a data-action='back'><i class='icon icon-back'></i></a>\n      <span class='edit-inline' data-action=\"edit-inline\" data-field=\"name\" style=\"display: inline;\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n      <input autocomplete=\"off\" maxlength=\"80\" class='edit-inline' data-action=\"edit-inline\" name=\"name\"\n        value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" data-original-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" style=\"display: none;\" required />\n      <small class='lift'>\n        <span class='edit-inline "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"comments") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":33},"end":{"line":10,"column":69}}})) != null ? stack1 : "")
    + "' data-action=\"edit-inline\" data-field=\"comments\"\n          style=\"display: inline;\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"comments") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":11,"column":35},"end":{"line":12,"column":93}}})) != null ? stack1 : "")
    + "</span>\n        <input autocomplete=\"off\" maxlength=\"200\" class='edit-inline' data-action=\"edit-inline\" name=\"comments\"\n          value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"comments") : depth0), depth0))
    + "\" data-original-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"comments") : depth0), depth0))
    + "\" style=\"display: none;\"\n          placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"commentsPlaceholder")), depth0))
    + "\" />\n      </small>\n    </h1>\n  </div>\n  <div class='toolbar edit-mode'>\n\n\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"lifecycles.rfm",{"name":"is","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":30,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/isNot.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"lifecycles.rfm",{"name":"isNot","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":45,"column":14}}})) != null ? stack1 : "")
    + "\n\n    <div class=\"btn-group\">\n      <button type='button' class='btn btn-default' data-action='cancel' data-require-permission='campaigns:create'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n      </button>\n      <button type='button' class='btn btn-default' data-action='duplicate' data-require-permission='campaigns:create'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"duplicate")), depth0))
    + "\n      </button>\n      <button type='button' class='btn btn-danger' data-action='delete' data-class='btn-danger'\n        data-params='{\"id\": \""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"}'\n        data-require-permission='campaigns:delete'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "\n      </button>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isMasterUser")),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":4},"end":{"line":65,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n  <div class='content edit scrollbar'>\n    <div class='padder'>\n      <ul id='automation-tabs' class='nav nav-pills'>\n        <li class='active'>\n          <a>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"rules")), depth0))
    + "\n          </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"showContentsTab")),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":8},"end":{"line":81,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"showTemplatesTab")),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":8},"end":{"line":88,"column":15}}})) != null ? stack1 : "")
    + "        <li>\n          <a data-navigate='automations/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "/activity?filter.type=triggered&filter.created.gtrel=now-1M'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"activity")), depth0))
    + "\n          </a>\n        </li>\n      </ul>\n      <div class='panel panel-default'>\n        <div class='panel-body panel-body-padded'>\n          <div class='tab-content'>\n            <div class='tab-pane active' id='details'>\n              <div class=\"steps\">\n                <div class=\""
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"lifecycles.rfm",{"name":"is","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":28},"end":{"line":101,"column":92}}})) != null ? stack1 : "")
    + " step trigger\n                                clickeable\" data-action=\"edit-trigger\">\n                  <h4>\n                    <i class=\"fa fa-bolt\" />"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"trigger")), depth0))
    + "\n                    <span class=\"edit-buttons\"><i class=\"edit fa fa-pencil\"></i></span>\n                  </h4>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/inKeys.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggersTypes")),{"name":"inKeys","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":109,"column":18},"end":{"line":125,"column":29}}})) != null ? stack1 : "")
    + "                  <p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"recurring") : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":20},"end":{"line":131,"column":27}}})) != null ? stack1 : "")
    + "                  </p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"execLimit") : stack1)) != null ? lookupProperty(stack1,"enabled") : stack1),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":133,"column":18},"end":{"line":141,"column":25}}})) != null ? stack1 : "")
    + "                </div>\n\n                <div class=\"arrow\"><i class=\"fa fa-arrow-down\" /></div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"steps") : depth0),{"name":"each","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":145,"column":16},"end":{"line":315,"column":25}}})) != null ? stack1 : "")
    + "              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "empty";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"comments") : depth0), depth0));
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"commentsPlaceholder")), depth0));
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button type='button' class='btn btn-disabled' data-action=\"cantActivateLifeCycle\">\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"enable")), depth0))
    + "\n      </button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"enabled") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":6},"end":{"line":37,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"enabled") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":6},"end":{"line":43,"column":17}}})) != null ? stack1 : "")
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button type='button' class='btn btn-primary' data-action='disable' data-require-permission='campaigns:create'>\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"disable")), depth0))
    + "\n      </button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button type='button' class='btn btn-primary' data-action='enable' data-require-permission='campaigns:create'>\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"enable")), depth0))
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"enableRestricted")),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":41,"column":90}}})) != null ? stack1 : "")
    + "\n      </button>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "<i class=\"fa fa-lock\" aria-hidden=\"true\"></i>";
},"16":function(container,depth0,helpers,partials,data) {
    return "    <button type='button' class='btn btn-default' data-action='edit-json' data-require-permission='campaigns:create'>\n      Edición avanzada\n    </button>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n          <a data-navigate='automations/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "/contents'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"contents")), depth0))
    + "\n          </a>\n        </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n          <a data-navigate='automations/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "/templates'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"contents")), depth0))
    + "\n          </a>\n        </li>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return " pending-box ";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <strong>\n                    "
    + container.escapeExpression(__default(require("../../../helpers/handlebars/translate.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggersTypes")),((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":111,"column":20},"end":{"line":111,"column":90}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"paramName") : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":20},"end":{"line":114,"column":27}}})) != null ? stack1 : "")
    + "                  </strong>\n                  <p class=\"step-legend\">\n                    "
    + ((stack1 = __default(require("../../../helpers/handlebars/translate.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggersComments")),((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":117,"column":20},"end":{"line":117,"column":95}}})) != null ? stack1 : "")
    + "</p>\n\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    \""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"paramName") : stack1), depth0))
    + "\"\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"custom",{"name":"is","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":120,"column":20},"end":{"line":124,"column":27}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                      <strong>Custom trigger: </strong> <code>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"customKey") : stack1), depth0))
    + "</code>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                      <code>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"trigger") : depth0), depth0))
    + "</code>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + alias1(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggerNextExecution")), depth0))
    + " "
    + alias1(__default(require("../../../helpers/handlebars/timeago.js")).call(alias2,((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"recurringTrigger"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"next_execution")),{"name":"timeago","hash":{},"data":data,"loc":{"start":{"line":128,"column":68},"end":{"line":129,"column":75}}}))
    + ":\n                    "
    + alias1(__default(require("../../../helpers/handlebars/moment.js")).call(alias2,((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"recurringTrigger"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"next_execution")),"LLL",{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":130,"column":20},"end":{"line":130,"column":90}}}))
    + "\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <p class=\"trigger-options\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggerExcludeSameContactFor")), depth0))
    + "\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"execLimit") : stack1)) != null ? lookupProperty(stack1,"amount") : stack1), depth0))
    + "\n                    "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayUnits"))) && lookupProperty(stack1,"plural")),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"execLimit") : stack1)) != null ? lookupProperty(stack1,"unit") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":136,"column":20},"end":{"line":137,"column":51}}}))
    + "\n                  </p>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <p class=\"trigger-options\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggerEveryTime")), depth0))
    + "</p>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"filter",{"name":"is","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":146,"column":16},"end":{"line":160,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"branch",{"name":"is","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":161,"column":16},"end":{"line":173,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"delay",{"name":"is","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":174,"column":16},"end":{"line":208,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"email",{"name":"is","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":209,"column":16},"end":{"line":241,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"msg_email",{"name":"is","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":242,"column":16},"end":{"line":274,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"transform",{"name":"is","hash":{},"fn":container.program(75, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":275,"column":16},"end":{"line":291,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"msg_whatsapp",{"name":"is","hash":{},"fn":container.program(77, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":293,"column":16},"end":{"line":309,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"next_step") : depth0),{"name":"if","hash":{},"fn":container.program(79, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":312,"column":16},"end":{"line":314,"column":23}}})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"step filter "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"params") : stack1),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":40},"end":{"line":147,"column":82}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"params") : stack1),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":84},"end":{"line":148,"column":74}}})) != null ? stack1 : "")
    + ">\n                  <h4><i class=\"fa fa-filter\" />"
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"stepNames")),(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":149,"column":48},"end":{"line":150,"column":31}}}))
    + "\n                    <span class=\"edit-buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isMasterUser")),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":22},"end":{"line":154,"column":29}}})) != null ? stack1 : "")
    + "                      <i class=\"edit fa fa-pencil\"></i>\n                    </span>\n                  </h4>\n                  "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"continueIf")), depth0))
    + " <strong>"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"comments") : depth0), depth0))
    + "</strong>.\n                </div>\n";
},"40":function(container,depth0,helpers,partials,data) {
    return " clickeable";
},"42":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                  data-action=\"edit-params\" data-step=\""
    + container.escapeExpression(container.lambda((data && lookupProperty(data,"index")), depth0))
    + "\" ";
},"44":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                      <i data-action=\"edit-js\" data-step=\""
    + container.escapeExpression(container.lambda((data && lookupProperty(data,"index")), depth0))
    + "\" class=\"edit-js fa fa-code\"></i>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"step branch "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isMasterUser")),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":40},"end":{"line":162,"column":102}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isMasterUser")),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":104},"end":{"line":163,"column":110}}})) != null ? stack1 : "")
    + ">\n                  <h4><i class=\"fa fa-code-fork\" />"
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"stepNames")),(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":164,"column":51},"end":{"line":165,"column":31}}}))
    + "\n\n                    <span class=\"edit-buttons\"><i class=\"edit fa fa-pencil\"></i></span>\n\n                  </h4>\n                  "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"continueIf")), depth0))
    + " <strong>"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"comments") : depth0), depth0))
    + "</strong>,\n                  "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"otherwiseGoto")), depth0))
    + " <strong>"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"on_false") : stack1), depth0))
    + "</strong>\n                </div>\n";
},"47":function(container,depth0,helpers,partials,data) {
    return "clickeable";
},"49":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-action=\"edit-js\" data-step=\""
    + container.escapeExpression(container.lambda((data && lookupProperty(data,"index")), depth0))
    + "\" ";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"step delay clickeable\" data-step=\""
    + alias1(container.lambda((data && lookupProperty(data,"index")), depth0))
    + "\" data-action=\"edit-delay\">\n                  <h4><i class=\"fa fa-hourglass-half\" />"
    + alias1(__default(require("../../../helpers/handlebars/translate.js")).call(alias2,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"stepNames")),(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":176,"column":56},"end":{"line":177,"column":31}}}))
    + "<span class=\"edit-buttons\"><i class=\"edit fa fa-pencil\"></i></span>\n                  </h4>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"delay") : stack1)) != null ? lookupProperty(stack1,"amount") : stack1),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.program(57, data, 0),"data":data,"loc":{"start":{"line":179,"column":18},"end":{"line":191,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"window") : stack1),{"name":"unless","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":192,"column":18},"end":{"line":194,"column":29}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"window") : stack1),{"name":"if","hash":{},"fn":container.program(61, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":195,"column":18},"end":{"line":206,"column":25}}})) != null ? stack1 : "")
    + "                </div>\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"waitAtleast")), depth0))
    + " <strong>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"delay") : stack1)) != null ? lookupProperty(stack1,"amount") : stack1), depth0))
    + "\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"delay") : stack1)) != null ? lookupProperty(stack1,"amount") : stack1),1,{"name":"is","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":181,"column":20},"end":{"line":184,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/isNot.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"delay") : stack1)) != null ? lookupProperty(stack1,"amount") : stack1),1,{"name":"isNot","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":185,"column":18},"end":{"line":187,"column":28}}})) != null ? stack1 : "")
    + "                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"beforeContinuing")), depth0))
    + ".\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayUnits"))) && lookupProperty(stack1,"singular")),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"delay") : stack1)) != null ? lookupProperty(stack1,"unit") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":182,"column":20},"end":{"line":183,"column":42}}}))
    + "</strong>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + container.escapeExpression(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayUnits"))) && lookupProperty(stack1,"plural")),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"delay") : stack1)) != null ? lookupProperty(stack1,"unit") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":186,"column":18},"end":{"line":186,"column":93}}}))
    + "</strong>\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayNoDelay")), depth0))
    + "\n";
},"59":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <p class=\"trigger-options\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayWindowDisabled")), depth0))
    + "</p>\n";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <p class=\"trigger-options\">\n                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayWindowEnabled")), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"window") : stack1)) != null ? lookupProperty(stack1,"days") : stack1),{"name":"each","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":198,"column":20},"end":{"line":200,"column":29}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"window") : stack1)) != null ? lookupProperty(stack1,"periods") : stack1),{"name":"each","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":201,"column":20},"end":{"line":204,"column":29}}})) != null ? stack1 : "")
    + "                  </p>\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"daysOfWeek")),depth0,{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":199,"column":20},"end":{"line":199,"column":72}}}))
    + ",\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayWindowFrom")), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"from") : depth0), depth0))
    + "\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayWindowTo")), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"to") : depth0), depth0))
    + "\n";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"step email clickeable\" data-action=\"goto-content-tab\"\n                  data-template=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"template_id") : stack1), depth0))
    + "\" data-step=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\n                  <h4>\n                    <i class=\"fa fa-envelope\" />\n                    "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"stepNames")),(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":214,"column":20},"end":{"line":214,"column":76}}}))
    + "\n                    <span class=\"edit-buttons\"><i class=\"edit fa fa-pencil\"></i></span>\n                  </h4>\n                  <div class=\"preview\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"template") : depth0)) != null ? lookupProperty(stack1,"from") : stack1)) != null ? lookupProperty(stack1,"email") : stack1),{"name":"if","hash":{},"fn":container.program(67, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":219,"column":20},"end":{"line":224,"column":27}}})) != null ? stack1 : "")
    + "                    <div>\n                      "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"subject")), depth0))
    + ": "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"template") : depth0)) != null ? lookupProperty(stack1,"subject") : stack1),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.program(71, data, 0),"data":data,"loc":{"start":{"line":226,"column":46},"end":{"line":230,"column":29}}})) != null ? stack1 : "")
    + "                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"template") : depth0)) != null ? lookupProperty(stack1,"preheader") : stack1),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":232,"column":20},"end":{"line":237,"column":27}}})) != null ? stack1 : "")
    + "                  </div>\n                </div>\n";
},"67":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div>\n                      "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"from")), depth0))
    + ":\n                      <strong>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"template") : depth0)) != null ? lookupProperty(stack1,"from") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "&lt;"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"template") : depth0)) != null ? lookupProperty(stack1,"from") : stack1)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "&gt;</strong>\n                    </div>\n";
},"69":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                      <strong>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"template") : depth0)) != null ? lookupProperty(stack1,"subject") : stack1), depth0))
    + "</strong>\n";
},"71":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"noSubject")), depth0))
    + "\n";
},"73":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div>\n                      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"preheader")), depth0))
    + ":\n                      <strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"template") : depth0)) != null ? lookupProperty(stack1,"preheader") : stack1), depth0))
    + "</strong>\n                    </div>\n";
},"75":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"step transform  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"params") : stack1),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":276,"column":44},"end":{"line":276,"column":86}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"params") : stack1),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":276,"column":88},"end":{"line":277,"column":74}}})) != null ? stack1 : "")
    + ">\n                  <h4><i class=\"fa fa-download\" />\n                    "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"stepNames")),(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":279,"column":20},"end":{"line":279,"column":76}}}))
    + "\n                    <span class=\"edit-buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isMasterUser")),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":281,"column":22},"end":{"line":283,"column":29}}})) != null ? stack1 : "")
    + "                      <i class=\"edit fa fa-pencil\"></i>\n                    </span>\n                  </h4>\n                  <strong>"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"comments") : depth0), depth0))
    + "</strong>\n                  <p class=\"step-legend\">\n                    "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"stepLegends")),(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":289,"column":20},"end":{"line":289,"column":78}}}))
    + "</p>\n                </div>\n";
},"77":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"step transform clickeable\" \n                  data-action=\"edit-whatsapp\" data-step=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\n                  <h4><i class=\"fa fa-comment\" />\n                    "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"stepNames")),(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":297,"column":20},"end":{"line":297,"column":76}}}))
    + "\n                    <span class=\"edit-buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isMasterUser")),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":299,"column":22},"end":{"line":301,"column":29}}})) != null ? stack1 : "")
    + "                      <i class=\"edit fa fa-pencil\"></i>\n                    </span>\n                  </h4>\n                  <strong>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"comments") : depth0), depth0))
    + "</strong>\n                  <p class=\"step-legend\">\n                    "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"stepLegends")),(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":307,"column":20},"end":{"line":307,"column":78}}}))
    + "</p>\n                </div>\n";
},"79":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"arrow\"><i class=\"fa fa-arrow-down\" /></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":324,"column":9}}})) != null ? stack1 : "");
},"useData":true});