<template>
  <div class="grid gap-2">
    <p class="text-sm font-medium text-gray-700">{{ t("title") }}</p>
    <div class="grid grid-cols-2 gap-4">
      <SelectMenu :selected="selectedOption" :data-items="options" @update:selected="onUpdateOption" />
      <SimpleInput
        :model-value="selected.value"
        :type="inputType"
        :placeholder="placeholder"
        :has-error="!!error"
        @update:model-value="onInput"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

// Components
import SelectMenu from "@molecules/SelectMenu.vue";
import SimpleInput from "@atoms/SimpleInput.vue";

// Lang
import { useI18n } from "vue-i18n";

// Types
import type { DataItem } from "@domain/data";

// Props
const props = defineProps<{
  options: Array<DataItem>;
  selected: DataItem;
  error: string;
}>();

// Events
const emit = defineEmits<{
  (e: "update:selected-option", value: DataItem): void;
  (e: "update:selected", value: DataItem): void;
}>();

// i18n
const { t } = useI18n();

// Computed properties
const selectedOption = computed<DataItem>(() => {
  const selectedOption = props.options.find((option) => option.key === props.selected.key);

  if (!selectedOption) return props.options[0];

  return selectedOption;
});

const placeholder = computed(() => {
  const key = selectedOption.value.key;
  if (key === "whatsapp" || key === "phone") {
    return t("placeholder.tel");
  }
  if (key === "web") {
    return t("placeholder.web");
  }
  return t("placeholder.email");
});

const inputType = computed(() => {
  const key = selectedOption.value.key;
  return key === "whatsapp" || key === "phone" ? "text" : "email";
});

// Methods
const onUpdateOption = (value: DataItem) => {
  emit("update:selected-option", value);
};

const onInput = (value: string) => {
  emit("update:selected", {
    key: props.selected.key,
    value: value,
  });
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Botón de contacto",
    "placeholder": {
      "tel": "12345678",
      "email": "contacto{'@'}mitienda.com",
      "web": "www.mitienda.com",
    },
  },
  "pt": {
    "title": "Botão de contato",
    "placeholder": {
      "tel": "12345678",
      "email": "contacto{'@'}mitienda.com",
      "web": "www.mitienda.com",
    },
  },
}
</i18n>
