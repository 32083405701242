import { useRequests } from "@api/requests";

// Types
import { StoreBrand, StoreBrandEditorRequest } from "./brandEditor.types";

export function useTiendanubeBranding() {
  const request = useRequests();

  async function get(): Promise<StoreBrand> {
    const res = await request<StoreBrandEditorRequest>({
      url: `/integrations/tiendanube/branding`,
      method: "get",
    });

    return res.data;
  }

  return {
    get,
  };
}
