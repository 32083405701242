<template>
  <div class="flex flex-col bg-white">
    <div
      :class="[
        (publicTemplateGalleryIsOpen || historySelectorIsOpen) && 'pointer-events-none blur-[1px]',
        'flex h-full flex-col',
      ]"
    >
      <div :class="[unlayerModalOpen && 'brightness-50 filter', 'flex flex-col']" @click="closeUnlayerModals">
        <div :class="[unlayerModalOpen && 'pointer-events-none', 'flex justify-between bg-gray-600 p-3']">
          <div
            class="flex max-w-[60%] items-center space-x-6"
            :class="[editingTitle ? 'flex-grow pr-2' : 'flex-shrink']"
          >
            <EditableTitle
              v-if="!unlayerIsLoading"
              ref="vueTemplateEditorNameRef"
              :content="relation && relation.relationName ? relation.relationName : template?.name"
              :editable="!relation"
              :error="errorName"
              class="w-full truncate"
              data-intercom-target="EditorTitle"
              @commit="updateTemplateName"
              @cancel="onCancelEditingTitle"
              @edit="onEditingTitle"
            />
            <div v-else class="ml-3 h-5 w-60 animate-pulse rounded-xl bg-gray-500 opacity-25" />
            <HeaderLinks
              v-if="!editingTitle"
              :links="links"
              data-intercom-target="EditorHelpLinks"
              @tour="onTourLink"
              @help="onHelpLink"
              @video="onVideoLink"
            />
          </div>
          <div class="mr-4 flex space-x-4">
            <Button
              theme="header-primary"
              :model-value="previewButtonModelValue"
              size="small"
              :disabled="unlayerIsLoading || templateError"
              :loading="loadingPreview"
              :show-tooltip="showPreviewTooltip"
              :tooltip="vuePreviewText"
              class="overflow-visible"
              data-intercom-target="EditorPreviewButton"
              @click="previewMode ? closePreview() : onOpenPreview()"
            >
              <template #leading>
                <div><PencilAltIcon v-if="previewMode" /> <EyeIcon v-else /></div
              ></template>
            </Button>
            <Button
              theme="green-lime"
              :model-value="saveButtonModelValue"
              size="small"
              :disabled="unlayerIsLoading || !allowSave"
              :loading="loadingSave"
              :show-tooltip="showSaveTooltip"
              :tooltip="t('saveAndExitButton')"
              class="overflow-visible"
              data-intercom-target="EditorSaveAndExitButton"
              @click="onSaveTemplate"
            >
              <template #leading><CheckCircleIcon /></template>
            </Button>
            <DropDownButton
              theme="black"
              :disabled="unlayerIsLoading"
              :navigation="menuOptions"
              class="z-20 my-auto"
              position="bottom-end"
              :auto-placements="['bottom-end', 'left']"
              data-intercom-target="EditorMoreOptionsButton"
              @update:selected="HandleMenuOptionSelection"
            ></DropDownButton>
          </div>
        </div>
        <div
          v-show="!previewMode"
          :class="[unlayerModalOpen && 'pointer-events-none', 'relative bg-white']"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
        >
          <transition
            leave-active-class="transition-all transform duration-200 overflow-hidden"
            leave-from-class="max-h-full"
            leave-to-class="max-h-0"
            enter-active-class="transition-all transform duration-200 overflow-hidden"
            enter-to-class="max-h-full"
            enter-from-class="max-h-0"
          >
            <TemplateConfiguration
              v-if="template"
              v-show="editorHeaderIsOpen"
              v-model:fields="fields"
              :template-type="template.type"
              :disabled="unlayerIsLoading"
              :editor-instance="editorInstance"
              :subject="template.subject"
              :subjects="template.options.subjects"
              :pre-header="template.preheader"
              :selected-sender="selectedSender"
              :sender-id="template.sender_id"
              :monitor-openings="template.tracking.open"
              :monitor-clicks="template.tracking.click"
              :track-analytics="template.tracking.utm.enabled"
              :campaign-name="template.tracking.utm.campaign"
              :to-recipient="template.options.override_to"
              :bcc-recipient="template.options.bcc"
              :ignore-unsubscribe-status="template.options.ignore_unsubscribe_status"
              :supports-branding="template.options.supports_branding"
              :sample-context="template.options.sample_context"
              :relation="relation"
              :account-features="accountFeatures"
              class="shadow"
              @openConfig="openConfig"
              @closeConfig="closeConfig"
              @update:subject="updateSubject"
              @update:subjects="updateSubjects"
              @update:pre-header="updatepreHeader"
              @update:sender-id="updateSenderID"
              @update:selected-sender="updateSelectedSender"
              @update:monitor-openings="updateMonitorOpenings"
              @update:monitor-clicks="updateMonitorClicks"
              @update:track-analytics="updateTrackAnalytics"
              @update:campaign-name="updateTrackCampaignName"
              @update:to-recipient="updateToRecipient"
              @update:bcc-recipient="updateBccRecipient"
              @update:ignore-unsubscribe-status="updateIgnoreUnsubscribeStatus"
              @update:supports-branding="updateSupportsBranding"
              @update:sample-context="updateSampleContext"
            />
          </transition>
          <button
            type="button"
            class="absolute -bottom-3 left-3 inline-flex items-center rounded-full border border-transparent bg-sky-300 p-0.5 text-white opacity-0 shadow-sm transition-all duration-150 hover:bg-sky-400 focus:outline-none"
            :class="{ 'opacity-100': mouseOverHeader || !editorHeaderIsOpen }"
            @click="toggleEditorHeaderIsOpen"
          >
            <ChevronDoubleDownIcon
              class="h-4 w-4 transition-all"
              aria-hidden="true"
              :class="{ 'rotate-180': editorHeaderIsOpen }"
            />
          </button>
        </div>
      </div>
      <TemplateContentPreview
        v-if="previewMode && template && (tplId || template.id)"
        ref="vueTemplateContentPreviewRef"
        :template="template"
        :tpl-id="tplId || template?.id || ''"
        :subjects="template.options.subjects"
        :sender="selectedSender"
        :contact="contact"
        :preview-format="previewFormat"
        :relation="relation"
        :show="{
          showSubject: true,
          showPreHeader: true,
          showSender: true,
          showSendTest: true,
          showPreviewContact: true,
          showFormatToggle: true,
        }"
        :use-auto-save="unsavedChanges"
        class="overflow-auto px-4"
        :class="!previewMode && 'hidden'"
        template-preview-id="vue-template-editor-preview-html"
        @update:contact="updateSelectContact"
        @update:preview-format="onTogglePreviewFormat"
      />
      <div v-show="unlayerIsLoading" class="flex flex-grow flex-col items-center justify-center">
        <LoadingSpinner class="h-8 w-8 text-sky-500" aria-hidden="true" />
      </div>
      <div
        v-show="!unlayerIsLoading && !previewMode && !templateError && template && template.type === 'unlayer'"
        id="editor"
        class="flex-grow"
        :class="configIsOpen && 'pointer-events-none blur-sm'"
      />
      <div
        v-show="!unlayerIsLoading && !previewMode && !templateError && template && template.type === 'html'"
        class="overflow-auto"
      >
        <iframe ref="htmlEditorPreviewRef" src="" frameborder="0" scrolling="no" class="h-full" style="width: 100%" />
      </div>
    </div>
    <ConfirmationModal id="discardModal" v-bind="discardModal" @cancel="closeDiscardModal" @accept="discardChanges" />
    <ConfirmationModal
      id="auditErrorModal"
      v-bind="auditErrorModal"
      :without-overflow="true"
      @update:dismiss-value="updateAuditErrorModalDismissValue"
      @cancel="closeAuditModalModal"
      @accept="onSaveAuditedTemplate"
    >
      <template #acceptButtonLeading>
        <CheckIcon class="h-5 w-5" aria-hidden="true" />
      </template>
      <template #body>
        <TemplateAuditoryList
          v-if="template?.audit_results"
          :auditory="template?.audit_results"
          class="mb-8 mt-4 text-sm"
        />
      </template>
    </ConfirmationModal>
    <ConfirmationModal id="auditErrorCriticalModal" v-bind="auditErrorCriticalModal" @cancel="closeAuditCriticalModal">
      <template #body>
        <TemplateAuditoryList
          v-if="template?.audit_results"
          :auditory="template?.audit_results"
          class="mb-8 mt-4 text-sm"
        />
      </template>
    </ConfirmationModal>
    <ConfirmationModal
      id="multipleEditingAlertModal"
      v-bind="multipleEditingAlertModal"
      @accept="closeMultipleEditingAlertModal"
      @cancel="closeConcurrency"
    />
    <ConfirmationModal
      id="autoSaveAlertModal"
      v-bind="autoSaveAlertModal"
      @accept="closeAutoSaveAndOpenHistorySelector"
      @cancel="handleCloseAutoSaveAlertModal"
    />
    <ConfirmationModal
      id="unlayerMaintenanceModal"
      v-bind="unlayerMaintenanceModal"
      @cancel="closeUnlayerMaintenanceModal"
    />
    <ConfirmationModal
      id="unlayerExportTimeOutError"
      v-bind="unlayerExportErrorModal"
      @accept="saveTemplate"
      @cancel="closeUnlayerExportErrorModal"
    >
      <template #acceptButtonLeading>
        <RefreshIcon />
      </template>
    </ConfirmationModal>
    <DisplayConditionModal
      :open="displayConditionsModalIsOpen"
      :condition="selectedDisplayCondition"
      :fields="fields"
      :interests="interests"
      @apply="applyDisplayConditions"
      @delete="deleteDisplayConditions"
      @close="closeDisplayConditions"
    />
    <transition
      leave-active-class="transition duration-200 ease"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
      enter-active-class="transition duration-300 ease"
      enter-to-class="opacity-100 scale-100"
      enter-from-class="opacity-0 scale-95"
    >
      <!-- <ConfirmationModal
      id="startAgainModal"
      v-bind="startAgainModal"
      @cancel="closeStartAgainModal"
      @accept="closeAndStartAgain"
    /> -->
      <TemplateHistorySelector
        v-if="(tplId || (template && template?.id)) && historySelectorIsOpen"
        :tpl-id="tplId || template!.id"
        :autosave="autosaveHistoryTemplate ? autosaveHistoryTemplate : undefined"
        :history="template?.history || []"
        :autosave-history="template?.autosave_history || []"
        :loading-restore="restoringHistory"
        class="fixed inset-5 z-20 h-[95vh] overflow-hidden rounded-lg shadow-xl"
        @close="handleCloseHistorySelector"
        @restore="restoreVersion"
      />
    </transition>
    <transition
      leave-active-class="transition duration-200 ease"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
      enter-active-class="transition duration-300 ease"
      enter-to-class="opacity-100 scale-100"
      enter-from-class="opacity-0 scale-95"
    >
      <TemplateGallery
        v-if="publicTemplateGalleryIsOpen"
        class="fixed inset-5 z-20 h-[95vh] overflow-hidden rounded-lg shadow-xl"
        :show-alert-apply-template="!isEmptyTemplate"
        :tpl-id="tplId ?? template?.id"
        :show="{
          myCampaigns: relation?.relationType !== 'automation',
          myTemplates: relation?.relationType !== 'automation',
          templateGallery: relation?.relationType !== 'automation',
        }"
        @select="applyTemplateFromGallery"
        @selectHTML="applyTemplateHTMLFromGallery"
        @select-new-template="startNewTemplate"
        @close="handleCloseTemplateGallery"
      />
    </transition>
    <FontSelectorModal :open="fontAdjustmentsIsOpen" @update:open="toggleFontAdjustments" @change="onFontChanges" />
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, onUnmounted, reactive, ref, watchEffect, toRaw } from "vue";

// Data
import { links } from "./templateEditor.data";

//Components
import Button from "@atoms/SimpleButton.vue";
import DropDownButton from "@molecules/DropDownButton";
import type { Categories, DataItem } from "@molecules/DropDownButton";
import EditableTitle from "@molecules/EditableTitle.vue";
import TemplateConfiguration from "./components/TemplateConfiguration/TemplateConfiguration.vue";
import ConfirmationModal from "@molecules/ConfirmationModal.vue";
import TemplateContentPreview from "@organisms/Templates/TemplatePreview/TemplateContentPreview.vue";
import TemplateHistorySelector from "@organisms/Templates/TemplateHistorySelector/TemplateHistorySelector.vue";
import TemplateGallery from "@organisms/Templates/TemplateGallery/TemplateGallery.vue";
import HeaderLinks from "@molecules/HeaderLinks/HeaderLinks.vue";
import FontSelectorModal from "@organisms/FontSelector/FontSelectorModal.vue";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";
import DisplayConditionModal from "./components/DisplayConditions/DisplayConditionModal.vue";
import TemplateAuditoryList from "../TemplateAuditory/TemplateAuditoryList.vue";

//Icon
import {
  RefreshIcon,
  CheckCircleIcon,
  XCircleIcon,
  PencilAltIcon,
  EyeIcon,
  ChevronDoubleDownIcon,
} from "@heroicons/vue/solid";
import { DocumentIcon, ClockIcon, AdjustmentsIcon, UploadIcon, DownloadIcon, CheckIcon } from "@heroicons/vue/outline";

//I18n
import { useI18n } from "vue-i18n";

// Services
import { useEmailTemplate } from "@api/modules/templates/templates";
import { useAccountFeatures } from "@api/modules/account";
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Application
import { useTemplatesApp, templateTimes, resetTemplateTimes } from "@application";
import { useUnlayer } from "@application/unlayer/unlayer.app";

// Store
import { useContactsStore, useIntegrationsStore, useAccountStore, useFeaturesStore } from "@store";

//Utils
import { useNotifications } from "@composables/notifications";
import { useDebounceFn } from "@vueuse/core";
import { useBreakpoints } from "@composables/breakpoints";
import { differenceInMinutes } from "date-fns";

//Store
import { storeToRefs } from "pinia";
import { useUserConfigStore, useSessionStore } from "@store";

//Types
import type { AxiosError } from "axios";
import type { Sender } from "@domain/Senders";
import type { Template, TemplateAutoSave, HistoryTemplateRestore, TemplatePreview } from "@domain/Templates";

import type { RelationType, AuditModeType } from "@domain/Templates";
import type { Fields } from "@/vue/api/modules/fields";
import type { ErrorAPI } from "@/vue/api/models/requestWrappers";
import type { AcceptDetails, ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";
import type { Contact } from "@/vue/api/modules/contacts/contacts.types";
import type { previewWidthFormats } from "@organisms/Templates/TemplatePreview/TemplateContentPreview.vue";
import type { RestoreData } from "@organisms/Templates/TemplateHistorySelector/TemplateHistorySelector.vue";
import type { AccountFeatures } from "@domain/account";
import type { Condition } from "@domain/conditions";
import type { Interests } from "@domain/interests";

export interface Relation {
  relationId: string;
  relationName: string;
  relationType: RelationType;
}

export interface SavedEmit {
  template: Template;
  sender?: Sender;
  subjects?: Array<string>;
}

enum MenuOptionsKeys {
  startAgain,
  versionHistory,
  fontsConfig,
  importTemplate,
  exportTemplate,
  discardChanges,
}

const { t, locale } = useI18n();
const { notify } = useNotifications();
const { lgBp } = useBreakpoints();
const trackingEventsService = useTrackingEvents();
const userConfigStore = useUserConfigStore();
const { templatesEditorConfig } = storeToRefs(userConfigStore);
const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

//Props
const props = withDefaults(
  defineProps<{
    tplId?: string;
    relation?: Relation | undefined;
    auditMode?: AuditModeType;
    contact?: Contact;
    notAllowErrors?: boolean;
    isTemplateFromGallery?: boolean;
  }>(),
  {
    tplId: undefined,
    relation: undefined,
    auditMode: "no_audit",
    contact: undefined,
    notAllowErrors: false,
    isTemplateFromGallery: false,
  },
);

//Emits
const emit = defineEmits<{
  (e: "saved", savedObject: SavedEmit): void;
  (e: "close"): void;
  (e: "update:contact", value: Contact | undefined): void;
}>();

let pusher: any;
let channelConnected: string;

const updateSelectContact = (value: Contact | undefined) => {
  emit("update:contact", value);
};

const close = () => {
  pusher?.unsubscribe(channelConnected);
  emit("close");
};

const closeConcurrency = () => {
  trackingEventsService.amplitude({
    name: "APP_TPLEDITOR_CONCURRENT_EXIT",
  });
  close();
};

//Maintenance
const unlayerMaintenance = window.config.unlayerMaintenance;
const unlayerMaintenanceModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("unlayerMaintenanceModal.confirmationTitle"),
  message: t("unlayerMaintenanceModal.confirmationMessage"),
  cancelText: t("unlayerMaintenanceModal.cancelButton"),
  severity: "critical",
});
const openUnlayerMaintenanceModal = () => (unlayerMaintenanceModal.open = true);
const closeUnlayerMaintenanceModal = () => {
  unlayerMaintenanceModal.open = false;
  close();
};

// Application
const unlayerApp = useUnlayer();
const templatesApp = useTemplatesApp();

// Store
const contactsStore = useContactsStore();
const integrationsStore = useIntegrationsStore();
const accountStore = useAccountStore();
const featuresStore = useFeaturesStore();

//Tour
const eventEmitted = ref(false);

const emitEditorTourEvent = () => {
  if (eventEmitted.value) return;

  eventEmitted.value = true;
  window.Intercom("trackEvent", "visit-template-editor", {
    lang: locale.value.slice(0, 2),
  });
};

//Account Features
const useAccountFeaturesAPI = useAccountFeatures();
const accountFeatures = ref<AccountFeatures>();

const onTourLink = () => {
  trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_HELP_TOUR" });
  closePreview();
};

const onHelpLink = () => {
  trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_HELP_DOCS" });
};

const onVideoLink = () => {
  trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_HELP_VIDEOS" });
};

// State
const lastAutosaveHistoryDate = ref<Date>(new Date());
const template = ref<Template>();
const interests = ref<Interests>([]);
const fields = ref<Fields>();

const editingTitle = ref<boolean>(false);
const isEmptyTemplate = computed(() => {
  if (!template.value) return true;

  return templatesApp.isEmptyTemplate({
    template: template.value,
    ignoreContents: [
      {
        slug: "footer",
        type: "custom",
      },
    ],
  });
});

//Flags
const templateError = ref(false);
const setTemplateError = () => (templateError.value = true);

const ignoreAutoSave = ref(false);

const unlayerModalOpen = ref(false);

const isFirstLoad = ref(true);
const editorIsReady = ref(false);

const unlayerIsLoading = ref(false);

const unsavedAutoSaveChanges = ref(false);
const setUnsavedAutoSaveChanges = (value: boolean) => (unsavedAutoSaveChanges.value = value);

const unsavedChanges = ref(false);
const setUnsavedChanges = () => (unsavedChanges.value = true);
const setSavedChanges = () => (unsavedChanges.value = false);

const errorName = ref(false);
const setTemplateNameInvalid = () => (errorName.value = true);
const setTemplateNameValid = () => (errorName.value = false);

const previewMode = ref(false);
const openPreview = () => (previewMode.value = true);
const closePreview = () => (previewMode.value = false);

const loadingPreview = ref(false);
const loadingSave = ref(false);

const configIsOpen = ref(false);
const openConfig = () => (configIsOpen.value = true);

const closeConfig = () => {
  configIsOpen.value = false;
  applyAuditValidations();

  // * Force change to audit
  if (!editorInstance) return;
  const preheader = template.value?.preheader;

  unlayerApp.setBodyValues({
    unlayerInstance: editorInstance,
    values: { preheader: preheader + " a" },
  });
  unlayerApp.setBodyValues({
    unlayerInstance: editorInstance,
    values: { preheader },
  });
};

const previewFormat = ref<previewWidthFormats>("desktop");
const vueTemplateContentPreviewRef = ref();
const refresPreviewhHeight = computed(() => vueTemplateContentPreviewRef.value?.refreshHeight);

const onTogglePreviewFormat = (value: previewWidthFormats) => {
  previewFormat.value = value;
  refresPreviewhHeight.value();
};

const editorHeaderIsOpen = ref(true);
const editorHeaderIsOpenByUser = ref(true);
const toggleEditorHeaderIsOpen = () => {
  editorHeaderIsOpen.value = !editorHeaderIsOpen.value;
  editorHeaderIsOpenByUser.value = editorHeaderIsOpen.value;
};

const mouseOverHeader = ref(false);
const onMouseEnter = () => (mouseOverHeader.value = true);
const onMouseLeave = () => (mouseOverHeader.value = false);

const allowSave = ref(true);

const historySelectorIsOpen = ref(false);
const openHistorySelector = () => {
  unlayerApp.switchToContentTab({ editorId: "#editor" });
  historySelectorIsOpen.value = true;
};
const closeHistorySelector = () => (historySelectorIsOpen.value = false);
const handleCloseHistorySelector = () => {
  closeHistorySelector();
  emitEditorTourEvent();
};

const publicTemplateGalleryIsOpen = ref(false);
const openTemplateGallery = () => {
  unlayerApp.switchToContentTab({ editorId: "#editor" });
  publicTemplateGalleryIsOpen.value = true;
};
const closeTemplateGallery = () => (publicTemplateGalleryIsOpen.value = false);

const autosaveHistoryTemplate = ref<TemplateAutoSave>();

const restoringHistory = ref(false);

//ConfirmationModals
const discardModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("discardModal.confirmationTitle"),
  message: t("discardModal.confirmationMessage"),
  cancelText: t("discardModal.cancelButton"),
  acceptText: t("discardModal.confirmButton"),
  severity: "warning",
});
const closeDiscardModal = () => (discardModal.open = false);
const openDiscardModal = () => (discardModal.open = true);

const auditErrorModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("auditErrorModal.confirmationTitle"),
  message: t("auditErrorModal.confirmationMessage"),
  acceptText: t("auditErrorModal.confirmButton"),
  acceptLoading: false,
  cancelText: t("auditErrorModal.cancelButton"),
  dismissText: t("auditErrorModal.dismissText"),
  dismissValue: false,
  severity: "info",
});
const updateAuditErrorModalDismissValue = (newValue: boolean) => (auditErrorModal.dismissValue = newValue);
const closeAuditModalModal = () => (auditErrorModal.open = false);
const openAuditModalModal = () => (auditErrorModal.open = true);

const auditErrorCriticalModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("auditErrorCriticalModal.confirmationTitle"),
  message: t("auditErrorCriticalModal.confirmationMessage"),
  cancelText: t("auditErrorCriticalModal.cancelButton"),
  severity: "warning",
});
const closeAuditCriticalModal = () => (auditErrorCriticalModal.open = false);
const openAuditCriticalModal = () => (auditErrorCriticalModal.open = true);

const autoSaveAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("autosaveAlert.confirmationTitle"),
  message: t("autosaveAlert.confirmationMessage"),
  acceptText: t("autosaveAlert.confirmButton"),
  cancelText: t("autosaveAlert.cancelButton"),
  severity: "info",
});
const closeAutoSaveAlertModal = () => {
  autoSaveAlertModal.open = false;
};
const openAutoSaveAlertModal = () => (autoSaveAlertModal.open = true);
const closeAutoSaveAndOpenHistorySelector = async () => {
  autoSaveAlertModal.open = false;
  await onOpenHistorySelector();
};
const handleCloseAutoSaveAlertModal = async () => {
  closeAutoSaveAlertModal();
  emitEditorTourEvent();

  if (!template.value) return;
  await TemplatesAPI.cleanAutoSave({ tpl_id: template.value.id });
};

//Const
const MIN_SUBJECTS_NUMBER_AB = 2;

const menuOptions = computed<Categories>(() => {
  let options: Categories = {
    1: [
      {
        key: MenuOptionsKeys.versionHistory,
        icon: ClockIcon,
        value: t("menuOptions.versionHistory"),
      },
    ],
    3: [
      {
        key: MenuOptionsKeys.discardChanges,
        icon: XCircleIcon,
        value: t("menuOptions.discardChanges"),
        theme: "red",
      },
    ],
  };

  const isMasterUser = session.value?.isMasterUser ?? false;

  if (template.value && template.value.type === "unlayer") {
    options = {
      ...options,
      1: [
        ...options[1],
        {
          key: MenuOptionsKeys.fontsConfig,
          icon: AdjustmentsIcon,
          value: t("menuOptions.fontAdjustments"),
        },
      ],
    };
  }

  if (
    template.value &&
    template.value.type === "unlayer"
    // && (isMasterUser || accountFeatures.value?.ALLOW_IMPORT_EXPORT_UNLAYER === "1")
  ) {
    options = {
      ...options,
      2: [
        {
          key: MenuOptionsKeys.importTemplate,
          icon: UploadIcon,
          value: t("menuOptions.importTemplate"),
        },
        {
          key: MenuOptionsKeys.exportTemplate,
          icon: DownloadIcon,
          value: t("menuOptions.exportTemplate"),
        },
      ],
    };
  }

  if (props.relation?.relationType !== "automation" || accountFeatures.value?.UNLAYER_ALLOW_HTML === "1") {
    options = {
      ...options,
      1: [
        {
          key: MenuOptionsKeys.startAgain,
          icon: DocumentIcon,
          value: t("menuOptions.startAgain"),
        },
        ...options[1],
      ],
    };
  }

  return options;
});

let editorInstance: UnlayerInstance | undefined; //Editor instance loaded onMounted

const closeUnlayerModals = () => {
  if (!unlayerModalOpen.value) return;
  unlayerApp.sendCloseModalsEvent({ editorId: "#editor" });
};

const TemplatesAPI = useEmailTemplate();

//Autosave
const autoSave = async () => {
  if (ignoreAutoSave.value) return;
  let saveHistory = false;
  if (!lastAutosaveHistoryDate.value || differenceInMinutes(new Date(), lastAutosaveHistoryDate.value) >= 5) {
    lastAutosaveHistoryDate.value = new Date();
    saveHistory = true;
  }

  if (!template.value) return;
  await templatesApp.autoSaveTemplate({ unlayerInstance: editorInstance, template: template.value, saveHistory });
  setUnsavedAutoSaveChanges(false);
};

const autosaveFn = useDebounceFn(autoSave, 10000, { maxWait: 10000 });

const onOpenPreview = async () => {
  if (unsavedAutoSaveChanges.value) {
    loadingPreview.value = true;
    await autoSave();
    loadingPreview.value = false;
  }

  openPreview();
};

//State
const previewButtonModelValue = computed(() => {
  if (!lgBp.value) return;

  return previewMode.value ? t("returnToEditButton") : t("previewButton");
});

const saveButtonModelValue = computed(() => {
  if (!lgBp.value) return;
  return t("saveAndExitButton");
});

const vueTemplateEditorNameRef = ref();

const showPreviewTooltip = ref(false);
const showSaveTooltip = ref(false);
const vuePreviewText = ref(t("previewButton"));

watchEffect(() => {
  vuePreviewText.value = previewMode.value ? t("returnToEditButton") : t("previewButton");
});

watchEffect(() => {
  if (lgBp.value) {
    showPreviewTooltip.value = false;
    showSaveTooltip.value = false;
  } else {
    showPreviewTooltip.value = true;
    showSaveTooltip.value = true;
  }
});

const onCancelEditingTitle = () => {
  editingTitle.value = false;
};
const onEditingTitle = () => {
  editingTitle.value = true;
};

const updateTemplateName = (newName: string) => {
  editingTitle.value = false;
  setTemplateNameValid();
  if (!template.value) return;

  template.value.name = newName;
  setUnsavedChanges();
};

const applyAuditValidations = () => {
  if (!template.value || !editorInstance) return;
  console.log("applyAuditValidations");
  unlayerApp.applyAuditValidations({
    editorId: "#editor",
    unlayerInstance: editorInstance,
    template: template.value,
    auditMode: props.auditMode,
  });
};

const updateSubject = (newSubject: string) => {
  if (!template.value) return;
  template.value.subject = newSubject;
  setUnsavedAutoSaveChanges(true);
  autosaveFn();
  setUnsavedChanges();
  // console.log("updateSubject");
  // applyAuditValidations();
};
const updateSubjects = (newSubjects: Array<string>) => {
  if (!template.value) return;
  template.value.subject = newSubjects[0];
  template.value.options = {
    ...template.value.options,
    subjects: newSubjects,
  };
  setUnsavedAutoSaveChanges(true);
  autosaveFn();
  setUnsavedChanges();
  // console.log("updateSubjects");
  // applyAuditValidations();
};
const updatepreHeader = (newpreHeader: string) => {
  if (!template.value || !editorInstance) return;
  template.value.preheader = newpreHeader;
  unlayerApp.setBodyValues({ unlayerInstance: editorInstance, values: { preheader: newpreHeader } });
  setUnsavedAutoSaveChanges(true);
  autosaveFn();
  setUnsavedChanges();
};
const updateSenderID = (newSenderID: string) => {
  if (!template.value) return;
  template.value.sender_id = newSenderID || "";
  setUnsavedAutoSaveChanges(true);
  setUnsavedChanges();
  // console.log("updateSenderID");
  // applyAuditValidations();
};

const selectedSender = ref<Sender>();
const updateSelectedSender = (value: Sender) => {
  selectedSender.value = value;
  if (isFirstLoad.value) {
    isFirstLoad.value = false;
    return;
  }
  setUnsavedAutoSaveChanges(true);
  setUnsavedChanges();
};

const updateMonitorOpenings = (newMonitorOpenings: boolean) => {
  if (!template.value) return;
  template.value.tracking.open = newMonitorOpenings;
  templatesEditorConfig.value = {
    ...toRaw(templatesEditorConfig.value),
    monitoringOptions: {
      ...toRaw(templatesEditorConfig.value.monitoringOptions),
      monitorOpenings: newMonitorOpenings,
    },
  };

  setUnsavedAutoSaveChanges(true);
  setUnsavedChanges();
};

const updateMonitorClicks = (newMonitorClicks: boolean) => {
  if (!template.value) return;
  template.value.tracking.click = newMonitorClicks;

  templatesEditorConfig.value = {
    ...toRaw(templatesEditorConfig.value),
    monitoringOptions: {
      ...toRaw(templatesEditorConfig.value.monitoringOptions),
      monitorClicks: newMonitorClicks,
    },
  };
  setUnsavedAutoSaveChanges(true);
  setUnsavedChanges();
};

const updateTrackAnalytics = (newTrackAnalytics: boolean) => {
  if (!template.value) return;
  template.value.tracking.utm.enabled = newTrackAnalytics;
  templatesEditorConfig.value = {
    ...toRaw(templatesEditorConfig.value),
    monitoringOptions: {
      ...toRaw(templatesEditorConfig.value.monitoringOptions),
      trackAnalytics: newTrackAnalytics,
    },
  };
  setUnsavedAutoSaveChanges(true);
  setUnsavedChanges();
  // console.log("updateTrackAnalytics");
  // applyAuditValidations();
};

const updateTrackCampaignName = (newTrackCampaignName: string) => {
  if (!template.value) return;
  template.value.tracking.utm.campaign = newTrackCampaignName;

  setUnsavedAutoSaveChanges(true);
  setUnsavedChanges();
  // console.log("updateTrackCampaignName");
  // applyAuditValidations();
};

const updateToRecipient = (newToRecipient: string) => {
  if (!template.value) return;
  template.value.options.override_to = newToRecipient;
  setUnsavedAutoSaveChanges(true);
  setUnsavedChanges();
};

const updateBccRecipient = (newBccRecipient: string) => {
  if (!template.value) return;
  template.value.options.bcc = newBccRecipient;
  setUnsavedAutoSaveChanges(true);
  setUnsavedChanges();
};

const updateSupportsBranding = (supportsBranding: boolean) => {
  if (!template.value) return;
  template.value.options.supports_branding = supportsBranding;
  setUnsavedAutoSaveChanges(true);
  setUnsavedChanges();
};

const updateIgnoreUnsubscribeStatus = (newIgnoreUnsubscribeStatus: boolean) => {
  if (!template.value) return;
  template.value.options.ignore_unsubscribe_status = newIgnoreUnsubscribeStatus;
  setUnsavedAutoSaveChanges(true);
  setUnsavedChanges();
};

const updateSampleContext = (newSampleContext: string) => {
  if (!template.value) return;
  template.value.options.sample_context = newSampleContext;
  setUnsavedAutoSaveChanges(true);
  setUnsavedChanges();
};

//Methods
const onOpenHistorySelector = async () => {
  closePreview();
  autosaveHistoryTemplate.value = await getAutoSaveToRestore();
  openHistorySelector();
  trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_HISTORY_OPEN" });
};

const getAutoSaveToRestore = async (): Promise<TemplateAutoSave | undefined> => {
  let tplRestore: TemplateAutoSave;
  const tplId = props?.tplId || template.value?.id;
  if (!tplId) return;

  const tplAutoSave = await useTemplatePreview(tplId);
  if (!tplAutoSave?.html || !template.value?.autosave) return;
  tplRestore = {
    timestamp: template.value.autosave.timestamp,
    user_id: template.value.autosave.user_id,
    json: "",
    html: tplAutoSave.html,
    text: "",
    subject: tplAutoSave?.subject || "",
    preheader: tplAutoSave?.preheader || "",
  };
  return tplRestore;
};

const getRestoreData = async (restoreData: RestoreData) => {
  let tplRestore: HistoryTemplateRestore;
  if (restoreData.historyitem.id === "autosave") {
    tplRestore = {
      json: template.value?.autosave.json,
      html: template.value?.autosave.html,
      text: template.value?.autosave.text,
      subject: template.value?.autosave?.subject || "",
      preheader: template.value?.autosave?.preheader || "",
      type: template.value?.type || "unlayer",
    };
    return tplRestore;
  }

  const templateId = props?.tplId || template.value?.id;
  if (!templateId) {
    notify({
      title: t("errorRestoreTemplate.errorTitle"),
      text: t("errorRestoreTemplate.errorMessage"),
      theme: "error",
    });
    return;
  }

  const tplh = await TemplatesAPI.getHistoryTemplate({
    tpl_id: templateId,
    tplh_id: restoreData.historyitem.id,
  });

  tplRestore = {
    json: tplh.contents.json,
    html: tplh.contents.html,
    text: tplh.contents.text,
    subject: tplh.subject,
    preheader: tplh.preheader,
    type: tplh.type,
  };
  return tplRestore;
};

const applyTemplateHTML = async ({ html }: { html: string }) => {
  if (!template.value) return;

  template.value.type = "html";
  template.value.contents.html = html;
  template.value.contents.json = "";
  template.value.contents.text = "";
  template.value.preheader = "";

  await templatesApp.applyRules({ template: template.value, auditMode: props.auditMode });

  templatesApp.auditTemplateHTML({ template: template.value, auditMode: props.auditMode });

  if (editorInstance) destroyUnlayerInstance();

  templatesApp.attachHTMLToIframe({
    html: template.value.contents.html,
    iframeElement: htmlEditorPreviewRef.value,
  });
};

const restoreVersion = async (restoreData: RestoreData) => {
  trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_HISTORY_RESTORE", includeMasterUsers: true });

  if (!template.value) return;
  restoringHistory.value = true;

  const tplRestore = await getRestoreData(restoreData);
  if (!tplRestore) return;

  if (restoreData.options.useSubject) template.value.subject = tplRestore.subject;

  if (restoreData.options.usePreheader && tplRestore.type !== "html" && restoreData.options.useContent)
    template.value.preheader = tplRestore.preheader;

  if (restoreData.options.useContent) {
    if (tplRestore.type === "html") {
      await applyTemplateHTML({
        html: tplRestore.html ?? "",
      });
    } else {
      template.value.type = "unlayer";
      template.value.contents.json = tplRestore.json;
      template.value.contents.html = tplRestore.html;
      template.value.contents.text = tplRestore.text;

      await instanceNewEditor({ template: template.value });
    }
  }

  restoringHistory.value = false;
  closePreview();
  closeHistorySelector();
  setUnsavedAutoSaveChanges(true);
  setUnsavedChanges();
  emitEditorTourEvent();
};

const handleTemplateNameHasError = () => {
  notify({
    title: t("invalidTemplateName.errorTitle"),
    text: t("invalidTemplateName.errorMessage"),
    theme: "error",
  });
  vueTemplateEditorNameRef.value?.startEdit();
  setTemplateNameInvalid();
};

const updateTemplate = (newTemplate: Template) => (template.value = newTemplate);

const discardChanges = async () => {
  if (!template.value) return;

  await TemplatesAPI.cleanAutoSave({ tpl_id: template.value?.id });
  removeEventListener("beforeunload", beforeUnloadListener, { capture: true });
  close();
};

const htmlEditorPreviewRef = ref();

const applyTemplateHTMLFromGallery = async (selectedTemplate: string) => {
  if (!template.value) return;

  await applyTemplateHTML({ html: selectedTemplate });

  setUnsavedAutoSaveChanges(true);
  autosaveFn();
  setUnsavedChanges();
  unlayerIsLoading.value = false;
};

const applyTemplateFromGallery = async ({
  template: newTemplate,
  isPublicTemplate,
}: {
  template: Template;
  isPublicTemplate: boolean;
}) => {
  trackingEventsService.dispatchAll({
    name: "APP_TPLEDITOR_GALLERY_APPLY",
    data: {
      template_id: newTemplate.id,
      name: newTemplate.name,
    },
    includeMasterUsers: true,
  });

  if (newTemplate.type === "html" && newTemplate.contents?.html) {
    return applyTemplateHTMLFromGallery(newTemplate.contents.html);
  }

  templatesApp.clearTimerTools({ template: newTemplate });

  const newTemplateJSON = templatesApp.getTemplateJSON({ template: newTemplate });

  if (!template.value) return;

  template.value.type = "unlayer";
  template.value.contents = newTemplate.contents;

  if (!isPublicTemplate) {
    const preheader = newTemplate.preheader;
    const subject = newTemplate.subject;
    const senderId = newTemplate.sender_id;

    updatepreHeader(preheader);
    updateSubject(subject);
    updateSenderID(senderId);
  }

  await instanceNewEditor({ template: template.value, templateJSON: newTemplateJSON });

  initTemplateValues(template.value);

  setUnsavedAutoSaveChanges(true);
  autosaveFn();
  setUnsavedChanges();

  emitEditorTourEvent();
};

const handleCloseTemplateGallery = () => {
  closeTemplateGallery();
  emitEditorTourEvent();
};

const exportTemplate = async () => {
  trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_EXPORT", includeMasterUsers: true });
  if (!template.value || !editorInstance) return;
  await unlayerApp.downloadExportedTemplate({ unlayerInstance: editorInstance, fileName: template.value.name });
};

const importTemplate = async () => {
  trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_IMPORT", includeMasterUsers: true });

  if (!template.value) return;

  const importedTemplateJSON = await templatesApp.importTemplate();
  if (!importedTemplateJSON) return;

  template.value.contents.json = JSON.stringify(importedTemplateJSON);

  await instanceNewEditor({ template: template.value, templateJSON: importedTemplateJSON });

  closePreview();

  setUnsavedAutoSaveChanges(true);
  autosaveFn();
  setUnsavedChanges();
};

const auditTimes = {
  timeAuditStart: 0,
  timeAuditEnd: 0,
};

const resetAuditTimes = () => {
  auditTimes.timeAuditStart = 0;
  auditTimes.timeAuditEnd = 0;
};

const onSaveTemplate = async () => {
  if (!template.value) return;
  unlayerApp.switchToContentTab({ editorId: "#editor" });

  if (!unsavedChanges.value) {
    removeEventListener("beforeunload", beforeUnloadListener, { capture: true });
    close();
    return;
  }
  loadingSave.value = true;

  auditTimes.timeAuditStart = performance.now();
  if (template.value.type === "unlayer" && editorInstance) {
    await templatesApp.auditTemplateUnlayer({
      editorId: "#editor",
      unlayerInstance: editorInstance,
      template: template.value,
      auditMode: props.auditMode,
    });
  } else {
    templatesApp.auditTemplateHTML({ template: template.value, auditMode: props.auditMode });
  }
  auditTimes.timeAuditEnd = performance.now();
  console.log("onSaveTemplate auditTemplate: ", auditTimes.timeAuditEnd - auditTimes.timeAuditStart);

  if (props.notAllowErrors && template.value.audit_results.status == "FAIL") {
    openAuditCriticalModal();
    loadingSave.value = false;

    return;
  }

  const ignoreAuditAlert = Boolean(template.value?.options?.ignore_audit_alert);

  if (template.value.audit_results.status !== "PASS" && !ignoreAuditAlert) {
    openAuditModalModal();
    loadingSave.value = false;
    return;
  }

  await saveTemplate();
};

const onSaveAuditedTemplate = async (options: AcceptDetails) => {
  if (!template.value) return;
  auditErrorModal.acceptLoading = true;
  template.value.options = {
    ...template.value.options,
    ignore_audit_alert: options.dismissValue || false,
  };

  await saveTemplate();
};

const unlayerExportErrorModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("unlayerExportErrorModal.confirmationTitle"),
  message: t("unlayerExportErrorModal.confirmationMessage"),
  cancelText: t("unlayerExportErrorModal.cancelButton"),
  acceptText: t("unlayerExportErrorModal.acceptButton"),
  acceptLoading: false,
  severity: "info",
});
const openUnlayerExportErrorModal = () => (unlayerExportErrorModal.open = true);
const closeUnlayerExportErrorModal = () => {
  unlayerExportErrorModal.acceptLoading = false;
  unlayerExportErrorModal.open = false;
};

const saveTemplate = async () => {
  unlayerExportErrorModal.acceptLoading = true;

  let [timeStart, timeEnd, timeSaveAPIstart, timeSaveAPIEnd] = [0, 0, 0, 0];
  timeStart = performance.now();
  let error = false;

  try {
    if (!template.value) return;
    ignoreAutoSave.value = true;

    timeSaveAPIstart = performance.now();
    const saveResponse = await templatesApp.saveTemplate({
      unlayerInstance: editorInstance,
      template: template.value,
      auditMode: props.auditMode,
    });

    timeSaveAPIEnd = performance.now();

    if (saveResponse.isOk()) {
      removeEventListener("beforeunload", beforeUnloadListener, { capture: true });

      setUnsavedAutoSaveChanges(false);
      setSavedChanges();

      emit("saved", {
        template: saveResponse.value,
        sender: selectedSender.value,
        subjects: template.value?.options?.subjects,
      });
      close();

      return;
    }

    if (saveResponse.isErr() && saveResponse.error.type === "TIMEOUT") {
      unlayerExportErrorModal.acceptLoading = false;
      closeUnlayerExportErrorModal();
      openUnlayerExportErrorModal();

      trackingEventsService.dispatchAll({
        name: "APP_TPLEDITOR_SAVE_TIMEOUT",
        data: {
          time: timeEnd - timeStart,
          timeSave: timeSaveAPIEnd - timeSaveAPIstart,
          timeAPI: templateTimes.timeAPISaveEnd - templateTimes.timeAPISaveStart,
          timeExportHTML: templateTimes.timeExportHTMLEnd - templateTimes.timeExportHTMLStart,
          timeExportText: templateTimes.timeExportTextEnd - templateTimes.timeExportTextStart,
          timeExportFirst: templateTimes.timeExportTemplateFirstEnd - templateTimes.timeExportTemplateFirstStart,
          timeExportHTMLSecond: templateTimes.timeExportHTMLSecondEnd - templateTimes.timeExportHTMLSecondStart,
          timeExportTextSecond: templateTimes.timeExportTextSecondEnd - templateTimes.timeExportTextSecondStart,
          timeExportSecond: templateTimes.timeExportTemplateSecondEnd - templateTimes.timeExportTemplateSecondStart,
          templateLength: template.value.contents.html?.length,
          error: true,
        },
        includeMasterUsers: true,
      });
      return;
    }

    if (saveResponse.isErr() && saveResponse.error.type === "ERROR") {
      error = true;
      ignoreAutoSave.value = false;
      unlayerExportErrorModal.acceptLoading = false;
    }
  } catch (e) {
    error = true;
    ignoreAutoSave.value = false;
    const response = (e as AxiosError).response?.data as ErrorAPI;
    const isDuplicatedName = response.error.errors && response.error.errors?.["name"] === "duplicated";
    if (isDuplicatedName) {
      handleTemplateNameHasError();
      return;
    }
  } finally {
    unlayerExportErrorModal.acceptLoading = false;
    loadingSave.value = false;
    auditErrorModal.acceptLoading = false;

    timeEnd = performance.now();
    console.log("Saved timeSave: ", timeEnd - timeStart);
    console.log("Saved timeAPI: ", templateTimes.timeAPISaveEnd - templateTimes.timeAPISaveStart);
    console.log(
      "Saved timeExportFirst: ",
      templateTimes.timeExportTemplateFirstEnd - templateTimes.timeExportTemplateFirstStart,
    );
    console.log(
      "Saved timeExportSecond: ",
      templateTimes.timeExportTemplateSecondEnd - templateTimes.timeExportTemplateSecondStart,
    );

    console.log("audit time: ", auditTimes.timeAuditEnd - auditTimes.timeAuditStart);

    trackingEventsService.dispatchAll({
      name: "APP_TPLEDITOR_SAVE",
      data: {
        time: timeEnd - timeStart,
        timeSave: timeSaveAPIEnd - timeSaveAPIstart,
        timeAPI: templateTimes.timeAPISaveEnd - templateTimes.timeAPISaveStart,
        timeAudit: auditTimes.timeAuditEnd - auditTimes.timeAuditStart,
        timeExportHTML: templateTimes.timeExportHTMLEnd - templateTimes.timeExportHTMLStart,
        timeExportText: templateTimes.timeExportTextEnd - templateTimes.timeExportTextStart,
        timeExportFirst: templateTimes.timeExportTemplateFirstEnd - templateTimes.timeExportTemplateFirstStart,
        timeExportHTMLSecond: templateTimes.timeExportHTMLSecondEnd - templateTimes.timeExportHTMLSecondStart,
        timeExportTextSecond: templateTimes.timeExportTextSecondEnd - templateTimes.timeExportTextSecondStart,
        timeExportSecond: templateTimes.timeExportTemplateSecondEnd - templateTimes.timeExportTemplateSecondStart,
        error,
      },
      includeMasterUsers: true,
    });
    resetTemplateTimes();
    resetAuditTimes();
  }
};

const initTemplateValues = (tpl: Template) => {
  const initiatorfns = [
    () => {
      //Set Audit mode
      if (!tpl.audit_results) {
        tpl.audit_results = { mode: props?.auditMode || "campaign", status: "PASS", errors: [] };
        return tpl;
      }

      tpl.audit_results.mode = props?.auditMode || "campaign";
    },
    () => {
      //Test AB init empty subjects
      if (props.relation?.relationType !== "campaign_testab") return;

      const hasMinimunSubjects = tpl.options.subjects && tpl.options?.subjects?.length < MIN_SUBJECTS_NUMBER_AB;
      if (!hasMinimunSubjects) return;

      tpl.options.subjects = tpl.options.subjects || [];

      while (tpl.options?.subjects?.length < MIN_SUBJECTS_NUMBER_AB) {
        tpl.options.subjects?.push("");
      }
    },
  ];

  initiatorfns.forEach((fn) => fn());

  return tpl;
};

const useTemplatePreview = async (id: string): Promise<TemplatePreview | undefined> => {
  try {
    const utmCampaign = template.value?.tracking.utm.enabled ? template.value?.tracking.utm.campaign : undefined;

    const tpl = await TemplatesAPI.getTemplatePreview({
      tpl_id: id,
      contact_id: props.contact?.id.toString(),
      utm_campaign: utmCampaign,
      use_autosave: true,
    });

    return tpl;
  } catch (e) {
    setTemplateError();
    return undefined;
  }
};

const useTemplate = async (id: string): Promise<Template | undefined> => {
  try {
    const tpl = await TemplatesAPI.getTemplate({ tpl_id: id });

    return tpl;
  } catch (e) {
    setTemplateError();
    removeEventListener("beforeunload", beforeUnloadListener, { capture: true });
    close();
    return undefined;
  }
};

const createTemplate = async (): Promise<Template | undefined> => {
  try {
    const tpl = await TemplatesAPI.createTemplate({
      type: "unlayer",
    });

    return tpl;
  } catch (e) {
    removeEventListener("beforeunload", beforeUnloadListener, { capture: true });
    close();
    return undefined;
  }
};

const startNewTemplate = async () => {
  if (!template.value) return;

  template.value.type = "unlayer";

  if (!editorInstance) {
    await instanceNewEditor({ template: template.value });
  }

  if (!editorInstance) return;

  await templatesApp.startNewTemplate({
    unlayerInstance: editorInstance,
    template: template.value,
    auditMode: props.auditMode,
  });

  setUnsavedAutoSaveChanges(true);
  autosaveFn();
  setUnsavedChanges();
  console.log("startNewTemplate");
  applyAuditValidations();

  emitEditorTourEvent();
};

// Reset unlayer
const destroyUnlayerInstance = () => {
  if (!editorInstance) return;
  editorInstance.destroy();
  editorInstance = undefined;
};

const timeEditor = {
  timeStart: 0,
  timeEnd: 0,
  timeUnlayerStart: 0,
  timeUnlayerloadEnd: 0,
  timeUnlayerReadyEnd: 0,
  createInstanceStart: 0,
  createInstanceEnd: 0,
  fetchsStart: 0,
  fetchsEnd: 0,
};

const resetTimesEditor = () => {
  timeEditor.timeStart = 0;
  timeEditor.timeEnd = 0;
  timeEditor.fetchsStart = 0;
  timeEditor.fetchsEnd = 0;
  timeEditor.timeUnlayerStart = 0;
  timeEditor.timeUnlayerloadEnd = 0;
  timeEditor.timeUnlayerReadyEnd = 0;
  timeEditor.createInstanceStart = 0;
  timeEditor.createInstanceEnd = 0;
};

const instanceNewEditor = async ({ template, templateJSON }: { template: Template; templateJSON?: UnlayerJSON }) => {
  try {
    if (!template) return;

    editorIsReady.value = false;
    unlayerIsLoading.value = true;

    const templateFonts = templatesApp.getTemplateFonts({ template });
    const filteredFonts = templateFonts.filter((font) => font.url);

    if (editorInstance) destroyUnlayerInstance();
    timeEditor.createInstanceStart = performance.now();
    editorInstance = await unlayerApp.createInstance({ editorId: "#editor", templateFonts: filteredFonts });
    
    timeEditor.createInstanceEnd = performance.now();
    timeEditor.timeUnlayerStart = performance.now();

    editorInstance.addEventListener("design:loaded", async function () {
      console.log("design:loaded");
      timeEditor.timeUnlayerloadEnd = performance.now();

      if (!editorIsReady.value || !template || !editorInstance) return;

      unlayerApp.setBodyValues({ unlayerInstance: editorInstance, values: { preheader: template.preheader } });

      applyAuditValidations();
      unlayerApp.replaceBrokenImagesUrls({ editorId: "#editor" });
      unlayerIsLoading.value = false;
      timeEditor.timeEnd = performance.now();

      resetTimesEditor();
    });

    editorInstance.addEventListener("editor:ready", async function () {
      console.log("editor:ready");
      timeEditor.timeUnlayerReadyEnd = performance.now();

      if (editorInstance) {
        trackingEventsService.dispatchAll({
          name: "APP_TPLEDITOR_LOADED",
          data: {
            version: editorInstance.versions.current,
            time: timeEditor.timeEnd - timeEditor.timeStart,
            timeCreateInstance: timeEditor.createInstanceEnd - timeEditor.createInstanceStart,
            timeFetchs: timeEditor.fetchsEnd - timeEditor.fetchsStart,
            timeUnlayerLoad: timeEditor.timeUnlayerloadEnd - timeEditor.timeUnlayerStart,
            timeUnlayerReady: timeEditor.timeUnlayerReadyEnd - timeEditor.timeUnlayerStart,
          },
        });
      }

      if (editorInstance) {
        unlayerApp.setupToolValidators({ unlayerInstance: editorInstance });
        unlayerApp.setupTemplateValidator({ unlayerInstance: editorInstance });
      }

      if (!template || !session.value) {
        unlayerIsLoading.value = false;
        return;
      }
      unlayerApp.sendData({
        session: toRaw(session.value),
        accountFeatures: toRaw(accountFeatures.value),
      });

      editorIsReady.value = true;

      if (editorInstance && (!template || isEmptyTemplate.value)) {
        unlayerIsLoading.value = false;
        unlayerApp.setBodyValues({ unlayerInstance: editorInstance, values: { contentWidth: "660px" } });

        applyAuditValidations();
        return;
      }

      if (templateJSON) {
        template.contents.json = JSON.stringify(templateJSON);
      }

      templatesApp.applyRules({ template, auditMode: props.auditMode });
      const jsonDesign = templatesApp.getTemplateJSON({ template });

      if (!jsonDesign) return;

      if (editorInstance) unlayerApp.loadTemplate({ unlayerInstance: editorInstance, templateJSON: jsonDesign });
    });

    editorInstance.addEventListener("design:updated", function () {
      if (!template || !editorIsReady.value) return;

      unlayerApp.replaceBrokenImagesUrls({ editorId: "#editor" });

      setUnsavedAutoSaveChanges(true);
      autosaveFn();
      setUnsavedChanges();
      allowSave.value = true;
    });

    editorInstance.registerCallback("displayCondition", function (data, done) {
      selectedDisplayCondition.value = data as unknown as Condition; // Fix Typing
      setDisplayConditionsCallback = done;
      openDisplayConditions();
    });
  } catch (e) {
    notify({
      title: t("errorCustomTools.errorTitle"),
      text: t("errorCustomTools.errorMessage"),
      theme: "error",
      timeout: 10000,
    });
    trackingEventsService.dispatchAll({
      name: "APP_TPLEDITOR_LOAD_ERROR",
    });
    close();
  }
};

//Fonts config
const fontAdjustmentsIsOpen = ref(false);
const openFontAdjustments = () => {
  closePreview();
  fontAdjustmentsIsOpen.value = true;
};
const toggleFontAdjustments = async (value: boolean) => {
  fontAdjustmentsIsOpen.value = value;
};
const onFontChanges = async () => {
  trackingEventsService.dispatchAll({
    name: "APP_TPLEDITOR_ADD_FONT",
    includeMasterUsers: true,
  });

  if (!template.value) return;
  await instanceNewEditor({ template: template.value });
};

const handlerMenuOptions: Record<MenuOptionsKeys, () => void> = {
  [MenuOptionsKeys.startAgain]: () => {
    trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_GALLERY_OPEN" });
    closePreview();
    openTemplateGallery();
  },
  [MenuOptionsKeys.versionHistory]: onOpenHistorySelector,
  [MenuOptionsKeys.fontsConfig]: openFontAdjustments,

  [MenuOptionsKeys.importTemplate]: importTemplate,

  [MenuOptionsKeys.exportTemplate]: exportTemplate,
  [MenuOptionsKeys.discardChanges]: async () => {
    trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_DISCARD" });
    unsavedChanges.value ? openDiscardModal() : await discardChanges();
  },
};

const HandleMenuOptionSelection = async (option: DataItem) => await handlerMenuOptions[option.key]?.();

// Display conditions
const displayConditionsModalIsOpen = ref(false);
const selectedDisplayCondition = ref<Condition>();
let setDisplayConditionsCallback;

const openDisplayConditions = () => {
  displayConditionsModalIsOpen.value = true;
};

const applyDisplayConditions = (newCondition: Condition) => {
  trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_CONDITION_APPLY", includeMasterUsers: true });
  if (!setDisplayConditionsCallback) return;

  setDisplayConditionsCallback(newCondition);
  setDisplayConditionsCallback.value = undefined;
};
const deleteDisplayConditions = () => {
  if (!setDisplayConditionsCallback) return;

  setDisplayConditionsCallback();
  setDisplayConditionsCallback.value = undefined;
};

const closeDisplayConditions = () => {
  displayConditionsModalIsOpen.value = false;
};

const startNotifications = () => {
  if (!template.value) return;

  const hasAutoSave = template.value.autosave.json;

  if (hasAutoSave) openAutoSaveAlertModal();
};

// Multiple Editing
const multipleEditingAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("multipleEditingAlertModal.confirmationTitle"),
  message: t("multipleEditingAlertModal.confirmationMessage"),
  acceptText: t("multipleEditingAlertModal.confirmButton"),
  cancelText: t("multipleEditingAlertModal.cancelButton"),
  severity: "warning",
});
const closeMultipleEditingAlertModal = () => {
  trackingEventsService.amplitude({
    name: "APP_TPLEDITOR_CONCURRENT_IGNORE",
  });
  startNotifications();
  multipleEditingAlertModal.open = false;
  multipleEditingAlertModal.message = t("multipleEditingAlertModal.confirmationMessage");
};
const openMultipleEditingAlertModal = (user: string, isSameUser: boolean) => {
  if (isSameUser) {
    multipleEditingAlertModal.message = t("multipleEditingAlertModal.confirmationMessageSameUser");
  } else {
    multipleEditingAlertModal.message = t("multipleEditingAlertModal.confirmationMessage", { user });
  }
  multipleEditingAlertModal.open = true;
};

//LifeCycle
onMounted(async () => {
  if (unlayerMaintenance) {
    openUnlayerMaintenanceModal();
    return;
  }

  timeEditor.timeStart = performance.now();
  unlayerIsLoading.value = true;
  ignoreAutoSave.value = false;

  const templatePromise = props?.tplId ? useTemplate(props.tplId) : createTemplate();

  timeEditor.fetchsStart = performance.now();
  const [_, __, ___, ____, newTemplate] = await Promise.all([
    contactsStore.fetchAll(),
    integrationsStore.fetchIntegrations(),
    accountStore.fetchConfig(),
    featuresStore.fetchFeatures(),
    templatePromise,
  ]);
  timeEditor.fetchsEnd = performance.now();

  // Concurrent edition check
  channelConnected = `presence-tpleditor-${newTemplate?.id}`;

  let members: Array<{
    username: string;
    id: string;
    name: string;
    email: string;
    account: string;
    isMasterUser: string;
  }> = [];

  let isSameUserEditing = false;

  if (session.value) {
    templatesApp
      .getUsersEditing({
        channel: channelConnected,
        username: session.value?.account?.username,
        name: session.value?.user?.name,
        email: session.value?.user?.email,
        account: session.value?.account?.code,
        isMasterUser: session.value?.isMasterUser,
      })
      .then(({ pusherInstance, members: usersConnected, me }) => {
        pusher = pusherInstance;
        members = usersConnected;

        isSameUserEditing = usersConnected.some(
          (users) => users.account === me.account && users.email === me.email && users.username === me.username,
        );

        const firstUserConnected = members[0];

        if (usersConnected != undefined && usersConnected.length > 0 && firstUserConnected) {
          openMultipleEditingAlertModal(
            `${firstUserConnected.name} ${
              firstUserConnected.isMasterUser === "true"
                ? t("multipleEditingAlertModal.fromPerfit")
                : "(" + firstUserConnected.email + ")"
            }`,
            isSameUserEditing,
          );
          trackingEventsService.amplitude({
            name: "APP_TPLEDITOR_CONCURRENT_WARNING",
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  // End concurrent edition check

  interests.value = contactsStore.getInterests();
  fields.value = contactsStore.getFields();
  accountFeatures.value = featuresStore.getFeatures();

  if (!newTemplate) return;

  if (!props.tplId) {
    newTemplate.tracking.click =
      templatesEditorConfig.value.monitoringOptions.monitorClicks ?? newTemplate.tracking.click;
    newTemplate.tracking.open =
      templatesEditorConfig.value.monitoringOptions.monitorOpenings ?? newTemplate.tracking.open;
    newTemplate.tracking.utm.enabled =
      templatesEditorConfig.value.monitoringOptions.trackAnalytics ?? newTemplate.tracking.utm.enabled;
  }

  window.onmessage = function (e) {
    if (e.data.action === "modalOpened") {
      unlayerModalOpen.value = true;
      editorHeaderIsOpen.value = false;
    }

    if (e.data.action === "modalClosed") {
      unlayerModalOpen.value = false;
      editorHeaderIsOpen.value = editorHeaderIsOpenByUser.value;
    }

    if (e.data.action === "updatePerfitAD") {
      const status = e.data.data.status ? "1" : "0";

      useAccountFeaturesAPI.put({ key: "PERFIT_FOOTER_AD", value: status });
    }

    if (e.data.action === "eventProductSelected") {
      const data = e.data;

      trackingEventsService.dispatchAll({
        name: "APP_TPLEDITOR_PRODUCT_INSERT",
        data: {
          integration: data.data.integration,
        },
        includeMasterUsers: true,
      });
    }

    if (e.data.action === "eventFileUploaded") {
      const data = e.data;

      trackingEventsService.dispatchAll({
        name: "APP_TPLEDITOR_FILE_UPLOAD",
        data: {
          file_name: data.data.fileName,
          file_type: data.data.fileType,
          file_size: data.data.fileSize,
        },
      });
    }
  };

  if (!members === undefined || members.length === 0) {
    startNotifications();
  }

  if (newTemplate.options.duplicated || props.isTemplateFromGallery) {
    templatesApp.clearTimerTools({ template: newTemplate });
  }

  updateTemplate(newTemplate);

  if (!template.value) return;

  if (template.value.type === "unlayer" || !template.value.contents.html) {
    template.value.type = "unlayer";
    await instanceNewEditor({ template: template.value });
  } else {
    templatesApp.attachHTMLToIframe({
      html: template.value.contents.html,
      iframeElement: htmlEditorPreviewRef.value,
    });
    unlayerIsLoading.value = false;
  }

  const hasAutoSave = template.value.autosave.json;

  if (hasAutoSave) openAutoSaveAlertModal();

  initTemplateValues(template.value);

  if (!hasAutoSave && (!props?.tplId || isEmptyTemplate.value)) {
    openTemplateGallery();
    return;
  }

  if (!hasAutoSave) emitEditorTourEvent();
});

//Event Listeners
const beforeUnloadListener = (event: BeforeUnloadEvent) => {
  if (unsavedChanges.value) {
    event.preventDefault();
    return (event.returnValue = "Are you sure you want to leave?");
  }
};

const onPopState = () => {
  autoSave().then(() => {
    close();
  });
};

addEventListener("beforeunload", beforeUnloadListener, { capture: true });
addEventListener("popstate", onPopState);
onUnmounted(() => {
  removeEventListener("beforeunload", beforeUnloadListener, { capture: true });
  removeEventListener("popstate", onPopState);
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "previewButton": "Previsualizar",
    "returnToEditButton": "Volver a editar",
    "saveAndExitButton": "Guardar y salir",
    "autosaveAlert": {
      "confirmationTitle": "Recuperar versión no guardada",
      "confirmationMessage": "Existe una versión de este diseño que no fue guardada correctamente. ¿Qué deseas hacer?",
      "confirmButton": "Revisar",
      "cancelButton": "Descartar"
    },

    "discardModal": {
      "confirmationTitle": "¿Deseas salir sin guardar los cambios?",
      "confirmationMessage": "Estás a punto de abandonar este email y perder los cambios que hayas aplicado. ¿Qué deseas hacer?",
      "confirmButton": "Descartar cambios",
      "cancelButton": "Cancelar"
    },

    "auditErrorModal": {
      "confirmationTitle": "Tu email precisa ajustes",
      "confirmationMessage": "Existen algunos problemas pendientes de corregir. ¿Deseas guardar y salir de todas formas?",
      "confirmButton": "Guardar y salir",
      "dismissText": "No volver a mostrar este mensaje para este email.",
      "cancelButton": "Revisar problemas"
    },

    "auditErrorCriticalModal": {
      "confirmationTitle": "Errores pendientes de corrección",
      "confirmationMessage": "Existen algunos problemas que deben ser corregidos antes de guardar ya que el automation se encuentra activo.",
      "cancelButton": "Revisar problemas"
    },
    "unlayerMaintenanceModal": {
      "confirmationTitle": "El editor no está disponible",
      "confirmationMessage": "La edición de plantillas no está disponible momentaneamente. Por favor intenta más tarde.",
      "cancelButton": "Cerrar"
    },
    "unlayerExportErrorModal": {
      "confirmationTitle": "Demora en guardar",
      "confirmationMessage": "El guardado de la plantilla está tomando más tiempo de lo habitual. Puede deberse a un problema temporal, una conexión lenta o inestable.",
      "cancelButton": "Seguir editando",
      "acceptButton": "Reintentar"
    },

    "multipleEditingAlertModal": {
      "confirmationTitle": "Edición en simultáneo",
      "confirmationMessage": "{user} está editando este email actualmente. Si ambos realizan cambios al mismo tiempo pueden producirse resultados no deseados.",
      "confirmationMessageSameUser": "Estás editando este email actualmente desde otra sesión. Si realizas cambios al mismo tiempo pueden producirse resultados no deseados.",
      "confirmButton": "Entiendo los riesgos",
      "cancelButton": "Salir y volver luego",
      "fromPerfit": "de Perfit"
    },

    "startAgainModal": {
      "confirmationTitle": "¿Desea empezar de nuevo?",
      "confirmationMessage": "Se descartará el contenido del diseño actual sin guardar los cambios que hayas aplicado.",
      "confirmButton": "Descartar y reiniciar",
      "cancelButton": "Cancelar"
    },

    "autosave": {
      "autosaveFound": "Existe una versión de este diseño que no fue guardada correctamente. ¿Deseas recuperarla?",
      "autosaveFoundButton": "Revisar y restaurar plantilla"
    },

    "invalidTemplateName": {
      "errorTitle": "El nombre que seleccionaste ya existe",
      "errorMessage": "Prueba con otro nombre de plantilla para poder continuar"
    },

    "errorCustomTools": {
      "errorTitle": "Error al inicializar el editor",
      "errorMessage": "Intenta nuevamente. Si el problema persiste por favor ponte en contacto con soporte."
    },

    "errorRestoreTemplate": {
      "errorTitle": "Hubo un error al intentar restaurar la plantilla",
      "errorMessage": "La plantilla no tiene un id valido para restaurarse"
    },
    "getIntegrations": {
      "errorTitle": "Error al obtener las integraciones",
      "errorMessage": "Hubo un problema para obtener las integraciones de la cuenta"
    },
    "menuOptions": {
      "startAgain": "Empezar de nuevo",
      "versionHistory": "Historial de versiones",
      "fontAdjustments": "Ajustes de fuentes",
      "importTemplate": "Importar template",
      "exportTemplate": "Exportar template",
      "discardChanges": "Descartar cambios y salir"
    }
  },
  "pt": {
    "previewButton": "Pré-visualizar",
    "returnToEditButton": "Volver a editar",
    "saveAndExitButton": "Salvar e sair",
    "autosaveAlert": {
      "confirmationTitle": "Recuperar versão não salva",
      "confirmationMessage": "Existe uma versão deste template que não foi salva corretamente. O que você deseja fazer?",
      "confirmButton": "Revisar",
      "cancelButton": "Eliminar"
    },

    "discardModal": {
      "confirmationTitle": "Deseja sair sem salvar as modificações?",
      "confirmationMessage": "Você está prestes a abandonar este template e perder as modificações aplicadas. O que deseja fazer?",
      "confirmButton": "Descartar modificações",
      "cancelButton": "Cancelar"
    },

    "auditErrorModal": {
      "confirmationTitle": "Seu template precisa de ajustes",
      "confirmationMessage": "Encontramos alguns problemas pendentes de correção no seu template. Recomendamos verifica-los. Deseja salvar e sair?",
      "confirmButton": "Salvar e sair",
      "dismissText": "Não mostrar esta mensagem no template atual.",
      "cancelButton": "Revisar erros"
    },

    "multipleEditingAlertModal": {
      "confirmationTitle": "Edição simultânea",
      "confirmationMessage": "{user} está editando este e-mail no momento. Se ambos fizerem alterações ao mesmo tempo, resultados indesejados podem ocorrer.",
      "confirmationMessageSameUser": "Você está editando este e-mail no momento de outra sessão. Se fizer alterações ao mesmo tempo, resultados indesejados podem ocorrer.",
      "confirmButton": "Entendo os riscos",
      "cancelButton": "Sair e voltar depois",
      "fromPerfit": "da Perfit"
    },

    "auditErrorCriticalModal": {
      "confirmationTitle": "Erros pendentes de correção",
      "confirmationMessage": "Existem alguns problemas que devem ser corrigidos antes de salvar, pois o automation se encontra ativo.",
      "cancelButton": "Revisar erros"
    },

    "unlayerMaintenanceModal": {
      "confirmationTitle": "O editor não está disponível",
      "confirmationMessage": "A edição de modelos não está disponível no momento. Por favor, tente novamente mais tarde.",
      "cancelButton": "Fechar"
    },

    "unlayerExportErrorModal": {
      "confirmationTitle": "Demora em salvar",
      "confirmationMessage": "Salvar o desenho está demorando mais que o normal. Poder ser devido a um problema temporário, uma conexão lento ou instável.",
      "cancelButton": "Continuar editando",
      "acceptButton": "Tentar novamente"
    },

    "startAgainModal": {
      "confirmationTitle": "Deseja começar novamente?",
      "confirmationMessage": "Não serão consideradas as mudanças aplicadas no conteúdo do template atual.",
      "confirmButton": "Descartar e reiniciar",
      "cancelButton": "Cancelar"
    },

    "autosave": {
      "autosaveFound": "Existe uma versão deste template que não foi salva corretamente. Deseja recupera-la?",
      "autosaveFoundButton": "Revisar e restaurar template"
    },

    "invalidTemplateName": {
      "errorTitle": "O nome selecionado já existe",
      "errorMessage": "Tente com outro nome para poder continuar"
    },

    "errorCustomTools": {
      "errorTitle": "Erro ao inicializar o editor",
      "errorMessage": "Tente novamente. Se o problema persistir, entre em contato com o suporte."
    },
    "menuOptions": {
      "startAgain": "Começar novamente",
      "versionHistory": "Histórico de versões",
      "fontAdjustments": "Ajustes de fontes",
      "importTemplate": "Importar template",
      "exportTemplate": "Exportar template",
      "discardChanges": "Descartar modificações"
    }
  }
}
</i18n>
