<template>
  <div>
    <div v-if="loadingState" class="flex-col-reverse flex items-center"> 
      <LoadingSpinner class="h-5 w-5 text-sky-500" aria-hidden="true" />
    </div>
    <div v-else="loadingState">
      <div v-if="hascustomFieldsOptions">
        <FormGroup id="custom-fields-select" :label="t('customFields.label')">
          <SelectMenu
            :data-items="customFieldsOptions"
            :selected="selectedCustomFields"
            @update:selected="handleFieldSelection"
          />
        </FormGroup>

        <input type="hidden" name="order_custom_fields_id" :value="selectedCustomFields.key" />

        <FormGroup
          v-if="selectedCustomFields.data?.value_type == 'text_list'"
          id="custom-fields-value-type-text-list"
          :label="t('customFields.value_type.text_list')"
        >
          <SelectMenu
            :data-items="transformedValues"
            :selected="selectedCustomFieldsValueTypeTextListValue"
            @update:selected="handleFieldValueTypeTextList"
          />
        </FormGroup>

        <FormGroup
          v-if="selectedCustomFields.data?.value_type && selectedCustomFields.data?.value_type !== 'text_list'"
          id="custom-fields-value-type"
          :label="t('customFields.value_type.text_input')"
        >
          <SimpleInput :type="inputType" :model-value="inputModelValue" @update:model-value="UpdateInputModelValue" />
        </FormGroup>

        <input type="hidden" name="order_custom_fields_value" :value="inputHiddenValue" />
      </div>
      <div v-if="!hascustomFieldsOptions">
        <p>{{ t("customFields.empty.text") }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

// API
import { useAutomationsOrderCustomFields } from "@api/modules/automations/automationsOrderCustomFields";

// Components
import FormGroup from "@molecules/FormGroup.vue";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";
import SimpleInput from "@atoms/SimpleInput.vue";
import SelectMenu from "@molecules/SelectMenu.vue";

// Types
import type { CustomFields } from "@/vue/api/modules/automations/automationsOrderCustomFields.types";
import type { DataItem } from "@molecules/SelectMenu.vue";

interface customFieldsData {
  value_type: "text" | "numeric" | "date" | "text_list";
  description: string;
  values: Array<string>;
}

const props = withDefaults(
  defineProps<{
    inputModelValue: string;
    customFieldsSelected: DataItem<customFieldsData> | undefined;
  }>(),
  {
    inputModelValue: "",
    customFieldsSelected: undefined,
  },
);

// States
const { t } = useI18n();
const automationsOrderCustomFields = useAutomationsOrderCustomFields();
const customFields = ref<CustomFields>([]);

const inputModelValue = ref(props.inputModelValue);
const inputHiddenValue = ref("");
const loadingState = ref(false);

const customFieldsOptions = computed<DataItem<customFieldsData>[]>(() => {
  if (customFields.value.length > 0) {
    return customFields.value
      .filter((field) => field.value_type !== "date") // En esta primera instancia no vamos a mostrar los valores de tipo Date
      .map((field) => ({
        key: field.id,
        value: field.name,
        data: {
          value_type: field.value_type,
          description: field.description,
          values: field.values,
        },
      }));
  }
  return [];
});

const selectedCustomFields = computed<DataItem<customFieldsData>>(() => {
  return (
    customFieldsOptions.value.find((option) => option.key === selectedCustomFieldsKey.value) ??
    customFieldsOptions.value[0]
  );
});

const manualSelectedValue = ref<{ key: string; value: string } | undefined>(undefined);
const selectedCustomFieldsValueTypeTextListValue = computed(() => {
  if (manualSelectedValue.value) {
    return manualSelectedValue.value;
  }

  if (selectedCustomFields.value?.key && selectedCustomFields.value.data?.value_type === "text_list") {
    const matchedOption = customFields.value.find(
      (field) => field.id === selectedCustomFields.value.key && field.value_type === "text_list",
    );

    if (matchedOption && Array.isArray(matchedOption.values)) {
      const matchingValue = matchedOption.values.find((value) => value === inputModelValue.value);
      if (matchingValue) {
        return { key: matchingValue, value: matchingValue };
      }
    }
  }

  if (selectedCustomFields.value?.data?.value_type === "text_list" && customFields.value.length > 0) {
    const defaultOption = customFields.value.find(
      (field) => field.id === selectedCustomFields.value.key && field.value_type === "text_list",
    );
    if (defaultOption && defaultOption.values.length > 0) {
      return { key: defaultOption.values[0], value: defaultOption.values[0] };
    }
  }

  return undefined;
});
const hascustomFieldsOptions = computed<boolean>(() => {
  return customFieldsOptions.value.length > 0;
});

const inputType = computed(() => {
  const valueType = selectedCustomFields.value?.data?.value_type;
  if (valueType === "numeric") return "number";
  if (valueType === "date") return "date";
  return "text";
});

const selectedCustomFieldsKey = ref<string | undefined>(props.customFieldsSelected?.key);

// Handles
const handleFieldSelection = (newValue) => {
  selectedCustomFieldsKey.value = newValue.key;
  manualSelectedValue.value = undefined;
};
const handleFieldValueTypeTextList = (newValue) => {
  manualSelectedValue.value = newValue;
  inputHiddenValue.value = newValue?.key;
};

const transformedValues = computed(() => {
  const data = selectedCustomFields.value.data;
  if (data?.value_type === "text_list" && Array.isArray(data.values)) {
    return data.values.map((value) => ({ key: value, value }));
  }
  return [];
});

//Emits
const emit = defineEmits<{
  (e: "update:inputModelValue", value: string): void;
}>();

const UpdateInputModelValue = (value: string) => {
  inputModelValue.value = value;
  emit("update:inputModelValue", value);
  inputHiddenValue.value = value;
};

// Methods
const listCustomFields = async () => {
  try {
    loadingState.value = true;
    customFields.value = await automationsOrderCustomFields.list();
    console.log("customFields", customFields.value);
  } catch (e) {
    console.log(e);
  } finally {
    loadingState.value = false;
  }
};

onMounted(async () => {
  await listCustomFields();
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "customFields": {
      "label": "Selecciona un campo personalizado",
      "value_type": {
        "text_list": "Selecciona un valor del campo personalizado",
        "text_input": "Ingresa un valor del campo personalizado"
      },
      "empty": {
        "text": "No existen campos personalizados en ventas. Debes ingresar a tu tienda y crearlos para avanzar con la configuración de esta automatización."
      }
    }
  },
  "pt": {
    "customFields": {
      "label": "Selecione um campo personalizado",
      "value_type": {
        "text_list": "Selecione um valor do campo personalizado",
        "text_input": "Insira um valor do campo personalizado"
      },
      "empty": {
        "text": "Não existem campos personalizados em vendas. Você deve acessar sua loja e criá-los para continuar com a configuração desta automação."
      }
    }
  }
}
</i18n>
