// bannedDomains
let bannedDomains = [
  "1url.com/",
  "6url.ru/",
  "acortar.link",
  "adf.ly/",
  "bc.vc/",
  "bit.do/",
  "bit.ly/",
  "bitly.com/",
  "bl.ink/",
  "buff.ly/",
  "buzurl.com/",
  "cur.lv/",
  "cutt.us/",
  "db.tt/",
  "filoops.info/",
  "is.gd/",
  "ity.im/",
  "j.mp/",
  "link.zip.net/",
  "lnkd.in/",
  "mcaf.ee/",
  "ow.ly/",
  "po.st/",
  "prettylinkpro.com/",
  "q.gs/",
  "qr.ae/",
  "qr.net/",
  "scrnch.me/",
  "short.io/",
  "su.pr/",
  "t.co/",
  "tinyurl.com/",
  "tr.im/",
  "tweez.me/",
  "twitthis.com/",
  "u.bb/",
  "u.to/",
  "v.gd/",
  "vzturl.com/",
  "x.co/",
  "yourls.org/",
];

export const getBannedDomains = () => {
  return bannedDomains;
};

export type UnlayerValidator = {
  id: string;
  icon: FontAwesomeIcon | UrlIcon;
  severity: "WARNING" | "ERROR";
  title: string;
  location?: {
    collection: string;
    id: string;
  };
  description: string;
  tool?: {
    type: string;
  };
};

export type UnlayerValidators = Array<UnlayerValidator>;

export type Locales =
  | "es"
  | "pt"
  | "en"
  | "es-AR"
  | "es-BO"
  | "es-BR"
  | "es-CA"
  | "es-CL"
  | "es-CO"
  | "es-CR"
  | "es-ES"
  | "es-EC"
  | "es-GT"
  | "es-HN"
  | "es-MX"
  | "es-PA"
  | "es-PE"
  | "es-PY"
  | "es-UY"
  | "es-PT"
  | "es-SV"
  | "es-US"
  | "es-VE"
  | "pt-AR"
  | "pt-BR"
  | "pt-PT"
  | "en-AR"
  | "en-US"
  | "en-BR"
  | "en-MX";

export type UnlayerLocales = "pt-BR" | "pt-PT" | "es-ES" | "en-CA" | "en-US";

export const isValidUnlayerTemplate = (json: UnlayerJSON | unknown): json is UnlayerJSON => {
  const validations = [
    (json as UnlayerJSON)?.body?.rows != undefined,
    (json as UnlayerJSON)?.body?.values != undefined,
    (json as UnlayerJSON)?.counters != undefined,
    (json as UnlayerJSON)?.schemaVersion != undefined,
  ];

  return validations.every(Boolean);
};

export enum FooterLocales {
  es = "es",
  pt = "pt",
  en = "en",
  fr = "fr",
  iw = "iw",
}

export type UnsubscribeLinkOptions = Record<FooterLocales, Array<{ key: string; value: string }>>;

export type EditorLocales = "pt-PT" | "es-ES" | "pt-BR" | "fr-FR" | "fr-CA" | "en-CA" | "en-US";

export type UnlayerTranslationRecord = Record<string, string | Record<string, string>>;

export type UnlayerExportHTMLDataParams = {
  design: JSONString;
  html: string;
};

export type UnlayerExportTextDataParams = {
  design: JSONString;
  text: string;
};

export type UnlayerAuditResults = {
  status: "PASS" | "WARNING" | "FAIL";
  errors: Array<{ id: string; severity: string }>;
};

export type UnlayerToolValidatorParams = {
  defaultErrors: Array<UnlayerValidator>;
  values: unknown;
};

type Values = {
  displayCondition?: {
    after: string;
    before: string;
    description: string;
    label: string;
  } & Record<string, unknown>;
} & Record<string, unknown>;

export interface Content {
  id: string;
  type: string;
  slug: string;
  values: Values;
}

export type Contents = Array<Content>;

export interface Column {
  id: string;
  contents: Contents;
  values: Values;
}
export type Columns = Array<Column>;

export interface Row {
  id: string;
  cells: Array<number>;
  columns: Columns;
  values: Values;
}

export type Rows = Array<Row>;

export interface TemplateDesign {
  body: {
    id: string;
    rows: Rows;
    values: Values;
  };
  counters: unknown;
  schemaVersion: number;
}

export type UnlayerValidatorParams = {
  html: string;
  defaultErrors: Array<UnlayerValidator>;
};

export interface DisplayCondition {
  type: string;
  label: string;
  description: string;
  before: string;
  after: string;
}

export interface TemplateBlock {
  id?: string;
  userId: string;
  displayMode: "email";
  category: string;
  tags: string[];
  data: any;
  custom: boolean;
}

export type DisplayConditions = Array<DisplayCondition>;

export interface UnlayerInitConfig {
  id: string; // div id mount
  projectId: 77966; //Perfit unique ID
  templateId?: number;
  displayMode: "email"; // "email" | "web"
  locale?: EditorLocales;
  safeHtml?: boolean;

  user?: {
    id: string; //Allows user saved blocks
    name?: string;
    email?: string;
  };

  features?: {
    textEditor?: {
      spellChecker: boolean;
      tables: boolean;
      cleanPaste: boolean;
    };
    preheaderText?: boolean;
    preview?: boolean;
    imageEditor?: boolean;
    userUploads?: boolean;
    stockImages?: {
      enabled: boolean;
      safeSearch: boolean;
      defaultSearchTerm: string;
    };
    audit?: boolean;
    ai?: boolean;
    smartMergeTags?: boolean;
  };

  mergeTagsConfig?: {
    sort?: boolean;
  };

  appearance?: {
    theme: "light" | "dark";
    panels?: {
      tools: {
        dock: "left" | "right";
      };
    };
    loader?: {
      html: string; //HTML string
      css: string; //CSS string
    };
  };

  editor?: {
    autoSelectOnDrop?: boolean;
  };

  specialLinks?: Array<{ name: string; href: string; target: string }> | [];

  linkTypes?: Array<{
    name?: string;
    enabled?: boolean;
    label?: string;
    attrs?: Record<string, string>;
    fields?:
      | Array<{
          name: string;
          label: string;
          defaultValue: string;
          inputType?: "text" | "number" | "email" | "url" | "tel" | "date" | "time" | "textarea";
          placeholderText?: string | null;
          options?: Array<unknown>;
        }>
      | [];
  }>;

  tools: any;
  // tools?: {
  //   text?: {
  //     icon?: string;
  //     properties?: {
  //       text: {
  //         value: string;
  //       };
  //     };
  //   };
  //   button?: {
  //     icon?: string;
  //     properties?: {
  //       text: {
  //         value: string;
  //       };
  //     };
  //   };
  //   image?: {
  //     icon?: string;
  //   };
  //   divider?: {
  //     icon?: string;
  //   };
  // };

  tabs?: {
    content?: {
      icon?: string;
    };
    blocks?: {
      icon?: string;
    };
    body?: {
      icon?: string;
    };
    images?: {
      icon?: string;
    };
    userUploads?: {
      icon?: string;
    };
    audit?: {
      icon?: string;
    };
  };

  mergeTags?: Record<
    string,
    {
      name?: string;
      value?: string;
      sample?: string;
    }
  >;

  designTags?: {
    business_name?: string;
    postal_address?: string;
  };

  fonts?: {
    showDefaultFonts?: boolean;
    customFonts?: Array<{ label: string; value: string; url?: UrlString }>;
  };

  customCSS?: string | Array<string>;
  customJS?: string | Array<string>;

  translations?: UnlayerTranslationRecord;
}

//Unlayer Patch -> waiting for unlayer support for types
type UnlayerInstance = Omit<Unlayer, "createEditor">;

export interface Unlayer {
  React: any;
  versions: {
    current: string;
    latest: string;
    stable: string;
  };
  createEditor: (config: UnlayerInitConfig) => UnlayerInstance;
  loadBlank: (params?: { backgroundColor: HexColor }) => void;
  exportHtml: (
    callback: (data: UnlayerExportHTMLDataParams) => void,
    options: {
      minify: boolean;
    },
  ) => void;
  exportPlainText: (
    callback: (data: UnlayerExportTextDataParams) => void,
    options: {
      ignoreLinks: boolean;
      ignoreImages: boolean;
      ignorePreheader: boolean;
    },
  ) => void;
  audit: (callback: (data: UnlayerAuditResults) => void) => void;
  setValidator: (callback: (data: UnlayerValidatorParams) => void) => void;
  setToolValidator: (element: string, callback: (data: UnlayerToolValidatorParams) => void) => void;
  loadDesign: (json: TemplateDesign) => void;
  addEventListener: (event: string, callback: () => void) => void;
  setBodyValues: (options: { contentWidth?: string; preheaderText: string }) => void;
  setDisplayConditions: (conditions: DisplayConditions) => void;
  registerTool: (options: Record<string, any>) => void;
  registerPropertyEditor: (options: Record<string, any>) => void;
  registerCallback: (
    event: string,
    callback: (block: TemplateBlock, done: (block: TemplateBlock) => void) => void,
  ) => void;
  registerProvider: (
    type: string,
    provider: (params: { category: string; search: string }, done: (blocks: TemplateBlock[]) => void) => void,
  ) => void;
  createViewer: (params: { render: (values: any) => string }) => void;
}
