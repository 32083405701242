// Types
import type { Industry } from "@domain/account";
import type { AuthenticationApplication } from "@application";
import { isLoginAccountsResponse } from "@application/ports";

// Utils
import { ok, err } from "neverthrow";
import { addDays } from "date-fns";
import { createId } from "@paralleldrive/cuid2";

// Application
import { useGeoLocationApplication } from "@application";

// Services
import { useAuthenticationService, useCookiesService, useSessionService } from "@services";
import { isAuthenticationObject } from "@/vue/types/authentication";

export const useAuthenticationApp = (): AuthenticationApplication => {
  const authService = useAuthenticationService();
  const cookiesService = useCookiesService();
  const sessionService = useSessionService();
  const geoLocationApp = useGeoLocationApplication();

  function getDeviceId() {
    const currentDeviceId = cookiesService.getCookie({
      name: "perfit-device-id",
    });

    return currentDeviceId;
  }

  function setDeviceId(deviceId: string) {
    cookiesService.setCookie({
      cname: "perfit-device-id",
      exDays: 180,
      value: deviceId,
    });
  }

  function createDeviceId() {
    const deviceId = createId();

    return deviceId;
  }

  function updateDeviceId() {
    let currentDeviceId = getDeviceId();

    if (!currentDeviceId) {
      currentDeviceId = createDeviceId();
    }

    setDeviceId(currentDeviceId);

    return currentDeviceId;
  }

  const authApp: AuthenticationApplication = {
    async loginApp(params) {
      const authData = params.data;

      // Array de permisos que se utilizan en backbone
      localStorage.setItem("acl", JSON.stringify(authData.legacyResponse.acl));

      // TODO AUTH: app.initLang
      let navigatorLang = navigator.language && navigator.language.slice(0, 2);

      const res = await geoLocationApp.detectCountry();

      // Override del lang por si en brasil tienen navegador en ingles
      if (res.isOk() && res.value === "br") {
        navigatorLang = "pt";
      }

      let lang =
        (authData.legacyResponse &&
          authData.legacyResponse.userType !== "MASTER" &&
          authData.legacyResponse.details?.user?.language) ||
        (navigatorLang && ["es", "pt"].includes(navigatorLang) ? navigatorLang : "es");

      const langStored = localStorage.getItem("lang");
      if (!authData.legacyResponse && langStored) {
        lang = JSON.parse(langStored);
      }

      window.app.lang = lang;

      cookiesService.setCookie({
        cname: "session",
        exDays: 1,
        value: encodeURIComponent(
          JSON.stringify({
            mainAccount: authData.legacyResponse.account,
            expires: authData.tokenExpiration,
            account: authData.legacyResponse.account,
            details: authData.legacyResponse.details,
            token: authData.legacyResponse.token,
            tokenExpiration: authData.legacyResponse.tokenExpiration,
            user: authData.legacyResponse.user,
            userType: authData.legacyResponse.userType,
          }),
        ),
      });

      // Cookie for communication between apps
      const domain = window.location.hostname.includes("localhost") ? "localhost" : window.config.domain;
      const environment = window.config.environment;

      cookiesService.setCookie({
        cname: `session.${environment}`,
        exDays: 1,
        domain: domain,
        value: encodeURIComponent(
          JSON.stringify({
            token: authData.legacyResponse.token,
            tokenExpirationDate: addDays(new Date(), 1).toUTCString(),
            accountId: authData.legacyResponse.account,
            language: lang === "pt" ? "pt" : "es",
            isMasterUser: authData.legacyResponse.userType === "MASTER",
          }),
        ),
      });

      // Se agrega esta cookie en backbone
      cookiesService.deleteCookie({
        name: "expired",
      });

      return ok(undefined);
    },
    async login(params) {
      const deviceId = updateDeviceId();

      const loginRes = await authService.login({
        user: params.user,
        password: params.password,
        account: params.account,
        deviceId: deviceId,
      });

      if (loginRes.isErr()) {
        return err(loginRes.error);
      }

      if (isLoginAccountsResponse(loginRes.value)) {
        return ok(loginRes.value);
      }

      await this.loginApp({ data: loginRes.value });

      return ok(undefined);
    },
    async loginTiendanube(params) {
      const deviceId = updateDeviceId();

      try {
        const loginRes = await authService.loginTiendanube({
          code: params.code,
          deviceId: deviceId,
        });

        if (loginRes.isErr()) {
          return err(undefined);
        }

        if (isAuthenticationObject(loginRes.value)) {
          await this.loginApp({ data: loginRes.value });
          return ok({
            type: "loggedIn",
          });
        }

        return ok({
          type: "signup_required",
          data: {
            address: loginRes.value.store.business_address ?? "",
            businessName: loginRes.value.store.business_name ?? "",
            email: loginRes.value.store.email ?? "",
            website: loginRes.value.store.url_with_protocol,
            preinit: loginRes.value.preinit,
            country: loginRes.value.store.country,
            name: loginRes.value.store.name,
            industry: loginRes.value.store.type as Industry,
            phone: loginRes.value.store.phone,
            tnPlan: loginRes.value.store.plan_name,
            tnCreatedAt: loginRes.value.store.created_at,
          },
        });
      } catch (e) {
        return err(undefined);
      }
    },
    async loginWithToken(params) {
      const res = await sessionService.getWithToken(params);

      if (res.isErr()) {
        return err(res.error);
      }

      // Array de permisos que se utilizan en backbone
      localStorage.setItem("acl", JSON.stringify(res.value.acl));

      // TODO AUTH: app.initLang
      let navigatorLang = navigator.language && navigator.language.slice(0, 2);

      const geoRes = await geoLocationApp.detectCountry();

      // Override del lang por si en brasil tienen navegador en ingles
      if (geoRes.isOk() && geoRes.value === "br") {
        navigatorLang = "pt";
      }

      let lang =
        (res.value && res.value.userType !== "MASTER" && res.value.details?.user?.language) ||
        (navigatorLang && ["es", "pt"].includes(navigatorLang) ? navigatorLang : "es");

      const langStored = localStorage.getItem("lang");
      if (!res.value && langStored) {
        lang = JSON.parse(langStored);
      }

      window.app.lang = lang;

      cookiesService.setCookie({
        cname: "session",
        exDays: 1,
        value: encodeURIComponent(
          JSON.stringify({
            mainAccount: res.value.account,
            expires: res.value.tokenExpiration,
            account: res.value.account,
            details: res.value.details,
            token: res.value.token,
            tokenExpiration: res.value.tokenExpiration,
            user: res.value.user,
            userType: res.value.userType,
          }),
        ),
      });

      // Cookie for communication between apps
      const domain = window.location.hostname.includes("localhost") ? "localhost" : window.config.domain;
      const environment = window.config.environment;

      cookiesService.setCookie({
        cname: `session.${environment}`,
        exDays: 1,
        domain: domain,
        value: encodeURIComponent(
          JSON.stringify({
            token: res.value.token,
            tokenExpirationDate: addDays(new Date(), 1).toUTCString(),
            accountId: res.value.account,
            language: lang === "pt" ? "pt" : "es",
            isMasterUser: res.value.userType === "MASTER",
          }),
        ),
      });

      // Se agrega esta cookie en backbone
      cookiesService.deleteCookie({
        name: "expired",
      });

      return ok(res.value);
    },
    async resetPassword(params) {
      const resetPasswordRes = await authService.resetPassword({
        user: params.user,
        account: params.account !== "" ? params.account : undefined,
      });

      if (resetPasswordRes.isErr() && resetPasswordRes.error.type === "ACCOUNT_REQUIRED") {
        return err({
          type: "ACCOUNT_REQUIRED",
        });
      }

      if (resetPasswordRes.isErr() && resetPasswordRes.error.type === "VALIDATION_ERROR") {
        return err({
          type: "VALIDATION_ERROR",
          invalidFields: {
            email: resetPasswordRes.error.invalidFields.email,
          },
        });
      }

      if (resetPasswordRes.isErr() && resetPasswordRes.error.type === "UNAUTHORIZED") {
        return err({
          type: "UNAUTHORIZED",
        });
      }

      return ok(undefined);
    },
    async updatePassword(params) {
      const res = await authService.updatePassword(params);
      if (res.isErr()) {
        return err(res.error);
      }

      await this.loginApp({ data: res.value });

      return ok(undefined);
    },
    async signup(params) {
      const deviceId = updateDeviceId();

      let navigatorLang = navigator.language && navigator.language.slice(0, 2);

      const res = await geoLocationApp.detectCountry();

      // Override del lang por si en brasil tienen navegador en ingles
      if (res.isOk() && res.value === "br") {
        navigatorLang = "pt";
      }

      const loginRes = await authService.signup({
        country: params.country,
        store: params.store,
        website: params.website,
        password: params.password,
        email: params.email,
        code: params.code,
        businessName: params.businessName,
        lang: navigatorLang,
        deviceId: deviceId,
      });

      if (loginRes.isErr()) {
        return err(loginRes.error);
      }

      if (isAuthenticationObject(loginRes.value)) {
        await this.loginApp({ data: loginRes.value });
      }

      return ok(undefined);
    },
    async signupTiendanube(params) {
      const deviceId = updateDeviceId();

      let navigatorLang = navigator.language && navigator.language.slice(0, 2);

      const res = await geoLocationApp.detectCountry();

      // Override del lang por si en brasil tienen navegador en ingles
      if (res.isOk() && res.value === "br") {
        navigatorLang = "pt";
      }

      const loginRes = await authService.signupTiendanube({ ...params, deviceId: deviceId, lang: navigatorLang });

      if (loginRes.isErr()) {
        return err(loginRes.error);
      }

      if (isAuthenticationObject(loginRes.value)) {
        await this.loginApp({ data: loginRes.value });
      }

      return ok(undefined);
    },
  };

  return authApp;
};
