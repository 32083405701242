import { ref, computed, watchEffect, reactive } from "vue";

// Utils
import { differenceInDays } from "date-fns";
import Intercom from "@helpers/intercom";

// Store
import { defineStore, storeToRefs } from "pinia";
import {
  useIntegrationsStore,
  useAccountStore,
  useSendersStore,
  useUserStore,
  useSessionStore,
  useContactsStore,
  useUserActivityStore,
  useInvoicePaymentsStore,
  useAlertStore,
} from "@store";

// Application
import { useRouterApp, useUserActivityApplication } from "@application";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Domain
import {
  Ecommerce,
  ecommerceHasAbandonedCart,
  ecommerceHasOptinSupport,
  isEcommerceId,
  isSupportedEcommerce,
} from "@domain/ecommerce";

import type { Rewards, RewardId, TaskIds, Tasks } from "@domain/onboarding";
import { getTaskIdFromEventAction, getEventActionFromTaskId } from "@domain/onboarding";

import {
  getTaskIdFromEvent,
  isOmittedEvent,
  isRewardClaimedEvent,
  isRewardEvent,
  isRewardOfferedEvent,
  isUserActivityOnboardingState,
  getRewardIdFromEventAction,
  isAutoOmittedEvent,
  isMarkedCompletedEvent,
} from "@domain/userActivity";

import type { UserActivityAction, UserActivityData, UserActivityOnboardingData } from "@domain/userActivity";

// I18n
import { getI18nInstance } from "@locales/i18n";
import esMessages from "./i18n/onboarding.es.json";
import ptMessages from "./i18n/onboarding.pt.json";
import { useBrand } from "@/vue/api/modules/brand/brandEditor";

export const useOnboardingStore = defineStore("onboardingStore", () => {
  const userActivityApp = useUserActivityApplication();
  const routerApp = useRouterApp();

  const sendersStore = useSendersStore();
  const userStore = useUserStore();
  const sessionStore = useSessionStore();
  const contactsStore = useContactsStore();
  const accountStore = useAccountStore();
  const integrationsStore = useIntegrationsStore();
  const onboardingStore = useOnboardingStore();
  const userActivity = useUserActivityStore();
  const invoicePayment = useInvoicePaymentsStore();
  const alertStore = useAlertStore();
  const trackingEventsService = useTrackingEvents();
  const brandApi = useBrand();

  const { events, eventsFetched } = storeToRefs(userActivity);
  const { t, mergeLocaleMessage } = getI18nInstance().global;

  mergeLocaleMessage("es", esMessages);
  mergeLocaleMessage("pt", ptMessages);

  const rewards = reactive<Rewards>({
    trial: {
      daysToClaim: 30,
      enable: true,
      offered: false,
      claimed: false,
      async execute() {
        if (sessionStore.session.isMasterUser) return;

        console.log("apply trial");
        userActivityApp.submit({
          action: `reward.trial.claimed`,
        });
        this.claimed = true;
        alertStore.update();
      },
    },
    demo: {
      daysToClaim: 30,
      enable: true,
      offered: false,
      claimed: false,
      async execute() {
        if (sessionStore.session.isMasterUser) return;

        const esUrl = "https://calendly.com/d/dnc-y2d-3cv?hide_gdpr_banner=1";
        const ptUrl = "https://calendly.com/d/dnn-kmy-hzj?hide_gdpr_banner=1";

        window.Calendly.showPopupWidget(sessionStore.session.lang === "pt" ? ptUrl : esUrl);
        userActivityApp.submit({
          action: `reward.demo.claimed`,
        });
        this.claimed = true;
      },
    },
    discount: {
      daysToClaim: 30,
      enable: true,
      offered: false,
      claimed: false,
      async execute() {
        if (sessionStore.session.isMasterUser) return;

        console.log("show discount");
        userActivityApp.submit({
          action: `reward.discount.claimed`,
        });
        this.claimed = true;
      },
    },
    customTemplate: {
      daysToClaim: 30,
      enable: true,
      offered: false,
      claimed: false,
      async execute() {
        if (sessionStore.session.isMasterUser) return;

        Intercom.showNewMessage(t(`reward.customTemplate.message`));
        userActivityApp.submit({
          action: `reward.customTemplate.claimed`,
        });
        this.claimed = true;
      },
    },
    workshop2500Contacts: {
      // daysToClaim: 30,
      enable: true,
      offered: false,
      claimed: false,
      async execute() {
        if (sessionStore.session.isMasterUser) return;

        window.open("https://www.google.com.ar", "_blank");
        userActivityApp.submit({
          action: `reward.workshop2500Contacts.claimed`,
        });
        this.claimed = true;
      },
    },
    workshopFewContacts: {
      // daysToClaim: 30,
      enable: true,
      offered: false,
      claimed: false,
      async execute() {
        if (sessionStore.session.isMasterUser) return;

        window.open("https://www.google.com.ar", "_blank");
        userActivityApp.submit({
          action: `reward.workshopFewContacts.claimed`,
        });
        this.claimed = true;
      },
    },
  });

  // * ----- Rewards update status with events -----
  watchEffect(() => {
    rewards.trial.enable =
      sessionStore.trialStatus === "none" &&
      (!sessionStore.plan?.type || sessionStore.plan?.type === "FREE") &&
      contactsStore.contactsCount < 500;

    rewards.discount.enable = invoicePayment.invoices.length === 0;

    if (!eventsFetched.value) {
      return;
    }

    events.value.forEach((event) => {
      if (!isRewardEvent(event.action)) return;

      const rewardId = getRewardIdFromEventAction(event.action);
      const daysToClaim = rewards[rewardId].daysToClaim;

      if (isRewardClaimedEvent(event.action)) {
        rewards[rewardId].enable = false;
        rewards[rewardId].claimed = true;
        return;
      }

      if (isRewardOfferedEvent(event.action) && daysToClaim) {
        rewards[rewardId].enable = Math.abs(differenceInDays(new Date(), new Date(event.created))) <= daysToClaim;
        rewards[rewardId].offered = true;
        return;
      }
    });
  });

  const firstTasksState = ref<Tasks>();
  const tasks = reactive<Tasks>({
    campaignSent: {
      completed: false,
      omitted: false,
      enable: true,
      action: async () => {
        routerApp.navigate({
          path: "campaigns?campaign_create=true",
        });
      },
      moreAction: () => {
        window.Intercom("showArticle", 1079);
      },
    },
    importList: {
      completed: false,
      omitted: false,
      enable: true,
      action: async () => {
        routerApp.navigate({
          path: "lists/import",
        });
      },
      moreAction: () => {
        window.Intercom("showArticle", 873);
      },
      omit: async () => {
        await onboardingStore.setTaskOmitted({
          id: "importList",
        });
      },
    },
    collaboration: {
      completed: false,
      omitted: false,
      enable: true,
      omit: async () => {
        await onboardingStore.setTaskOmitted({
          id: "collaboration",
        });
      },
    },
    enableWelcomeAutomation: {
      completed: false,
      omitted: false,
      enable: true,
      action: async () => {
        routerApp.navigate({
          path: "automations/all?autom_category=welcome",
        });
      },
      moreAction: () => {
        window.Intercom("showArticle", 6671345);
      },
      markComplete: async () => {
        await onboardingStore.setTaskMarkedComplete({
          id: "enableWelcomeAutomation",
        });
      },
    },
    enableOptin: {
      completed: false,
      omitted: false,
      enable: true,
      action: async () => {
        routerApp.navigate({
          path: "optins/new",
        });
      },
      moreAction: () => {
        window.Intercom("showArticle", 1748);
      },
      markComplete: async () => {
        await onboardingStore.setTaskMarkedComplete({
          id: "enableOptin",
        });
      },
    },
    sender: {
      completed: false,
      omitted: false,
      enable: true,
      moreAction: () => {
        window.Intercom("showArticle", 1820858);
      },
    },
    storeIntegration: {
      completed: false,
      omitted: false,
      enable: true,
      omit: async () => {
        await onboardingStore.setTaskOmitted({
          id: "storeIntegration",
        });
      },
      moreAction: () => {
        routerApp.navigate({
          path: "integrations",
        });
      },
    },
    brandEditor: {
      completed: false,
      omitted: false,
      enable: true,
    },
    // TODO
    abandonedCart: {
      completed: false,
      omitted: false,
      enable: true,
      action: async () => {
        routerApp.navigate({
          path: "automations/all?autom_category=recover",
        });
      },
      moreAction: () => {
        window.Intercom("showArticle", 6671345);
      },
      markComplete: async () => {
        await onboardingStore.setTaskMarkedComplete({
          id: "abandonedCart",
        });
      },
    },
    recoverInactive: {
      completed: false,
      omitted: false,
      enable: true,
      action: async () => {
        routerApp.navigate({
          path: "automations/all?autom_category=reactivation",
        });
      },
      moreAction: () => {
        window.Intercom("showArticle", 6671345);
      },
      markComplete: async () => {
        await onboardingStore.setTaskMarkedComplete({
          id: "recoverInactive",
        });
      },
    },
    loyalty: {
      completed: false,
      omitted: false,
      enable: true,
      action: async () => {
        routerApp.navigate({
          path: "automations/all?autom_category=loyalty",
        });
      },
      moreAction: () => {
        window.Intercom("showArticle", 6671345);
      },
      markComplete: async () => {
        await onboardingStore.setTaskMarkedComplete({
          id: "loyalty",
        });
      },
    },
  });

  const ecommerceIntegration = computed<Ecommerce | undefined>(() => integrationsStore.ecommerceIntegrations[0]);
  const recommendedStore = computed<Ecommerce | undefined>(() => {
    if (ecommerceIntegration.value) return ecommerceIntegration.value;

    const accountStoreType = accountStore.getStoreType();
    if (accountStoreType && isEcommerceId(accountStoreType)) {
      return accountStoreType;
    }

    return undefined;
  });

  const { senders } = storeToRefs(sendersStore);
  const { users } = storeToRefs(userStore);

  const isFirstOnboardingState = ref(true);

  // * ----- Tasks update with data and events -----
  const loadingTasks = ref(true);
  watchEffect(async () => {
    if (
      !sendersStore.sendersFetched ||
      !accountStore.accountConfigDataFetched ||
      !userStore.usersFetched ||
      !integrationsStore.integrationsFetched ||
      !userActivity.eventsFetched
    ) {
      return;
    }
    loadingTasks.value = true;

    const someSendersIsOk = senders.value?.some((s) => s.auth.status === "OK");
    const hasSupportedRecommendedEcommerce = !!recommendedStore.value && isSupportedEcommerce(recommendedStore.value);
    const hasOptinSupport = !!recommendedStore.value && ecommerceHasOptinSupport(recommendedStore.value);
    const hasAbandonedCart = !!recommendedStore.value && ecommerceHasAbandonedCart(recommendedStore.value);
    const brand = await brandApi.current();

    if (!tasks.collaboration.completed) {
      tasks.collaboration.completed = !!users.value?.length && users.value.length > 1;
    }

    if (!tasks.storeIntegration.completed) {
      tasks.storeIntegration.completed = !!ecommerceIntegration.value;
    }

    if (!tasks.sender.completed) {
      tasks.sender.completed = !!someSendersIsOk;
    }

    if (!tasks.brandEditor.completed) {
      tasks.brandEditor.completed = brand != null && Object.keys(brand).length > 0;
    }

    tasks.importList.enable = !recommendedStore.value || !hasSupportedRecommendedEcommerce;

    tasks.enableOptin.enable = hasOptinSupport;

    tasks.abandonedCart.enable = hasSupportedRecommendedEcommerce && hasAbandonedCart;

    tasks.recoverInactive.enable = hasSupportedRecommendedEcommerce;

    tasks.loyalty.enable = hasSupportedRecommendedEcommerce;

    events.value.forEach((event) => {
      if (isUserActivityOnboardingState(event)) {
        firstTasksState.value = event.data.tasks;
        isFirstOnboardingState.value = false;
        return;
      }

      const actionMap: Partial<Record<UserActivityAction, TaskIds>> = {
        "automation.welcome.enabled": "enableWelcomeAutomation",
        "automation.loyalty.enabled": "loyalty",
        "automation.abandoned_cart.enabled": "abandonedCart",
        "automation.reactivation.enabled": "recoverInactive",
        "list.imported": "importList",
        "campaign.sent": "campaignSent",
        "optin.enabled": "enableOptin",
      };

      const taskIdDetected = actionMap[event.action];
      if (taskIdDetected) {
        tasks[taskIdDetected].completed = true;
        return;
      }

      if (isAutoOmittedEvent(event.action) || isMarkedCompletedEvent(event.action)) {
        const taskId = getTaskIdFromEvent(event.action);
        tasks[taskId].completed = true;
        return;
      }

      if (isOmittedEvent(event.action)) {
        const taskId = getTaskIdFromEvent(event.action);
        tasks[taskId].omitted = true;
        return;
      }
    });

    loadingTasks.value = false;

    if (!isFirstOnboardingState.value) return;

    isFirstOnboardingState.value = events.value.every((event) => event.action !== "onboarding.first.state");

    if (!isFirstOnboardingState.value) return;

    // * Emits first onboarding state
    await userActivityApp.submit({
      action: "onboarding.first.state",
      data: {
        tasks,
      },
    });

    firstTasksState.value = tasks;
    isFirstOnboardingState.value = false;
  });

  const emitRewardOffered = (rewardId: RewardId) => {
    const alreadyEmmited = events.value.some((event) => {
      if (!isRewardEvent(event.action)) return false;

      return getRewardIdFromEventAction(event.action) === rewardId;
    });

    if (alreadyEmmited || sessionStore.session.isMasterUser) return;

    userActivityApp.submit({
      action: `reward.${rewardId}.offered`,
    });

    rewards[rewardId].offered = true;
  };

  const setTaskOmitted = ({ id }: { id: TaskIds }) => {
    if (tasks?.[id]?.completed) return;
    userActivityApp.submit({
      action: `onboarding.${id}.omitted`,
    });

    tasks[id] = {
      ...tasks[id],
      completed: tasks[id].completed,
      omitted: true,
      enable: tasks[id].enable,
    };

    const action = getEventActionFromTaskId(id);
    trackingEventsService.amplitude({
      name: "APP_HOME_TASK_OMITTED",
      data: {
        action: action,
        taskId: id,
      },
    });
  };

  const setTaskCompleted = async ({ id }: { id: TaskIds }) => {
    tasks[id] = {
      ...tasks[id],
      completed: true,
      omitted: tasks[id].omitted,
      enable: tasks[id].enable,
    };
  };

  const setTaskMarkedComplete = ({ id }: { id: TaskIds }) => {
    if (tasks?.[id]?.completed) return;
    userActivityApp.submit({
      action: `onboarding.${id}.markedComplete`,
    });

    tasks[id] = {
      ...tasks[id],
      completed: true,
      omitted: tasks[id].omitted,
      enable: tasks[id].enable,
    };

    const action = getEventActionFromTaskId(id);
    trackingEventsService.amplitude({
      name: "APP_HOME_TASK_MARKED_COMPLETE",
      data: {
        action: action,
        taskId: id,
      },
    });
  };

  const submitUserEvent = async (params: {
    action: UserActivityAction;
    timestamp?: DateString;
    data?: UserActivityData | UserActivityOnboardingData;
  }) => {
    await userActivityApp.submit(params);

    const taskId = getTaskIdFromEventAction(params.action);

    if (taskId) {
      trackingEventsService.amplitude({
        name: "APP_HOME_TASK_COMPLETED",
        data: {
          action: params.action,
          taskId: taskId,
        },
      });

      setTaskCompleted({ id: taskId });
    }
  };

  return {
    tasks,
    loadingTasks,
    firstTasksState,
    rewards,
    recommendedStore,
    ecommerceIntegration,
    events,
    emitRewardOffered,
    setTaskOmitted,
    setTaskCompleted,
    setTaskMarkedComplete,
    submitUserEvent,
  };
});
