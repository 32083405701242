import { computed, ComputedRef, toRaw, watch } from "vue";

import type { Step, Sections, Section } from "./home.types";

// Icons
import {
  PuzzleIcon,
  PaperAirplaneIcon,
  UserGroupIcon,
  ClipboardListIcon,
  LightningBoltIcon,
  MailIcon,
  ShoppingCartIcon,
  ShoppingBagIcon,
  StarIcon,
} from "@heroicons/vue/outline";
import {
  PlusIcon,
  ShoppingCartIcon as ShoppingCartIconSolid,
  ClipboardListIcon as ClipboardListIconSolid,
  LightningBoltIcon as LightningBoltIconSolid,
  MailIcon as MailIconSolid,
} from "@heroicons/vue/solid";

import PaintBrushIcon from "@tokens/icons/PaintBrush.vue";

// Store
import { storeToRefs } from "pinia";
import { useOnboardingStore, useSessionStore } from "@store";

// Utils
import { useI18n } from "vue-i18n";
import { isAfter } from "date-fns";

// Domain
// getFirstEnabledRewardId, someRewardClaimed
import { areSpecifiedTasksCompletedOrOmitted } from "@domain/onboarding";

export const getComputedSections = (): ComputedRef<Sections> => {
  const { t, locale } = useI18n();
  const onboardingStore = useOnboardingStore();
  const sessionStore = useSessionStore();

  const sections = computed<Sections>(() => {
    // rewards from onboardingStore
    const { tasks, firstTasksState, recommendedStore, ecommerceIntegration } = storeToRefs(onboardingStore);

    // const accountRewardId = !someRewardClaimed({
    //   rewards: rewards.value,
    //   rewardsIds: ["trial", "demo"],
    // })
    //   ? getFirstEnabledRewardId({
    //       rewards: rewards.value,
    //       rewardsIds: ["trial", "demo"],
    //     })
    //   : undefined;

    // const automationRewardId = !someRewardClaimed({
    //   rewards: rewards.value,
    //   rewardsIds: ["discount", "customTemplate"],
    // })
    //   ? getFirstEnabledRewardId({
    //       rewards: rewards.value,
    //       rewardsIds: ["discount", "customTemplate"],
    //     })
    //   : undefined;

    // const fidelityRewardId = !someRewardClaimed({
    //   rewards: rewards.value,
    //   rewardsIds: ["workshopFewContacts", "workshop2500Contacts"],
    // })
    //   ? getFirstEnabledRewardId({
    //       rewards: rewards.value,
    //       rewardsIds: ["workshopFewContacts", "workshop2500Contacts"],
    //     })
    //   : undefined;

    const sections: Sections = [
      {
        id: "account",
        title: t("steps.account.title"),
        // rewardButtonText: accountRewardId ? t(`reward.${accountRewardId}.button`) : undefined,
        // rewardText: accountRewardId ? t(`reward.${accountRewardId}.text`) : undefined,
        // rewardId: accountRewardId,
        steps: [
          {
            id: "storeIntegration",
            type: "integration",
            icon: PuzzleIcon,
            title: t("steps.account.sections.storeIntegration.title"),
            duration: t("steps.account.sections.storeIntegration.duration"),
            recommendedStore: recommendedStore.value,
            integrated: !!ecommerceIntegration.value,
            completedMessage: t("steps.account.sections.storeIntegration.completedMessage"),
            omittedMessage: t("steps.account.sections.storeIntegration.omittedMessage"),
            omitText: t("steps.account.sections.storeIntegration.omitText"),
            moreText: t("steps.account.sections.storeIntegration.moreText"),
          },
          {
            id: "brandEditor",
            type: "branding",
            icon: toRaw(PaintBrushIcon),
            title: t("steps.account.sections.brandEditor.title"),
            duration: t("steps.account.sections.brandEditor.duration"),
            completedMessage: t("steps.account.sections.brandEditor.completedMessage"),
          },
          {
            id: "importList",
            type: "video",
            icon: UserGroupIcon,
            title: t("steps.account.sections.importList.title"),
            duration: t("steps.account.sections.importList.duration"),
            url: "",
            urlImage:
              locale.value.slice(0, 2).toLocaleLowerCase() === "pt"
                ? "https://cdn.myperfit.net/1718134974700-ImportListHomebr.png"
                : "https://cdn.myperfit.net/1718134943913-ImportListHomeEsp.png",
            description: t("steps.account.sections.importList.description"),
            content: {
              title: t("steps.account.sections.importList.content.title"),
              description: t("steps.account.sections.importList.content.description"),
            },
            omitText: t("steps.account.sections.importList.omitText"),
            omittedMessage: t("steps.account.sections.importList.omittedMessage"),
            completedMessage: t("steps.account.sections.importList.completedMessage"),
            moreText: t("steps.account.sections.importList.moreText"),
            allowAction: true,
            actionText: t("steps.account.sections.importList.actionText"),
            actionIcon: PlusIcon,
          },
          {
            id: "sender",
            type: "senders",
            icon: PaperAirplaneIcon,
            title: t("steps.account.sections.senders.title"),
            duration: t("steps.account.sections.senders.duration"),
            allowOmit: false,
            completedMessage: t("steps.account.sections.senders.completedMessage"),
          },
        ],
      },
      {
        id: "automation",
        title: t("steps.automation.title"),
        // rewardButtonText: automationRewardId ? t(`reward.${automationRewardId}.button`) : undefined,
        // rewardText: automationRewardId ? t(`reward.${automationRewardId}.text`) : undefined,
        // rewardId: automationRewardId,
        steps: [
          {
            id: "enableOptin",
            type: "video",
            icon: ClipboardListIcon,
            title: t("steps.automation.sections.enableOptin.title"),
            duration: t("steps.automation.sections.enableOptin.duration"),
            url:
              locale.value.slice(0, 2).toLocaleLowerCase() === "pt"
                ? "https://www.youtube.com/embed/eIVUT1VRNYc?rel=0"
                : "https://www.youtube.com/embed/BilLw0VolKA?rel=0",
            description: t("steps.automation.sections.enableOptin.description"),
            content: {
              title: t("steps.automation.sections.enableOptin.content.title"),
              description: [
                t("steps.automation.sections.enableOptin.content.descriptionOne"),
                t("steps.automation.sections.enableOptin.content.descriptionTwo"),
                t("steps.automation.sections.enableOptin.content.descriptionThree"),
              ],
            },
            allowOmit: false,
            allowMarkComplete:
              isAfter(new Date("2024-01-07"), new Date(sessionStore.session.account.created)) &&
              !tasks.value.enableOptin.completed &&
              !tasks.value.enableOptin.omitted,
            markCompleteText: t("steps.automation.sections.enableOptin.markCompleteText"),
            completedMessage: t("steps.automation.sections.enableOptin.completedMessage"),
            moreText: t("steps.automation.sections.enableOptin.moreText"),
            allowAction: true,
            actionText: t("steps.automation.sections.enableOptin.actionText"),
            actionIcon: ClipboardListIconSolid,
          },
          {
            id: "enableWelcomeAutomation",
            type: "video",
            icon: LightningBoltIcon,
            title: t("steps.automation.sections.enableWelcomeAutomation.title"),
            duration: t("steps.automation.sections.enableWelcomeAutomation.duration"),
            url:
              locale.value.slice(0, 2).toLocaleLowerCase() === "pt"
                ? "https://www.youtube.com/embed/W8GucWEfq84?rel=0"
                : "https://www.youtube.com/embed/PJsYAqHTyfA?rel=0",
            description: t("steps.automation.sections.enableWelcomeAutomation.description"),
            content: {
              title: t("steps.automation.sections.enableWelcomeAutomation.content.title"),
              description: [
                t("steps.automation.sections.enableWelcomeAutomation.content.descriptionOne"),
                t("steps.automation.sections.enableWelcomeAutomation.content.descriptionTwo"),
                t("steps.automation.sections.enableWelcomeAutomation.content.descriptionThree"),
              ],
            },
            allowOmit: false,
            allowMarkComplete:
              isAfter(new Date("2024-01-07"), new Date(sessionStore.session.account.created)) &&
              !tasks.value.enableWelcomeAutomation.completed &&
              !tasks.value.enableWelcomeAutomation.omitted,
            markCompleteText: t("steps.automation.sections.enableWelcomeAutomation.markCompleteText"),
            completedMessage: t("steps.automation.sections.enableWelcomeAutomation.completedMessage"),
            moreText: t("steps.automation.sections.enableWelcomeAutomation.moreText"),
            allowAction: true,
            actionText: t("steps.automation.sections.enableWelcomeAutomation.actionText"),
            actionIcon: LightningBoltIconSolid,
          },
          {
            id: "campaignSent",
            type: "video",
            icon: MailIcon,
            title: t("steps.automation.sections.campaignSent.title"),
            duration: t("steps.automation.sections.campaignSent.duration"),
            url:
              locale.value.slice(0, 2).toLocaleLowerCase() === "pt"
                ? "https://www.youtube.com/embed/995bpBfqwoo?rel=0"
                : "https://www.youtube.com/embed/Pxh4Tl4P3tA?rel=0",
            description: t("steps.automation.sections.campaignSent.description"),
            content: {
              title: t("steps.automation.sections.campaignSent.content.title"),
              description: [
                t("steps.automation.sections.campaignSent.content.descriptionOne"),
                t("steps.automation.sections.campaignSent.content.descriptionTwo"),
                t("steps.automation.sections.campaignSent.content.descriptionThree"),
              ],
            },
            allowOmit: false,
            completedMessage: t("steps.automation.sections.campaignSent.completedMessage"),
            moreText: t("steps.automation.sections.campaignSent.moreText"),
            allowAction: true,
            actionText: t("steps.automation.sections.campaignSent.actionText"),
            actionIcon: MailIconSolid,
          },
        ],
      },
      {
        id: "fidelity",
        title: t("steps.fidelity.title"),
        // rewardButtonText: fidelityRewardId ? t(`reward.${fidelityRewardId}.button`) : undefined,
        // rewardText: fidelityRewardId ? t(`reward.${fidelityRewardId}.text`) : undefined,
        // rewardId: fidelityRewardId,
        steps: [
          {
            id: "abandonedCart",
            type: "video",
            icon: ShoppingCartIcon,
            title: t("steps.fidelity.sections.abandonedCart.title"),
            duration: t("steps.fidelity.sections.abandonedCart.duration"),
            url:
              locale.value.slice(0, 2).toLocaleLowerCase() === "pt"
                ? "https://www.youtube.com/embed/W8GucWEfq84?rel=0"
                : "https://www.youtube.com/embed/PJsYAqHTyfA?rel=0",
            description: t("steps.fidelity.sections.abandonedCart.description"),
            content: {
              title: t("steps.fidelity.sections.abandonedCart.content.title"),
              description: [
                t("steps.fidelity.sections.abandonedCart.content.descriptionOne"),
                t("steps.fidelity.sections.abandonedCart.content.descriptionTwo"),
                t("steps.fidelity.sections.abandonedCart.content.descriptionThree"),
              ],
            },
            allowOmit: false,
            allowMarkComplete:
              isAfter(new Date("2024-01-07"), new Date(sessionStore.session.account.created)) &&
              !tasks.value.abandonedCart.completed &&
              !tasks.value.abandonedCart.omitted,
            markCompleteText: t("steps.fidelity.sections.abandonedCart.markCompleteText"),
            completedMessage: t("steps.fidelity.sections.abandonedCart.completedMessage"),
            moreText: t("steps.fidelity.sections.abandonedCart.moreText"),
            allowAction: true,
            actionText: t("steps.fidelity.sections.abandonedCart.actionText"),
            actionIcon: ShoppingCartIconSolid,
          },
          {
            id: "recoverInactive",
            type: "video",
            icon: ShoppingBagIcon,
            title: t("steps.fidelity.sections.recoverInactive.title"),
            duration: t("steps.fidelity.sections.recoverInactive.duration"),
            url:
              locale.value.slice(0, 2).toLocaleLowerCase() === "pt"
                ? "https://www.youtube.com/embed/W8GucWEfq84?rel=0"
                : "https://www.youtube.com/embed/PJsYAqHTyfA?rel=0",
            description: t("steps.fidelity.sections.recoverInactive.description"),
            content: {
              title: t("steps.fidelity.sections.recoverInactive.content.title"),
              description: [
                t("steps.fidelity.sections.recoverInactive.content.descriptionOne"),
                t("steps.fidelity.sections.recoverInactive.content.descriptionTwo"),
                t("steps.fidelity.sections.recoverInactive.content.descriptionThree"),
              ],
            },
            allowOmit: false,
            allowMarkComplete:
              isAfter(new Date("2024-01-07"), new Date(sessionStore.session.account.created)) &&
              !tasks.value.recoverInactive.completed &&
              !tasks.value.recoverInactive.omitted,
            markCompleteText: t("steps.fidelity.sections.recoverInactive.markCompleteText"),
            completedMessage: t("steps.fidelity.sections.recoverInactive.completedMessage"),
            moreText: t("steps.fidelity.sections.recoverInactive.moreText"),
            allowAction: true,
            actionText: t("steps.fidelity.sections.recoverInactive.actionText"),
            actionIcon: ShoppingCartIconSolid,
          },
          {
            id: "loyalty",
            type: "video",
            icon: StarIcon,
            title: t("steps.fidelity.sections.loyalty.title"),
            duration: t("steps.fidelity.sections.loyalty.duration"),
            url:
              locale.value.slice(0, 2).toLocaleLowerCase() === "pt"
                ? "https://www.youtube.com/embed/W8GucWEfq84?rel=0"
                : "https://www.youtube.com/embed/PJsYAqHTyfA?rel=0",
            description: t("steps.fidelity.sections.loyalty.description"),
            content: {
              title: t("steps.fidelity.sections.loyalty.content.title"),
              description: [
                t("steps.fidelity.sections.loyalty.content.descriptionOne"),
                t("steps.fidelity.sections.loyalty.content.descriptionTwo"),
                t("steps.fidelity.sections.loyalty.content.descriptionThree"),
              ],
            },
            allowOmit: false,
            allowMarkComplete:
              isAfter(new Date("2024-01-07"), new Date(sessionStore.session.account.created)) &&
              !tasks.value.loyalty.completed &&
              !tasks.value.loyalty.omitted,
            markCompleteText: t("steps.fidelity.sections.loyalty.markCompleteText"),
            completedMessage: t("steps.fidelity.sections.loyalty.completedMessage"),
            moreText: t("steps.fidelity.sections.loyalty.moreText"),
            allowAction: true,
            actionText: t("steps.fidelity.sections.loyalty.actionText"),
            actionIcon: ShoppingCartIconSolid,
          },
        ],
      },
    ];

    // * ----- Sections/Steps autocompletion
    return sections.reduce((sections, section) => {
      const steps = section.steps.filter((step) => tasks.value[step.id].enable);
      if (steps.length === 0) return sections;

      const formattedSteps = steps.reduce((newSteps, step) => {
        newSteps.push({
          completed: tasks.value[step.id].completed,
          omitted: tasks.value[step.id].omitted,
          allowOmit: !tasks.value[step.id].completed && !tasks.value[step.id].omitted,
          allowMarkComplete: false,
          omit: tasks.value[step.id].omit,
          markComplete: tasks.value[step.id].markComplete,
          moreAction: tasks.value[step.id].moreAction,
          action: tasks.value[step.id].action,
          ...step,
        });
        return newSteps;
      }, [] as Array<Step>);

      const sectionCompleted = areSpecifiedTasksCompletedOrOmitted({
        tasks: tasks.value,
        tasksIds: formattedSteps.map((section) => section.id),
      });

      const sectionWasAlreadyCompleted =
        firstTasksState.value &&
        areSpecifiedTasksCompletedOrOmitted({
          tasks: firstTasksState.value,
          tasksIds: formattedSteps.map((section) => section.id),
        });

      const filteredSections: Section = {
        completed: sectionCompleted,
        showReward: section.rewardId && !sectionWasAlreadyCompleted,
        ...section,
        steps: formattedSteps, // Don´t override
      };

      sections.push(filteredSections);
      return sections;
    }, [] as Sections);
  });

  // * Section completed: Emits reward offered
  watch(sections, (newSection, oldSection) => {
    newSection.forEach((newStep, index) => {
      const completed = newStep.completed;
      const wasCompleted = oldSection[index].completed;
      if (!wasCompleted && completed && newStep.rewardId) {
        onboardingStore.emitRewardOffered(newStep.rewardId);
      }
    });
  });

  return sections;
};

export const getComputedFeaturedEventId = (): ComputedRef<string> => {
  const { locale } = useI18n();

  return computed<string>(() => {
    const sessionStore = useSessionStore();

    const { hasPlan } = storeToRefs(sessionStore);
    if (hasPlan) {
      return locale.value.slice(0, 2) === "pt"
        ? "19f6122d-e38c-491f-b8a1-656dadcbb816"
        : "19f6122d-e38c-491f-b8a1-656dadcbb816";
    } else {
      return locale.value.slice(0, 2) === "pt"
        ? "19f6122d-e38c-491f-b8a1-656dadcbb816"
        : "19f6122d-e38c-491f-b8a1-656dadcbb816";
    }
  });
};
