import type { DataItem } from "@domain/data";

export const findDeep = <T extends Record<string, any>>(
  obj: T,
  predicate: (value: any, key?: string) => boolean,
): Array<any> => {
  const results: Array<any> = [];

  Object.entries(obj).forEach(([key, value]) => {
    if (predicate(value, key)) {
      results.push(value);
      return;
    }

    if (typeof value === "object" && value !== null) {
      const nestedResults = findDeep(value, predicate);
      if (nestedResults.length > 0) {
        results.push(...nestedResults);
      }
    }
  });

  return results;
};

export const findDeepObject = <T extends Record<string, any>>(
  obj: T,
  predicate: (value: any, key?: string) => boolean,
): Array<any> => {
  const results: Array<any> = [];

  Object.entries(obj).forEach(([key, value]) => {
    if (predicate(value, key)) {
      results.push(obj);
      return;
    }

    if (typeof value === "object" && value !== null) {
      const nestedResults = findDeepObject(value, predicate);
      if (nestedResults.length > 0) {
        results.push(...nestedResults);
      }
    }
  });

  return results;
};

export const replaceElementInArrayById = <A extends { id: unknown }>(array: Array<A>, newElement: A): Array<A> => {
  const updatedArray = array.map((element) => {
    if (element.id === newElement.id) {
      return newElement;
    }
    return element;
  });

  if (!updatedArray.some((element) => element.id === newElement.id)) {
    updatedArray.push(newElement);
  }

  return updatedArray;
};

export const generateUniqueIdFromString = async (baseString: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(baseString);
  const hashBuffer = await crypto.subtle.digest("SHA-1", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const uniqueId = hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
  return uniqueId;
};

export const generateRandomId = async () => {
  const randomArray = new Uint8Array(16);
  crypto.getRandomValues(randomArray);
  const uniqueId = Array.from(randomArray)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
  return uniqueId;
};

export const toDataItems = <
  KeyType extends string | number | symbol = string,
  ValueType extends string | number | symbol = string,
  O extends Record<KeyType | ValueType, unknown> = Record<KeyType | ValueType, unknown>,
>(
  array: Array<O>,
  keyId: KeyType,
  valueId: ValueType,
): Array<DataItem<O[KeyType], O[ValueType], O>> => {
  return array.map((item) => {
    return {
      key: item[keyId],
      value: item[valueId],
      data: item,
    };
  });
};
