<template>
  <div :class="['flex justify-center rounded py-1 hmd:py-1.5 hxl:py-2']">
    <a
      ref="linkRef"
      :href="route.path"
      :class="[
        current ? 'bg-gray-200 text-sky-400' : 'text-gray-500 hover:text-sky-400',
        'group flex w-fit justify-center rounded-md px-1 py-1 hxl:px-2 hxl:py-2',
      ]"
      :data-intercom-target="'nav-' + route.route"
      @click="(ev) => updateRoute(ev, route)"
    >
      <component
        :is="current ? route.currentIcon : route.icon"
        class="h-7 w-7 shrink-0 transform stroke-[1.5] duration-100 group-hover:scale-110"
        aria-hidden="true"
      />
    </a>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";

import { getWebUIv2Path, shouldRedirectToNewUI } from "../routes";
import type { Route } from "../routes.types";

import { useTooltip } from "@composables/tooltips";

// Application
import { useRouterApp } from "@application";

const props = withDefaults(
  defineProps<{
    route: Route;
    current?: boolean;
  }>(),
  {
    current: false,
  },
);

const routerApp = useRouterApp();

const updateRoute = (event: Event, route: Route) => {
  event.preventDefault();
  event.stopPropagation();

  if (shouldRedirectToNewUI(route.path)) {
    const pathToRedirect = getWebUIv2Path(route.path);
    location.replace(`${window.config.webUIv2URL}${pathToRedirect}`);
    return;
  }

  routerApp.navigate({
    path: route.path,
  });
};

const linkRef = ref();

const setTooltip = () => {
  useTooltip(linkRef, props.route.name, {
    placement: "right",
    zIndex: 9999,
    theme: "light",
    arrow: false,
    offset: [0, 17],
    delay: [0, 0],
  });
};
onMounted(() => {
  setTooltip();
});
</script>
