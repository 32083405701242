interface SetCookieParamsBase {
  cname: string;
  value: string;
  domain?: string;
  path?: string;
}

interface SetCookieByDateParams extends SetCookieParamsBase {
  exDate: DateString;
}

interface SetCookieByTimeParams extends SetCookieParamsBase {
  exDays?: number;
  exHours?: number;
  exMinutes?: number;
  exSeconds?: number;
  exMiliseconds?: number;
}

export const isSetCookieByDate = (
  param: SetCookieByDateParams | SetCookieByTimeParams,
): param is SetCookieByDateParams => {
  return (param as SetCookieByDateParams).exDate !== undefined;
};

export type SetCookieParams = SetCookieByDateParams | SetCookieByTimeParams;

export interface DeleteCookieParams {
  name: string;
  domain?: string;
  path?: string;
}

export interface GetCookieParams {
  name: string;
}

export interface GetParsedCookieParams {
  name: string;
}

export interface CookiesService {
  setCookie(params: SetCookieParams): void;
  deleteCookie(params: DeleteCookieParams): void;
  getCookie(params: GetCookieParams): string;
  getParsedCookie<V = string>(params: GetParsedCookieParams): V | undefined;
}
