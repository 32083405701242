<template>
  <div class="mb-6 border border-gray-200 bg-white sm:rounded-lg">
    <div class="flex justify-between px-4 py-4 sm:p-6">
      <div>
        <h3 class="m-0 text-base font-medium leading-6 text-gray-900">
          {{ t("title") }}
        </h3>
        <p class="text-paragraph mt-1 text-sm text-gray-500">
          {{ t("description") }}
        </p>
      </div>
      <div class="flex flex-col justify-center">
        <SimpleButton theme="primary" @click="openBrandEditor">
          {{ t("button") }}
        </SimpleButton>

        <BrandEditorModal :open="brandEditorModal" @close="closeBrandEditor" @save="closeBrandEditor" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

// Components
import BrandEditorModal from "@/vue/components/organisms/BrandEditor/BrandEditorModal.vue";
import SimpleButton from "@atoms/SimpleButton.vue";

// Lang
import { useI18n } from "vue-i18n";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// States
const { t } = useI18n();
const trackingEventsService = useTrackingEvents();
const brandEditorModal = ref(false);

const openBrandEditor = () => {
  trackOpenBrandEditor();

  brandEditorModal.value = true;
};

const closeBrandEditor = () => {
  brandEditorModal.value = false;
};

const trackOpenBrandEditor = () => {
  trackingEventsService.amplitude({
    name: "APP_BRAND_EDITOR_CONFIG_ACCOUNT_OPENED",
  });
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Elementos de tu marca",
    "description": "Configura los elementos de tu marca como colores, logotipo o tipo de letra y aplicalos en los e-mails de tus automations.",
    "button": "Configurar",
  },
  "pt": {
    "title": "Elementos de sua marca",
    "description": "Configure os elementos da sua marca, como cores, logotipo ou ou fonte, e aplique-os nos e-mails das suas automações.",
    "button": "Configurar",
  },
}
</i18n>
