<template>
  <div class="grid gap-2">
    <div class="grid grid-cols-2 items-center gap-4">
      <p class="text-sm font-medium text-gray-700">{{ t("title") }}</p>
      <FormSwitch :model-value="useSocialMediaLinks" @update:modelValue="updateUseSocialMediaLinks" />
    </div>

    <transition-group
      leave-active-class="transition duration-300 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0 transform translate-x-10"
      enter-active-class="transition duration-300 ease-in-out"
      enter-to-class="opacity-100"
      enter-from-class="opacity-0 transform translate-x-10"
    >
      <div
        v-for="(link, index) in socialMediaSelected"
        v-show="useSocialMediaLinks"
        :key="index"
        class="flex items-center gap-4"
      >
        <div class="flex flex-1 gap-4">
          <div class="w-full">
            <SelectMenu
              :selected="socialMediaOptions.find((option) => option.key === link.key)"
              :data-items="socialMediaOptionsFiltered(link)"
              :has-error="useSocialMediaLinks && hasError && errors.$each.$response.$errors[index].key.length > 0"
              @update:selected="
                (selected) => {
                  link.key = selected.key;
                }
              "
            />
          </div>

          <div class="w-full">
            <SimpleInput
              class="w-full"
              :model-value="link.value"
              type="text"
              :placeholder="t('placeholder')"
              :has-error="useSocialMediaLinks && hasError && errors.$each.$response.$errors[index].value.length > 0"
              @update:model-value="
                (value) => {
                  link.value = value;
                }
              "
            />
          </div>
        </div>
        <IconButton
          :label="index === 0 ? t('buttons.addIcon') : t('buttons.trashIcon')"
          :theme="index === 0 ? 'success' : 'danger'"
          class="ml-2"
          :class="{
            'pointer-events-none opacity-50':
              (socialMediaSelected.length >= maxLinks && index === 0) ||
              (socialMediaSelected.length <= 1 && index !== 0),
          }"
          @click="index === 0 ? addLink() : removeLink(index)"
        >
          <PlusCircleIcon v-if="index === 0" />
          <TrashIcon v-else />
        </IconButton>
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts" setup>
// Components
import { PlusCircleIcon, TrashIcon } from "@heroicons/vue/outline";
import IconButton from "@atoms/IconButton.vue";
import FormSwitch from "@molecules/FormSwitch.vue";
import SelectMenu from "@molecules/SelectMenu.vue";
import SimpleInput from "@atoms/SimpleInput.vue";

// Lang
import { useI18n } from "vue-i18n";

// Types
import type { DataItem } from "@domain/data";

// Props
const props = defineProps<{
  socialMediaSelected: DataItem[];
  socialMediaOptions: DataItem[];
  socialMediaFiltered: DataItem[];
  maxLinks?: number;
  useSocialMediaLinks?: boolean;
  errors: any;
  hasError: boolean;
}>();

// Events
const emit = defineEmits<{
  (e: "update:social-media-links", value: Array<DataItem>): void;
  (e: "update:use-social-media-links", value: boolean): void;
}>();

const updateUseSocialMediaLinks = (value: boolean) => {
  emit("update:use-social-media-links", value);
};

// i18n
const { t } = useI18n();

// States
const maxLinks = props.maxLinks ?? 8;

const socialMediaOptionsFiltered = (selected: DataItem) => {
  const newOptions = [...props.socialMediaFiltered];

  const optionSelected = props.socialMediaOptions.find((option) => option.key === selected.key);

  if (!optionSelected) return newOptions;

  newOptions.push(optionSelected);

  return newOptions;
};

// Methods
const addLink = () => {
  if (props.socialMediaSelected.length < maxLinks) {
    const socialMediaSelected = props.socialMediaSelected;
    socialMediaSelected.push({ key: "", value: "" });
    emit("update:social-media-links", socialMediaSelected);
  }
};

const removeLink = (index: number) => {
  if (props.socialMediaSelected.length > 1) {
    const updatedLinks = [...props.socialMediaSelected];
    updatedLinks.splice(index, 1);
    emit("update:social-media-links", updatedLinks);
  }
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Redes sociales",
    "placeholder": "https://",
    "buttons": {
      "addIcon": "Agregar red social",
      "trashIcon": "Eliminar red social",
    },
  },
  "pt": {
    "title": "Redes sociais",
    "placeholder": "https://",
    "buttons": {
      "addIcon": "Adicionar rede social",
      "trashIcon": "Remover rede social",
    },
  },
}
</i18n>
