<template>
  <Teleport to="body">
    <TemplateEditor
      :tpl-id="tplId"
      :relation="relation"
      :audit-mode="auditMode"
      :contact="contact"
      :not-allow-errors="notAllowErrors"
      :is-template-from-gallery="isTemplateFromGallery"
      :class="[{ 'lg:mt-12': alertStore.message }]"
      class="fixed inset-0 z-100 overflow-hidden"
      @close="onClose"
      @saved="onSaved"
      @update:contact="updateSelectContact"
    />
  </Teleport>
</template>

<script lang="ts" setup>
//Components
import TemplateEditor from "@/vue/components/organisms/Templates/TemplateEditor/TemplateEditor.vue";

//Types
import type { SavedEmit } from "@/vue/components/organisms/Templates/TemplateEditor/TemplateEditor.vue";
import type { AuditModeType } from "@domain/Templates";
import { Relation } from "@/vue/components/organisms/Templates/TemplateEditor/TemplateEditor.vue";
import { Contact } from "@domain/contacts";
import { useAlertStore } from "@store/alertStore/alert.store";

const alertStore = useAlertStore();

//Props
withDefaults(
  defineProps<{
    tplId?: string;
    relation?: Relation | undefined;
    auditMode?: AuditModeType;
    contact?: Contact;
    notAllowErrors?: boolean;
    isTemplateFromGallery?: boolean;
  }>(),
  {
    tplId: undefined,
    relation: undefined,
    auditMode: "no_audit",
    contact: undefined,
    notAllowErrors: false,
  },
);
//Emits
const emit = defineEmits<{
  (e: "close"): void;
  (e: "saved", savedObject: SavedEmit): void;
  (e: "update:contact", value: Contact | undefined): void;
}>();

const onClose = () => emit("close");
const onSaved = (savedObject: SavedEmit) => emit("saved", savedObject);
const updateSelectContact = (value: Contact | undefined) => {
  emit("update:contact", value);
};
</script>
