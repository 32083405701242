import type { UserActivityAction } from "@domain/userActivity";

export type TaskIds =
  | "storeIntegration"
  | "brandEditor"
  | "importList"
  | "sender"
  | "collaboration"
  | "enableOptin"
  | "enableWelcomeAutomation"
  | "campaignSent"
  | "abandonedCart"
  | "recoverInactive"
  | "loyalty";

export const taskIds: TaskIds[] = [
  "storeIntegration",
  "brandEditor",
  "importList",
  "sender",
  "collaboration",
  "enableOptin",
  "enableWelcomeAutomation",
  "campaignSent",
  "abandonedCart",
  "recoverInactive",
  "loyalty",
];

export interface Task {
  completed: boolean;
  omitted: boolean;
  enable: boolean;
  allowAction?: boolean;
  allowOmit?: boolean;
  omit?: () => Promise<void>;
  markComplete?: () => Promise<void>;
  moreAction?: () => void;
  action?: () => Promise<void>;
}

export type Tasks = Record<TaskIds, Task>;

export const areSpecifiedTasksCompletedOrOmitted = ({
  tasks,
  tasksIds,
}: {
  tasks: Tasks;
  tasksIds: TaskIds[];
}): boolean => {
  return tasksIds.every((id) => {
    return (tasks?.[id] && tasks[id].enable && tasks[id].completed) || (tasks?.[id] && tasks[id].omitted);
  });
};

export type RewardId =
  | "trial"
  | "demo"
  | "discount"
  | "customTemplate"
  | "workshopFewContacts"
  | "workshop2500Contacts";

export interface RewardData {
  daysToClaim?: number;
  enable: boolean;
  offered: boolean;
  claimed: boolean;
  execute: () => Promise<void>;
}

export type Rewards = Record<RewardId, RewardData>;

export const getFirstEnabledRewardId = ({
  rewards,
  rewardsIds,
}: {
  rewards: Rewards;
  rewardsIds: RewardId[];
}): RewardId | undefined => {
  const enableRewardId = rewardsIds.find((id) => {
    return rewards[id].enable;
  });

  if (!enableRewardId) return;

  return enableRewardId;
};

export const someRewardClaimed = ({ rewards, rewardsIds }: { rewards: Rewards; rewardsIds: RewardId[] }): boolean => {
  return rewardsIds.some((id) => rewards[id].claimed);
};

export const getTaskIdFromEventAction = (action: UserActivityAction): TaskIds | undefined => {
  const mapping: Partial<Record<UserActivityAction, TaskIds>> = {
    "campaign.sent": "campaignSent",
    "optin.enabled": "enableOptin",
    "automation.welcome.enabled": "enableWelcomeAutomation",
    "automation.abandoned_cart.enabled": "abandonedCart",
    "automation.loyalty.enabled": "loyalty",
    "automation.reactivation.enabled": "recoverInactive",
    "list.imported": "importList",
  };

  return mapping?.[action];
};

export const getEventActionFromTaskId = (action: TaskIds): UserActivityAction | undefined => {
  const mapping: Partial<Record<TaskIds, UserActivityAction>> = {
    campaignSent: "campaign.sent",
    enableOptin: "optin.enabled",
    enableWelcomeAutomation: "automation.welcome.enabled",
    abandonedCart: "automation.abandoned_cart.enabled",
    loyalty: "automation.loyalty.enabled",
    recoverInactive: "automation.reactivation.enabled",
    importList: "list.imported",
  };

  return mapping?.[action];
};
