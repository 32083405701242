import { EditView } from "@/core/magnet";
import app from "@/app";
import { _ } from "@/helpers/utils";
import sessionTpl from "../templates/index.hbs";
import clientsTpl from "../templates/modals/clients.hbs";
import accountsTpl from "../templates/modals/accounts.hbs";
const moment = window.moment;

export default EditView.extend({
  module: "session",
  templates: {
    initial: sessionTpl,
  },
  el: ".app",
  renderArea: ".session",
  cache: false,
  events: {
    "click [data-action=open-accounts-modal]": "openAccountsModal",
    "click [data-action=change-account]": "changeAccount",
    "click [data-action=logout]": "logout",
    "click [data-action=profile]": "gotoUserProfile",
    "submit [data-form=search-client]": "searchClient",
  },

  // Custom initialization method
  initialize: function () {
    var self = this;
    self.template = sessionTpl;
    self.item = app.session;
  },

  // Renders the session partial
  render: function () {
    var self = this,
      code;

    self.item.resolveRelationships(self.cache, function (relationships) {
      // Solve relationships and render
      self.item.relationshipsData = self.item.parseRelationships(relationships);
      self.$(self.renderArea).html(self.template(self.renderData()));
      self.afterRender();

      // If the user has no account selected, show the account selection modal
      if (!self.item.get("inAccount")) {
        return self.openAccountsModal();
      }

      // Otherwise, mark the current account
      code = self.item.get("account");
      $.ajax({
        url: "accounts",
        headers: {
          "Account-Prefix": false,
        },
        data: {
          q: code,
        },
        success: function (response) {
          var currentAccount = _.findWhere(response.data, {
            code: String(code),
          });
          if (currentAccount) {
            currentAccount.isChild = currentAccount && currentAccount.parent ? true : false;
            app.session.set("currentAccount", currentAccount);
          } else {
            app.integrations.bugsnag.notify(new Error(`Could not find current account: ${code}`));
          }
          app.layout.hideParentsElements();
          app.layout.hideAgencyElements();
        },
      });
    });
  },

  // Open the account selection modal
  openAccountsModal: function () {
    var self = this;

    // Abort if the user has no accounts to change to
    if (!self.item.get("canChangeAccount")) {
      return false;
    }

    // Performs an AJAX call to request clients
    self.item.resolveRelationships(self.cache, function (relationships) {
      self.item.relationshipsData = self.item.parseRelationships(relationships);
      self.openModal(accountsTpl, self.renderData());
    });
  },

  // Searchs a child account
  searchClient: function (e) {
    var self = this,
      form = $(e.currentTarget),
      client = form.find("[name=client]").val(),
      template = clientsTpl;
    e.preventDefault();

    // Performs an AJAX call to request clients
    $.ajax({
      url: "accounts",
      headers: {
        "Account-Prefix": false,
      },
      data: {
        q: "*" + client + "*",
        limit: 100,
      },
      success: function (response) {
        var accounts = response.data;

        // Change relationship data
        window.accounts = accounts;

        // Show the clients list in the modal
        $(self.modal)
          .find(".accounts")
          .html(
            template({
              lang: window.lang,
              data: accounts,
            }),
          );
      },
    });
  },

  // Changes the selected account
  changeAccount: function (e) {
    var self = this,
      account = $(e.currentTarget).data("code"),
      encoded,
      newSession;

    $.ajax({
      url: account,
      headers: {
        "Account-Prefix": false,
      },
      success: function (response) {
        var accountDetails = response.data,
          expires = moment().add(1, "days").toDate().toUTCString();

        // Create a new session object
        newSession = {
          token: self.item.get("token"),
          user: self.item.get("user"),
          mainAccount: self.item.get("mainAccount"),
          userType: self.item.get("userType"),
          account: account,
          details: _.extend(app.session.get("details"), {
            account: accountDetails,
          }),
        };

        let sharedSession = {
          token: self.item.get("token"),
          tokenExpirationDate: expires,
          accountId: account,
          language: app.lang === "pt" ? "pt" : "es",
          isMasterUser: self.item.get("userType") === "MASTER",
        };

        // Updates session to include the selected account
        self.item.set("account", account);
        self.item.set("inAccount", true);
        delete app.session.attributes.started;
        encoded = encodeURIComponent(JSON.stringify(newSession));
        let encodedShared = encodeURIComponent(JSON.stringify(sharedSession));

        const domain = window.location.hostname.includes("localhost") ? "localhost" : window.config.domain;
        const environment = window.config.environment;

        document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = `session.${environment}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        // ! compatibility with first try -- todo: Deprecate
        document.cookie = `session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;

        document.cookie = `session=${encoded}; expires=${expires}; path=/;`;
        // Cookie for communication between apps
        document.cookie = `session.${environment}=${encodedShared}; expires=${expires}; domain=${domain}; path=/;`;

        // Close the modal and go to the dashboard
        $(self.modal)
          .on("hidden.bs.modal", function () {
            // Clear local storage
            var acls = localStorage.getItem("acl");
            localStorage.removeItem("acl");
            localStorage.removeItem("trial");
            localStorage.setItem("acl", acls);

            // Close modal and relaunch
            $(this).off("hidden.bs.modal");
            app.router.navigateTo("home");
            window.Backbone.history.stop();
            window.Backbone.history.start();

            //hack capocha para recargue bien el menu lateral
            location.reload();
          })
          .modal("hide");
      },
    });
  },

  // Logs out a user and removes the session cookie
  logout: function () {
    app.session.logout();
  },

  gotoUserProfile: function () {
    app.router.navigateTo(`settings/${app.session.attributes.mainAccount}/users/me`);
  },
});
