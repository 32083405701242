<template>
  <CampaignsTable
    v-if="render === 'table'"
    :sort-by="sortBy"
    :campaigns="campaigns"
    :selected="selectedCampaigns"
    :selected-columns="selectedColumns"
    :columns="columns"
    :loading="loading"
    :skeleton="skeleton"
    class="h-full"
    @update:sort-by="updateSortBy"
    @duplicateCampaign="openDuplicateModal"
    @exportCampaign="exportCampaign"
    @archiveCampaign="archiveCampaign"
    @unarchiveCampaign="unarchiveCampaign"
    @deleteCampaign="openDeleteAlertModal"
    @stopCampaign="openStopAlertModal"
    @update-selected="updateSelectedCampaigns"
    @fetch-campaigns="fetchCampaigns"
    @select-tag="selectTagSearch"
  />
  <CampaignsGrid
    v-else
    :campaigns="campaigns"
    :selected="selectedCampaigns"
    :loading="loading"
    :skeleton="skeleton"
    class="h-full"
    @select-campaign="selectCampaign"
    @update-selected="updateSelectedCampaigns"
    @remove-selected-campaign="deselectCampaign"
    @fetch-campaigns="fetchCampaigns"
  />
  <SelectionBar v-show="selectedCampaigns && selectedCampaigns?.length > 0">
    <div class="flex items-center space-x-2">
      <template v-if="selectedCampaigns?.length && selectedCampaigns?.length > 0">
        <span class="text-sm text-white">{{
          t(
            "selectedLists",
            {
              count: n(Number(selectedCampaigns?.length) ?? 0, "decimal"),
              total: n(Number(totalCount) ?? 0, "decimal"),
            },
            { plural: selectedCampaigns?.length },
          )
        }}</span>
      </template>
      <IconButton
        v-if="selectedCampaigns?.length < totalCount"
        :label="t('selectAllButton')"
        size="min"
        theme="none"
        class="bg-gray-600"
        @click="selectAll"
      >
        <MultiSelectionIcon class="h-5 w-5 text-gray-400 hover:text-white" />
      </IconButton>
    </div>
    <div class="flex space-x-4">
      <IconButton
        v-show="allSelectedCampaignsAreScheduled"
        :label="t('selected.stop')"
        size="min"
        theme="none"
        class="bg-gray-600"
        @click="openStopSelectedAlertModal"
      >
        <StopIcon class="h-5 w-5" />
      </IconButton>
      <IconButton
        v-show="selectedCampaigns.length === 1"
        :label="t('selected.duplicate')"
        size="min"
        theme="none"
        class="bg-gray-600 !py-0"
        @click="() => openDuplicateModal(selectedCampaigns[0])"
      >
        <ClipboardDocumentListIcon class="h-6 w-6" />
      </IconButton>
      <IconButton
        :label="t('selected.export')"
        size="min"
        theme="none"
        class="bg-gray-600"
        @click="exportCampaignsBulk"
      >
        <DownloadIcon class="h-5 w-5" />
      </IconButton>
      <IconButton
        v-show="allSelectedCampaignsAreNotArchived"
        :label="t('selected.archive')"
        size="min"
        theme="none"
        class="bg-gray-600"
        @click="archiveBulk"
      >
        <ArchiveIcon class="h-5 w-5" />
      </IconButton>
      <IconButton
        v-show="allSelectedCampaignsAreArchived"
        :label="t('selected.unarchive')"
        size="min"
        theme="none"
        class="bg-gray-600"
        @click="unarchiveBulk"
      >
        <ArchiveIcon class="h-5 w-5" />
      </IconButton>
      <PermissionsIconButton
        v-show="allSelectedCampaignsCanBeDeleted"
        :label="t('selected.delete')"
        :permissions="['campaigns:delete']"
        size="min"
        theme="none"
        class="bg-gray-600"
        @click="openDeleteSelectedAlertModal"
      >
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </PermissionsIconButton>
      <IconButton :label="t('closeButton')" size="min" theme="none" class="bg-gray-600" @click="clearSelected">
        <XIcon class="h-5 w-5 text-gray-400 hover:text-white" />
      </IconButton>
    </div>
  </SelectionBar>
  <ConfirmationModal id="stopAlertModal" v-bind="stopAlertModal" @accept="stopCampaign" @cancel="closeStopAlertModal"
    ><template #acceptButtonLeading>
      <StopIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
    </template>
  </ConfirmationModal>
  <ConfirmationModal
    id="stopSelectedAlertModal"
    v-bind="stopSelectedAlertModal"
    @accept="stopBulkCampaigns"
    @cancel="closeStopSelectedAlertModal"
    ><template #acceptButtonLeading>
      <StopIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
    </template>
  </ConfirmationModal>

  <ConfirmationModal
    id="deleteAlertModal"
    v-bind="deleteAlertModal"
    @accept="deleteCampaign"
    @cancel="closeDeleteListAlertModal"
    ><template #acceptButtonLeading>
      <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
    </template>
  </ConfirmationModal>
  <ConfirmationModal
    id="deleteSelectedAlertModal"
    v-bind="deleteSelectedAlertModal"
    @accept="deleteSelected"
    @cancel="closeDeleteFontAlertModal"
    ><template #acceptButtonLeading>
      <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
    </template>
  </ConfirmationModal>
  <DuplicateModal
    v-model:open="duplicateModalIsOpen"
    :campaign="selectedCampaign"
    :loading-accept="loadingDuplicate"
    @duplicate="duplicateCampaign"
    :hasEddieTemplate="hasEddieTemplate"
  />
</template>

<script lang="ts" setup>
import { ref, reactive, toRaw, computed } from "vue";

import type { ColumnsIds } from "./campaigns.types";

// Components
import CampaignsTable from "./components/CampaignsTable/CampaignsTable.vue";
import CampaignsGrid from "./components/CampaignsGrid/CampaignsGrid.vue";
import DuplicateModal from "./components/DuplicateModal.vue";
import ConfirmationModal from "@molecules/ConfirmationModal.vue";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";
import IconButton from "@atoms/IconButton.vue";
import PermissionsIconButton from "@organisms/Permissions/PermissionsIconButton";
import SelectionBar from "@atoms/SelectionBar";
import type { Columns, SortColumn } from "@molecules/Table";
import type { Items } from "@molecules/CheckboxListButton";

// Icon
import { TrashIcon, XIcon, DownloadIcon, StopIcon, ArchiveIcon } from "@heroicons/vue/outline";
import MultiSelectionIcon from "@/vue/components/tokens/icons/MultiSelectionIcon.vue";
import ClipboardDocumentListIcon from "@/vue/components/tokens/icons/ClipboardDocumentListIcon.vue";

// Composables
import { useNotifications } from "@composables/notifications";
import { useTrackingEvents } from "@composables/trackingevents";

// Store
import { useNotificationStore } from "@store";

// Application
import { useRouterApp } from "@application";

// Services
import { useCampaigns } from "@api/modules/campaigns/campaigns";
import { useFeaturesStore } from "@store";

// Domain
import type { Campaigns, Campaign, ListColumn } from "@domain/campaigns";
import type { Tag } from "@domain/tag";
import type { AxiosError } from "axios";

// I18n
import { useI18n } from "vue-i18n";

const { t, n } = useI18n();
const routerApp = useRouterApp();
const campaignsAPI = useCampaigns();
const { notify } = useNotifications();
const notificationStore = useNotificationStore();
const trackingEventsService = useTrackingEvents();
const featuresStore = useFeaturesStore();

const props = withDefaults(
  defineProps<{
    campaigns?: Campaigns;
    totalCount: number;
    render: "grid" | "table";
    selectedColumns: Items<string, string, ListColumn>;
    columns: Columns<ColumnsIds | "options">;
    sortBy: SortColumn<ColumnsIds>;
    loading?: boolean;
    skeleton?: boolean;
  }>(),
  {
    campaigns: () => [],
    totalCount: 0,
    render: "grid",
    loading: false,
    skeleton: false,
  },
);

const emit = defineEmits<{
  newCampaign: [Campaign];
  "update:campaigns": [Campaigns];
  "update:totalCount": [number];
  fetchCampaigns: [void];
  "update:sortBy": [SortColumn<ColumnsIds>];
  selectTagSearch: [Tag];
}>();

const fetchCampaigns = () => emit("fetchCampaigns");
const updateSortBy = (sort: SortColumn<ColumnsIds>) => emit("update:sortBy", sort);
const selectTagSearch = (tag: Tag) => emit("selectTagSearch", tag);

const selectedCampaigns = ref<Campaigns>([]);
const allSelectedCampaignsAreScheduled = computed(() => {
  return selectedCampaigns.value.every((c) => c.state === "SCHEDULED");
});
const allSelectedCampaignsCanBeDeleted = computed(() => {
  return selectedCampaigns.value.every((c) => c.state === "DRAFT");
});
const allSelectedCampaignsAreArchived = computed(() => {
  return selectedCampaigns.value.every((c) => c.archived);
});
const allSelectedCampaignsAreNotArchived = computed(() => {
  return selectedCampaigns.value.every((c) => !c.archived && c.state === "SENT");
});
const selectAll = () => {
  selectedCampaigns.value = props.campaigns;
};

const selectCampaign = (campaign: Campaign) => {
  selectedCampaigns.value = [...selectedCampaigns.value, campaign];
};

const deselectCampaign = (campaign: Campaign) => {
  selectedCampaigns.value = selectedCampaigns.value.filter((c) => c.id != campaign.id);
};

const updateSelectedCampaigns = (newSelectedCampaigns: Campaigns) => {
  selectedCampaigns.value = newSelectedCampaigns;
};

const clearSelected = () => {
  selectedCampaigns.value = [];
};

const removeCampaigns = (campaignsToDelete: Campaigns) => {
  const filteredCampaigns = toRaw(props.campaigns).filter((campaign) => {
    return !campaignsToDelete.some((r) => r.id === campaign.id);
  });
  emit("update:campaigns", filteredCampaigns);
};

const removeCampaign = (campaignToDelete: Campaign) => {
  const filteredCampaigns = toRaw(props.campaigns).filter((campaign) => {
    return campaign.id !== campaignToDelete.id;
  });
  emit("update:campaigns", filteredCampaigns);
};

const hasEddieTemplate = ref(false);
const selectedCampaign = ref<Campaign>();
const duplicateModalIsOpen = ref(false);
const loadingDuplicate = ref(false);

const openDuplicateModal = async (campaign: Campaign) => {
  hasEddieTemplate.value = await isEddieTemplate(campaign);

  if (hasEddieTemplate.value) {
    const eddieFeatures = getEddieFeatures();

    if (!eddieFeatures) return undefined;

    trackingEventsService.amplitude({
      name: "APP_EDDIE_DUPLICATE_NOTIFICATION",
      data: {
        source: "campaign",
        id: campaign.id,
        feature_unlayer_optional: eddieFeatures.unlayer_optional,
        feature_unlayer_default: eddieFeatures.unlayer_default,
        feature_hide_legacy_templates: eddieFeatures.hide_legacy_templates,
      },
    });
  }
  selectedCampaign.value = campaign;
  duplicateModalIsOpen.value = true;
};

const getEddieFeatures = () => {
  const features = featuresStore.features;

  if (!features) return undefined;

  const eddieFeatures = {
    unlayer_optional: features.UNLAYER_OPTIONAL === "1" ? true : false,
    unlayer_default: features.UNLAYER_DEFAULT === "1" ? true : false,
    hide_legacy_templates: features.HIDE_LEGACY_TEMPLATES === "1" ? true : false,
  };
  return eddieFeatures;
};

const isEddieTemplate = async (campaign: Campaign): Promise<boolean> => {
  const id = campaign.id.toString();
  const campaignData = await campaignsAPI.getId(id);

  if (!campaignData.options) return false;

  return !campaignData.options.UNLAYER_TPL_ID;
};

const openCampaign = (campaignId) => {
  routerApp.navigate({
    path: `/campaigns/${campaignId}`,
  });
};

const duplicateCampaign = async (name: string) => {
  loadingDuplicate.value = true;
  const ids = selectedCampaigns.value.map((c) => c.id.toString());
  const selectedCampaignId = selectedCampaign.value?.id?.toString();
  if (!selectedCampaignId && ids.length === 0) return;

  const id = selectedCampaignId || ids[0];

  try {
    const newCampaign = await campaignsAPI.duplicate({ id, name, showError: false });
    notify({
      title: t("notifications.duplicate.title"),
      text: t("notifications.duplicate.text"),
      theme: "success",
    });

    emit("update:campaigns", [newCampaign, ...props.campaigns]);

    emit("update:totalCount", props.totalCount + 1);
    openCampaign(newCampaign.id);
  } catch (e) {
    if ((e as AxiosError)?.response?.status === 409) {
      notify({
        title: t("notifications.duplicateErrorDuplicated.title"),
        text: t("notifications.duplicateErrorDuplicated.text"),
        theme: "error",
      });
    } else {
      notify({
        title: t("notifications.duplicateError.title"),
        text: t("notifications.duplicateError.text"),
        theme: "error",
      });
    }
    return;
  } finally {
    loadingDuplicate.value = false;
    selectedCampaigns.value = [];
    selectedCampaign.value = undefined;
    duplicateModalIsOpen.value = false;
  }
};

const stopAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("stopAlert.confirmationTitle"),
  message: t("stopAlert.confirmationMessage", { name: selectedCampaign.value?.name ?? "" }),
  acceptText: t("stopAlert.confirmButton"),
  cancelText: t("stopAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeStopAlertModal = () => {
  stopAlertModal.open = false;
};

const openStopAlertModal = (campaign: Campaign) => {
  selectedCampaign.value = campaign;
  stopAlertModal.message = t("stopAlert.confirmationMessage", { name: selectedCampaign.value.name ?? "" });
  stopAlertModal.open = true;
};

const stopSelectedAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("stopSelectedAlert.confirmationTitle"),
  message: t(
    "stopSelectedAlert.confirmationMessage",
    { count: selectedCampaigns.value?.length },
    { plural: selectedCampaigns.value?.length },
  ),
  acceptText: t("stopSelectedAlert.confirmButton"),
  cancelText: t("stopSelectedAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeStopSelectedAlertModal = () => {
  stopSelectedAlertModal.open = false;
};

const openStopSelectedAlertModal = () => {
  stopSelectedAlertModal.message = t(
    "stopSelectedAlert.confirmationMessage",
    { count: selectedCampaigns.value?.length },
    { plural: selectedCampaigns.value?.length },
  );
  stopSelectedAlertModal.open = true;
};

const deleteAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteAlert.confirmationTitle"),
  message: t("deleteAlert.confirmationMessage", { name: selectedCampaign.value?.name ?? "" }),
  acceptText: t("deleteAlert.confirmButton"),
  cancelText: t("deleteAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteListAlertModal = () => {
  deleteAlertModal.open = false;
};

const openDeleteAlertModal = (campaign: Campaign) => {
  selectedCampaign.value = campaign;
  deleteAlertModal.message = t("deleteAlert.confirmationMessage", { name: selectedCampaign.value.name ?? "" });
  deleteAlertModal.open = true;
};

const deleteSelectedAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t(
    "deleteSelectedAlert.confirmationTitle",
    { count: selectedCampaigns.value?.length },
    { plural: selectedCampaigns.value?.length },
  ),
  message: t(
    "deleteSelectedAlert.confirmationMessage",
    { count: selectedCampaigns.value?.length },
    { plural: selectedCampaigns.value?.length },
  ),
  acceptText: t("deleteSelectedAlert.confirmButton"),
  cancelText: t("deleteSelectedAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteFontAlertModal = () => {
  deleteSelectedAlertModal.open = false;
};

const openDeleteSelectedAlertModal = () => {
  deleteSelectedAlertModal.title = t(
    "deleteSelectedAlert.confirmationTitle",
    { count: selectedCampaigns.value?.length },
    { plural: selectedCampaigns.value?.length },
  );
  deleteSelectedAlertModal.message = t(
    "deleteSelectedAlert.confirmationMessage",
    { count: selectedCampaigns.value?.length },
    { plural: selectedCampaigns.value?.length },
  );
  deleteSelectedAlertModal.open = true;
};

const deleteCampaign = async () => {
  const campaignToDelete = selectedCampaign.value;
  const id = selectedCampaign.value?.id?.toString();
  if (!id || !campaignToDelete) return;

  try {
    deleteAlertModal.acceptLoading = true;
    await campaignsAPI.delete({ id });
    deleteAlertModal.acceptLoading = false;

    emit("update:totalCount", props.totalCount - 1);
    notify({
      title: t("notifications.delete.title"),
      text: t("notifications.delete.text"),
      theme: "success",
    });
    removeCampaign(campaignToDelete);
  } finally {
    // if (rows.value.length < fetchlimit && totalCount.value >= fetchlimit) {
    //   await fetchAutomations();
    // }
    closeDeleteListAlertModal();
    selectedCampaign.value = undefined;
  }
};

const deleteSelected = async () => {
  deleteSelectedAlertModal.acceptLoading = true;
  await deleteBulk();
  deleteSelectedAlertModal.acceptLoading = false;
  deleteSelectedAlertModal.open = false;
};

const deleteBulk = async () => {
  if (!selectedCampaigns.value) return;
  try {
    const deletePromises = selectedCampaigns.value?.map((selected) =>
      campaignsAPI.delete({ id: selected.id.toString() }),
    );
    await Promise.all(deletePromises);
    notify({
      title: t("notifications.deleteBulk.title"),
      text: t("notifications.deleteBulk.text"),
      theme: "success",
    });
    emit("update:totalCount", props.totalCount - selectedCampaigns.value?.length);
    removeCampaigns(selectedCampaigns.value);
  } finally {
    // if (rows.value.length < fetchlimit && totalCount.value >= fetchlimit) {
    //   await fetchAutomations();
    // }
    clearSelected();
  }
};

const stopCampaign = async () => {
  const id = selectedCampaign.value?.id?.toString();
  if (!selectedCampaign.value || !id) return;
  stopAlertModal.acceptLoading = true;

  try {
    await campaignsAPI.stop({ id });
    removeCampaign(selectedCampaign.value);
    notify({
      title: t("notifications.stop.title"),
      text: t("notifications.stop.text"),
      theme: "success",
    });
    trackingEventsService.dispatchAll({
      name: "",
    });

    trackingEventsService.dispatchAll({
      name: "APP_CAMPAIGN_STOPPED",
      data: {
        name: selectedCampaign.value.name,
        id: selectedCampaign.value.id,
      },
    });
  } catch (e) {
    return;
  } finally {
    stopAlertModal.acceptLoading = false;
    selectedCampaign.value = undefined;
    closeStopAlertModal();
  }
};

const stopBulkCampaigns = async () => {
  if (!selectedCampaigns.value) return;
  stopSelectedAlertModal.acceptLoading = true;

  try {
    const campaignStopPromises = selectedCampaigns.value?.map((selected) => {
      campaignsAPI.stop({ id: selected.id.toString() });
      trackingEventsService.dispatchAll({
        name: "APP_CAMPAIGN_STOPPED",
        data: {
          name: selected.name,
          id: selected.id,
        },
      });
    });
    await Promise.all(campaignStopPromises);
  } finally {
    notify({
      title: t("notifications.stops.title"),
      text: t("notifications.stops.text"),
      theme: "success",
    });
    stopSelectedAlertModal.acceptLoading = false;
    emit("update:totalCount", props.totalCount - selectedCampaigns.value?.length);
    removeCampaigns(selectedCampaigns.value);
    closeStopSelectedAlertModal();
    clearSelected();
  }
};

const exportCampaign = async (campaign: Campaign) => {
  const id = campaign?.id?.toString();
  if (!id) return;

  try {
    await campaignsAPI.export({ ids: [id] });
    await notificationStore.fetchTasks();
    notify({
      title: t("notifications.export.title"),
      text: t("notifications.export.text"),
      theme: "info",
    });
  } catch (e) {
    return;
  } finally {
    selectedCampaign.value = undefined;
  }
};

const exportCampaignsBulk = async () => {
  if (!selectedCampaigns.value) return;
  try {
    const ids = selectedCampaigns.value.map((c) => c.id.toString());
    await campaignsAPI.export({ ids });
    await notificationStore.fetchTasks();
    notify({
      title: t("notifications.exports.title"),
      text: t("notifications.exports.text"),
      theme: "info",
    });
  } catch (e) {
    return;
  } finally {
    clearSelected();
  }
};

const archiveCampaign = async (campaign: Campaign) => {
  const campaignToRemove = campaign;
  const id = campaign?.id?.toString();
  if (!id) return;

  try {
    await campaignsAPI.archive({ id });
    notify({
      title: t("notifications.archive.title"),
      text: t("notifications.archive.text"),
      theme: "success",
    });
  } catch (e) {
    return;
  } finally {
    emit("update:totalCount", props.totalCount - 1);
    removeCampaign(campaignToRemove);
    selectedCampaign.value = undefined;
  }
};

const archiveBulk = async () => {
  if (!selectedCampaigns.value) return;
  const filteredCampaigns = selectedCampaigns.value.filter((c) => !c.archived);
  try {
    const archivePromises = filteredCampaigns?.map((selected) => campaignsAPI.archive({ id: selected.id.toString() }));
    await Promise.all(archivePromises);
  } finally {
    notify({
      title: t("notifications.archives.title"),
      text: t("notifications.archives.text"),
      theme: "success",
    });
    emit("update:totalCount", props.totalCount - filteredCampaigns.length);
    removeCampaigns(filteredCampaigns);
    clearSelected();
  }
};

const unarchiveCampaign = async (campaign: Campaign) => {
  const campaignToRemove = campaign;
  const id = campaign?.id?.toString();
  if (!id) return;

  try {
    await campaignsAPI.unarchive({ id });
    notify({
      title: t("notifications.unarchive.title"),
      text: t("notifications.unarchive.text"),
      theme: "success",
    });
  } catch (e) {
    return;
  } finally {
    emit("update:totalCount", props.totalCount - 1);
    removeCampaign(campaignToRemove);
    selectedCampaign.value = undefined;
  }
};

const unarchiveBulk = async () => {
  if (!selectedCampaigns.value) return;
  const filteredCampaigns = selectedCampaigns.value.filter((c) => c.archived);
  try {
    const archivePromises = filteredCampaigns?.map((selected) =>
      campaignsAPI.unarchive({ id: selected.id.toString() }),
    );
    await Promise.all(archivePromises);
  } finally {
    notify({
      title: t("notifications.unarchives.title"),
      text: t("notifications.unarchives.text"),
      theme: "success",
    });
    emit("update:totalCount", props.totalCount - filteredCampaigns.length);
    removeCampaigns(filteredCampaigns);
    clearSelected();
  }
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "selectedLists": "1 campaña seleccionada de {total} | {count} campañas seleccionadas de {total}",
    "selectAllButton": "Seleccionar todo",
    "selected": {
      "export": "Exportar seleccionadas",
      "delete": "Eliminar",
      "duplicate": "Duplicar",
      "stop": "Detener",
      "archive": "Archivar",
      "unarchive": "Desarchivar"
    },
    "deleteSelectedAlert": {
      "confirmationTitle": "¿Quieres eliminar esta campaña? | ¿Quieres eliminar estas {count} campañas?",
      "confirmationMessage": "Al hacerlo, eliminas el diseño, métricas e información sobre esta campaña y no podrás deshacer esta acción. | Al hacerlo, eliminas el diseño, métricas e información sobre estas campañas y no podrás deshacer esta acción.",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    },
    "deleteAlert": {
      "confirmationTitle": "¿Quieres eliminar esta campaña?",
      "confirmationMessage": "Al hacerlo, eliminas el diseño, métricas e información sobre esta campaña y no podrás deshacer esta acción.",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    },
    "stopSelectedAlert": {
      "confirmationTitle": "Detener campañas",
      "confirmationMessage": "Se detendra 1 campaña seleccionada. ¿Deseas continuar? | Se detendran {count} campañas seleccionadas. ¿Deseas continuar?",
      "confirmButton": "Detener",
      "cancelButton": "Cancelar"
    },
    "stopAlert": {
      "confirmationTitle": "Detener campaña",
      "confirmationMessage": "Se detendra la campaña {name}. ¿Deseas continuar?",
      "confirmButton": "Detener",
      "cancelButton": "Cancelar"
    },
    "notifications": {
      "duplicateErrorDuplicated": {
        "title": "Error al duplicar",
        "text": "El nombre de la campaña ya existe."
      },
      "duplicateError": {
        "title": "Error al duplicar",
        "text": "Hubo un error al duplicar la campaña."
      },
      "archive": {
        "title": "Campaña Archivada",
        "text": "La campaña se archivó correctamente."
      },
      "unarchive": {
        "title": "Campaña desarchivada",
        "text": "La campaña se desarchivo correctamente."
      },
      "export": {
        "title": "Exportación de campaña",
        "text": "Se está exportando la campaña."
      },
      "exports": {
        "title": "Exportación de campañas",
        "text": "Se están exportando las campañas."
      },
      "archives": {
        "title": "Campañas archivadas",
        "text": "Las campañas se archivaron correctamente."
      },
      "unarchives": {
        "title": "Campañas desarchivadas",
        "text": "Las campañas se desarchivaron correctamente."
      },
      "delete": {
        "title": "Campaña eliminada",
        "text": "La campaña se eliminó correctamente."
      },
      "deleteBulk": {
        "title": "Campañas eliminadas",
        "text": "Las campañas se eliminaron correctamente."
      },
      "duplicate": {
        "title": "Campaña duplicada",
        "text": "La campaña se duplicó correctamente."
      },
      "stop": {
        "title": "Campaña detenida",
        "text": "La campaña se detuvo correctamente."
      },
      "stops": {
        "title": "Campañas detenidas",
        "text": "Las campañas se detuvieron correctamente."
      }
    },
    "closeButton": "Cancelar"
  },
  "pt": {
    "selectedLists": "1 campanha selecionada de {total} | {count} campanhas selecionadas de {total}",
    "selectAllButton": "Selecionar tudo",
    "selected": {
      "export": "Exportar selecionadas",
      "delete": "Excluir",
      "duplicate": "Duplicar",
      "stop": "Parar",
      "archive": "Arquivar",
      "unarchive": "Desarquivar"
    },
    "deleteSelectedAlert": {
      "confirmationTitle": "Você quer excluir esta campanha? | Você quer excluir essas {count} campanhas?",
      "confirmationMessage": "Ao fazer isso, você exclui o template, métricas e informações sobre esta campanha e não poderá desfazer esta ação. | Ao fazer isso, você exclui os templates, métricas e informações sobre essas campanhas e não poderá desfazer esta ação.",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    },
    "deleteAlert": {
      "confirmationTitle": "Você quer excluir esta campanha? | Você quer excluir essas campanhas?",
      "confirmationMessage": "Ao fazer isso, você exclui o template, métricas e informações sobre esta campanha e não poderá desfazer esta ação. | Ao fazer isso, você exclui os templates, métricas e informações sobre essas campanhas e não poderá desfazer esta ação.",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    },
    "stopSelectedAlert": {
      "confirmationTitle": "Parar campanhas",
      "confirmationMessage": "Será interrompida 1 campanha selecionada. Deseja continuar? | Serão interrompidas {count} campanhas selecionadas. Deseja continuar?",
      "confirmButton": "Parar",
      "cancelButton": "Cancelar"
    },
    "stopAlert": {
      "confirmationTitle": "Parar campanha",
      "confirmationMessage": "A campanha {name} será interrompida. Deseja continuar?",
      "confirmButton": "Parar",
      "cancelButton": "Cancelar"
    },
    "notifications": {
      "duplicateErrorDuplicated": {
        "title": "Erro ao duplicar",
        "text": "O nome da campanha já existe."
      },
      "duplicateError": {
        "title": "Erro ao duplicar",
        "text": "Houve um erro ao duplicar a campanha."
      },
      "archive": {
        "title": "Campanha arquivada",
        "text": "A campanha foi arquivada com sucesso."
      },
      "unarchive": {
        "title": "Campanha desarquivada",
        "text": "A campanha foi desarquivada com sucesso."
      },
      "export": {
        "title": "Exportação de campanha",
        "text": "A campanha está sendo exportada."
      },
      "exports": {
        "title": "Exportação de campanhas",
        "text": "As campanhas estão sendo exportadas."
      },
      "archives": {
        "title": "Campanhas arquivadas",
        "text": "As campanhas foram arquivadas com sucesso."
      },
      "unarchives": {
        "title": "Campanhas desarquivadas",
        "text": "As campanhas foram desarquivadas com sucesso."
      },
      "delete": {
        "title": "Campanha excluída",
        "text": "A campanha foi excluída com sucesso."
      },
      "deleteBulk": {
        "title": "Campanhas excluídas",
        "text": "As campanhas foram excluídas com sucesso."
      },
      "duplicate": {
        "title": "Campanha duplicada",
        "text": "A campanha foi duplicada com sucesso."
      },
      "stop": {
        "title": "Campanha interrompida",
        "text": "A campanha foi interrompida com sucesso."
      },
      "stops": {
        "title": "Campanhas interrompidas",
        "text": "As campanhas foram interrompidas com sucesso."
      }
    },
    "closeButton": "Cancelar"
  }
}
</i18n>
