<template>
  <div class="flex h-full w-full justify-between">
    <div class="mr-1 w-full overflow-y-auto py-12">
      <div class="mx-auto flex max-w-5xl flex-grow flex-col space-y-12 px-5 2xl:px-10">
        <div>
          <div v-if="useTiendanubeTheme" class="mb-5">
            <PoweredByPerfitPT v-if="session.lang === 'pt'" />
            <PoweredByPerfit v-else />
          </div>

          <h1 class="my-0 text-3xl font-extrabold text-gray-700">{{ t("title") }}</h1>
          <p class="text-base text-gray-500">{{ t("description") }}</p>
        </div>
        <Accordion :loading="loadingTasks" :sections="sections" />
        <Resources />
      </div>
    </div>
    <EventsSide class="tw-hidden shrink-0 border-l border-gray-300 xl:flex xl:w-96" />
  </div>
</template>
<script lang="ts" setup>
import { onMounted, computed } from "vue";

import { getComputedSections } from "./home.data";

// Components
import Accordion from "./components/Accordion.vue";
import EventsSide from "./components/EventsSide.vue";
import Resources from "./components/Resources.vue";

import PoweredByPerfitPT from "./components/PoweredByPerfitPT.vue";
import PoweredByPerfit from "./components/PoweredByPerfit.vue";

// Store
import {
  useContactsStore,
  useSessionStore,
  useSendersStore,
  useAccountStore,
  useIntegrationsStore,
  useUserStore,
  useInvoicePaymentsStore,
  useFeaturesStore,
  useOnboardingStore,
} from "@store";
import { storeToRefs } from "pinia";

// Utils
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const onboardingStore = useOnboardingStore();
const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);
const sendersStore = useSendersStore();
const contactsStore = useContactsStore();
const accountStore = useAccountStore();
const integrationsStore = useIntegrationsStore();
const userStore = useUserStore();
const invoicePayment = useInvoicePaymentsStore();
const { loadingTasks } = storeToRefs(onboardingStore);
const featuresStore = useFeaturesStore();

const sections = getComputedSections();

const useTiendanubeTheme = computed(() => {
  return integrationsStore.isTiendanube;
});

onMounted(async () => {
  await Promise.all([
    contactsStore.fetchContactsLength(),
    sessionStore.fetchPlan(),
    sendersStore.fetchSenders(),
    accountStore.fetchAccountData(),
    integrationsStore.fetchIntegrations(),
    userStore.fetchUsers(),
    invoicePayment.fetchInvoices(),
    featuresStore.fetchFeatures(),
  ]);
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Descubre el poder de automatizar e impulsar tu negocio",
    "description": "Completa las tareas para avanzar en los niveles, optimizar tu cuenta y vender más.",
    "reward": {
      "trial": {
        "text": "Completa las tareas para acceder a un trial gratuito con nuevas funcionalidades.",
        "button": "Aplicar",
      },
      "demo": {
        "text": "Completa las tareas y accede a una demo gratuita con nosotros",
        "button": "Aplicar",
      },
      "discount": {
        "text": "Completa las tareas y accede a un descuento especial en la compra de un plan.",
        "button": "Aplicar",
      },
      "customTemplate": {
        "text": "Completa las tareas y accede al diseño de una plantilla personalizada.",
        "button": "Aplicar",
      },
      "workshopFewContacts": {
        "text": "Completa las tareas y accede a un workshop",
        "button": "Aplicar",
      },
      "workshop2500Contacts": {
        "text": "Completa las tareas y accede a un workshop",
        "button": "Aplicar",
      },
    },
    "steps": {
      "account": {
        "title": "Primeros pasos para configurar tu cuenta",
        "sections": {
          "storeIntegration": {
            "title": "Integra tu tienda",
            "duration": "10 minutos",
            "moreText": "Ver más integraciones",
            "omitText": "Omitir integración",
            "completedMessage": "¡Felicitaciones! Ya completaste esta tarea",
            "omittedMessage": "¡Continua avanzando! Omitiste esta tarea, puedes avanzar con las siguientes.",
          },
          "importList": {
            "title": "Importa una lista",
            "description": "Importa una lista y añade esos contactos con toda su información a tu audiencia.",
            "duration": "5 minutos",
            "content": {
              "title": "Gestiona tu audiencia",
              "description": "Las listas de contactos te permitirán mantener la información de tu audiencia organizada y optimizada para enviar campañas más segmentadas y crear estrategias automatizadas más efectivas. Puedes comenzar a importar tus listas con el botón de Importar Listas, o también puedes hacerlo desde la sección Audiencia del menú ingresando a la solapa Listas.",
            },
            "completedMessage": "¡Felicitaciones! Ya completaste esta tarea.",
            "actionText": "Importar lista",
            "moreText": "Saber más sobre listas",
            "omitText": "Omitir importación",
            "omittedMessage": "¡Continua avanzando! Omitiste esta tarea, puedes avanzar con las siguientes.",
          },
          "senders": {
            "title": "Registra un remitente",
            "duration": "8 minutos",
            "completedMessage": "¡Felicitaciones! Ya completaste esta tarea.",
          },
          "brandEditor": {
            "title": "Configuración de tu marca",
            "duration": "3 minutos",
            "completedMessage": "¡Felicitaciones! Ya completaste esta tarea.",
          },
          "collaboration": {
            "title": "Trabaja en equipo",
            "duration": "10 minutos",
            "moreText": "Leer más sobre roles de usuarios",
            "completedMessage": "¡Felicitaciones! Ya completaste esta tarea.",
            "omittedMessage": "¡Continua avanzando! Omitiste esta tarea, puedes avanzar con las siguientes.",
            "actionText": "Enviar invitación",
            "omitText": "Omitir invitación",
          },
        },
      },
      "automation": {
        "title": "Usa herramientas para que crezca tu audiencia",
        "rewardText": "Completa las tareas y accede a un descuento especial en la compra de un plan.",
        "rewardButtonText": "Aplicar",
        "sections": {
          "enableOptin": {
            "title": "Inserta un formulario en tu tienda",
            "description": "Crea e inserta formularios de suscripción en tu tienda para aumentar tu audiencia.",
            "duration": "15 minutos",
            "content": {
              "title": "Sigue creciendo",
              "descriptionOne": "Personaliza los formularios con la imagen, colores y estilos de tu marca. ¡Es muy sencillo!",
              "descriptionTwo": "Insértalos en tu tienda online con un simple click, configurando su comportamiento y ubicación.",
              "descriptionThree": "Aumenta su efectividad ofreciendo descuentos o beneficios, y envíalos usando los automations.",
            },
            "completedMessage": "¡Felicitaciones! Ya completaste esta tarea.",
            "actionText": "Crear formulario",
            "moreText": "Leer más sobre formularios",
            "markCompleteText": "Marcar como completada",
          },
          "enableWelcomeAutomation": {
            "title": "Activa un automation de bienvenida",
            "description": "Automatiza la comunicación de tu tienda online y atrae posibles compradores que la visitaron.",
            "duration": "10 minutos",
            "content": {
              "title": "¡No lo dejes ir!",
              "descriptionOne": "Configúralos por única vez y observa como aumentan tus ventas. ¡Todo en piloto automático!",
              "descriptionTwo": "Aprovecha los automations del ciclo de vida para crear estrategias de fidelización y retención.",
              "descriptionThree": "Convierte más ventas usando los automations de recupero de carrito abandonado y visitas.",
            },
            "completedMessage": "¡Felicitaciones! Ya completaste esta tarea.",
            "actionText": "Crear automation",
            "moreText": "Leer más sobre automations",
            "markCompleteText": "Marcar como completada",
          },
          "campaignSent": {
            "title": "Envía una campaña a tu audiencia",
            "description": "Envía campañas impactantes para conquistar a tu audiencia y aumentar tus ventas.",
            "duration": "15 minutos",
            "content": {
              "title": "Aumenta tus ventas",
              "descriptionOne": "Promociona nuevos productos y ofertas, o comunica descuentos para fechas especiales.",
              "descriptionTwo": "Planifica y programa tu estrategia de envíos, mide los resultados y vuelve a enviar.",
              "descriptionThree": "Inserta productos tomando las imágenes, precios y detalles desde tu tienda muy fácilmente.",
            },
            "completedMessage": "¡Felicitaciones! Ya completaste esta tarea.",
            "actionText": "Crear campaña",
            "moreText": "Leer más sobre campañas",
          },
        },
      },
      "fidelity": {
        "title": "Automatiza tu estrategia y potencia tu tienda",
        "rewardText": "Completa las tareas y accede a un evento para potenciar tu tienda",
        "rewardButtonText": "Aplicar",
        "sections": {
          "abandonedCart": {
            "title": "Recupera carritos abandonados",
            "description": "Crea y activa un automation de carrito abandonado para alentar a tus compradores indecisos.",
            "duration": "15 minutos",
            "content": {
              "title": "Retorno seguro",
              "descriptionOne": "Crea, edita y activa un automation de carrito abandonado para recuperar compras en tu tienda virtual.",
              "descriptionTwo": "Recuerda a tus visitantes sobre los productos que dejaron en el carrito y alientalos a comprar.",
              "descriptionThree": "Incluye incentivos, descuentos u ofertas para motivar a tus clientes a finalizar la compra. ¡Recupera esa venta!",
            },
            "completedMessage": "¡Felicitaciones! Ya completaste esta tarea.",
            "actionText": "Crear automation",
            "moreText": "Leer más sobre automations",
            "markCompleteText": "Marcar como completada",
          },
          "recoverInactive": {
            "title": "Recupera compradores frecuentes",
            "description": "Llama la atención de compradores que dejaron de visitarte, envía promociones o descuentos para recuperarlos.",
            "duration": "10 minutos",
            "content": {
              "title": "Reaviva la conexión",
              "descriptionOne": "Recupera clientes que han estado inactivos con un automation, reavivando la conexión con tu marca.",
              "descriptionTwo": "Aprovecha la oportunidad de ofrecer ofertas irresistibles y descuentos exclusivos.",
              "descriptionThree": "Personaliza mensajes para abordar las razones de su inactividad aumentando la probabilidad de recuperación.",
            },
            "completedMessage": "¡Felicitaciones! Ya completaste esta tarea.",
            "actionText": "Crear automation",
            "moreText": "Leer más sobre automations",
            "markCompleteText": "Marcar como completada",
          },
          "loyalty": {
            "title": "Convierte compradores en campeones",
            "description": "Incentiva compradores por su lealtad y frecuendia de compra para transformarlos en campeones de tu tienda.",
            "duration": "15 minutos",
            "content": {
              "title": "Construyendo conexión",
              "descriptionOne": "Automatiza mensajes con descuentos exclusivos después de cada compra. ¡Haz que se sientan especiales!",
              "descriptionTwo": "Crea un programa de lealtad automático que premie cada compra. ¡Cuanto más compran las recompensas mejoran!",
              "descriptionThree": "Automatiza solicitudes para opiniones después de cada compra. ¡Sus opiniones positivas son fundamentales!",
            },
            "completedMessage": "¡Felicitaciones! Ya completaste esta tarea.",
            "actionText": "Crear automation",
            "moreText": "Leer más sobre automations",
            "markCompleteText": "Marcar como completada",
          },
        },
      },
    },
  },
  "pt": {
    "title": "Descubra o poder de automatizar e impulsionar seu negócio",
    "description": "Complete as tarefas para avançar níveis, otimizar sua conta e vender mais.",
    "reward": {
      "trial": {
        "text": "Complete as tarefas para acessar um teste gratuito com novas funcionalidades.",
        "button": "Aplicar",
      },
      "demo": {
        "text": "Complete as tarefas e acesse uma demonstração gratuita conosco",
        "button": "Aplicar",
      },
      "discount": {
        "text": "Complete as tarefas e acesse um desconto especial na compra de um plano.",
        "button": "Aplicar",
      },
      "customTemplate": {
        "text": "Complete as tarefas e acesse o design de um modelo personalizado.",
        "button": "Aplicar",
      },
      "workshopFewContacts": {
        "text": "Complete as tarefas e acesse um workshop",
        "button": "Aplicar",
      },
      "workshop2500Contacts": {
        "text": "Complete as tarefas e acesse um workshop",
        "button": "Aplicar",
      },
    },
    "steps": {
      "account": {
        "title": "Primeiros passos para configurar sua conta",
        "sections": {
          "storeIntegration": {
            "title": "Integre sua loja",
            "duration": "10 minutos",
            "moreText": "Ver mais integrações",
            "omitText": "Omitir integração",
            "completedMessage": "Parabéns! Você já completou esta tarefa.",
            "omittedMessage": "Continue avançando! Você omitiu esta tarefa, pode avançar com as seguintes.",
          },
          "importList": {
            "title": "Importe uma lista",
            "description": "Importe uma lista e adicione esses contatos com todas as suas informações à sua audiência.",
            "duration": "5 minutos",
            "content": {
              "title": "Gestiona sua audiência",
              "description": "As listas de contatos permitirão que você mantenha as informações do seu público organizadas e otimizadas para enviar campanhas mais segmentadas e criar estratégias automatizadas mais eficazes. Você pode começar a importar suas listas com o botão Importar Listas, ou também pode fazer isso a partir da seção Audiência no menu acessando a aba Listas.",
            },
            "completedMessage": "Parabéns! Você já completou esta tarefa.",
            "actionText": "Importar lista",
            "moreText": "Saber mais sobre listas",
            "omitText": "Omitir importação",
            "omittedMessage": "Continue avançando! Você omitiu esta tarefa, pode avançar com as seguintes.",
          },
          "senders": {
            "title": "Registre um remetente",
            "duration": "8 minutos",
            "completedMessage": "Parabéns! Você já completou esta tarefa.",
          },
          "brandEditor": {
            "title": "Configure sua marca",
            "duration": "3 minutos",
            "completedMessage": "Parabéns! Você já completou esta tarefa.",
          },
          "collaboration": {
            "title": "Trabalhe em equipe",
            "duration": "10 minutos",
            "moreText": "Leia mais sobre papéis de usuários",
            "completedMessage": "Parabéns! Você já completou esta tarefa.",
            "omittedMessage": "Continue avançando! Você omitiu esta tarefa, pode avançar com as seguintes.",
            "actionText": "Enviar convite",
            "omitText": "Omitir convite",
          },
        },
      },
      "automation": {
        "title": "Use ferramentas para aumentar sua audiência",
        "rewardText": "Complete as tarefas e acesse um desconto especial na compra de um plano.",
        "rewardButtonText": "Aplicar",
        "sections": {
          "enableOptin": {
            "title": "Insira um formulário em sua loja",
            "description": "Crie e insira formulários de inscrição em sua loja para aumentar sua audiência.",
            "duration": "15 minutos",
            "content": {
              "title": "Continue crescendo",
              "descriptionOne": "Personalize os formulários com a imagem, cores e estilos da sua marca. É muito fácil!",
              "descriptionTwo": "Insira-os na sua loja online com apenas alguns cliques, configurando seu comportamento e localização",
              "descriptionThree": "Aumente sua eficácia oferecendo descontos ou benefícios e enviando-os usando os Automations.",
            },
            "completedMessage": "Parabéns! Você já completou esta tarefa.",
            "actionText": "Criar formulário",
            "moreText": "Leia mais sobre formulários",
            "markCompleteText": "Marcar como concluído",
          },
          "enableWelcomeAutomation": {
            "title": "Ative um Automation de boas-vindas",
            "description": "Automatize a comunicação de sua loja online e atraia possíveis compradores que a visitaram.",
            "duration": "10 minutos",
            "content": {
              "title": "Não deixe passar!",
              "descriptionOne": "Configure-os uma vez e veja como suas vendas aumentam. Tudo em piloto automático!",
              "descriptionTwo": "Aproveite os automations do ciclo de vida para criar estratégias de fidelização e retenção.",
              "descriptionThree": "Converta mais vendas usando os automations de recuperação de carrinho abandonado e visitas.",
            },
            "markCompleteText": "Marcar como concluído",
            "completedMessage": "Parabéns! Você já completou esta tarefa.",
            "actionText": "Criar automation",
            "moreText": "Leia mais sobre automations",
          },
          "campaignSent": {
            "title": "Envie uma campanha para sua audiência",
            "description": "Envie campanhas impactantes para conquistar sua audiência e aumentar suas vendas.",
            "duration": "15 minutos",
            "content": {
              "title": "Aumente suas vendas",
              "descriptionOne": "Promova novos produtos e ofertas ou comunique descontos para datas especiais.",
              "descriptionTwo": "Planeje e programe sua estratégia de envios, meça os resultados e reenvie.",
              "descriptionThree": "Insira produtos tomando as imagens, preços e detalhes de sua loja muito facilmente.",
            },
            "completedMessage": "Parabéns! Você já completou esta tarefa.",
            "actionText": "Criar campanha",
            "moreText": "Leia mais sobre campanhas",
          },
        },
      },
      "fidelity": {
        "title": "Automatize sua estratégia e potencialize sua loja",
        "rewardText": "Complete as tarefas e acesse um evento para potencializar sua loja",
        "rewardButtonText": "Aplicar",
        "sections": {
          "abandonedCart": {
            "title": "Recupere carrinhos abandonados",
            "description": "Crie e ative uma automação de carrinho abandonado para incentivar seus compradores indecisos.",
            "duration": "15 minutos",
            "content": {
              "title": "Retorno garantido",
              "descriptionOne": "Crie, edite e ative uma automação de carrinho abandonado para recuperar compras em sua loja virtual.",
              "descriptionTwo": "Lembre seus visitantes sobre os produtos que deixaram no carrinho e incentive-os a comprar.",
              "descriptionThree": "Inclua incentivos, descontos ou ofertas para motivar seus clientes a finalizar a compra. Recupere essa venda!",
            },
            "markCompleteText": "Marcar como concluído",
            "completedMessage": "Parabéns! Você já completou esta tarefa.",
            "actionText": "Criar automação",
            "moreText": "Leia mais sobre automations",
          },
          "recoverInactive": {
            "title": "Recupere compradores frequentes",
            "description": "Chame a atenção de compradores que pararam de visitá-lo, envie promoções ou descontos para recuperá-los.",
            "duration": "10 minutos",
            "content": {
              "title": "Reacenda a conexão",
              "descriptionOne": "Recupere clientes que estiveram inativos com uma automação, reavivando a conexão com sua marca.",
              "descriptionTwo": "Aproveite a oportunidade para oferecer ofertas irresistíveis e descontos exclusivos.",
              "descriptionThree": "Personalize mensagens para abordar as razões de sua inatividade, aumentando a probabilidade de recuperação.",
            },
            "markCompleteText": "Marcar como concluído",
            "completedMessage": "Parabéns! Você já completou esta tarefa.",
            "actionText": "Criar automação",
            "moreText": "Leia mais sobre automations",
          },
          "loyalty": {
            "title": "Transforme compradores em campeões",
            "description": "Incentive compradores por sua lealdade e frequência de compra para transformá-los em campeões da sua loja.",
            "duration": "15 minutos",
            "content": {
              "title": "Construindo conexões",
              "descriptionOne": "Automatize mensagens com descontos exclusivos após cada compra. Faça-os se sentirem especiais!",
              "descriptionTwo": "Crie um programa de fidelidade automático que recompense cada compra. Quanto mais compram, melhores são as recompensas!",
              "descriptionThree": "Automatize solicitações de avaliações após cada compra. Suas opiniões positivas são fundamentais!",
            },
            "markCompleteText": "Marcar como concluído",
            "completedMessage": "Parabéns! Você já completou esta tarefa.",
            "actionText": "Criar automação",
            "moreText": "Leia mais sobre automations",
          },
        },
      },
    },
  },
}
</i18n>

<style scoped>
::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  @apply bg-neutral-100;
  @apply my-16;
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-300;
  border-radius: 9999px;
}
</style>
