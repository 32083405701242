<template>
  <div class="border border-gray-200 bg-white sm:rounded-lg">
    <div class="px-6 pt-6">
      <h3 class="m-0 text-base font-medium leading-6 text-gray-900">{{ t("paymentMethod") }}</h3>
      <div v-if="showCVUTransfer">
        <CVUTransferDataPanel :allow-manual-generate-transfer-data="true" />
      </div>
      <div v-if="showCVUTransfer" class="w-full border-t border-gray-300" />
      <div class="my-10">
        <CreditCardEditor :gateway="gateway" :paymethod="paymethod" :disallow-delete="disallowDelete" mode="update" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type { PropType } from "vue";
import { useI18n } from "vue-i18n";
import CreditCardEditor from "@organisms/CreditCards/CreditCardEditor.vue";
import CVUTransferDataPanel from "@organisms/Plan/CVUTransferDataPanel/CVUTransferDataPanel.vue";

import { useSessionStore } from "@store";

defineProps({
  gateway: {
    type: String as PropType<"mp" | "pagouno" | "tnpagouno" | "stripeus" | "stripebr" | "stripemx">,
    required: false,
    default: "tnpagouno",
    validator: (value: string) => ["mp", "pagouno", "tnpagouno", "stripeus", "stripebr", "stripemx"].includes(value),
  },
  paymethod: {
    type: String,
    required: false,
    default: undefined,
  },
  disallowDelete: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { t } = useI18n();
const sessionStore = useSessionStore();
// const featuresStore = useFeaturesStore();

// const useTransferTN = computed(() => featuresStore.features?.USE_TRANSFER_TN === "1");
const showCVUTransfer = computed(() => sessionStore.session.account.country === "ar");
</script>

<i18n lang="json">
{
  "es": {
    "paymentMethod": "Método de pago"
  },
  "pt": {
    "paymentMethod": "Forma de pagamento"
  }
}
</i18n>
