// API
import { setLang, useRequests } from "@api/requests";

// Types
import type { AuthenticationService } from "@application/ports";
import type {
  LoginAPIResponse,
  ResetPasswordAPIResponse,
  LoginAccountRequiredResponse,
  ResetPasswordAccountRequiredResponse,
  UpdatePasswordAPIErrorResponse,
  // SignUpAPIResponse,
  LoginTiendanubeAPIResponse,
  SignUpAPIErrorResponse,
  SignupTiendanubeAPIResponse,
  // SignupTiendanubeAPIErrorResponse,
  SignUpAPIResponse,
  SignUpAPIData,
  UpdatePasswordAPIData,
  LoginTiendanubeAPIData,
  ResetPasswordAPIData,
  LoginAPIData,
  SignupTiendanubeAPIData,
} from "./authentication.types";

// Utils
import { ok, err } from "neverthrow";
import { ensureAxiosError } from "@helpers/error";
import { isAcceptedLanguage } from "@domain/language";

export const useAuthenticationService = (): AuthenticationService => {
  const requestAPIV2 = useRequests();

  const authenticationService: AuthenticationService = {
    async login(params) {
      try {
        const res = await requestAPIV2<LoginAPIResponse, LoginAPIData>({
          url: `login`,
          method: "post",
          disableNotifyErrors: true,
          allow401Error: true,
          data: {
            user: params.user,
            password: params.password,
            account: params?.account,
          },
          headers: {
            "X-Device-Id": params.deviceId,
          },
        });

        return ok({
          token: res.data.token,
          tokenExpiration: res.data.tokenExpiration,
          legacyResponse: res.data,
        });
      } catch (e) {
        const error = ensureAxiosError(e);

        const data = error.response?.data as LoginAccountRequiredResponse;

        const accountRequiredError = data.error.type === "ACCOUNT_REQUIRED";
        const accounts = data?.data;
        if (accountRequiredError && accounts) {
          return ok({
            accounts,
          });
        }

        const validationError = data.error.type === "VALIDATION_ERROR";
        if (validationError) {
          return err({
            type: "VALIDATION_ERROR",
            invalidFields: {
              user: data.error.validationErrors?.user,
              password: data.error.validationErrors?.password,
            },
          });
        }

        const unauthorizedError = data.error.type === "UNAUTHORIZED";
        if (unauthorizedError) {
          return err({
            type: "UNAUTHORIZED",
          });
        }

        const accountDisabledError = data.error.type === "ACCOUNT_DISABLED";
        if (accountDisabledError) {
          return err({
            type: "ACCOUNT_DISABLED",
          });
        }

        const passwordExpired = data.error.type === "PASSWORD_EXPIRED";
        if (passwordExpired) {
          return err({
            type: "PASSWORD_EXPIRED",
          });
        }

        throw error;
      }
    },
    async loginTiendanube(params) {
      const res = await requestAPIV2<LoginTiendanubeAPIResponse, LoginTiendanubeAPIData>({
        url: `login/tiendanube`,
        method: "post",
        disableNotifyErrors: true,
        allow401Error: true,
        data: {
          code: params.code,
        },
        headers: {
          "X-Device-Id": params.deviceId,
        },
      });

      if (res.data.result === "login") {
        return ok({
          result: "login",
          token: res.data.auth.token,
          tokenExpiration: res.data.auth.tokenExpiration,
          legacyResponse: res.data.auth,
        });
      }
      return ok(res.data);
    },
    async resetPassword(params) {
      try {
        await requestAPIV2<ResetPasswordAPIResponse, ResetPasswordAPIData>({
          url: `resetpassword`,
          method: "post",
          disableNotifyErrors: true,
          data: {
            user: params.user,
            account: params?.account,
          },
        });

        return ok(undefined);
      } catch (e) {
        const error = ensureAxiosError(e);

        const data = error.response?.data as ResetPasswordAccountRequiredResponse;

        const accountRequiredError = data.error.type === "ACCOUNT_REQUIRED";
        if (accountRequiredError) {
          return err({
            type: "ACCOUNT_REQUIRED",
          });
        }

        const validationError = data.error.type === "VALIDATION_ERROR";
        if (validationError) {
          return err({
            type: "VALIDATION_ERROR",
            invalidFields: {
              email: data.error.validationErrors?.username,
            },
          });
        }

        const unauthorizedError = data.error.type === "UNAUTHORIZED" || data.error.status === 404;
        if (unauthorizedError) {
          return err({
            type: "UNAUTHORIZED",
          });
        }

        throw error;
      }
    },
    async updatePassword(params) {
      try {
        const res = await requestAPIV2<LoginAPIResponse, UpdatePasswordAPIData>({
          url: `login`,
          method: "post",
          data: params,
          disableNotifyErrors: true,
        });

        return ok({
          token: res.data.token,
          tokenExpiration: res.data.tokenExpiration,
          legacyResponse: res.data,
        });
      } catch (e) {
        const error = ensureAxiosError(e);

        const data = error.response?.data as UpdatePasswordAPIErrorResponse;

        const validationError = data.error.type === "VALIDATION_ERROR";
        if (validationError) {
          return err({
            type: "VALIDATION_ERROR",
            invalidFields: {
              newPassword: data.error.validationErrors?.newPassword,
            },
          });
        }

        const unauthorizedError = data.error.type === "INVALID_REQUEST";
        if (unauthorizedError) {
          return err({
            type: "INVALID_REQUEST",
          });
        }

        throw error;
      }
    },
    async signup(params) {
      try {
        if (isAcceptedLanguage(params.lang)) {
          setLang(params.lang);
        }

        const res = await requestAPIV2<SignUpAPIResponse, SignUpAPIData>({
          url: `signup-new`,
          method: "post",
          disableNotifyErrors: true,
          data: {
            email: params.email,
            password: params.password,
            code: params.code,
            country: params.country,
            website: params.website,
            storeType: params.store,
            businessName: params.businessName,
          },
          headers: {
            "X-Device-Id": params.deviceId,
          },
        });

        return ok({
          token: res.data.token,
          tokenExpiration: res.data.tokenExpiration,
          legacyResponse: res.data,
        });
      } catch (e) {
        // TODO AUTH: new api
        const error = ensureAxiosError(e);

        const data = error.response?.data as SignUpAPIErrorResponse;

        const validationError = data.error.type === "VALIDATION_ERROR";
        if (validationError) {
          return err({
            type: "VALIDATION_ERROR",
            invalidFields: {
              code: data.error.validationErrors?.code,
              email: data.error.validationErrors?.email,
              name: data.error.validationErrors?.name,
              password: data.error.validationErrors?.password,
            },
          });
        }

        throw error;
      }
    },
    async signupTiendanube(params) {
      try {
        if (isAcceptedLanguage(params.lang)) {
          setLang(params.lang);
        }

        const res = await requestAPIV2<SignupTiendanubeAPIResponse, SignupTiendanubeAPIData>({
          url: `signup/tiendanube`,
          method: "post",
          data: {
            businessName: params.businessName,
            country: params.country,
            email: params.email,
            postalAddress: params.address,
            preinit: params.preinit,
            industry: params.industry,
            website: params.website,
            phone: params.phone,
            tnCreatedAt: params.tnCreatedAt,
            tnPlan: params.tnPlan,
          },
          headers: {
            "X-Device-Id": params.deviceId,
          },
          disableNotifyErrors: true,
        });

        return ok({
          token: res.data.token,
          tokenExpiration: res.data.tokenExpiration,
          legacyResponse: res.data,
        });
      } catch (e) {
        // TODO AUTH: new api
        const error = ensureAxiosError(e);

        // const data = error.response?.data as SignupTiendanubeAPIErrorResponse;

        // const validationError = data.error.type === "VALIDATION_ERROR";
        // if (validationError) {
        //   return err({
        //     type: "VALIDATION_ERROR",
        //     invalidFields: {
        //       code: data.error.validationErrors?.code,
        //       email: data.error.validationErrors?.email,
        //       name: data.error.validationErrors?.name,
        //       password: data.error.validationErrors?.password,
        //     },
        //   });
        // }

        throw error;
      }
    },
  };

  return authenticationService;
};
