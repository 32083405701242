var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='modal-dialog'>\n    <form data-form='apply-trial'>\n        <div class='modal-content'>\n            <div class='modal-header'>\n                <button type='button' class='close' data-dismiss='modal'>\n                    <span aria-hidden='true'>\n                        &times;\n                    </span>\n                </button>\n                <h4 class='modal-title'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"applyTrial")), depth0))
    + "\n                </h4>\n            </div>\n            <div class='modal-body'>\n                <div class='modal-body-padded'>\n                    <p>Al aplicar el Trial, se elimina el límite de contactos y la cuenta pasa a un plan por Packs.\n                        Luego de transcurrido los días de prueba, el trial finaliza y la cuenta vuelve al plan Freemium\n                        de 500 contactos.</p>\n                    <div class='form-group'>\n                        <label>Envíos</label>\n                        <input type='number' name='credits' placeholder='Emails' class='form-control' value='10000'\n                            max=\"50000\" min=\"1\" />\n                        <p class=\"input-legend\">Si ya está en trial, los envíos se acumulan con los disponibles.</p>\n                    </div>\n                    <div class='form-group'>\n                        <label>Días de prueba</label>\n                        <input type='number' name='days' placeholder='Días' class='form-control' value='15' max=\"90\"\n                            min=\"1\" />\n                        <p class=\"input-legend\">Los días se cuentan desde hoy. No se acumulan si ya está en trial. Max: 90 días.</p>\n                    </div>\n                </div>\n                <div class='modal-footer'>\n                    <button type='button' class='btn btn-link' data-dismiss='modal'>\n                        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n                    </button>\n                    <button type='submit' class='btn btn-primary'>\n                        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirm")), depth0))
    + "\n                    </button>\n                </div>\n            </div>\n        </div>\n    </form>\n</div>";
},"useData":true});